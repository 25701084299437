import { useEffect, useMemo, useState } from 'react'
import { PreviousBillingCycleWrapperProps } from './types'
import dayjs from 'dayjs'
import annotationPlugin from 'chartjs-plugin-annotation'
import { BarElement, CategoryScale, Chart as ChartJS, ChartData, LinearScale, Tooltip } from 'chart.js'
import { getBillingCycleChartDatasets } from './chartjs.config'
import PreviousBillingCycleChartMobile from './ChartMobile'
import PreviousBillingCycleChartDesktop from './ChartDesktop'
import { useTranslation } from 'react-i18next'
import { BillingCycleChartProps } from 'components/Charts/billing-cycle/types.ts'
import Totals from 'pages/App/consumption/your-consumption/components/totals-block/TotalsBlock.tsx'
import { DSChartColors } from 'types/colors.ts'
import { formatAmount } from 'utils/format.ts'

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, annotationPlugin)

const PreviousBillingCycleChart = ({ data, isTablet }: PreviousBillingCycleWrapperProps) => {
  // i18n
  const { t } = useTranslation('billing')

  // Local state
  const [activeDataPointIndex, setActiveDataPointIndex] = useState<number | null>(null)

  // Constants
  const months = data.months.map((bill) => dayjs(bill.datetime).toISOString())
  const extendedMonths = [...months, t('billingCycles.chart.legend.settlement')]
  const settlementColor = data.settlement <= 0 ? DSChartColors.GREEN : DSChartColors.ORANGE
  const isLastDataPoint = activeDataPointIndex === extendedMonths.length - 1
  const totalBilled = data.months.reduce((acc, { billedAmount }) => acc + billedAmount, 0)
  const totalActual = data.months.reduce((acc, { actualPrice }) => acc + actualPrice, 0)

  // Table data
  const chartData = useMemo<ChartData<'bar', (number | null)[], unknown>>(
    () => ({
      labels: extendedMonths,
      datasets: getBillingCycleChartDatasets(extendedMonths, data, isTablet)
    }),
    [data, months, isTablet]
  )

  const chartProps: Omit<BillingCycleChartProps, 'minMaxData'> = {
    chartData,
    labels: extendedMonths,
    setHoverDataPointIndex: setActiveDataPointIndex
  }

  // Reset active data point index if data is not available
  useEffect(() => {
    if (typeof activeDataPointIndex === 'number' && !data?.months[activeDataPointIndex]) {
      setActiveDataPointIndex(null)
    }
  }, [activeDataPointIndex, data?.months])

  return (
    <>
      <Totals listOnMobile>
        {/* BILLED */}
        <Totals.Block
          label={t('billingCycle.chart.billedInstalment')}
          indicator={{ color: DSChartColors.PINK, full: true }}
          addendum={typeof activeDataPointIndex === 'number' ? undefined : t('billingCycle.chart.total')}
        >
          {typeof activeDataPointIndex === 'number' && !!data?.months[activeDataPointIndex] ? (
            !isLastDataPoint && (
              <data value={data?.months[activeDataPointIndex]?.billedAmount}>
                € {formatAmount(data?.months[activeDataPointIndex]?.billedAmount)}
              </data>
            )
          ) : (
            <data value={totalBilled}>€ {formatAmount(totalBilled)}</data>
          )}
        </Totals.Block>

        <Totals.Block
          label={t('billingCycle.chart.actualInstalment')}
          indicator={{ color: DSChartColors.GREEN, stripped: true }}
          addendum={typeof activeDataPointIndex === 'number' ? undefined : t('billingCycle.chart.total')}
        >
          {typeof activeDataPointIndex === 'number' && !!data?.months[activeDataPointIndex] ? (
            !isLastDataPoint && (
              <data value={data?.months[activeDataPointIndex]?.actualPrice}>
                € {formatAmount(data?.months[activeDataPointIndex]?.actualPrice)}
              </data>
            )
          ) : (
            <data value={totalActual}>€ {formatAmount(totalActual)}</data>
          )}
        </Totals.Block>

        <Totals.Block label={t('billingCycle.chart.settlement')} indicator={{ color: settlementColor, full: true }}>
          {(typeof activeDataPointIndex !== 'number' || isLastDataPoint) && (
            <data value={data.settlement}>€ {formatAmount(data.settlement)}</data>
          )}
        </Totals.Block>
      </Totals>

      {isTablet ? <PreviousBillingCycleChartMobile {...chartProps} /> : <PreviousBillingCycleChartDesktop {...chartProps} />}
    </>
  )
}

export default PreviousBillingCycleChart
