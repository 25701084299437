import { useTranslation } from 'react-i18next'
import PageLayout from 'layouts/page-layout/PageLayout.tsx'
import { useStoreSelector } from 'hooks/store'
import Card from 'components/Card/Card.tsx'
import styles from './Move.module.scss'
import { routes } from 'types/routes.ts'
import Video from 'components/Video/Video.tsx'
import parse from 'html-react-parser'
import { Language } from 'store/app/types.ts'
import Link from 'components/Link/Link.tsx'
import { IS_BETA } from 'constants/envs.ts'
import { FaqSlug } from 'types/faq.ts'
import { useGetActiveReferralActionQuery } from 'store/queries/cms-api'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'
import { BlocksRenderer } from '@strapi/blocks-react-renderer'
import StepList from 'components/StepList/StepList.tsx'
import mixpanel from 'mixpanel-browser'
import { ContractFlowEvents, ReferralEvents } from 'types/tracking.ts'

const Move = () => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)

  // i18n
  const { t } = useTranslation(['move', 'referral'])

  // Redux queries
  const { data: activeAction, isLoading } = useGetActiveReferralActionQuery({ language })

  return (
    <PageLayout
      title={t('nav.move', { ns: 'common' })}
      classes={{ body: styles.move }}
      faqCategories={[FaqSlug.MOVE]}
      description={
        <p className={styles.subtitle}>
          {parse(
            t('info.description', {
              url: `https://files.boltenergie.be/energieovernamedocument/${
                language === Language.FR ? 'document-de-reprise-des-energies' : 'energieovernamedocument'
              }.pdf`
            })
          )}
        </p>
      }
      banner={
        IS_BETA
          ? {
              type: 'informative',
              message: t('betaBanner'),
              className: 'mb-400'
            }
          : undefined
      }
    >
      <div className={styles.grid}>
        <Card className={styles.content}>
          <StepList>
            <li>
              <strong>{t('info.steps.newAddress.title')}</strong>
              <p>{t('info.steps.newAddress.description')}</p>
            </li>
            <li>
              <strong>{t('info.steps.oldAddress.title')}</strong>
              <p>{t('info.steps.oldAddress.description')}</p>
            </li>
            <li>
              <strong>{t('info.steps.referral.title')}</strong>
              <p>{t('info.steps.referral.description')}</p>
            </li>
          </StepList>

          <p>{t('info.steps.extra')}</p>

          {!IS_BETA && (
            <Link href={routes.CONTRACTS} representation="button" onClick={() => mixpanel.track(ContractFlowEvents.OVERVIEW)}>
              {t('info.steps.button')}
            </Link>
          )}
        </Card>

        <Card className={styles['video-wrapper']}>
          <Video publicId={`website/videos/move-${language}`} thumbnailOffset="6" />
          <figcaption>{t('info.videoCaption')}</figcaption>
        </Card>

        <Card as="section" className={styles.referral}>
          {isLoading ? (
            <>
              <LoadingSkeleton.Title />
              <LoadingSkeleton.Paragraph lines={4} />
            </>
          ) : activeAction ? (
            <>
              <Card.Title as="h2" variant="h3" weight={700}>
                {activeAction.attributes.shortContent.title}
              </Card.Title>
              <BlocksRenderer content={activeAction.attributes.shortContent.description} />
            </>
          ) : (
            <>
              <Card.Title as="h2" variant="h3" weight={700}>
                {t('referral.title')}
              </Card.Title>
              <p>{t('referral.description')}</p>
            </>
          )}

          <Link
            loading={isLoading}
            representation="button"
            href={routes.REFERRAL_INFO}
            variant="layered"
            isFullwidth
            onClick={() => mixpanel.track(ReferralEvents.GO_TO_REFERRAL_PAGE, { withAction: !!activeAction })}
          >
            {t('referral.button')}
          </Link>
        </Card>
      </div>
    </PageLayout>
  )
}

export default Move
