import { Language } from 'store/app/types'

export type FormatCurrencyOptions = {
  maximumFractionDigits?: number
  language?: Language
}

export enum AddressFormats {
  FULL_SINGLE_LINE = 'fullSingleLine',
  FULL_TWO_LINES = 'fullTwoLines',
  STREET_NUMBER_BOX = 'streetNumberBox',
  POSTAL_CODE_TOWN = 'postalCodeTown'
}
