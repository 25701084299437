import { Table } from 'components/Table/Table'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './EventLog.module.scss'
import { transformAuthModel, transformEventLogType } from './utils'
import { UserTypes } from 'store/auth/types'
import { getUserTypeHomepage } from 'utils/user'
import { useStoreSelector } from 'hooks/store'
import { useGetUserEventLogsQuery } from 'store/queries/bolt-api/events'
import LoadingDots from 'components/LoadingDots/LoadingDots'
import Card from 'components/Card/Card.tsx'
import { routes } from 'types/routes.ts'
import Link from 'components/Link/Link.tsx'
import { selectContact } from 'store/contact/selectors.ts'
import PageLayout from 'layouts/page-layout/PageLayout.tsx'
import { EventLogCells, EventLogDataRow } from 'pages/App/event-log/types.ts'

const EventLog = () => {
  // Redux store
  const { userType } = useStoreSelector((store) => store.auth)
  const contactStore = useStoreSelector((store) => store.contact)
  const { email } = selectContact(contactStore)

  // Redux queries
  const { data: userEventLogsData, isLoading, isError } = useGetUserEventLogsQuery({ email }, { skip: userType !== UserTypes.SUPER_USER })

  // Local state
  const [search, setSearch] = useState<string>('')

  // React Router
  const navigate = useNavigate()

  /**
   * Triggered on first render
   * Redirects to home when not authenticated as superuser
   */
  useEffect(() => {
    if (userType !== UserTypes.SUPER_USER) navigate(getUserTypeHomepage(userType))
  }, [])

  const tableData: EventLogDataRow[] = useMemo(() => {
    return !userEventLogsData?.logs.length
      ? []
      : userEventLogsData?.logs
          .filter((eventLog) => {
            const { updaterEmail, userEmail, data } = eventLog

            return updaterEmail.includes(search) || userEmail.includes(search) || JSON.stringify(data).includes(search)
          })
          .map((eventLog, idx) => ({
            id: idx.toString(),
            uuid: crypto.randomUUID(),
            entries: {
              [EventLogCells.DATE]: {
                content: dayjs(eventLog.createdAt).format('DD/MM/YYYY HH:mm'),
                extraClasses: styles['td-text-top'],
                header: 'Date & time',
                data: eventLog.createdAt
              },
              [EventLogCells.TYPE]: {
                content: transformEventLogType(eventLog.type),
                extraClasses: styles['td-text-top'],
                header: 'Type',
                data: eventLog.type
              },
              [EventLogCells.UPDATER]: {
                content: eventLog.updaterEmail,
                extraClasses: styles['td-text-top'],
                header: 'Updater',
                data: eventLog.updaterEmail
              },
              [EventLogCells.AUTH_MODEL]: {
                content: transformAuthModel(eventLog.updaterAuthModel),
                extraClasses: styles['td-text-top'],
                header: 'Updater user type',
                data: eventLog.updaterAuthModel
              },
              [EventLogCells.USER]: {
                content: eventLog.userEmail,
                extraClasses: styles['td-text-top'],
                header: 'User',
                data: eventLog.userEmail
              },
              [EventLogCells.JSON]: {
                content: JSON.stringify(eventLog.data, null, '\t'),
                extraClasses: styles.json,
                header: 'Data',
                data: eventLog.data
              }
            }
          }))
  }, [userEventLogsData?.logs, search])

  return (
    <PageLayout title="Event log">
      <Link href={routes.ACCOUNT_SELECTION} leadingIcon="chevronLeft" className={styles.back}>
        Go back
      </Link>

      <Card className={styles['event-log']}>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="Search logs on emails & data..."
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
          />
        </div>

        <br />
        {isError && <div>Error, contact IT</div>}
        {userEventLogsData?.logs.length ? (
          <Table data={tableData} />
        ) : isLoading ? (
          <LoadingDots />
        ) : (
          <div>No event logs yet for this user</div>
        )}
      </Card>
    </PageLayout>
  )
}

export default EventLog
