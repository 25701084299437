import { Link } from 'react-router-dom'
import styles from './LanguageSwitchBar.module.scss'
import Logo from 'assets/logo.svg'
import { useStoreSelector } from 'hooks/store'
import { LanguageSwitchBarProps } from './types'
import { getUserTypeHomepage } from 'utils/user'
import LanguageSwitchButton from 'components/LanguageSwitchButton/LanguageSwitchButton'
import classNames from 'classnames'

const LanguageSwitchBar = ({ redirectUrl, compact, sticky }: LanguageSwitchBarProps) => {
  // REDUX STORE
  const { userType } = useStoreSelector((store) => store.auth)

  return (
    <nav
      className={classNames(styles['language-switch-bar'], {
        [styles.compact]: compact,
        [styles.sticky]: sticky
      })}
    >
      <Link to={redirectUrl || getUserTypeHomepage(userType)}>
        <img className={styles.logo} src={Logo} alt="Bolt Logo" />
      </Link>
      <LanguageSwitchButton />
    </nav>
  )
}

export default LanguageSwitchBar
