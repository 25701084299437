import { HeadingProps } from '@boltenergy-be/design-system/dist/components/heading/types'
import { PropsWithChildren } from 'react'

export type AvailablePaddings = 200 | 400 | 500 | 600 | 0
export type AvailableCardTags = 'div' | 'section' | 'article' | 'aside' | 'header' | 'nav' | 'button' | 'li' | 'fieldset'
export type CardProps<Tag extends keyof JSX.IntrinsicElements = 'div'> = {
  border?: boolean
  image?:
    | {
        src: string
        alt: string
        className?: string
        loading?: never
      }
    | {
        src?: never
        alt?: never
        className?: never
        loading: boolean
      }
  as?: keyof Pick<JSX.IntrinsicElements, Tag>
  color?: CardColors
  shadow?: boolean
  padding?: AvailablePaddings | { desktop: AvailablePaddings; mobile: AvailablePaddings }
} & JSX.IntrinsicElements[Tag]

export type CardTitleProps = PropsWithChildren<Partial<Pick<HeadingProps<'div'>, 'as' | 'variant' | 'className' | 'weight'>>>

export type CardHeaderProps = PropsWithChildren<JSX.IntrinsicElements['header']>

export enum CardColors {
  WHITE = 'white',
  PINK = 'pink',
  SUBDUED = 'subdued',
  BRAND = 'brand',
  YELLOW = 'yellow',
  LIGHT_BLUE = 'lightblue',
  SECONDARY = 'secondary',
  ORANGE = 'orange'
}
