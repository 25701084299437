import FormButtons from 'features/contracts/components/form-buttons/FormButtons'
import dayjs from 'dayjs'
import { DATE_FORMAT_REACT_DATEPICKER } from 'constants/constants'
import { useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TerminateDataInputs, TerminateDataProps, TerminateSteps } from 'features/contracts/terminate/types'
import IndexesFormsInputs from 'components/IndexesFormInputs/IndexesFormInputs'
import styles from '../../../Contracts.module.scss'
import ConfirmationModal from 'pages/App/consumption/edit-meter-readings/components/confirmation-modal/ConfirmationModal'
import { ConfirmationModalProps } from 'pages/App/consumption/edit-meter-readings/components/confirmation-modal/types'
import { useStoreSelector } from 'hooks/store'
import { ContractFlowTrackingTypes } from 'types/tracking.ts'
import DocumentUpload from 'features/contracts/components/document-upload/DocumentUpload.tsx'
import { selectCurrentContracts } from 'store/contact/selectors.ts'
import { DatePicker, Form, Heading } from '@boltenergy-be/design-system'
import classNames from 'classnames'

const MeterDetails = ({ setNextStep, terminateData }: TerminateDataProps) => {
  // REDUX STORE
  const { selected, billingContracts } = useStoreSelector((store) => store.contact)
  const { meterReadings } = useStoreSelector((store) => store.contracts)
  const { language } = useStoreSelector((store) => store.app)

  // Contracts
  const { serviceContracts } = selectCurrentContracts({ billingContracts, selected })

  // Local state
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false)
  const [confirmationState, setConfirmationState] = useState<ConfirmationModalProps['state']>('LOW')
  const [hasVeryHighUsage, setHasVeryHighUsage] = useState<boolean>(false)
  const [hasVeryLowUsage, setHasVeryLowUsage] = useState<boolean>(false)

  // i18n
  const { t } = useTranslation('contracts')

  // React Hook Form
  const reactHookForm = useForm<TerminateDataInputs>({
    defaultValues: {
      ...terminateData[TerminateSteps.METER_DETAILS],
      docUpload: terminateData[TerminateSteps.METER_DETAILS].docUpload ?? null
    }
  })
  const {
    control,
    handleSubmit,
    watch,
    getValues,
    formState: { errors }
  } = reactHookForm
  const watchDate = watch('date')

  // Constants
  const isDateInFuture = dayjs(watchDate).isAfter(dayjs())

  /**
   * Handles the form submit
   * @param {TerminateDataInputs} data
   */
  const onSubmit = (data: TerminateDataInputs) => {
    if (hasVeryLowUsage || hasVeryHighUsage) {
      setConfirmationState(hasVeryHighUsage && hasVeryLowUsage ? 'BOTH' : hasVeryLowUsage ? 'LOW' : 'HIGH')
      setIsConfirmationModalOpen(true)
    } else {
      setNextStep(data, TerminateSteps.REFERRAL)
    }
  }

  return (
    <section className={styles['terminate-section']}>
      <Heading as="h1" variant="h4">
        {t('terminate.meterDetails.title')}
      </Heading>
      <FormProvider {...reactHookForm}>
        <Form className={styles['narrow-grid']} onSubmit={handleSubmit(onSubmit)}>
          {/* DATE */}
          <Controller
            name="date"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => {
              return (
                <DatePicker
                  label={t('terminate.meterDetails.fields.date')}
                  error={errors.date?.message}
                  placeholderText={t('add.steps.meterDetails.form.contractStartDate.placeholder')}
                  language={language.toLowerCase() as 'nl' | 'fr'}
                  selected={dayjs(value).toDate()}
                  disabledKeyboardNavigation
                  maxDate={(terminateData[TerminateSteps.METER_DETAILS].digitalMeter ? dayjs().add(180, 'day') : dayjs()).toDate()}
                  minDate={dayjs().subtract(25, 'day').toDate()}
                  dateFormat={DATE_FORMAT_REACT_DATEPICKER}
                  onChange={onChange}
                />
              )
            }}
          />

          {/* DOC UPLOAD */}
          <DocumentUpload />

          {terminateData[TerminateSteps.METER_DETAILS].digitalMeter || isDateInFuture ? (
            <div className="form-group">
              <strong className={classNames(styles['fieldset-legend'], 'mb-none')}>{t('fields.meterReadings.label')}</strong>
              <div className={styles.description}>
                {t(
                  `fields.meterReadings.${terminateData[TerminateSteps.METER_DETAILS].digitalMeter ? 'messageDigitalMeter' : 'messageDateInFuture'}`
                )}
              </div>
            </div>
          ) : (
            <IndexesFormsInputs
              {...{ explicitRequired: !isDateInFuture, serviceContracts }}
              meterReadingsData={meterReadings?.data}
              setHigherCheck={(result) => setHasVeryHighUsage(result)}
              setLowerCheck={(result) => setHasVeryLowUsage(result)}
              showEan
              showLastMeterReadingByDgo
            />
          )}
          <FormButtons currentStep={TerminateSteps.METER_DETAILS} trackingId={ContractFlowTrackingTypes.TERMINATE} />
        </Form>
      </FormProvider>

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        setClose={() => setIsConfirmationModalOpen(false)}
        onSubmit={() => setNextStep(getValues(), TerminateSteps.REFERRAL)}
        state={confirmationState}
      />
    </section>
  )
}

export default MeterDetails
