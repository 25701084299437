import styles from './StackedInvoicesView.module.scss'
import { StackedInvoicesViewProps } from './types.ts'
import { InvoiceCells } from 'pages/App/billing/invoices/table-data/types.ts'
import { Icon } from '@boltenergy-be/design-system'
import { useTranslation } from 'react-i18next'
import mixpanel from 'mixpanel-browser'
import Link from 'components/Link/Link.tsx'
import { Fragment } from 'react'
import { InvoiceEvents } from 'types/tracking.ts'

const StackedInvoicesView = ({ data, handleClickRow, loading }: StackedInvoicesViewProps) => {
  const { t } = useTranslation('billing')

  return (
    <ul>
      {!!data.length &&
        data.map((row, rowIdx: number) => {
          return loading ? (
            <li key={row.uuid} className={styles['list-item']}>
              {Object.values(row.entries).map((cell, cellIdx) => (
                <Fragment key={cellIdx}>{cell.content}</Fragment>
              ))}
            </li>
          ) : (
            <li
              className={styles['list-item']}
              key={row.uuid}
              onClick={() => handleClickRow?.(rowIdx)}
              role="button"
              tabIndex={0}
              onKeyDown={(event) => {
                if (['Space', 'Enter'].includes(event.code)) handleClickRow?.(rowIdx)
              }}
            >
              <div className={styles.content}>
                <span className={styles.header}>
                  {row.entries[InvoiceCells.DOCUMENT_TYPE]?.content} • {row.entries[InvoiceCells.DATE]?.content}
                </span>

                {/* ADDRESS */}
                {row.entries[InvoiceCells.AMOUNT]?.extraContent && row.entries[InvoiceCells.AMOUNT]?.extraContent in row.entries && (
                  <address className={styles.address}>{row.entries[InvoiceCells.ADDRESS]?.content}</address>
                )}

                {/* AMOUNT + STATUS*/}
                <div className={styles.amount}>{row.entries[InvoiceCells.AMOUNT]?.content}</div>
              </div>

              <Icon name="chevronRight" className={styles.chevron} />

              {!row.entries[InvoiceCells.PAYMENT_ACTION]?.hidden && !!row.entries[InvoiceCells.PAYMENT_ACTION]?.data && (
                <Link
                  representation="button"
                  isFullwidth
                  className={styles.action}
                  as="a"
                  target="_blank"
                  href={row.entries[InvoiceCells.PAYMENT_ACTION].data as string}
                  onClick={(event) => {
                    event.stopPropagation()
                    mixpanel.track(InvoiceEvents.PAY_INVOICE)
                  }}
                >
                  {t('cells.payOnline', { ns: 'invoices' })}
                </Link>
              )}
            </li>
          )
        })}
    </ul>
  )
}

export default StackedInvoicesView
