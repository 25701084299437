import { InvoiceTableTabs, InvoiceTableDataRow, useInvoicesTableProps } from 'pages/App/billing/invoices/table-data/types.ts'
import { useStoreSelector } from 'hooks/store.ts'
import { filterDocumentsBasedOnTab, memoizedFilterOutDirectDebitBounce } from 'pages/App/billing/invoices/table-data/utils.tsx'
import { useGetCustomerAccountingQuery } from 'store/queries/bolt-api/customers'
import {
  getInvoiceTableData,
  getSuperUserInvoiceTableData,
  getTransactionTableData
} from 'pages/App/billing/invoices/table-data/tableData.tsx'
import { CustomerInvoice, CustomerPayment } from 'types/types.ts'
import useWindowSize from 'hooks/useWindowSize.tsx'
import { SMALL_DESKTOP_BREAKPOINT } from 'constants/viewport.ts'

/**
 * Returns the table 'headers' and 'rows' for a given route.
 * @param {useInvoicesTableProps} props
 */
const useInvoicesTable = ({ tab }: useInvoicesTableProps): InvoiceTableDataRow[] => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)
  const { selected, accounts } = useStoreSelector((store) => store.contact)
  const { data } = useGetCustomerAccountingQuery({
    customerNumber: selected.account
  })

  // constants
  const hasMultipleContracts = selected.account in accounts ? accounts[selected.account].billingContracts.length > 1 : false
  const filteredDocumentsByTypeBasedOnRoute = filterDocumentsBasedOnTab({ tab, documents: data?.documents ?? [] })
  const usesDirectDebit = !!accounts[selected.account]?.paymentDetails?.directDebit
  const filteredDocuments = memoizedFilterOutDirectDebitBounce({
    documents: filteredDocumentsByTypeBasedOnRoute,
    usesDirectDebit,
    tab
  })

  // Window size
  const { isSmaller: isTabletOrMobile } = useWindowSize(SMALL_DESKTOP_BREAKPOINT)

  switch (tab) {
    case InvoiceTableTabs.SUPER_USER_CUSTOMER:
    case InvoiceTableTabs.SUPER_USER_SUPPLIER: {
      return getSuperUserInvoiceTableData({
        documents: filteredDocuments,
        isTabletOrMobile,
        language,
        showAddress: hasMultipleContracts,
        usesDirectDebit
      })
    }

    case InvoiceTableTabs.OUTSTANDING_INVOICES:
    case InvoiceTableTabs.ALL_INVOICES: {
      return getInvoiceTableData({
        documents: filteredDocuments as CustomerInvoice[],
        isTabletOrMobile,
        language,
        showAddress: hasMultipleContracts,
        usesDirectDebit
      })
    }

    case InvoiceTableTabs.ALL_TRANSACTIONS: {
      return getTransactionTableData({
        documents: filteredDocuments as CustomerPayment[],
        isTabletOrMobile,
        language,
        showAddress: false,
        usesDirectDebit
      })
    }

    default:
      return []
  }
}

export default useInvoicesTable
