import { AddContractSteps } from 'features/contracts/add/types.ts'
import contractStyles from 'features/contracts/Contracts.module.scss'
import styles from './ReferralStep.module.scss'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import FormButtons from 'features/contracts/components/form-buttons/FormButtons.tsx'
import parse from 'html-react-parser'
import { EMAIL } from 'constants/regex.ts'
import { ReferralFormInputs, ReferralStepProps } from './types.ts'
import { Form, Heading, TextField } from '@boltenergy-be/design-system'

const ReferralStep = ({ setCurrentStep, setNextStep, defaultValues, isTerminate }: ReferralStepProps) => {
  // i18n
  const { t } = useTranslation('contracts')

  // React Hook Form
  const { register, handleSubmit, watch, formState } = useForm<ReferralFormInputs>({
    defaultValues,
    mode: 'onBlur'
  })
  const { errors } = formState
  const watchAllFields = watch()
  const isFormEmpty = Object.values(watchAllFields).every((input) => !input)

  // Constants
  const translationPrefix = isTerminate ? 'terminate.referral' : 'add.steps.referral'

  /**
   * Handle form submission, only save the inputs if there are any given
   * @param {ReferralFormInputs} inputs
   */
  const onSubmit = (inputs: ReferralFormInputs) => {
    if (Object.values(inputs).every((input) => !input)) {
      setCurrentStep()
    } else {
      setNextStep(inputs)
    }
  }

  return (
    <section className={styles['referral-step']}>
      <header>
        <Heading as="h1" variant="h4" className={contractStyles['referral-title']}>
          {parse(t(`${translationPrefix}.title`))}
        </Heading>
        <p className="mt-200">{t(`${translationPrefix}.description`)}</p>
      </header>

      <Form onSubmit={handleSubmit(onSubmit)}>
        {/* FIRST NAME */}
        <TextField
          id="firstName"
          label={t(`${translationPrefix}.form.firstName`)}
          {...register('firstName', { required: !isFormEmpty })}
          error={errors?.firstName?.message}
          grid="full"
        />

        {/* LAST NAME */}
        <TextField
          id="lastName"
          label={t(`${translationPrefix}.form.lastName`)}
          {...register('lastName', { required: !isFormEmpty })}
          error={errors?.lastName?.message}
          grid="full"
        />

        {/* EMAIL */}
        <TextField
          id="email"
          label={t(`${translationPrefix}.form.email`)}
          {...register('email', { pattern: { value: EMAIL, message: t('invalid.email', { ns: 'validation' }) }, required: !isFormEmpty })}
          error={errors?.email?.message}
          type="email"
          grid="full"
        />

        {errors && Object.values(errors)?.some(({ type }) => type === 'required') && (
          <p className="help-block text-negative">{t('invalid.requiredWhenNotAllEmpty', { ns: 'validation' })}</p>
        )}

        <FormButtons currentStep={AddContractSteps.REFERRAL} />
      </Form>
    </section>
  )
}

export default ReferralStep
