import { InvoiceType, InvoiceTypeTranslationKey } from 'types/types.ts'

export const InvoiceTypeTranslationKeys: { [key in InvoiceType]: InvoiceTypeTranslationKey } = {
  [InvoiceType.INSTALMENT]: 'deposit',
  [InvoiceType.INSTALMENT_CREDITNOTE]: 'deposit',
  [InvoiceType.SETTLEMENT]: 'settlement',
  [InvoiceType.SETTLEMENT_CREDITNOTE]: 'settlement',
  [InvoiceType.FINAL_SETTLEMENT]: 'finalSettlement',
  [InvoiceType.FINAL_SETTLEMENT_CREDITNOTE]: 'finalSettlement',
  [InvoiceType.INJECTION_SETTLEMENT_PRO]: 'injectionSettlementPro',
  [InvoiceType.INJECTION_SETTLEMENT_PRO_CREDITNOTE]: 'injectionSettlementPro',
  [InvoiceType.INJECTION]: 'injection',
  [InvoiceType.INJECTION_CREDITNOTE]: 'injection',
  [InvoiceType.BUDGET_METER]: 'budgetMeter',
  [InvoiceType.BUDGET_METER_CREDITNOTE]: 'budgetMeter',
  [InvoiceType.RECTIFICATION]: 'rectification',
  [InvoiceType.RECTIFICATION_CREDITNOTE]: 'rectification',
  [InvoiceType.CANCEL]: 'annulation',
  [InvoiceType.CANCEL_CREDITNOTE]: 'annulation',
  [InvoiceType.DIVERSE]: 'additionalInvoice',
  [InvoiceType.DIVERSE_CREDITNOTE]: 'additionalCompensation',
  [InvoiceType.MANUAL_CREDITNOTE]: 'friendsDiscount',
  [InvoiceType.CASHBACK]: 'cashback',
  [InvoiceType.PURCHASE_INJECTION]: 'injection',
  [InvoiceType.PURCHASE_INJECTION_CREDITNOTE]: 'injection',
  [InvoiceType.PURCHASE_INJECTION_PRO]: 'injection',
  [InvoiceType.PURCHASE_INJECTION_PRO_CREDITNOTE]: 'injection',
  [InvoiceType.MISC_INVOICE]: 'miscellaneousInvoice',
  [InvoiceType.MISC_CREDITNOTE]: 'miscellaneousCreditnote'
}
