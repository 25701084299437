import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'
import Link from 'components/Link/Link.tsx'

const NeedHelp = () => {
  const { t } = useTranslation('auth')

  return (
    <p>
      {parse(t('subSections.needHelp.email'))}
      <Link as="a" href="mailto:info@boltenergie.be" variant="secondary">
        e-mail
      </Link>{' '}
      {t('subSections.needHelp.orCall')}{' '}
      <Link as="a" href="tel:028993300" variant="secondary">
        02 899 33 00
      </Link>
    </p>
  )
}

export default NeedHelp
