import { roundToTwoDecimals } from 'utils/number'
import { EnergyType, ServiceContract } from 'types/contracts.ts'

/**
 * Adds the given newInstalment to the given contract
 *
 * @template ET
 * @param {ServiceContract<ET>} contract
 * @param {number} newInstalment
 * @returns {ServiceContract<ET>}
 */
export const addNewInstalmentToContract = <ET extends EnergyType>(
  contract: ServiceContract<ET>,
  newInstalment: number
): ServiceContract<ET> => {
  return {
    ...contract,
    detail: {
      ...contract.detail,
      instalment: Number(roundToTwoDecimals(newInstalment))
    }
  }
}
