import styles from './TerminateSideCard.module.scss'
import TerminateCardContent from './TerminateCardContent.tsx'
import Card from 'components/Card/Card.tsx'
import useWindowSize from 'hooks/useWindowSize.tsx'
import { useStoreSelector } from 'hooks/store.ts'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Icon } from '@boltenergy-be/design-system'
import { selectCurrentContracts } from 'store/contact/selectors.ts'
import Modal from 'components/Modals/Modal/Modal.tsx'
import { formatAddress } from 'utils/user.ts'

const TerminateSideCard = () => {
  // Redux
  const contactStore = useStoreSelector((store) => store.contact)

  // i18n
  const { t } = useTranslation('contracts')

  // Window size
  const { isTablet } = useWindowSize()

  // Local state
  const [showMobileDrawer, setShowMobileDrawer] = useState<boolean>(false)

  // Constants
  const billingContract = selectCurrentContracts(contactStore)
  const { address } = billingContract.serviceContracts.electricity.deliveryPoint

  return isTablet ? (
    <>
      <Card as="button" className={styles['sticky-footer-card']} onClick={() => setShowMobileDrawer(true)}>
        <strong>{t('terminate.sideCard.address')}</strong>
        <address className={styles.address}>{formatAddress(address)}</address>

        <Icon name="chevronUp" className={styles['toggle-icon']} />
      </Card>

      <Modal className={styles.drawer} isOpen={showMobileDrawer} setClose={() => setShowMobileDrawer(false)}>
        <TerminateCardContent selectedContracts={billingContract.serviceContracts} />
      </Modal>
    </>
  ) : (
    <Card as="aside" className={styles.card} border={false}>
      <TerminateCardContent selectedContracts={billingContract.serviceContracts} />
    </Card>
  )
}

export default TerminateSideCard
