import { UIMatch } from 'react-router-dom'

export type CommonLayoutProps = {
  classes?: {
    header?: string
    body?: string
  }
}

export enum Layouts {
  EDIT_LAYOUT = 'edit-layout',
  PAGE_LAYOUT = 'page-layout'
}

export type LayoutMatch = UIMatch<unknown, { layout?: Layouts }>
