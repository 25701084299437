import { BillShock } from 'types/billShock'
import { CHART_COLORS } from '../constants'
import pattern from 'patternomaly'
import { ChartColorKeys } from 'components/Charts/types.ts'

/**
 * Function to update proposed instalment to 20 if it's a negative amount
 *
 * @param {BillShock} billShockData
 * @returns {BillShock}
 */
export const updateProposedInstalmentBillShockData = (billShockData: BillShock): BillShock => {
  const futureMonths = billShockData.future.map((month) => {
    return {
      ...month,
      proposedInstalment: month.proposedInstalment < 20 ? 20 : month.proposedInstalment
    }
  })

  return {
    ...billShockData,
    future: futureMonths,
    instalment: {
      ...billShockData.instalment,
      minimum: billShockData.instalment.minimum < 20 ? 20 : billShockData.instalment.minimum,
      proposed: billShockData.instalment.proposed < 20 ? 20 : billShockData.instalment.proposed
    }
  }
}

/**
 * Get settlement color based on settlement total amount
 * @param {number} settlementTotal
 * @returns {string}
 */
export const getSettlementColor = (settlementTotal: number): string => (settlementTotal <= 0 ? CHART_COLORS.green : CHART_COLORS.orange)

/**
 * Create canvas stripped canvas pattern with given color
 * @param {ChartColorKeys} color
 * @returns {CanvasPattern}
 */
export const getChartPattern = (color: ChartColorKeys): CanvasPattern => {
  return pattern.draw('diagonal-right-left', CHART_COLORS.background, CHART_COLORS[color], 8)
}
