import { AddContractSteps } from 'features/contracts/add/types.ts'
import { TerminateSteps } from 'features/contracts/terminate/types.ts'

export enum ContractFlows {
  ADD = 'add',
  TERMINATE = 'terminate'
}

export type FlowSteps = AddContractSteps | TerminateSteps

export type ContractsLayoutProps<S extends FlowSteps | undefined> = {
  label: string
} & (
  | {
      flow: undefined
      steps?: never
      currentStep?: never
      setCurrentStep?: never
      trackingId?: never
    }
  | {
      flow: ContractFlows
      steps: S[]
      currentStep: S
      setCurrentStep: (step: S) => void
      trackingId?: string
    }
)
