import OverviewCard from '../OverviewCard.tsx'
import { routes } from 'types/routes.ts'
import { useStoreSelector } from 'hooks/store.ts'
import { selectCurrentAccount } from 'store/contact/selectors.ts'
import { useGetCustomerAccountingQuery } from 'store/queries/bolt-api/customers'
import { INVOICE_STATUS_CONFIG } from 'pages/App/billing/invoices/status-banner/constants.ts'
import { getInvoiceStatus } from 'pages/App/billing/invoices/status-banner/utils.ts'
import dayjs from 'dayjs'
import { DocumentType } from 'types/types.ts'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'
import { formatCurrency } from 'utils/format.ts'
import { InvoicesStatus } from 'pages/App/billing/invoices/status-banner/types.ts'
import styles from 'pages/App/overview/overview-cards/invoices/InvoicesOverviewCard.module.scss'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'
import { CardColors } from 'components/Card/types.ts'

const InvoicesOverviewCard = () => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)
  const { selected, accounts } = useStoreSelector((store) => store.contact)
  const account = selectCurrentAccount({ accounts, selected })

  // Redux Queries
  const { data, isLoading, isFetching, isError } = useGetCustomerAccountingQuery(
    { customerNumber: selected.account },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      skip: !selected.account
    }
  )

  // i18n
  const { t } = useTranslation('invoices')

  // Constants
  const loading = !data && (isLoading || isFetching)
  const usesDirectDebit = !!account?.paymentDetails?.directDebit
  const expiredInvoices = data?.documents.filter((document) => {
    if (document.documentType !== DocumentType.INVOICE) return false
    const invoiceDueDate = dayjs(document.dueDate)
    return dayjs().isAfter(invoiceDueDate.add(4, 'days'), 'day') && !document.paid
  })

  const status = getInvoiceStatus({
    balance: Number(data?.balance),
    usesDirectDebit,
    hasExpiredInvoices: !!expiredInvoices?.length
  })

  if (
    data?.hasBddubDocuments ||
    isError ||
    status === InvoicesStatus.BREAK_EVEN ||
    (usesDirectDebit && status !== InvoicesStatus.DIRECT_DEBIT_WITH_UNPAID_INVOICES)
  ) {
    return null
  }

  return (
    <OverviewCard
      isLoading={loading}
      color={isLoading ? CardColors.WHITE : INVOICE_STATUS_CONFIG[status]?.color}
      button={{
        label: t('overview.invoices.button', { ns: 'overview' }),
        href: routes.BILLING_INVOICES
      }}
      className={styles.card}
    >
      {loading ? (
        <LoadingSkeleton.Paragraph lines={2} />
      ) : (
        <OverviewCard.Title variant="h5" weight={400}>
          {parse(
            t(`invoicesStatusBanner.${INVOICE_STATUS_CONFIG[status].textKey}.title`, {
              amount: formatCurrency(Number(data?.balance), { language })
            })
          )}
        </OverviewCard.Title>
      )}
    </OverviewCard>
  )
}

export default InvoicesOverviewCard
