import { PriceHistory } from 'store/queries/bolt-api/contracts/types.ts'

/**
 * Get the indexes of the lowest prices in the data array
 * @param {PriceHistory[]} data
 * @param {number} amountOfIndexes
 * @returns {number[]}
 */
export const getIndexesOfLowestOrHighestPrices = (data: PriceHistory[], amountOfIndexes: number, identifier: 'high' | 'low'): number[] => {
  if (!data.length) return []

  // create a copy of the original array to avoid modifying it
  const sortedData = [...data]

  // Sort the array based on price in ascending order
  sortedData.sort((a, b) => (identifier === 'low' ? a.price - b.price : b.price - a.price))

  // Get the indexes of the first four elements (lowest prices)
  const indexes: number[] = []
  const limit = Math.min(amountOfIndexes, sortedData.length)
  for (let i = 0; i < limit; i++) {
    const index = data.findIndex((item) => item === sortedData[i])
    indexes.push(index)
  }

  return indexes
}
