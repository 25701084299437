import styles from './FriendStatus.module.scss'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { FriendStatusProps } from 'pages/App/referral/components/FriendStatus/types'
import dayjs from 'dayjs'
import { Heading, Icon } from '@boltenergy-be/design-system'
import { DEFAULT_REFERRAL_AMOUNT } from 'constants/referral.ts'
import { useStoreSelector } from 'hooks/store.ts'

const FriendStatus = ({ referral }: FriendStatusProps) => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)

  // i18n
  const { t } = useTranslation('referral')

  // constants
  const isClient = !referral.pending
  const isOlderReferral = dayjs().subtract(1, 'week').isAfter(dayjs(referral.createdAt))

  /**
   * Get the correct translation key depending on whether or not referral comes from move
   * @returns {string}
   */
  const getTranslationKey = () => {
    if (isClient) {
      return referral.simulationSalesOffice === 'Move Process' ? 'referrals.list.clientFromMove' : 'referrals.list.isClient'
    }
    return isOlderReferral ? 'referrals.list.notClient' : 'referrals.list.notClientAlt'
  }

  const formatDate = dayjs(referral?.closeDate).locale(language).format('DD MMMM YYYY')

  return (
    <li className={styles['friend-status']}>
      {isClient ? (
        <div className={styles['face-happy']}>
          <Icon name="faceHappy" />
        </div>
      ) : (
        <div className={styles['face-wow']}>
          <Icon name="faceWow" />
        </div>
      )}
      <div className={styles['friend-status-overview']}>
        <Heading as="h3" variant="h6" className={classNames(styles['friend-name'], styles['extra-bold'])}>
          {referral.firstName} {referral.lastName}
        </Heading>
        <p className={classNames(styles['friend-status-description'])}>{t(getTranslationKey(), '', { date: formatDate })}</p>
        {isClient && (
          <p className={classNames(styles['friend-status-result'])}>
            {t('friends.blocks.friendsList.list.reward', '', { euro: referral?.reward ?? DEFAULT_REFERRAL_AMOUNT })}
          </p>
        )}
      </div>
    </li>
  )
}

export default FriendStatus
