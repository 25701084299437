import { Button, Heading, InlineMessage, Drawer } from '@boltenergy-be/design-system'
import DataBlock from 'features/contracts/add/components/DataBlock/DataBlock'
import { useTranslation } from 'react-i18next'
import styles from './NewCaseDrawer.module.scss'
import { useEffect, useState } from 'react'
import { NewCaseDrawerProps } from './types'
import { useCreateOpportunityCaseMutation } from 'store/queries/bolt-api/contacts'
import { useForm } from 'react-hook-form'
import { OpportunityCase } from 'types/sales'
import classNames from 'classnames'
import { popSuccessToast, popErrorToast } from '@boltenergy-be/design-system'

const NewCaseDrawer = ({
  contactId,
  accountId,
  opportunityId,
  customerName,
  salesParameters,
  drawerOpen,
  setDrawerOpen
}: NewCaseDrawerProps) => {
  //i18n
  const { t } = useTranslation('sales')

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<OpportunityCase>()

  // Local state
  const [customer, setCustomer] = useState<string>(customerName)

  // Mutation
  const [createCase, result] = useCreateOpportunityCaseMutation()

  // Handle form submit
  const onSubmit = (data: OpportunityCase) => {
    const { subject, message } = data
    createCase({ accountId, contactId, opportunityId, caseContent: { subject, message } })
    reset()
  }

  // Only close edit view when we are certain the data has been updated & show toast messages
  useEffect(() => {
    if (result.isSuccess) {
      popSuccessToast(t('details.toast.successDrawer'))
    } else if (result.isError) {
      popErrorToast(t('salesError', { ns: 'common' }))
    }
    if (result.isSuccess || result.isError) {
      setDrawerOpen(false)
    }
  }, [result])

  return (
    <Drawer isOpen={drawerOpen} onRequestClose={() => setDrawerOpen(false)}>
      <section className={styles.drawer}>
        <Heading as="h2" variant="h4">
          {t('details.simulation.drawer.newCase')}
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DataBlock label={t('details.simulation.drawer.customer')}>
            <input
              type="text"
              value={customer}
              onChange={(e) => {
                setCustomer(e.target.value)
              }}
              disabled={true}
            />
          </DataBlock>
          <DataBlock label={t('details.simulation.drawer.subject')}>
            <select
              className={classNames('form-control', { [styles.error]: errors.subject })}
              {...register('subject', { required: true, validate: (value) => value !== t('selectOption', { ns: 'common' }) })}
              defaultValue={t('selectOption', { ns: 'common' })}
            >
              <option disabled>{t('selectOption', { ns: 'common' })}</option>
              {salesParameters?.map((parameter) => <option key={parameter.value}>{parameter.value}</option>)}
            </select>
            {errors.subject && errors.subject.type === 'required' && (
              <InlineMessage type="negative">{t('required', { ns: 'validation' })}</InlineMessage>
            )}
          </DataBlock>
          <DataBlock label={t('details.simulation.drawer.question')}>
            <textarea
              {...register('message', { required: true })}
              className={classNames(styles['message-input'], 'form-control', { [styles.error]: errors.subject })}
              maxLength={250}
            />
            {errors.message && errors.message.type === 'required' && (
              <InlineMessage type="negative">{t('required', { ns: 'validation' })}</InlineMessage>
            )}
          </DataBlock>
          <Button className={styles.submit} loading={result.isLoading}>
            {t('details.simulation.drawer.send')}
          </Button>
        </form>
      </section>
    </Drawer>
  )
}

export default NewCaseDrawer
