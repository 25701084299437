import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  LoginPayload,
  LoginResponseBody,
  LoginSuccessPayload,
  RegisterPayload,
  RejectValue,
  RequestNewPasswordPayload,
  ResetPasswordPayload
} from './types'
import { setUserType } from 'store/auth/slice'
import { setSessionUserType } from 'utils/userType'
import { UserTypes } from 'store/auth/types'
import { getAxiosRequestConfig, newRequest } from 'utils/request'
import { Methods } from 'types/request'
import { AuthTokens } from 'types/auth'
import { setIsAuthenticated, setIsSuperUser, setTokens } from 'utils/localStorageService'
import { activateBetaMode } from 'store/app/slice'
import { smr3BetaSuperUsers } from 'pages/App/consumption/smrBetaUsers'
import { determineUserTypeAuthRootPath } from 'store/auth/thunks/utils.ts'

export const login = createAsyncThunk<LoginSuccessPayload, LoginPayload, RejectValue>(
  'auth/login',
  async ({ email, password, userType }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setUserType(userType))
      setSessionUserType(userType)

      let url: string

      switch (userType) {
        case UserTypes.SALES:
          url = '/auth/sales/login'
          break
        case UserTypes.SUPER_USER:
          url = '/auth/internal/login'
          break
        default:
          url = '/auth/login'
      }

      const { success, data, message } = await newRequest<LoginResponseBody>(
        getAxiosRequestConfig(Methods.POST, url, {
          email,
          password,
          asPortalSuperUser: userType === UserTypes.SUPER_USER
        })
      )

      if (!success || !data?.tokens) {
        return thunkAPI.rejectWithValue(message ?? 'login')
      }

      // Save the tokens
      setIsAuthenticated(true, userType)
      setTokens(data.tokens as AuthTokens, userType)

      // Set superuser state
      if (userType === UserTypes.SUPER_USER) {
        setIsSuperUser(true)

        if (smr3BetaSuperUsers.includes(email)) {
          thunkAPI.dispatch(activateBetaMode())
        }
      }

      return { email }
    } catch (err) {
      const { message } = err as Error
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const register = createAsyncThunk<boolean, RegisterPayload, RejectValue>('auth/register', async ({ email }, thunkAPI) => {
  try {
    thunkAPI.dispatch(setUserType(UserTypes.CUSTOMER))
    setSessionUserType(UserTypes.CUSTOMER)

    const { success, message } = await newRequest(getAxiosRequestConfig(Methods.POST, '/auth/register', { email }))

    if (!success) {
      return thunkAPI.rejectWithValue(message ?? 'register')
    }

    return success
  } catch (err) {
    const { message } = err as Error
    return thunkAPI.rejectWithValue(message)
  }
})

export const requestNewPassword = createAsyncThunk<boolean, RequestNewPasswordPayload, RejectValue>(
  'auth/requestNewPassword',
  async ({ email, userType }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setUserType(userType))
      setSessionUserType(userType)

      const { success, message } = await newRequest(
        getAxiosRequestConfig(Methods.POST, `${determineUserTypeAuthRootPath(userType)}/request-new-password`, { email })
      )

      if (!success) {
        return thunkAPI.rejectWithValue(message ?? 'requestNewPassword')
      }

      return success
    } catch (err) {
      const { message } = err as Error
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const resetPassword = createAsyncThunk<boolean, ResetPasswordPayload, RejectValue>(
  'auth/resetPassword',
  async ({ token, password, userType }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setUserType(userType))
      setSessionUserType(userType)

      const { success, message } = await newRequest(
        getAxiosRequestConfig(Methods.POST, `${determineUserTypeAuthRootPath(userType)}/reset-password`, {
          token,
          password
        })
      )

      if (!success) {
        return thunkAPI.rejectWithValue(message ?? 'resetPassword')
      }

      return success
    } catch (err) {
      const { message } = err as Error
      return thunkAPI.rejectWithValue(message)
    }
  }
)
