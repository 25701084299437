import { Icon } from '@boltenergy-be/design-system'
import styles from './NavigationItemContent.module.scss'
import classNames from 'classnames'
import { NavigationItemContentProps } from './types.ts'
import Tag from 'components/Tag/Tag.tsx'

const NavigationItemContent = ({ icon, text, isMore, iconClass, tag }: NavigationItemContentProps) => (
  <>
    {Array.isArray(icon) ? (
      <div className={styles.icons}>
        {icon.map((i) => (
          <Icon key={i} name={i} className={classNames(styles.icon, iconClass)} />
        ))}
      </div>
    ) : (
      <Icon name={icon} className={classNames(styles.icon, iconClass)} />
    )}
    <span className={classNames(styles['text-content'], 'truncate', { [styles.more]: isMore })}>{text}</span>
    {tag && (
      <Tag color={tag.color} size="small">
        {tag.text}
      </Tag>
    )}
    {isMore && <Icon name="chevronRight" className={styles.chevron} />}
  </>
)

export default NavigationItemContent
