import dayjs, { ManipulateType } from 'dayjs'
import { NavigationItem, NavigationItemType } from 'components/Charts/consumption-navigation/types.ts'
import { PreviousBillingCyclePeriod } from 'types/billShock.ts'
import { ProductType } from 'types/types.ts'
import { t } from 'i18next'
import { ContractStatus, EnergyType, Granularity, UnknownServiceContract } from 'types/contracts.ts'
import { getArrayOfFormattedDatesBetweenDates } from 'utils/date.ts'
import { GranularityOptions, VolumeSearchQueries } from './types.ts'
import { memoize } from 'proxy-memoize'

/**
 * Generates the from & to value for the given params
 *
 * @param {string} date
 * @param {dayjs.ManipulateType} dayjsUnit
 * @returns {Pick<VolumeSearchQueries, 'from' | 'to'>}
 */
export const generateFromAndToValue = (date: string, dayjsUnit: ManipulateType): Pick<VolumeSearchQueries, 'from' | 'until'> => {
  return {
    from: dayjs(date).startOf(dayjsUnit),
    until: dayjs(date).endOf(dayjsUnit)
  }
}

/**
 * Generates the granularity navigation items based on the given electricityContract
 *
 * @param {UnknownServiceContract} contract
 * @returns {GranularityOptions}
 */
export const generateGranularityNavigationItems = (contract: UnknownServiceContract): GranularityOptions => {
  const endDate =
    contract.detail.status === ContractStatus.TERMINATED && dayjs().isAfter(dayjs(contract.detail.effectiveEndDate))
      ? dayjs(contract.detail.effectiveEndDate)
      : dayjs()

  const defaultGranularity = {
    [Granularity.MONTH]: {
      text: t('consumption:chart.header.pagination.year'),
      id: Granularity.MONTH,
      type: NavigationItemType.GRANULARITY,
      periods: getArrayOfFormattedDatesBetweenDates(contract.detail.contractualStartDate, endDate.endOf('year').toISOString(), 'year').map(
        (yearISO) => ({
          label: dayjs(yearISO).format('YYYY'),
          defaultSelected: dayjs(yearISO).format('YYYY') === endDate.year().toString(),
          value: yearISO
        })
      )
    }
  }

  return contract.detail.energyType === EnergyType.ELECTRICITY && contract.detail.dynamicTariff
    ? {
        ...defaultGranularity,
        [Granularity.DAY]: {
          text: t('consumption:chart.header.pagination.month'),
          id: Granularity.DAY,
          type: NavigationItemType.GRANULARITY,
          periods: getArrayOfFormattedDatesBetweenDates(
            contract.detail.contractualStartDate,
            endDate.endOf('month').toISOString(),
            'month'
          ).map((monthISO) => ({
            label: dayjs(monthISO).format('MMM YYYY'),
            defaultSelected: dayjs(monthISO).format('MMM YYYY') === endDate.format('MMM YYYY'),
            value: monthISO
          }))
        },
        [Granularity.HOUR]: {
          text: t('consumption:chart.header.pagination.day'),
          id: Granularity.HOUR,
          type: NavigationItemType.GRANULARITY,
          periods: getArrayOfFormattedDatesBetweenDates(
            contract.detail.contractualStartDate,
            endDate.endOf('day').toISOString(),
            'day'
          ).map((dateISO) => ({
            label: dayjs(dateISO).format('DD/MM/YYYY'),
            defaultSelected: dayjs(dateISO).format('DD/MM/YYYY') === endDate.subtract(3, 'day').format('DD/MM/YYYY'),
            value: dateISO
          }))
        }
      }
    : defaultGranularity
}

/**
 * Memoized variant of generateGranularityNavigationItems
 */
export const memoizedGenerateGranularityNavigationItems = memoize<UnknownServiceContract, GranularityOptions>(
  generateGranularityNavigationItems
)

/**
 * Generates the previous billing cycles navigation items based on the given previousBillingCyclesPeriods & activeProductType
 *
 * @param {PreviousBillingCyclePeriod[]} previousBillingCyclesPeriods
 * @param {ProductType} activeProductType
 * @returns {{ [key: string]: NavigationItem }}
 */
export const generatePreviousBillingCycleNavigationItems = (
  activeProductType: ProductType,
  previousBillingCyclesPeriods?: PreviousBillingCyclePeriod[]
): { [key: string]: NavigationItem } => {
  return (
    previousBillingCyclesPeriods?.reduce(
      (
        prev: {
          [key: string]: NavigationItem
        },
        curr: PreviousBillingCyclePeriod
      ) => {
        if (
          (activeProductType === ProductType.ELECTRICITY && curr.fuel?.electricity) ||
          (activeProductType === ProductType.GAS && curr.fuel?.gas)
        )
          prev[curr.id] = {
            type: NavigationItemType.CYCLE,
            text: `${dayjs(curr.startDate).format('DD/MM/YY')} - ${dayjs(curr.endDate).format('DD/MM/YYYY')}`,
            id: curr.id,
            fuel: curr.fuel
          } as NavigationItem
        return prev
      },
      {}
    ) || {}
  )
}
