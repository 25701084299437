import { Table } from 'components/Table/Table.tsx'
import { InvoicesTableProps } from './types.ts'
import styles from './InvoiceTable.module.scss'
import { useEffect, useState } from 'react'
import { paginate, sortRowsByKey } from 'pages/App/billing/invoices/utils.ts'
import StackedInvoicesView from './stacked-invoices-view/StackedInvoicesView.tsx'
import { SortingDirection, TableDataRow } from 'components/Table/types.ts'
import { Pagination } from '@boltenergy-be/design-system'
import { InvoiceCells, InvoiceTableTabs } from 'pages/App/billing/invoices/table-data/types.ts'
import useWindowSize from 'hooks/useWindowSize.tsx'
import InvoiceEntryModal from 'pages/App/billing/invoices/invoice-entry-modal/InvoiceEntryModal.tsx'
import classNames from 'classnames'

const InvoicesTable = ({ tableData, activeTab }: InvoicesTableProps) => {
  // Window size
  const { isMobile } = useWindowSize()

  // state
  const [currentPage, setCurrentPage] = useState(1)
  const [sorting, setSorting] = useState<{ key: InvoiceCells; direction: SortingDirection }>({
    key: InvoiceCells.DATE,
    direction: SortingDirection.DESC
  })
  const [infoModalRowIndex, setInfoModalRowIndex] = useState<number | null>(null)
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  // constants
  const pages: InvoicesTableProps['tableData'][] = paginate(tableData.sort(sortRowsByKey(sorting?.key, sorting?.direction)), 10)
  const selectedDataset = pages[currentPage - 1]

  const openModal = (index: number) => {
    setInfoModalRowIndex(index)
    setModalOpen(true)
  }

  // Reset the navigation when switching tab
  useEffect(() => {
    if (activeTab && currentPage !== 1) setCurrentPage(1)
  }, [activeTab])

  return (
    <>
      <div>
        {isMobile ? (
          <StackedInvoicesView data={selectedDataset} handleClickRow={openModal} />
        ) : (
          <Table
            {...{ sorting }}
            showHeader={[InvoiceTableTabs.SUPER_USER_CUSTOMER, InvoiceTableTabs.SUPER_USER_SUPPLIER].includes(activeTab)}
            data={selectedDataset}
            className={classNames(styles.table, styles[activeTab])}
            onSort={({ key, direction }) => setSorting({ key: key as InvoiceCells, direction })}
            handleClickRow={openModal}
          />
        )}
        {pages.length > 1 && (
          <Pagination
            pagesLength={pages.length}
            activePageNumber={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
            className={styles.pagination}
            maxWidth={320}
          />
        )}
      </div>

      <InvoiceEntryModal
        entry={
          typeof infoModalRowIndex === 'number'
            ? (selectedDataset[infoModalRowIndex]?.entries as TableDataRow<InvoiceCells>['entries'])
            : null
        }
        isOpen={modalOpen}
        setClose={() => setModalOpen(false)}
      />
    </>
  )
}

export default InvoicesTable
