import { UserTypes } from 'store/auth/types.ts'

/**
 * Determines the auth root path for the user type
 *
 * @param userType
 */
export const determineUserTypeAuthRootPath = (userType: UserTypes) => {
  switch (userType) {
    case UserTypes.SALES:
      return '/auth/sales'
    case UserTypes.SUPER_USER:
      return '/auth/internal'
    default:
      return '/auth'
  }
}
