import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialContractsState } from 'store/contracts/initial'
import { getMeterReadings } from 'store/contracts/thunks'
import { MeterReadings } from 'types/contracts'

export const contractsSlice = createSlice({
  name: 'contracts',
  initialState: initialContractsState,
  reducers: {
    resetContractsStore: () => initialContractsState,
    updateMeterReadings: (state, { payload }: PayloadAction<MeterReadings>) => {
      return {
        ...state,
        meterReadings: {
          ...state.meterReadings,
          data: payload
        }
      }
    }
  },
  extraReducers: (builder) => {
    // METER READINGS
    builder.addCase(getMeterReadings.pending, (state) => {
      state.meterReadings.loading = true
      state.meterReadings.error = undefined
    })

    builder.addCase(getMeterReadings.fulfilled, (state, { payload }) => {
      state.meterReadings.loading = false
      state.meterReadings.data = payload.meterReadings
      state.meterReadings.error = undefined
    })

    builder.addCase(getMeterReadings.rejected, (state, { payload }) => {
      state.meterReadings.loading = false
      state.meterReadings.error = payload
    })
  }
})

export const { resetContractsStore, updateMeterReadings } = contractsSlice.actions

export default contractsSlice.reducer
