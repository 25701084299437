import { MappedContractStatusProps } from './types.ts'
import styles from './MappedContractStatus.module.scss'
import { ContractStatus } from 'types/contracts.ts'

const MappedContractStatus = ({ status }: MappedContractStatusProps) => {
  /**
   * Determines the color classname the MappedContractStatus should have
   *
   * @returns {string}
   */
  const getMappedContractStatusConfig = (): { className: string; text: string } | undefined => {
    switch (status) {
      // Inactive
      case ContractStatus.ERROR:
      case ContractStatus.RECOVERY:
      case ContractStatus.UNKNOWN_POINT:
      case ContractStatus.WAITING_14_DAYS:
        return {
          className: styles.informative,
          text: 'Inactive'
        }

      // In progress
      case ContractStatus.ACTIVE:
      case ContractStatus.ILC_ONGOING:
      case ContractStatus.INACTIVE:
      case ContractStatus.REJECTED:
      case ContractStatus.VALIDATION_REJECT:
        return {
          className: styles.informative,
          text: 'In progress'
        }

      // Wrong energy product
      case ContractStatus.WRONG_ENERGY_PRODUCT:
        return {
          className: styles.negative,
          text: 'Wrong energy product'
        }

      // Cancelled
      case ContractStatus.CANCELLED:
        return {
          className: styles.negative,
          text: 'Cancelled'
        }

      // Abandoned
      case ContractStatus.ABANDONED:
        return {
          className: styles.negative,
          text: 'Abandoned'
        }

      // Terminated
      case ContractStatus.TERMINATED:
        return {
          className: styles.subdued,
          text: 'Terminated'
        }

      // Effective
      case ContractStatus.EFFECTIVE:
        return {
          className: styles.positive,
          text: 'Effective'
        }

      default:
        return undefined
    }
  }

  // Constants
  const config = getMappedContractStatusConfig()

  return config ? <strong className={config.className}>{config.text}</strong> : undefined
}

export default MappedContractStatus
