const FeedbackWidgetIcon = ({ isActive }: { isActive?: boolean }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
      {isActive ? (
        <g fill="none" fillRule="evenodd">
          <rect width="50" height="50" fill="#edfaf7" rx="6" />
          <path d="M8 8h34v34H8z" />
          <path
            fill="#23d5ae"
            d="M26.714 11.23c.453.132.765.548.765 1.02v8.854H33.5a1.063 1.063 0 0 1 .896 1.633L24.48 38.32a1.062 1.062 0 0 1-1.96-.57v-8.854H16.5a1.062 1.062 0 0 1-.896-1.633L25.52 11.68c.254-.399.74-.582 1.194-.45Z"
          />
        </g>
      ) : (
        <g fill="none" fillRule="evenodd">
          <rect width="50" height="50" fill="#8483751A" rx="0" />
          <path d="M8 8h34v34H8z" />
          <path
            fill="#848375"
            d="M26.714 11.23c.453.132.765.548.765 1.02v8.854H33.5a1.063 1.063 0 0 1 .896 1.633L24.48 38.32a1.062 1.062 0 0 1-1.96-.57v-8.854H16.5a1.062 1.062 0 0 1-.896-1.633L25.52 11.68c.254-.399.74-.582 1.194-.45Zm-8.278 15.54h5.147c.587 0 1.063.477 1.063 1.063v6.268l6.918-10.872h-5.147a1.063 1.063 0 0 1-1.063-1.062v-6.268L18.436 26.77Z"
          />
        </g>
      )}
    </svg>
  )
}

export default FeedbackWidgetIcon
