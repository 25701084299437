import { DocumentType } from 'types/types.ts'
import dayjs from 'dayjs'
import { CellColor } from 'pages/App/billing/invoices/invoices-table/cell/types.ts'
import i18next from 'i18next'
import { StatusProps, StatusReturnValue } from 'pages/App/billing/invoices/invoices-table/types.ts'

/**
 * Returns the status as variant and text value, for a given document
 *
 * @param {StatusProps} props
 */
export const getStatus = ({ document, usesDirectDebit }: StatusProps): StatusReturnValue => {
  // Constants
  const isCustomerInvoice = document?.documentType === DocumentType.INVOICE
  const today = dayjs()
  const invoiceDueDate = dayjs(isCustomerInvoice ? document?.dueDate : '')

  // ORDER IS IMPORTANT
  // ------------------

  // 1. Reminder fees
  if (document.documentType === DocumentType.PAYMENT && document.lineType === 90) {
    return {
      color: CellColor.DEFAULT,
      value: i18next.t('status.reminderFee', { ns: 'invoices' })
    }
  }

  // 2. Payments
  if (document.documentType === DocumentType.PAYMENT) {
    return {
      color: CellColor.DEFAULT,
      value: document.amount < 0 ? i18next.t('invoices:status.boltRecieved') : i18next.t('invoices:status.boltPayed')
    }
  }

  // 3. 'Bolt has received this amount' / 'Bolt has paid this amount'
  if (document.amount < 0 && document.paid) {
    return {
      color: CellColor.POSITIVE,
      value: i18next.t('invoices:status.boltPayed')
    }
  }

  // 4. “Will be refunded to you”
  if (document.amount < 0 && !document.paid) {
    return {
      color: CellColor.POSITIVE,
      value: i18next.t('invoices:status.willBeRefunded')
    }
  }

  // 5. 'Paid' / 'Bolt has paid this amount'
  if (document.paid) {
    return {
      color: CellColor.POSITIVE,
      value: i18next.t('invoices:status.paid')
    }
  }

  // 6. 'Direct debit failed'
  if (isCustomerInvoice && today.isAfter(invoiceDueDate.add(4, 'days'), 'day') && usesDirectDebit) {
    return {
      color: CellColor.NEGATIVE,
      value: i18next.t('invoices:status.directDebitFailed')
    }
  }

  // 7. 'Payable at XX/XX/XXXX' - bank transfer overdue
  if (isCustomerInvoice && today.isAfter(invoiceDueDate, 'day') && !usesDirectDebit) {
    return {
      color: CellColor.NEGATIVE,
      value: `${i18next.t('invoices:status.paymentDue')} ${dayjs(invoiceDueDate).format('DD/MM/YYYY')}`
    }
  }

  // 8. 'Payable at XX/XX/XXXX' - directDebit approaching
  if (isCustomerInvoice && invoiceDueDate.add(4, 'days').isAfter(today, 'day') && usesDirectDebit) {
    return {
      color: CellColor.INFORMATIVE,
      value: `${i18next.t('invoices:status.directDebitDue')} ${dayjs(invoiceDueDate).format('DD/MM/YYYY')}`
    }
  }

  // 9. 'Payable at XX/XX/XXXX' - bank transfer approaching
  if (isCustomerInvoice && invoiceDueDate.isSameOrAfter(today, 'day') && !usesDirectDebit) {
    return {
      color: CellColor.WARNING,
      value: `${i18next.t('invoices:status.paymentDue')} ${dayjs(invoiceDueDate).format('DD/MM/YYYY')}`
    }
  }

  return {
    color: undefined,
    value: ''
  }
}
