import { TagProps } from './types'
import styles from './Tag.module.scss'
import classNames from 'classnames'
import { Icon } from '@boltenergy-be/design-system'

const Tag = ({ className, color = 'orange', size = 'medium', icons, children }: TagProps) => {
  return (
    <span className={classNames(styles.tag, styles[color], styles[size], className)}>
      {!!icons && (
        <>
          {Array.isArray(icons) ? (
            <div className={styles.icons}>
              {icons.map((icon) => (
                <Icon name={icon} key={icon} />
              ))}
            </div>
          ) : (
            <Icon name={icons} />
          )}
        </>
      )}
      {children}
    </span>
  )
}

export default Tag
