import AddressSwitcher from 'components/AddressSwitcher/AdressSwitcher'
import styles from './AddressSwitcherBar.module.scss'
import { Heading } from '@boltenergy-be/design-system'
import useWindowSize from 'hooks/useWindowSize'
import { PropsWithChildrenAndClassName } from 'types/utility.ts'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { SMALL_DESKTOP_BREAKPOINT } from 'constants/viewport.ts'

const AddressSwitcherBar = ({ children, className }: PropsWithChildrenAndClassName<void>) => {
  const { isSmaller } = useWindowSize(SMALL_DESKTOP_BREAKPOINT)
  const { t } = useTranslation()

  return !isSmaller ? (
    <div className={classNames(styles['address-switcher-bar'], { [styles['with-children']]: !!children }, className)}>
      {children || (
        <Heading as="h2" variant="h5">
          {t('switchAddress')}
        </Heading>
      )}

      <AddressSwitcher containerClassName={styles['switcher-container']} className={styles.switcher} />
    </div>
  ) : null
}

export default AddressSwitcherBar
