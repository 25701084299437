import styles from './OverviewCard.module.scss'
import classNames from 'classnames'
import { OverviewCardProps } from './types'
import Card from 'components/Card/Card.tsx'
import { Heading } from '@boltenergy-be/design-system/dist/components/heading'
import { useTranslation } from 'react-i18next'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton'
import { DATE_FORMAT } from 'constants/constants.ts'

const OverviewCard = ({ date, blocks, isLoading }: OverviewCardProps) => {
  // i18n
  const { t } = useTranslation('billing')

  return (
    <Card as="section" className={styles['overview-card']} padding={{ desktop: 600, mobile: 400 }}>
      <header>
        <Heading as="h1" variant="h6">
          {t('billingCycle.overview.lastUpdate')}
        </Heading>
        {isLoading ? <LoadingSkeleton.Rectangle height={22} width={DATE_FORMAT.length + 'ch'} /> : <time dateTime={date}>{date}</time>}
      </header>

      <ul className={styles.blocks}>
        {blocks.map((block, idx) => (
          <li key={idx} className={styles.block}>
            <strong className={styles.label}>{block.label}</strong>
            {isLoading ? (
              <LoadingSkeleton.Rectangle height={24} width={200} />
            ) : (
              <data value={block.rawValue} className={classNames(styles.value, block.valueClass)}>
                {block.formattedValue}
              </data>
            )}
          </li>
        ))}
      </ul>
    </Card>
  )
}

export default OverviewCard
