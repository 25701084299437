import { Form, InlineMessage, Select, TextField } from '@boltenergy-be/design-system'
import { useTranslation } from 'react-i18next'
import styles from '../Details.module.scss'
import { SimulationDetailsProps } from '../types'
import DataBlock from 'features/contracts/add/components/DataBlock/DataBlock'
import { Opportunity, PaymentMode } from 'types/contacts'
import { useEffect, useState } from 'react'
import { Town } from 'api/types'
import { getTown } from 'api/addContract'
import { isValidPostalCode } from 'utils/addContract'
import { useFormContext } from 'react-hook-form'
import { Toggle } from '@boltenergy-be/design-system'
import IBAN from 'iban'
import { ALLOWED_IBAN_COUNTRIES } from 'features/contracts/add/constants.ts'
import { removeNonAlphanumericals } from 'utils/format.ts'
import { AddressWithCountryAndTownCode } from 'types/general.ts'

const EditSimulationDetails = ({ opportunity }: SimulationDetailsProps) => {
  // i18n
  const { t } = useTranslation(['sales', 'validation', 'common'])

  // React hookform
  const hookform = useFormContext<Partial<Omit<Opportunity, 'address'> & { address: AddressWithCountryAndTownCode }>>()
  const {
    register,
    setValue,
    watch,
    formState: { errors }
  } = hookform
  const watchTownName = watch('address.townName')
  const watchPaymentMode = watch('paymentMode')

  // Local state
  const [selectableTowns, setSelectableTowns] = useState<Town[]>([])

  // Constants
  const ibanIsRequired = watchPaymentMode === PaymentMode.DOMICILATION

  // Set town from postcode on page load
  useEffect(() => {
    validatePostalCodeAndFetchTowns(
      typeof opportunity.address.postalCode === 'string' ? parseInt(opportunity.address.postalCode) : opportunity.address.postalCode
    )
  }, [])

  /**
   * Handles the town select change & sets the town code
   *
   * @param {string} selectedTown
   */
  const handleTownSelectChange = (selectedTown: string) => {
    const town = selectableTowns.find((town) => town.townName === selectedTown)
    const townCode = town?.townCode ?? 0
    const townName = town?.townName ?? ''

    setValue('address.townCode', townCode, { shouldDirty: true })
    setValue('address.townName', townName, { shouldDirty: true })
  }

  /**
   * Fetches the town names based on the given postalCode
   *
   * @param {number} postalCode
   */
  const validatePostalCodeAndFetchTowns = async (postalCode: number) => {
    if (isValidPostalCode(postalCode)) {
      const response = await getTown(postalCode)

      if (response !== null) {
        const { towns } = response
        setSelectableTowns(towns)

        if (!towns.some((town) => town.townName === watchTownName)) {
          setValue('address.townName', towns[0].townName, { shouldDirty: true })
        }
        setValue('address.townCode', towns[0].townCode, { shouldDirty: true })
        return true
      }
    }

    return t('invalid.postalCode', { ns: 'validation' })
  }

  return (
    <Form>
      <TextField
        type="number"
        label={t('details.simulation.pricePerMonth')}
        name="pricePerMonth"
        defaultValue={opportunity.pricePerMonthInclVat}
        disabled
      />

      <TextField
        type="number"
        label={t('details.simulation.forcedAmount')}
        inputMode="numeric"
        {...register('forcedAmount', {
          required: false,
          max: { value: 10_000, message: t('validation:tooHigh', { max: 10_000 }) },
          min: { value: opportunity.pricePerMonthInclVat, message: t('validation:tooLow', { min: opportunity.pricePerMonthInclVat }) }
        })}
        error={errors?.forcedAmount?.message}
      />

      <span className="grid-col-full">
        <DataBlock label={t('details.simulation.paymentMethod')}>
          <Toggle
            options={Object.values(PaymentMode).map((value) => ({
              label: t(`details.simulation.paymentMethodOptions.${value}`),
              value: value
            }))}
            onClick={(value) => setValue('paymentMode', value as PaymentMode)}
            active={watchPaymentMode}
          />
          {errors?.paymentMode?.type === 'required' && <InlineMessage type="negative">{t('validation:required')}</InlineMessage>}
        </DataBlock>
      </span>

      <TextField
        label={t('details.simulation.IBAN')}
        {...register('ibanNumber', {
          required: ibanIsRequired ? t('validation:required') : false,
          validate: {
            allowed: (value) =>
              (!ibanIsRequired && !value) ||
              (!!value && ALLOWED_IBAN_COUNTRIES.includes(value.slice(0, 2))) ||
              t('validation:invalid.ibanCountries'),
            valid: (value) => (!ibanIsRequired && !value) || (!!value && IBAN.isValid(value)) || t('validation:invalid.iban')
          }
        })}
        onPaste={(e) => {
          const paste = removeNonAlphanumericals(e.clipboardData.getData('text'))
          e.currentTarget.value = paste
          setValue('ibanNumber', paste, { shouldValidate: true })
          e.preventDefault()
        }}
        error={errors?.ibanNumber?.message}
        grid="full"
      />

      <TextField
        label={t('details.simulation.address.street')}
        {...register('address.streetName', { required: t('validation:required') })}
        error={errors.address?.streetName?.message}
      />

      <div className={styles['double-column']}>
        <TextField
          label={t('details.simulation.address.number')}
          {...register('address.streetNumber', { required: t('validation:required') })}
          error={errors.address?.streetNumber?.message}
        />

        <TextField label={t('details.simulation.address.addition')} {...register('address.streetBox')} />
      </div>

      <TextField
        label={t('details.simulation.address.postalCode')}
        {...register('address.postalCode', {
          required: t('validation:required'),
          validate: (val) => validatePostalCodeAndFetchTowns(parseInt(val as string))
        })}
        error={errors.address?.postalCode?.message}
        onChange={(e) => {
          const value = e.target.value
          setValue('address.postalCode', value, { shouldValidate: true })
          validatePostalCodeAndFetchTowns(parseInt(value))
        }}
      />

      <Select
        label={t('details.simulation.address.locality')}
        {...register('address.townName', { required: t('validation:required') })}
        onChange={(e) => handleTownSelectChange(e.target.value)}
        value={watchTownName}
        error={errors.address?.townName?.message}
      >
        <option disabled>{watch('address.postalCode') === '' ? '' : t('common:defaultPlaceholders.select')}</option>
        {selectableTowns.map((town) => {
          return (
            <option key={town.townCode} value={town.townName}>
              {town.townName}
            </option>
          )
        })}
      </Select>
    </Form>
  )
}

export default EditSimulationDetails
