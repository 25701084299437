import { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation } from 'react-router-dom'
import { routes } from 'types/routes'
import { determineAccessRights, triggerGetMeterReadings } from 'utils/contracts'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import { selectCurrentContracts } from 'store/contact/selectors.ts'
import PageLayout from 'layouts/page-layout/PageLayout.tsx'
import { PageTab } from 'layouts/page-layout/types.ts'
import EditLayout from 'layouts/edit-layout/EditLayout.tsx'
import useGetRouteLayout from 'hooks/useGetRouteLayout.ts'
import { Layouts } from 'layouts/types.ts'
import { FaqSlug } from 'types/faq.ts'
import { useNavigate } from 'react-router'
import { Button, popErrorToast } from '@boltenergy-be/design-system'
import mixpanel from 'mixpanel-browser'
import { ConsumptionEvents } from 'types/tracking.ts'
import { log } from 'utils/logging.ts'
import { Flow } from 'types/logging.ts'
import { getAxiosRequestConfig, newRequest } from 'utils/request.ts'
import { Methods, Response } from 'types/request.ts'
import { GetContractConsumptionsResponseBody } from 'api/types.ts'

const Consumption = () => {
  // REDUX STORE
  const {
    meterReadings: { data, loading }
  } = useStoreSelector((store) => store.contracts)
  const { selected, billingContracts, isProducer } = useStoreSelector((store) => store.contact)
  const dispatch = useStoreDispatch()

  // Contracts
  const { electricity, gas } = selectCurrentContracts({ selected, billingContracts }).serviceContracts

  // Access rights
  const accessRights = determineAccessRights(electricity)

  // Local state
  const [attemptedMeterReadingsFetch, setAttemptedMeterReadingsFetch] = useState<boolean>(false)
  const [downloadingCsv, setDownloadingCsv] = useState<boolean>(false)
  const [downloadError, setDownloadError] = useState<boolean>(false)

  // i18n
  const { t } = useTranslation(['consumption', 'common'])

  // Layout
  const { layout } = useGetRouteLayout()

  // Router
  const { pathname } = useLocation()
  const navigate = useNavigate()

  /**
   * Fetch meter readings if none available
   */
  useEffect(() => {
    if (
      accessRights.meterReadings.canAccess &&
      accessRights.meterReadings.showContent &&
      !data?.electricity &&
      !loading &&
      !attemptedMeterReadingsFetch
    ) {
      setAttemptedMeterReadingsFetch(true)
      triggerGetMeterReadings(electricity, gas)
    }
  }, [accessRights, attemptedMeterReadingsFetch, data, dispatch, electricity, gas, loading])

  /**
   * Get tabs based on the user's access rights
   * @returns {PageTab[]}
   */
  const getTabs = (): PageTab[] => {
    const tabs: PageTab[] = [{ label: t('common:electricity'), path: routes.CONSUMPTION_ELECTRICITY }]

    if (gas) {
      tabs.push({ label: t('common:gas'), path: routes.CONSUMPTION_GAS })
    }

    if (accessRights.meterReadings.canAccess) {
      tabs.push({ label: t('tabs.meterReadings'), path: routes.CONSUMPTION_METER_READINGS })
    }

    return tabs
  }

  const downloadCsv = async () => {
    setDownloadingCsv(true)

    try {
      const {
        success,
        data: responseData,
        error,
        message
      }: Response<GetContractConsumptionsResponseBody> = await newRequest(
        getAxiosRequestConfig(Methods.GET, `/contracts/${electricity.contractNumber}/consumptions-csv`)
      )

      if (success && responseData) {
        // Download the file
        const blob = new Blob([responseData.consumptionsCsv], { type: 'text/csv;charset=utf-8;' })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `contract-${electricity.deliveryPoint.ean}.csv`
        a.click()
        window.URL.revokeObjectURL(url)
      }

      if (error) {
        setDownloadError(true)
        popErrorToast(t('yourConsumption.download.error'))
        log({ error: message || 'unhandled error', identifier: `[${Flow.CONTRACTS}:downloadCsv]` })
      }
    } catch (err) {
      setDownloadError(true)
      popErrorToast(t('yourConsumption.download.error'))
      log({ error: err as Error, identifier: `[${Flow.CONTRACTS}:downloadCsv]` })
    } finally {
      setDownloadingCsv(false)
    }
  }

  const ACTIONS: { [key: string]: ReactNode[] } = {
    [routes.CONSUMPTION_ELECTRICITY]: isProducer
      ? [
          <Button
            leadingIcon="download"
            variant="tertiary"
            disabled={downloadError}
            loading={downloadingCsv}
            onClick={async () => {
              await downloadCsv()
              mixpanel.track(ConsumptionEvents.DOWNLOADED_CSV)
            }}
          />
        ]
      : [],
    [routes.CONSUMPTION_GAS]: [],
    [routes.CONSUMPTION_METER_READINGS]: []
  }

  // Redirect to electricity usage if the user lands on the general consumption route
  useEffect(() => {
    if (pathname === routes.CONSUMPTION) {
      navigate(routes.CONSUMPTION_ELECTRICITY, { replace: true })
    }
  }, [pathname, navigate])

  return layout === Layouts.EDIT_LAYOUT ? (
    <EditLayout title={t('editMeterReadings.title')}>
      <Outlet />
    </EditLayout>
  ) : (
    <PageLayout
      title={t(`nav.${isProducer ? 'injection' : 'usage'}`, { ns: 'common' })}
      tabs={getTabs()}
      description={pathname.includes(routes.CONSUMPTION_METER_READINGS) ? t('consumption:meterReadings.description') : undefined}
      faqCategories={[FaqSlug.CONSUMPTION]}
      actions={ACTIONS[pathname]}
    >
      <Outlet />
    </PageLayout>
  )
}

export default Consumption
