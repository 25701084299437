import TooltipOnClick from 'components/Tooltip/TooltipOnClick.tsx'
import styles from './Options.module.scss'
import { CustomerInvoice, DocumentType } from 'types/types.ts'
import { OptionsProps, ToolTipContentProps } from './types.ts'
import { CellVariant } from '../cell/types.ts'
import Cell from '../cell/Cell.tsx'
import { useTranslation } from 'react-i18next'
import Link from 'components/Link/Link.tsx'
import { Icon } from '@boltenergy-be/design-system'

const ToolTipContent = ({ document }: ToolTipContentProps) =>
  !!(document as CustomerInvoice)?.pdfUrl && (
    <Link
      href={(document as CustomerInvoice).pdfUrl}
      as="a"
      representation="button"
      variant="tertiary"
      download
      isFullwidth
      target="_blank"
      trailingIcon="download"
      size="small"
    >
      {(document as CustomerInvoice).invoiceNumber}
    </Link>
  )

const Options = ({ document }: OptionsProps) => {
  // i18n
  const { t } = useTranslation('invoices')

  // Constants
  const isCustomerInvoice = document?.documentType === DocumentType.INVOICE

  return isCustomerInvoice ? (
    <Cell variant={CellVariant.OPTIONS} title={t('cells.moreOptions')}>
      <TooltipOnClick
        tooltipContent={<ToolTipContent document={document} />}
        tooltipClassName={styles.tooltip}
        tooltipPlacement="bottom-start"
        classNameTrigger={styles.trigger}
      >
        <Icon name="ellipsisH" className={styles.icon} />
      </TooltipOnClick>
    </Cell>
  ) : null
}

export default Options
