import { EMAIL } from 'constants/regex'
import { ReactNode, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { UrlSearchParamsKeys } from 'store/app/types'
import { routes } from 'types/routes'
import { CustomerEmailForm } from './types'
import { UserTypes } from 'store/auth/types'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import Card from 'components/Card/Card.tsx'
import { resetMoveFlow } from 'store/app/slice.ts'
import { Banner, Button, ButtonLoadingIndicator, Form, List, TextField } from '@boltenergy-be/design-system'
import { getContactByEmail } from 'store/contact/thunks'
import PageLayout from 'layouts/page-layout/PageLayout.tsx'
import styles from './CustomerEmail.module.scss'
import { logout } from 'utils/app.ts'
import { getUserTypeLoginPage } from 'utils/user.ts'
import { ApiResponseCodes, ContactsResponseCodes } from 'types/errorCodes.ts'

const CustomerEmail = () => {
  // REDUX
  const { urlSearchParams } = useStoreSelector((store) => store.app)
  const { userType } = useStoreSelector((store) => store.auth)
  const { loading, accounts, error, errorData } = useStoreSelector((store) => store.contact)
  const dispatch = useStoreDispatch()

  // React Router
  const navigate = useNavigate()

  // i18n
  const { t } = useTranslation(['home', 'common'])

  // React Hook Form
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<CustomerEmailForm>()

  // Constants
  const contactIdGiven = !!urlSearchParams[UrlSearchParamsKeys.CONTACT_ID]

  /**
   * Triggered everytime superuser changes
   * Checks if superuser & redirects to /home if not
   */
  useEffect(() => {
    if (userType === UserTypes.CUSTOMER || (accounts && Object.keys(accounts).length > 0)) navigate(routes.ACCOUNT_SELECTION)
    if (userType === UserTypes.SALES) navigate(routes.SALES_HOME)
  }, [userType, accounts, urlSearchParams])

  /**
   * Returns the error code as a translated, readable error message for the end users
   *
   * @returns {ReactNode | string}
   */
  const getTranslatedUserError = (): ReactNode | string => {
    // If error data is returned from the API, show it
    if (error === ApiResponseCodes.INTERNAL_SERVER_ERROR && errorData.length) {
      return (
        <List as="ol">
          {errorData.map((error) => (
            <li>{error}</li>
          ))}
        </List>
      )
    }

    // "Predefined" error messages
    switch (error) {
      // Contact does not exist or is not a customer
      case ApiResponseCodes.NOT_FOUND:
        return "No Primary Contact with status 'Customer' found in Salesforce for the given e-mail address."

      // Contact has no accounts linked
      case ContactsResponseCodes.NO_ACCOUNTS_FOUND:
        return 'Contact has no accounts linked to it in Salesforce.'

      // Contact has no customer accounts linked in Salesforce
      case ContactsResponseCodes.NO_CUSTOMER_ACCOUNTS_FOUND:
        return 'Contact has no customer accounts (consumer or producer) linked to it in Salesforce.'

      default:
        return 'Unknown error: create a bug ticket in Notion.'
    }
  }

  /**
   * Handles the form submit after validation by React Hook Form
   *
   * @param {CustomerEmailForm} data
   */
  const onSubmit = (data: CustomerEmailForm) => {
    const { email } = data
    dispatch(resetMoveFlow())
    dispatch(getContactByEmail({ email }))
  }

  return (
    <PageLayout
      title={t('superUser.welcome')}
      description={t(`superUser.description.${contactIdGiven ? 'contactId' : 'noContactId'}`)}
      classes={{ body: styles.body, header: styles.header }}
    >
      <Card>
        {contactIdGiven ? (
          // Fetching user data in <AppContainer /> is in progress
          <div className={styles.spinner}>
            <ButtonLoadingIndicator />
          </div>
        ) : (
          <>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                {...register('email', {
                  required: t('required', { ns: 'validation' }),
                  pattern: {
                    value: EMAIL,
                    message: t('invalid.email', { ns: 'validation' })
                  }
                })}
                type="email"
                label={t('superUser.email')}
                placeholder={t('superUser.email')}
                error={errors?.email?.message}
                grid="full"
                autoFocus
              />

              <Form.Footer>
                <Button loading={loading}>{t('superUser.button')}</Button>
              </Form.Footer>
            </Form>

            {error && (
              <Banner className="mt-500" type="blocking" title="Whoops, there is an issue with the data ⚡️">
                {getTranslatedUserError()}
              </Banner>
            )}
          </>
        )}
      </Card>

      <Button
        onClick={() => logout(() => navigate(getUserTypeLoginPage(UserTypes.SUPER_USER)), UserTypes.SUPER_USER)}
        className="mt-600"
        variant="tertiary"
      >
        {t('common:nav.logout')}
      </Button>
    </PageLayout>
  )
}

export default CustomerEmail
