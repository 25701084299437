import { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import { Rating } from 'react-simple-star-rating'
import request, { getAxiosRequestConfig } from 'utils/request'
import { Methods } from 'types/request'
import styles from './FeedbackWidget.module.scss'
import { FeedbackWidgetProps, FeedbackWidgetStep } from 'components/FeedbackWidget/types'
import FeedbackWidgetIcon from 'components/FeedbackWidget/FeedbackWidgetIcon'
import { Button, Form, Heading, TextArea, popSuccessToast } from '@boltenergy-be/design-system'
import { useStoreDispatch, useStoreSelector } from 'hooks/store.ts'
import { updateContact } from 'store/contact/thunks'
import classNames from 'classnames'
import parse from 'html-react-parser'
import { CommonTrackingEvents } from 'types/tracking.ts'
import mixpanel from 'mixpanel-browser'
import { IS_BETA } from 'constants/envs.ts'

const FeedbackWidget = ({ id, openFeedbackWidget, showTrigger, data, isBetaFeedback, className }: FeedbackWidgetProps) => {
  // Redux
  const { contact } = useStoreSelector((store) => store.contact)
  const dispatch = useStoreDispatch()

  // Local state
  const [widgetOpen, setWidgetOpen] = useState<boolean>(false)
  const [rating, setRating] = useState<number>(0)
  const [step, setStep] = useState<FeedbackWidgetStep>(FeedbackWidgetStep.RATING)
  const [feedback, setFeedback] = useState<string>('')

  // i18n
  const { t } = useTranslation(['feedbackWidget', 'common'])

  /**
   * Opens the widget from parent
   */
  useEffect(() => {
    setWidgetOpen(!!openFeedbackWidget)
  }, [openFeedbackWidget])

  // Constants
  const feedbackWidgetCookie = !!Cookies.get(`FeedbackWidget-${id}`)

  /**
   * Handles the on submit
   */
  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault() // prevent page refresh

    // Push entry to API
    try {
      await request(
        getAxiosRequestConfig(Methods.POST, '/feedback-widgets/entry', {
          feedbackWidgetId: id,
          score: rating,
          feedback,
          data
        })
      )

      if (IS_BETA) {
        finishWidget()
      } else {
        setStep(FeedbackWidgetStep.USER_TESTING)
      }
    } catch (err) {
      const { message } = err as Error
      throw new Error(message)
    }
  }

  /**
   * Set cookie, close and reset the widget
   */
  const closeWidget = () => {
    // set cookie that expires in 30 days
    Cookies.set(`FeedbackWidget-${id}`, 'hidden', { expires: 30, sameSite: 'Lax' })

    // close the widget
    setWidgetOpen(false)
  }

  /**
   * Handles the click on the rating
   * @param {number} value
   */
  const handleClickRating = (value: number) => {
    setRating(value)
    setStep(FeedbackWidgetStep.FEEDBACK)
  }

  /**
   * Handles the click on the testing button
   * @param {boolean} value
   */
  const handleClickTestingButton = (value: boolean) => {
    if (contact?.sfId) {
      dispatch(updateContact({ contactId: contact.sfId, contact: { userTester: value } }))
    }

    finishWidget()
  }

  /**
   * Finish the widget with toast, closing & reset
   */
  const finishWidget = () => {
    popSuccessToast(t('toast.success'))

    closeWidget()

    // Rest the widget after the CSS transition
    setTimeout(() => {
      resetWidget()
    }, 500)
  }

  /**
   * Get the title based on the step
   * @param {FeedbackWidgetStep} step
   * @returns {string}
   */
  const getTitle = (step: FeedbackWidgetStep): string => {
    switch (step) {
      case FeedbackWidgetStep.RATING:
        return t(isBetaFeedback ? 'beta.title' : 'rating.title')
      case FeedbackWidgetStep.FEEDBACK:
        return t(`feedback.title.${rating > 3 ? 'happy' : 'sad'}`)
      case FeedbackWidgetStep.USER_TESTING:
        return contact?.userTester ? t('userTesting.title.reConfirmation') : t('userTesting.title.new')
    }
  }

  /**
   * Reset the widget
   */
  const resetWidget = () => {
    setStep(FeedbackWidgetStep.RATING)
    setRating(0)
    setFeedback('')
  }

  return (
    <>
      {showTrigger && (
        <Button
          variant="layered"
          className={classNames(styles.button, className)}
          onClick={() => {
            if (!widgetOpen) {
              resetWidget()
            }
            setWidgetOpen(!widgetOpen)
            mixpanel.track(CommonTrackingEvents.OPEN_FEEDBACK_WIDGET)
          }}
        >
          {t(`${widgetOpen ? 'close' : 'button'}`)}
        </Button>
      )}

      <dialog className={styles['feedback-widget']} open={!(!showTrigger && feedbackWidgetCookie) && widgetOpen}>
        <header>
          <Heading className={styles.title} as="h1" variant="h6" weight={600}>
            {parse(getTitle(step))}
          </Heading>
          <Button size="small" variant="tertiary" leadingIcon="cross" onClick={() => closeWidget()} className={styles.close} />
        </header>

        {/* FIRST STEP - rating */}
        {step === FeedbackWidgetStep.RATING && (
          <>
            {isBetaFeedback && <small className="text-subdued">{t('beta.description')}</small>}
            <div className={styles['rating-wrapper']}>
              <Rating
                onClick={handleClickRating}
                initialValue={rating}
                emptyIcon={<FeedbackWidgetIcon />}
                fillIcon={<FeedbackWidgetIcon isActive />}
                emptyClassName={styles.icon}
                fillClassName={styles.icon}
                showTooltip={false}
                className={styles.rating}
              />
              <div className={styles.indication}>
                <small>{t('rating.indication.bad')}</small>
                <small>{t('rating.indication.good')}</small>
              </div>
            </div>
          </>
        )}

        {/* SECOND STEP - feedback */}
        {step === FeedbackWidgetStep.FEEDBACK && (
          <Form action="#" onSubmit={handleSubmit} className={styles.form}>
            <TextArea
              placeholder={t('feedback.optional')}
              label={t(`feedback.title.${rating > 3 ? 'happy' : 'sad'}`)}
              id="feedback"
              name="feedback"
              value={feedback}
              grid="full"
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setFeedback(e.currentTarget.value)}
            />
            <Form.Footer>
              <Button type="submit" isFullwidth>
                {t('feedback.submit')}
              </Button>
            </Form.Footer>
          </Form>
        )}

        {/* THIRD STEP - beta testing */}
        {step === FeedbackWidgetStep.USER_TESTING && (
          <>
            <small className="text-subdued">{t('userTesting.description')}</small>
            <div className={styles['action-buttons']}>
              <Button variant="secondary" onClick={() => handleClickTestingButton(true)}>
                {t('common:yes')}
              </Button>
              <Button variant="secondary" onClick={() => handleClickTestingButton(false)}>
                {t('common:no')}
              </Button>
            </div>
          </>
        )}
      </dialog>
    </>
  )
}

export default FeedbackWidget
