import { SettlementDefaultProps } from 'types/billShock'

export type PreviousBillingCycle = SettlementDefaultProps & {
  id: string
  total: number
  settlement: number
  fuel?: {
    electricity: boolean
    gas: boolean
  }
  usage: {
    electricity: {
      consumption: number
      injection: number
    }
    gas?: number
  }
  months: PreviousBillingCycleMonth[]
}

export type PreviousBillingCycleMonth = {
  datetime: string // ISO
  billedAmount: number
  actualPrice: number
}

// ENUMS

export enum CycleViews {
  CURRENT = 'current',
  PROPOSED = 'proposed'
}
