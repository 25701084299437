import { SubnavWithoutNavigationProps } from './types'
import classNames from 'classnames'
import styles from './Subnav.module.scss'
import parse from 'html-react-parser'

const SubnavWithoutNavigation = ({ tabs, activeTabPath, setActiveTab, className }: SubnavWithoutNavigationProps) => {
  return (
    <>
      <div className={classNames(styles.subnav, className)}>
        {tabs.map((tab, index) => (
          <button
            key={`subnav-${index}`}
            className={classNames(styles['subnav-wide-items'], styles['subnav-item'], {
              [styles.active]: tab.path === activeTabPath
            })}
            onClick={() => setActiveTab(tab.path)}
          >
            {typeof tab.label === 'string' ? parse(tab.label) : tab.label}
          </button>
        ))}
      </div>
    </>
  )
}

export default SubnavWithoutNavigation
