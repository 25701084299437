import { createSlice, isFulfilled, isPending, isRejected, PayloadAction } from '@reduxjs/toolkit'
import { initialAuthState } from 'store/auth/initial'
import { UserTypes } from './types'
import { login, register, requestNewPassword, resetPassword } from './thunks'

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    setUserType: (state, { payload }: PayloadAction<UserTypes | null>) => {
      return {
        ...state,
        userType: payload
      }
    }
  },
  extraReducers: (builder) => {
    // SPECIFIC THUNK REDUCERS
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.email = payload.email
      state.loading = false
      state.error = null
    })

    // IS PENDING MATCHER
    builder.addMatcher(isPending(login, register, requestNewPassword, resetPassword), (state) => {
      state.loading = true
      state.error = null
    })

    // IS FULFILLED
    builder.addMatcher(isFulfilled(register, requestNewPassword, resetPassword), (state) => {
      state.loading = false
      state.error = null
    })

    // IS REJECTED
    builder.addMatcher(isRejected(login, register, requestNewPassword, resetPassword), (state, { payload }) => {
      state.loading = false
      state.error = payload || null
    })
  }
})

export const { setUserType } = authSlice.actions

export default authSlice.reducer
