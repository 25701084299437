import { useEffect } from 'react'
import { useNavigate, Outlet } from 'react-router'
import { isAuthenticated } from 'utils/localStorageService.ts'
import { routes } from 'types/routes.ts'
import { checkIsSales } from 'utils/sales.ts'
import { ProtectedRouteProps } from 'router/ProtectedRoute/types.ts'

const ProtectedRoute = ({ userTypes }: ProtectedRouteProps) => {
  // Router
  const navigate = useNavigate()

  /**
   * Checks if the user is authenticated, if not, redirects to the login page
   */
  useEffect(() => {
    const authenticatedUserTypes = isAuthenticated()
    if (userTypes.every((userType) => !authenticatedUserTypes[userType])) {
      navigate(checkIsSales() ? routes.LOGIN_SALES : routes.LOGIN)
    }
  }, [])

  return <Outlet />
}

export default ProtectedRoute
