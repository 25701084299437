import { useTranslation } from 'react-i18next'
import { AddContractSteps } from 'features/contracts/add/types.ts'
import { FormButtonsProps } from 'features/contracts/components/form-buttons/types.ts'
import styles from 'features/contracts/components/form-buttons/FormButtons.module.scss'
import { Button, Form } from '@boltenergy-be/design-system'
import mixpanel from 'mixpanel-browser'
import { useStoreSelector } from 'hooks/store.ts'
import { ContractFlowEvents } from 'types/tracking.ts'

const FormButtons = ({ currentStep, loading = false, submitDisabled = false, onSubmit, trackingId }: FormButtonsProps) => {
  const { move } = useStoreSelector((store) => store.app)

  // i18n
  const { t } = useTranslation()

  return (
    <Form.Footer className={styles['form-buttons']}>
      <Button
        isFullwidth
        type="submit"
        disabled={submitDisabled}
        loading={loading}
        onClick={() => {
          if (trackingId) mixpanel.track(ContractFlowEvents.NEXT_STEP, { flow: move.flow, type: trackingId, currentStep })
          if (onSubmit) onSubmit()
        }}
      >
        {currentStep === AddContractSteps.CONFIRMATION ? t('confirm') : t('next')}
      </Button>
    </Form.Footer>
  )
}

export default FormButtons
