import { useEffect } from 'react'
import { Banner, Heading } from '@boltenergy-be/design-system'
import { useTranslation } from 'react-i18next'
import styles from './BillingCycles.module.scss'
import { Navigate } from 'react-router-dom'
import { routes } from 'types/routes'
import { UserTypes } from 'store/auth/types'
import { logBillingCycleViewedEvent } from 'api/events'
import { AccessRightsKeys } from 'types/contracts'
import { determineAccessRights, isCloseToTermination, triggerGetMeterReadings } from 'utils/contracts'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import { ReturnLater } from 'components/ReturnLater/ReturnLater'
import { selectContact, selectCurrentContracts } from 'store/contact/selectors.ts'
import { useGetBillShockQuery, useGetPreviousBillingCyclePeriodsQuery } from 'store/queries/bolt-api/contracts'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'
import dayjs from 'dayjs'
import BillshockError from './components/billshock-error/BillshockError.tsx'
import CycleLink from './components/cycle-link/CycleLink.tsx'
import { DATE_FORMAT } from 'constants/constants.ts'
import { PreviousBillingCyclePeriod } from 'types/billShock.ts'
import { icons } from '@boltenergy-be/design-system/dist/components/icon/icons'

const BillingCyclesOverview = () => {
  // REDUX STORE
  const { userType } = useStoreSelector((store) => store.auth)
  const {
    meterReadings: { data: meterReadingsData }
  } = useStoreSelector((store) => store.contracts)
  const { selected, billingContracts, contact } = useStoreSelector((store) => store.contact)
  const dispatch = useStoreDispatch()

  // Contracts
  const { email } = selectContact({ contact })
  const { serviceContracts } = selectCurrentContracts({ selected, billingContracts })
  const { electricity, gas } = serviceContracts

  // Access rights
  const accessRights = determineAccessRights(electricity)
  const shouldShowMeterReadings = accessRights.meterReadings.canAccess && accessRights.meterReadings.showContent
  const skipBillingCycles =
    !selected.billingContract ||
    !accessRights[AccessRightsKeys.BILLING_CYCLES].canAccess ||
    !accessRights[AccessRightsKeys.BILLING_CYCLES].showContent

  // Redux queries
  const {
    data: billShockData,
    isLoading: billShockLoading,
    error: billShockError
  } = useGetBillShockQuery({ billingContractId: selected.billingContract, email, serviceContracts }, { skip: skipBillingCycles })
  const {
    data: periods,
    isLoading: periodsLoading,
    error: periodsError
  } = useGetPreviousBillingCyclePeriodsQuery(selected.billingContract, { skip: skipBillingCycles })

  // i18n
  const { t } = useTranslation('billing')

  // Constants
  const closeToTermination = isCloseToTermination(electricity)

  /**
   * Fetch meter readings if none available
   */
  useEffect(() => {
    if (billShockData && shouldShowMeterReadings && typeof meterReadingsData === 'undefined') {
      triggerGetMeterReadings(electricity, gas)
    }
  }, [billShockData, meterReadingsData, dispatch, serviceContracts, shouldShowMeterReadings])

  /**
   * Logs the billingCycleViewed event through the API if the authenticated user is a regular user
   */
  useEffect(() => {
    if (accessRights.billingCycles.canAccess && accessRights.billingCycles.showContent && userType === UserTypes.CUSTOMER) {
      logBillingCycleViewedEvent(selected.billingContract)
    }
  }, [accessRights, selected.billingContract, userType])

  /**
   * Get the icons for the billing cycle period
   * @param {PreviousBillingCyclePeriod["fuel"]} fuel
   */
  const getPeriodIcons = (fuel: PreviousBillingCyclePeriod['fuel']) => {
    const fuelIcons: (keyof typeof icons)[] = []
    if (fuel?.electricity) fuelIcons.push('electricity')
    if (fuel?.gas) fuelIcons.push('gas')

    if (fuelIcons.length === 1) return fuelIcons[0]
    return fuelIcons
  }

  return !accessRights.billingCycles.canAccess ? (
    <Navigate to={routes.BILLING} replace />
  ) : !accessRights.billingCycles.showContent ? (
    <ReturnLater description={t('billingCycles.returnLater')} />
  ) : (
    <div className={styles['billing-cycles-overview']}>
      {/* CURRENT BILLING CYCLE */}
      <section className={styles.current}>
        <Heading as="h1" variant="h4">
          {t('billingCycles.current.title')}
        </Heading>

        {closeToTermination ? (
          <Banner type="informative">{t('closeToTermination', { ns: 'common' })}</Banner>
        ) : billShockLoading ? (
          <LoadingSkeleton>
            <LoadingSkeleton.Rectangle height={56} />
          </LoadingSkeleton>
        ) : billShockData ? (
          <CycleLink
            icon={billShockData?.billShock?.meters?.gas ? ['electricity', 'gas'] : 'electricity'}
            text={`${dayjs(billShockData.billShock.settlement.startDate).format(DATE_FORMAT)} - ${dayjs(billShockData.billShock.settlement.endDate).format(DATE_FORMAT)}`}
            href={routes.BILLING_CYCLE_DETAIL.replace(':id', 'current') as routes}
          />
        ) : (
          billShockError && <BillshockError error={billShockError} />
        )}
      </section>

      {/* PREVIOUS BILLING CYCLE */}
      <section className={styles.previous}>
        <Heading as="h1" variant="h4">
          {t('billingCycles.previous.title')}
        </Heading>

        {periodsLoading ? (
          <LoadingSkeleton>
            <LoadingSkeleton.Rectangle height={56} />
          </LoadingSkeleton>
        ) : periodsError ? (
          <BillshockError error={periodsError} />
        ) : periods?.length ? (
          <ul>
            {periods.map((period) => (
              <li key={period.id}>
                <CycleLink
                  icon={getPeriodIcons(period?.fuel)}
                  text={`${dayjs(period.startDate).format(DATE_FORMAT)} - ${dayjs(period.endDate).format(DATE_FORMAT)}`}
                  href={routes.BILLING_CYCLE_DETAIL.replace(':id', period.id) as routes}
                />
              </li>
            ))}
          </ul>
        ) : (
          <Banner>{t('billingCycles.previous.noneFound')}</Banner>
        )}
      </section>
    </div>
  )
}

export default BillingCyclesOverview
