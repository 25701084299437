import { AppStore, Language, MoveFlows } from 'store/app/types'
import Cookies from 'js-cookie'

const langCookie = Cookies.get('language')

export const initialAppState: AppStore = {
  betaMode: false,
  language: !!langCookie && langCookie !== 'undefined' ? (langCookie.toUpperCase() as Language) : Language.NL,
  redirect: null,
  showAllAddresses: false,
  error: null,
  urlSearchParams: {},
  move: {
    flow: MoveFlows.START
  }
}
