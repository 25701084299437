import { CellColor, CellVariant } from 'pages/App/billing/invoices/invoices-table/cell/types.ts'
import { getStatus } from 'pages/App/billing/invoices/invoices-table/utils.ts'
import { CustomerAccountingDocument, CustomerInvoice, CustomerPayment, DocumentType } from 'types/types.ts'
import i18n from 'translations/i18next.ts'
import dayjs from 'dayjs'
import { formatCurrency } from 'utils/format.ts'
import Link from 'components/Link/Link.tsx'
import { Language } from 'store/app/types.ts'
import mixpanel from 'mixpanel-browser'
import Cell from 'pages/App/billing/invoices/invoices-table/cell/Cell.tsx'
import AccountingInfo from 'pages/App/billing/invoices/invoices-table/accounting-info/AccountingInfo.tsx'
import i18next from 'i18next'
import Options from 'pages/App/billing/invoices/invoices-table/options/Options.tsx'
import { GetTableData, InvoiceCells, InvoiceTableDataRow, RegularTableCells, SuperUserTableCells, TransactionRows } from './types.ts'
import { getAddressByDocumentContractNumber } from 'pages/App/billing/invoices/utils.ts'
import { StatusReturnValue } from 'pages/App/billing/invoices/invoices-table/types.ts'
import { InvoiceTypeTranslationKeys } from 'pages/App/billing/invoices/constants.ts'
import { TableEntryData } from 'components/Table/types.ts'
import styles from './TableData.module.scss'
import parse from 'html-react-parser'
import { InvoiceEvents } from 'types/tracking.ts'

/**
 * Format the date entry data
 * @param {CustomerAccountingDocument} document
 * @returns {TableEntryData}
 */
const getDateCell = (document: CustomerAccountingDocument): TableEntryData => {
  return {
    header: i18n.t('invoices:tableHeaders.date'),
    data: document.date,
    content: dayjs(document.date).format('DD/MM/YYYY')
  }
}

/**
 * Format the date entry data
 * @param {CustomerAccountingDocument} document
 * @returns {TableEntryData}
 */
const getInvoiceNumberCell = (document: CustomerAccountingDocument): TableEntryData => {
  return {
    hidden: true, // Hidden because it's only being used in the modal
    data: 'invoiceNumber' in document ? document.invoiceNumber : null,
    content: '',
    header: ''
  }
}

/**
 * Format the amount entry data with status text display
 * @param {CustomerAccountingDocument} document
 * @param {StatusReturnValue} status
 * @param {Language} language
 * @param {boolean} showAddress
 * @param {boolean} isTabletOrMobile
 * @returns {TableEntryData}
 */
const getAmountCell = (
  document: CustomerAccountingDocument,
  status: StatusReturnValue,
  language: Language,
  showAddress?: boolean,
  isTabletOrMobile?: boolean
): TableEntryData => {
  return {
    header: i18n.t('invoices:tableHeaders.amount'),
    data: document.amount,
    content: (
      <Cell variant={CellVariant.PAYMENT_AMOUNT} color={status.color}>
        {formatCurrency(document.amount, { language })} {isTabletOrMobile ? `· ${status.value}` : ''}
      </Cell>
    ),
    ...(isTabletOrMobile && {
      extraClasses: styles['combined-cell'],
      extraContent: document.documentType === DocumentType.INVOICE && showAddress ? InvoiceCells.ADDRESS : undefined
    })
  }
}

/**
 * Format the status entry data
 *
 * @param {CustomerAccountingDocument} document
 * @param {StatusReturnValue} status
 * @param {boolean} showAddress
 * @param {boolean} isTabletOrMobile
 * @returns {TableEntryData}
 */
const getStatusCell = (
  document: CustomerAccountingDocument,
  status: StatusReturnValue,
  showAddress?: boolean,
  isTabletOrMobile?: boolean
): TableEntryData => {
  return {
    hidden: !!isTabletOrMobile,
    header: i18n.t('invoices:tableHeaders.status'),
    data: status.value,
    content: (
      <Cell variant={CellVariant.STATUS} color={status.color}>
        {parse(status.value)}
      </Cell>
    ),
    ...(!isTabletOrMobile && {
      extraClasses: styles['combined-cell'],
      extraContent: document.documentType === DocumentType.INVOICE && showAddress ? InvoiceCells.ADDRESS : undefined
    })
  }
}

/**
 * Format the doc type entry data
 * @param {CustomerAccountingDocument} document
 * @returns {TableEntryData}
 */
const getDocTypeCell = (document: CustomerAccountingDocument): TableEntryData => {
  const type = 'invoiceType' in document ? document.invoiceType : document.documentType

  return {
    header: i18n.t('invoices:tableHeaders.documentType'),
    data: type,
    content: parse(
      (type === DocumentType.PAYMENT
        ? i18next.t('invoices:cells.bankTransaction')
        : InvoiceTypeTranslationKeys[type]
          ? i18next.t(`invoices:invoiceTypes.${InvoiceTypeTranslationKeys[type]}`)
          : ''
      ).replaceAll(' ', '&nbsp;')
    )
  }
}

/**
 * Format the doc type entry data
 * @param {CustomerAccountingDocument} document
 * @returns {TableEntryData}
 */
const getAddressCell = (document: CustomerAccountingDocument): TableEntryData => {
  const address = getAddressByDocumentContractNumber({ document })
  return {
    hidden: true,
    header: '',
    data: address,
    content: address
  }
}

/**
 * Get the  invoice table data for a regular customer
 * @template DocumentType - CustomerInvoice in this case
 * @param {GetTableData<DocumentType>} params
 * @returns {InvoiceTableDataRow<RegularTableCells>[]}
 */
export const getInvoiceTableData = ({
  documents,
  isTabletOrMobile,
  language,
  showAddress,
  usesDirectDebit
}: GetTableData<CustomerInvoice>): InvoiceTableDataRow<RegularTableCells>[] => {
  return documents.map((invoice) => {
    const status = getStatus({ document: invoice, usesDirectDebit })
    const hidePaymentButton = invoice.paid || !invoice.paymentUrl || (usesDirectDebit && status.color !== CellColor.NEGATIVE)

    return {
      id: invoice.id,
      uuid: crypto.randomUUID(),
      color: status.color,
      entries: {
        [InvoiceCells.INVOICE_NUMBER]: getInvoiceNumberCell(invoice),
        [InvoiceCells.DATE]: getDateCell(invoice),
        [InvoiceCells.DOCUMENT_TYPE]: getDocTypeCell(invoice),
        [InvoiceCells.AMOUNT]: getAmountCell(invoice, status, language, showAddress, isTabletOrMobile),
        [InvoiceCells.STATUS]: getStatusCell(invoice, status, showAddress, isTabletOrMobile),
        [InvoiceCells.ADDRESS]: getAddressCell(invoice),
        [InvoiceCells.PAYMENT_ACTION]: {
          hideOnSmallDesktop: true,
          header: '',
          data: hidePaymentButton ? null : invoice.paymentUrl,
          content: hidePaymentButton ? (
            ''
          ) : (
            <Cell variant={CellVariant.ACTION}>
              <Link
                representation="button"
                isFullwidth
                size="small"
                as="a"
                target="_blank"
                href={invoice.paymentUrl}
                className={styles['pay-button']}
                onClick={(event) => {
                  event.stopPropagation()
                  mixpanel.track(InvoiceEvents.PAY_INVOICE)
                }}
              >
                {i18n.t('invoices:cells.payOnline')}
              </Link>
            </Cell>
          )
        },
        ...(invoice.pdfUrl && {
          [InvoiceCells.DOWNLOAD_ACTION]: {
            hideOnSmallDesktop: true,
            header: '',
            data: invoice.pdfUrl,
            content: (
              <Cell variant={CellVariant.ACTION}>
                <Link
                  href={invoice.pdfUrl}
                  as="a"
                  trailingIcon="download"
                  download
                  size="small"
                  variant="secondary"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => mixpanel.track(InvoiceEvents.DOWNLOAD_PDF)}
                >
                  {invoice.invoiceNumber}
                </Link>
              </Cell>
            )
          }
        })
      }
    } satisfies InvoiceTableDataRow<RegularTableCells>
  })
}

/**
 * Get the  invoice table data for a superuser
 * @template DocumentType - CustomerInvoice | CustomerPayment in this case
 * @param {GetTableData<DocumentType>} params
 * @returns {InvoiceTableDataRow<RegularTableCells>[]}
 */
export const getSuperUserInvoiceTableData = ({
  documents,
  isTabletOrMobile,
  language,
  showAddress,
  usesDirectDebit
}: GetTableData<CustomerInvoice | CustomerPayment>): InvoiceTableDataRow<SuperUserTableCells>[] => {
  return documents.map((document) => {
    const status = getStatus({ document: document, usesDirectDebit })

    return {
      id: document.id,
      uuid: crypto.randomUUID(),
      color: status.color,
      entries: {
        [InvoiceCells.INVOICE_NUMBER]: getInvoiceNumberCell(document),
        [InvoiceCells.DATE]: getDateCell(document),
        [InvoiceCells.DOCUMENT_TYPE]: getDocTypeCell(document),
        [InvoiceCells.AMOUNT]: getAmountCell(document, status, language, showAddress, isTabletOrMobile),
        [InvoiceCells.STATUS]: getStatusCell(document, status, showAddress, isTabletOrMobile),
        [InvoiceCells.ADDRESS]: getAddressCell(document),
        [InvoiceCells.RUNNING_BALANCE]: {
          content: formatCurrency(document.runningBalance, { language }),
          data: document.runningBalance,
          header: i18n.t('invoices:tableHeaders.outstanding')
        },
        [InvoiceCells.MATCHING_NUMBER]: {
          content: <AccountingInfo document={document} />,
          data: document.matchingNumber,
          header: i18n.t('invoices:tableHeaders.accountingInfo')
        },
        [InvoiceCells.PAYMENT_METHOD]: {
          content: (
            <Cell
              variant={CellVariant.PAYMENT_METHOD}
              title={
                document.directDebitSent || document.paymentSent
                  ? i18next.t(`invoices:cells.${document.paymentSent ? 'paymentSent' : 'directDebitSent'}`)
                  : undefined
              }
            >
              {document.directDebitSent && 'D'}
              {document.paymentSent && 'B'}
            </Cell>
          ),
          data: null,
          header: ''
        },
        [InvoiceCells.OPTIONS]: {
          content: <Options document={document} />,
          data: null,
          header: ''
        }
      }
    } satisfies InvoiceTableDataRow<SuperUserTableCells>
  })
}

/**
 * Get the  transactions table data for a regular customer
 * @template DocumentType - CustomerPayment in this case
 * @param {GetTableData<DocumentType>} params
 * @returns {InvoiceTableDataRow<RegularTableCells>[]}
 */
export const getTransactionTableData = ({
  documents,
  isTabletOrMobile,
  language,
  usesDirectDebit
}: GetTableData<CustomerPayment>): InvoiceTableDataRow<TransactionRows>[] => {
  return documents.map((payment) => {
    const status = getStatus({ document: payment, usesDirectDebit })

    return {
      id: payment.id,
      uuid: crypto.randomUUID(),
      color: status.color,
      entries: {
        [InvoiceCells.DATE]: getDateCell(payment),
        [InvoiceCells.DOCUMENT_TYPE]: getDocTypeCell(payment),
        [InvoiceCells.AMOUNT]: getAmountCell(payment, status, language, false, isTabletOrMobile),
        [InvoiceCells.STATUS]: getStatusCell(payment, status)
      }
    } satisfies InvoiceTableDataRow<TransactionRows>
  })
}
