import { getAxiosRequestConfig, newRequest } from 'utils/request'
import { Methods, Response } from 'types/request'
import { Granularity } from 'types/contracts'
import { PriceHistoryMetrics } from 'store/queries/bolt-api/contracts/types.ts'

/**
 * Get price history api call
 * @param {Granularity} granularity
 * @param {string} from YYYY-MM-DD notation
 * @param {string} until YYYY-MM-DD notation
 * @param {"kWh" | "MWh"} [unit]
 * @param {string} [authHeader]
 * @returns {Promise<PriceHistoryMetrics | null>}
 */
export const getPriceHistory = async (
  granularity: Granularity,
  from: string,
  until: string,
  unit?: 'kWh' | 'MWh',
  authHeader?: string
): Promise<PriceHistoryMetrics | null> => {
  const { success, data }: Response<{ priceHistory: PriceHistoryMetrics }> = await newRequest(
    getAxiosRequestConfig(
      Methods.GET,
      `/metrics/price-history?granularity=${granularity}&from=${from}&until=${until}&unit=${unit || 'MWh'}`,
      null,
      null,
      authHeader
        ? {
            Authorization: authHeader
          }
        : null
    )
  )

  return success && data?.priceHistory ? data.priceHistory : null
}
