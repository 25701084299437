import { getMarketPricesChartOptions } from 'components/Charts/market-prices/chartjs.config.ts'
import { Chart } from 'react-chartjs-2'
import { MarketPricesChartProps } from './types.ts'

const MarketPricesChartMobile = ({ chartData, setHoverDataPointIndex, isCompact }: MarketPricesChartProps) => {
  // Constants
  const options = getMarketPricesChartOptions(setHoverDataPointIndex, true, isCompact)

  return <Chart type="bar" options={options as any} data={chartData} />
}

export default MarketPricesChartMobile
