import { useStoreSelector } from 'hooks/store.ts'
import { Navigate } from 'react-router'
import { getIndexRedirectUrl } from '../utils/app.ts'

const RedirectToIndex = ({ replace }: { replace?: boolean }) => {
  const { userType } = useStoreSelector((store) => store.auth)

  return <Navigate to={getIndexRedirectUrl(userType)} {...{ replace }} />
}

export default RedirectToIndex
