import { AxiosRequestConfig } from 'axios'
import { CreateMeterReadingsRequest, ServiceContractElectricity, ServiceContractGas, UnknownServiceContract } from 'types/contracts'
import { CreateContractData } from 'features/contracts/add/types'
import { Methods, PortalUserDataObject, Response } from 'types/request'
import { roundToTwoDecimals } from 'utils/number'
import { getAxiosRequestConfig, newRequest } from 'utils/request'
import { store } from 'store/index.ts'
import { getLowerCaseLanguage } from 'utils/app.ts'
import { Language } from 'store/app/types.ts'
import { IS_BETA } from 'constants/envs.ts'
import { log } from 'utils/logging.ts'

/**
 * Creates a new contract (either for a new or existing customer)
 *
 * @param {CreateContractData} data
 * @returns {Promise<boolean>}
 */
export const createContract = async (data: CreateContractData): Promise<Response> => {
  if (IS_BETA) {
    log({ error: 'Cannot create a contract in beta', identifier: '[BETA:contracts]' })
    return { success: false }
  }
  return newRequest(getAxiosRequestConfig(Methods.POST, '/contracts', data))
}

/**
 * Creates meter readings
 *
 * @param {CreateMeterReadingsRequest} data
 * @returns {Promise<boolean>}
 */
export const saveMeterReadings = async (data: CreateMeterReadingsRequest): Promise<boolean> => {
  // Create user object
  const { contact, selected } = store.getState().contact
  const user = {
    email: contact!.email || '',
    firstName: contact!.firstName || '',
    language: getLowerCaseLanguage(contact!.language || Language.NL),
    customerNumber: selected!.account
  } satisfies PortalUserDataObject

  const { contractNumber, ...rest } = data
  const { success } = await newRequest(
    getAxiosRequestConfig(Methods.POST, `/contracts/${contractNumber}/meter-readings`, { user, ...rest })
  )

  return success
}

/**
 * Generates the bill shock axios request config for the given data
 *
 * @param {string} billingContractId
 * @param {string} email
 * @param {Contract} electricityContract
 * @param {Contract} gasContract
 * @returns {AxiosRequestConfig}
 */
export const generateBillShockAxiosRequestConfig = (
  billingContractId: string,
  email: string,
  electricityContract: ServiceContractElectricity,
  gasContract?: ServiceContractGas
): AxiosRequestConfig => {
  const data = {
    email,
    instalments: [
      {
        contractId: electricityContract.contractNumber,
        instalment: roundToTwoDecimals(Number(electricityContract.detail.instalment))
      },
      ...(gasContract
        ? [
            {
              contractId: gasContract.contractNumber,
              instalment: roundToTwoDecimals(Number(gasContract.detail.instalment))
            }
          ]
        : [])
    ]
  }

  return getAxiosRequestConfig(Methods.POST, `/contracts/${billingContractId}/bill-shock`, data)
}

/**
 * Generates the axios request config for fetching the meter readings
 *
 * @param {string} contractNumber
 * @param {boolean=} onlyProvidedByDgo
 * @returns {AxiosRequestConfig}
 */
export const generateGetMeterReadingsAxiosRequestConfig = (contractNumber: string, onlyProvidedByDgo?: boolean): AxiosRequestConfig => {
  const url = `/contracts/${contractNumber}/last-meter-reading`
  const searchParams = new URLSearchParams()

  if (onlyProvidedByDgo) searchParams.append('onlyProvidedByDgo', onlyProvidedByDgo.toString())

  return getAxiosRequestConfig(Methods.GET, url + searchParams.toString())
}

/**
 * Updates the dynamicTariff or monthlyBilling smart meter option for the given service contract
 * @param {Contract["id"]} serviceContractId
 * @param {Partial<Pick<Contract, "dynamicTariff" | "monthlyBilling">>} body
 * @returns {any}
 */
export const patchServiceContract = (
  serviceContractId: UnknownServiceContract['contractNumber'],
  body: Partial<Pick<UnknownServiceContract['detail'], 'dynamicTariff' | 'monthlyBilling'>>
): Promise<Response> => {
  return newRequest(getAxiosRequestConfig(Methods.PATCH, `/contracts/${serviceContractId}/update`, { contract: body }))
}
