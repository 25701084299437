export type EventLog = {
  type: EventLogType
  updaterEmail: string
  updaterAuthModel: AuthModel
  userEmail: string
  data: Record<string, unknown>
  createdAt: Date
}

// ENUMS

export enum EventLogType {
  BILLING_CYCLE_VIEWED = 'billingCycleViewed',
  CONTRACT_TERMINATED = 'contractTerminated',
  CREATE_CONTRACT = 'createContract',
  METER_READINGS_ADDED = 'meterReadingsAdded',
  PORTAL_BILLING_UPDATED = 'portalBillingUpdated',
  PORTAL_CUSTOMER_UPDATED = 'portalCustomerUpdated',
  PORTAL_INSTALMENT_UPDATED = 'portalInstalmentUpdated',
  PORTAL_SETTLEMENT_REQUESTED = 'portalSettlementRequested',
  SERVICE_CONTRACT_UPDATED = 'serviceContractUpdated'
}

export enum AuthModel {
  INTERNAL = 'internalUsers',
  SALES = 'salesUsers',
  USER = 'users'
}
