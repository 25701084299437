import { ConsumptionView, PlanConsumptionContentProps } from './types.ts'
import parse from 'html-react-parser'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { Response } from 'types/request.ts'
import { useTranslation } from 'react-i18next'
import { ReactElement, useMemo, useState } from 'react'
import { useGetPriceHistoryQuery } from 'store/queries/bolt-api/metrics'
import { Granularity } from 'types/contracts.ts'
import dayjs from 'dayjs'
import { getIndexesOfLowestOrHighestPrices } from './utils.ts'
import { TransfoErrorCodes } from 'types/billShock.ts'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'
import mixpanel from 'mixpanel-browser'
import { CommonTrackingParams, ConsumptionEvents, ConsumptionTrackingParams } from 'types/tracking.ts'
import { useLocation } from 'react-router'
import MarketPricesChart from 'components/Charts/market-prices/MarketPricesChart.tsx'
import { Banner, Toggle } from '@boltenergy-be/design-system'
import styles from '../MarketPrices.module.scss'
import { formatHour } from 'utils/date'

const MarketPricesContent = ({ compact }: PlanConsumptionContentProps) => {
  // i18n
  const { t } = useTranslation('marketPrices')

  // Router
  const { pathname } = useLocation()

  // Local state
  const [activeView, setActiveView] = useState<ConsumptionView>(ConsumptionView.TODAY)

  // Redux Query
  const skipFetchTomorrow = activeView === ConsumptionView.TOMORROW && dayjs().isBefore(dayjs().set('hour', 15))
  const { data, isLoading, isFetching, isError, error } = useGetPriceHistoryQuery(
    {
      granularity: Granularity.HOUR,
      from:
        activeView === ConsumptionView.TODAY ? dayjs().startOf('day').toISOString() : dayjs().startOf('day').add(1, 'day').toISOString(),
      until:
        activeView === ConsumptionView.TODAY
          ? dayjs().endOf('day').add(1, 'day').toISOString()
          : dayjs().endOf('day').add(2, 'day').toISOString(),
      unit: 'kWh'
    },
    { skip: skipFetchTomorrow }
  )

  // Constants
  const lowestPriceIndexes = getIndexesOfLowestOrHighestPrices(data?.priceHistory.electricity || [], 4, 'low')
  const highestPriceIndexes = getIndexesOfLowestOrHighestPrices(data?.priceHistory.electricity || [], 1, 'high')

  /**
   * Get error message based on error code
   * @param {TransfoErrorCodes | string} code
   * @returns {React.ReactElement}
   */
  const getErrorMessage = (code?: TransfoErrorCodes | string): ReactElement => {
    if (code === TransfoErrorCodes.E311_NO_DATA_AVAILABLE_FOR_FUTURE_DATES) {
      return <Banner type="informative">{parse(t('error.notYetKnown'))}</Banner>
    }

    return <Banner type="warning">{t('errorTryLater', { ns: 'common' })}</Banner>
  }

  /**
   * Set the hour to 00:00 if curr is 23, thus preventing the fetching of element 24 from the array of prices
   * @param {number} curr
   * @returns {string}
   */
  const correctLastHourLowestPrice = (curr: number) => {
    return curr === 23 ? '00:00' : formatHour(dayjs(data?.priceHistory.electricity[curr + 1].date))
  }

  // Memoized off-peak hours
  const offPeakHours = useMemo<{ from: string; until: string }[]>(() => {
    return lowestPriceIndexes
      .sort((a, b) => a - b)
      .reduce((rv: { from: string; until: string }[], curr, idx, original) => {
        if (!data?.priceHistory.electricity[curr].date) return rv

        // Always add first element with from & until hour
        if (!rv.length) {
          rv.push({
            from: formatHour(dayjs(data?.priceHistory.electricity[curr].date)),
            until: correctLastHourLowestPrice(curr)
          })
          return rv
        }

        // If the current index is a consecutive index compared to the previous one, update the until hour
        if (curr === original[idx - 1] + 1) {
          rv[rv.length - 1].until = correctLastHourLowestPrice(curr)
        } else {
          // Else, add a new entry
          rv.push({
            from: formatHour(dayjs(data?.priceHistory.electricity[curr].date)),
            until: correctLastHourLowestPrice(curr)
          })
        }

        return rv
      }, [])
  }, [data?.priceHistory.electricity, lowestPriceIndexes])

  return (
    <>
      <Toggle
        disabled={isLoading || isFetching}
        active={activeView}
        isFullwidth
        onClick={(view) => {
          mixpanel.track(ConsumptionEvents.PLAN_CONSUMPTION_TOGGLED, {
            [ConsumptionTrackingParams.VIEW]: view,
            [CommonTrackingParams.ROUTE]: pathname
          })
          setActiveView(view)
        }}
        options={[
          { value: ConsumptionView.TODAY, label: t('toggle.today') },
          { value: ConsumptionView.TOMORROW, label: t('toggle.tomorrow') }
        ]}
      />

      {!compact &&
        !isError &&
        !skipFetchTomorrow &&
        (isFetching || isLoading ? (
          <LoadingSkeleton>
            <LoadingSkeleton.Rectangle width="100%" height={40} />
          </LoadingSkeleton>
        ) : (
          <Banner type="informative">
            {parse(
              t('offPeakHours.text', {
                unit: activeView === ConsumptionView.TODAY ? t('toggle.today') : t('toggle.tomorrow'),
                ranges: offPeakHours.map((range, idx) =>
                  idx === 0 ? `${range.from} - ${range.until}` : t('offPeakHours.separator', { range: `${range.from} - ${range.until}` })
                )
              }).replace(/,/g, '')
            )}
          </Banner>
        ))}

      {/* CHART */}
      {isLoading || isFetching ? (
        <LoadingSkeleton className={styles['loader-group']}>
          {!compact && (
            <>
              <LoadingSkeleton.Rectangle height={60} />
              <LoadingSkeleton.Rectangle height={60} />
            </>
          )}
          <LoadingSkeleton.Rectangle className={styles['loader-portal']} aspectRatio="2.5 / 1" />
        </LoadingSkeleton>
      ) : isError || skipFetchTomorrow || !data?.priceHistory.electricity.length ? (
        <>
          {getErrorMessage(
            skipFetchTomorrow
              ? TransfoErrorCodes.E311_NO_DATA_AVAILABLE_FOR_FUTURE_DATES
              : isError
                ? ((error as FetchBaseQueryError).data as Response)?.message
                : 'error'
          )}

          <div className={styles.placeholder} />
        </>
      ) : (
        <MarketPricesChart
          data={data?.priceHistory.electricity || []}
          isCompact={compact}
          {...{ lowestPriceIndexes, highestPriceIndexes }}
        />
      )}
    </>
  )
}

export default MarketPricesContent
