import { ELProduct, NGProduct } from 'types/products'
import { ContactModes, Direction, PhysicalMeterType, UnknownServiceContract } from 'types/contracts.ts'
import { AddressWithCountryAndTownCode } from 'types/general.ts'
import { LanguageLowercase } from 'store/app/types.ts'
import resources from 'translations/dutch'
import { RawStrapiList, RawStrapiTypeInsideData } from 'types/cms.ts'

// GENERAL TYPES

export type AddressTile = {
  billingNumber: string
  address: AddressWithCountryAndTownCode
  terminated: boolean
  customerId: UnknownServiceContract['customerNumber']
  isProfessional: boolean
  contracts: {
    electricity: boolean | null
    gas: boolean | null
  }
  terminatedContracts: {
    electricity: boolean | null
    gas: boolean | null
  }
  subscriptionDate: UnknownServiceContract['detail']['subscriptionDate']
}

export type Contact = {
  contactMode: ContactModes
  email: string
  phoneMobile: string
}

export type Email = {
  subject: string
  message: string
}

export type FaqQuestion = {
  id: number
  question: string
  answer: string
  subcategory?: RawStrapiTypeInsideData<FaqSubCategory>
  isFeatured?: boolean
  isNewPortal?: boolean
}

export type FaqCategory = {
  id: number
  name: string
  slug: string
  order: number
  locale: string
  questions: FaqQuestion[]
  subcategories: RawStrapiList<FaqSubCategory>
}

export type FaqSubCategory = {
  name: string
  slug: string
}

export type Invoice = {
  date: string
  expireDate: string
  type: InvoiceType
  payed: boolean
  paymentURL: string
  invoiceURL: string
  mandateNbr: string
  amount: string
  invoiceNbr: string
}

type CustomerGeneralDocument = {
  id: string
  date: string
  amount: number
  runningBalance: number
  paid: boolean
  matchingNumber: number
  directDebitSent: boolean
  paymentSent: boolean
  accountingEntity: AccountingEntity
}

export type CustomerInvoice = {
  documentType: DocumentType.INVOICE
  pdfUrl: string
  paymentUrl: string
  invoiceType: InvoiceType
  dueDate: string
  electricityContractNumber: string
  invoiceNumber: number
} & CustomerGeneralDocument

export type CustomerPayment = {
  lineType: number
  documentType: DocumentType.PAYMENT
} & CustomerGeneralDocument

export type CustomerAccountingDocument = CustomerInvoice | CustomerPayment
export type InvoiceTypeTranslationKey = keyof typeof resources.invoices.invoiceTypes

export type Person = {
  firstName: string
  lastName: string
  birthDate?: string
}

export type PreviousIndex = {
  meterNumber: string
  meterType: PhysicalMeterType
  registers: Register[]
}

export type BiLingualValue<T> = {
  [LanguageLowercase.NL]: T
  [LanguageLowercase.FR]: T
}

export type Product = {
  electricity: ELProduct
  gas: NGProduct
}

export type Register = {
  registerName: string
  direction: Direction
  timeframeCode: TimeframeCode
}

export type ReservedContract = {
  id: string
  consumption: number
}

export type Town = {
  postalCode: number
  townName: string
  townCode: number
  mainTownName: string
  geoCode: string
  languageCode: 'NL' | 'FR'
}

// ENUMS

export enum AccountingEntity {
  CUSTOMER = 'customer',
  SUPPLIER = 'supplier'
}

export enum ClientType {
  PROFESSIONAL = 'Professional',
  RESIDENTIAL = 'Residential'
}

export enum SimplifiedContractStatus {
  INACTIVE = 'inactive',
  IN_PROGRESS = 'inProgress',
  CANCELLED = 'cancelled',
  TERMINATED = 'terminated',
  ACTIVE = 'active'
}

export enum CustomerCategory {
  B2B = 'B2B',
  RES = 'RES',
  SME = 'SME',
  SOHO = 'SOHO'
}

export enum CustomerStatus {
  VALIDATED = 'Validated',
  NOT_VALIDATED = 'Not Validated'
}

export enum DeliveryMode {
  EMAIL = 'Email',
  POST = 'Post'
}

export enum InvoiceType {
  INSTALMENT = 'VFVF',
  INSTALMENT_CREDITNOTE = 'CNVF',
  SETTLEMENT = 'VFAF',
  SETTLEMENT_CREDITNOTE = 'CNAF',
  FINAL_SETTLEMENT = 'VFEA',
  FINAL_SETTLEMENT_CREDITNOTE = 'CNEA',
  INJECTION_SETTLEMENT_PRO = 'VFAI',
  INJECTION_SETTLEMENT_PRO_CREDITNOTE = 'CNAI',
  INJECTION = 'VFIN',
  INJECTION_CREDITNOTE = 'CNIN',
  BUDGET_METER = 'VFBM',
  BUDGET_METER_CREDITNOTE = 'CNBM',
  RECTIFICATION = 'VFRE',
  RECTIFICATION_CREDITNOTE = 'CNRE',
  CANCEL = 'VFCA',
  CANCEL_CREDITNOTE = 'CNCA',
  DIVERSE = 'VFDV',
  DIVERSE_CREDITNOTE = 'CNDV',
  MANUAL_CREDITNOTE = 'CNMN',
  CASHBACK = 'BCNMN',
  PURCHASE_INJECTION = 'AKIN',
  PURCHASE_INJECTION_CREDITNOTE = 'ACIN',
  PURCHASE_INJECTION_PRO = 'AKAI',
  PURCHASE_INJECTION_PRO_CREDITNOTE = 'ACAI',
  MISC_INVOICE = 'VFAK',
  MISC_CREDITNOTE = 'CNAK'
}

export enum ProductType {
  ELECTRICITY = 'electricity',
  GAS = 'gas'
}

export enum TimeframeCode {
  HIGH = 'HIGH',
  LOW = 'LOW',
  NIGHT_EXCLUSIVE = 'NIGHT_EXCLUSIVE',
  NOT_USED = 'NOT_USED',
  TOTAL_HOUR = 'TOTAL_HOUR'
}

export enum VatApplication {
  SUBJECT_TO = 'Subject to',
  NOT_SUBJECT_TO = 'Not Subject to'
}

export enum DocumentType {
  INVOICE = 'invoice',
  PAYMENT = 'payment'
}
