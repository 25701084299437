import Card from 'components/Card/Card'
import { ConditionalCardProps } from './types'

const ConditionalCard = ({ asCard, children, className }: ConditionalCardProps) => {
  return asCard ? (
    <Card padding={600} className={className}>
      {children}
    </Card>
  ) : (
    <div className={className}>{children}</div>
  )
}

export default ConditionalCard
