import contractsStyles from 'features/contracts/Contracts.module.scss'
import classNames from 'classnames'
import { Language } from 'store/app/types.ts'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { DocumentUploadInputs } from './types.ts'
import { useStoreSelector } from 'hooks/store.ts'
import parse from 'html-react-parser'
import { Button, TextField } from '@boltenergy-be/design-system'
import { useRef } from 'react'
import useWindowSize from 'hooks/useWindowSize.tsx'
import styles from './DocumentUpload.module.scss'
import { MAX_FILE_SIZE } from './constant.ts'

const DocumentUpload = () => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)

  // Window size
  const { isTablet } = useWindowSize()

  // i18n
  const { t } = useTranslation('contracts')

  // References
  const fileInputRef = useRef<HTMLInputElement>(null)

  // React Hook Form
  const { register, watch, formState, setValue } = useFormContext<DocumentUploadInputs>()
  const { errors } = formState
  const watchDocUpload = watch('docUpload')

  return (
    <div className="grid-col-full">
      <fieldset className="column form-group">
        <legend className={contractsStyles['fieldset-legend']}>{t('add.steps.meterDetails.form.docUpload')}</legend>
        <p className="mb-400">
          {parse(
            t('common.documentAlert', {
              url: 'https://www.boltenergie.be/nl/encyclopedie/energie-thuis/regel-energiecontract-voor-verhuis/energieovernamedocument-nodig'
            })
          )}
        </p>
        <div className={styles.upload}>
          <input
            hidden
            type="file"
            accept="application/pdf, image/jpeg, image/png, image/heic, image/heif"
            multiple={false}
            {...register('docUpload', {
              validate: (value) => {
                if (value && value[0]) return value[0]?.size < MAX_FILE_SIZE

                return true
              }
            })}
            onChange={(event) => {
              setValue('docUpload', event.currentTarget.files, { shouldValidate: true })
            }}
            id="docUpload"
            className={classNames('form-control', { error: errors?.docUpload })}
            ref={fileInputRef}
          />

          {watchDocUpload && watchDocUpload[0] && (
            <TextField
              label=""
              name="file"
              type="text"
              value={watchDocUpload[0].name}
              readOnly
              layoutClassName={styles.file}
              error={
                errors?.docUpload?.type === 'validate' &&
                t('invalid.fileSize', { size: language === Language.NL ? '4mb' : '4mo', ns: 'validation' })
              }
              suffix={{
                as: (
                  <Button
                    leadingIcon="cross"
                    className={styles.delete}
                    variant="tertiary"
                    size="small"
                    onClick={() => setValue('docUpload', null)}
                  />
                )
              }}
            />
          )}

          <Button variant={isTablet ? 'layered' : 'secondary'} isFullwidth onClick={() => fileInputRef?.current?.click()} type="button">
            {t('chooseFile', { ns: 'common' })}
          </Button>
        </div>
      </fieldset>
    </div>
  )
}

export default DocumentUpload
