import { useEffect, useLayoutEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router'
import { routes } from 'types/routes'
import styles from 'pages/App/App.module.scss'
import mixpanel from 'mixpanel-browser'
import EnergyTransitionDocumentContextProvider from 'store/react-context/energy-transition-document/EnergyTransitionDocumentContextProvider.tsx'
import { useStoreDispatch, useStoreSelector } from 'hooks/store.ts'
import MainNavigation from 'layouts/components/navigation/MainNavigation.tsx'
import { UserTypes } from 'store/auth/types.ts'
import { logout } from 'utils/app.ts'
import { isAuthenticated } from 'utils/localStorageService.ts'
import { getSalesUserData } from 'store/sales/thunks'
import { setUserType } from 'utils/userType.ts'
import { deleteRedirect } from 'store/app/slice.ts'
import { getUserTypeLoginPage } from 'utils/user.ts'
import PageLoading from 'components/PageLoading/PageLoading.tsx'
import classNames from 'classnames'
import useGetRouteLayout from 'hooks/useGetRouteLayout.ts'
import { Layouts } from 'layouts/types.ts'
import { IS_BETA } from 'constants/envs.ts'

const SalesApp = () => {
  // Redux
  const { redirect } = useStoreSelector((store) => store.app)
  const { userType } = useStoreSelector((store) => store.auth)
  const { loading, error } = useStoreSelector((store) => store.sales)
  const dispatch = useStoreDispatch()

  // React Router
  const { pathname } = useLocation()
  const navigate = useNavigate()

  // Auth constants
  const authenticatedUserTypes = isAuthenticated()

  // Constants
  const { layout } = useGetRouteLayout()
  const isEditingLayout = layout === Layouts.EDIT_LAYOUT
  const hideNavigation = pathname === routes.SALES_CONTRACTS_ADD || isEditingLayout

  /**
   * Handle things on first render
   */
  useEffect(() => {
    // Redirect to the regular portal or logout if the user is not a sales user
    if (!authenticatedUserTypes[UserTypes.SALES]) {
      logout(() => navigate(getUserTypeLoginPage(UserTypes.SALES)), UserTypes.SALES)
    }

    // Change the page title
    document.title = 'Bolt Sales Portal'

    // Set the user type
    setUserType(UserTypes.SALES)

    // Fetch the sales user data
    dispatch(getSalesUserData())
  }, [])

  useEffect(() => {
    if (redirect && redirect.includes('/sales')) {
      navigate(redirect)
    }
    // Clear redirect from AppStore
    dispatch(deleteRedirect())
  }, [redirect])

  // Define mixpanel super property
  useEffect(() => {
    mixpanel.register({
      userType: userType,
      beta: IS_BETA
    })
  }, [userType])

  /**
   * Handles the logout with correct redirect
   */
  const handleLogout = () => logout(() => navigate(routes.LOGIN_SALES))

  // Smaller sidebar-width for sales portal
  useLayoutEffect(() => {
    const variableSelector = '--sidebar-width'
    const sidebarWidth = getComputedStyle(document.documentElement).getPropertyValue(variableSelector)

    // Only update CSS variable if necessary
    if (sidebarWidth !== '260px') {
      document.documentElement.style.setProperty(variableSelector, '260px')
    }
  }, [])

  return (
    <EnergyTransitionDocumentContextProvider>
      <div className={classNames(styles['app-layout'], { [styles['has-navigation']]: !hideNavigation })}>
        {!hideNavigation && <MainNavigation handleLogout={handleLogout} isSales={userType === UserTypes.SALES} />}
        <main className={classNames(styles.main, { [styles.wide]: hideNavigation })}>
          {loading || error ? <PageLoading isLoading={loading} showError={!!error} /> : <Outlet />}
        </main>
      </div>
    </EnergyTransitionDocumentContextProvider>
  )
}

export default SalesApp
