import { Criticality, IdentifierFormats, Logging, Severity } from 'types/logging.ts'
import Bugsnag from '@bugsnag/js'
import { HANDLED_ERRORS } from 'constants/errors.ts'

/**
 * Logs the error and metadata to Bugsnag
 * @param {Logging} paramsObject
 */
export const log = <T extends IdentifierFormats>({
  error,
  metaData,
  identifier,
  severity = Severity.ERROR,
  criticality = Criticality.MEDIUM
}: Logging<T>) => {
  const isHandledError = typeof error === 'string' ? HANDLED_ERRORS.includes(error) : HANDLED_ERRORS.includes(error?.message)

  Bugsnag.notify(error, (event) => {
    if (metaData) Object.keys(metaData).forEach((section) => event.addMetadata(section, metaData[section]))
    event.context = `[${criticality.toUpperCase()}] - ${identifier}`
    event.severity = isHandledError ? Severity.INFO : severity
  })
}
