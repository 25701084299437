import { MarketPricesChartProps, MarketPricesWrapperProps } from 'components/Charts/market-prices/types.ts'
import { BarElement, CategoryScale, Chart as ChartJS, LinearScale, PointElement } from 'chart.js'
import { yScaleTitle } from 'components/Charts/plugins.ts'
import useWindowSize from 'hooks/useWindowSize.tsx'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { getMarketPricesChartDatasets } from 'components/Charts/market-prices/chartjs.config.ts'
import MarketPricesChartMobile from 'components/Charts/market-prices/ChartMobile.tsx'
import MarketPricesChartDesktop from 'components/Charts/market-prices/ChartDesktop.tsx'
import styles from 'components/Charts/Charts.module.scss'
import Totals from 'pages/App/consumption/your-consumption/components/totals-block/TotalsBlock.tsx'
import { DSChartColors, DSFunctionalColors } from 'types/colors.ts'
import { formatAmount } from 'utils/format.ts'
import { useTranslation } from 'react-i18next'
import { useStoreSelector } from 'hooks/store.ts'
import classNames from 'classnames'

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, yScaleTitle)

const MarketPricesChart = ({ data, lowestPriceIndexes, highestPriceIndexes, isCompact }: MarketPricesWrapperProps) => {
  // Redux
  const { isProducer } = useStoreSelector((store) => store.contact)

  // i18n
  const { t } = useTranslation(['marketPrices', 'common'])

  // Custom hooks
  const { isTablet } = useWindowSize()

  // Local state
  const [activeDataPointIndex, setActiveDataPointIndex] = useState<number | null>(null)

  // Constants
  const labels = data.map((entry) => dayjs(entry.date).format('HH'))

  // Chart data
  const chartData = useMemo<any>(
    () => ({
      labels,
      datasets: getMarketPricesChartDatasets({
        labels,
        data,
        lowestIndexes: lowestPriceIndexes,
        compact: !!isCompact,
        isProducer
      })
    }),
    [data, labels, lowestPriceIndexes, isCompact, isProducer]
  )

  // Props to pass
  const chartProps: MarketPricesChartProps = {
    chartData,
    setHoverDataPointIndex: setActiveDataPointIndex,
    isCompact
  }

  // Reset active data point index if data is not available
  useEffect(() => {
    if (typeof activeDataPointIndex === 'number' && !data[activeDataPointIndex]) {
      setActiveDataPointIndex(null)
    }
  }, [activeDataPointIndex, data])

  return (
    <>
      <Totals compact={isCompact}>
        {typeof activeDataPointIndex === 'number' && !!data[activeDataPointIndex] ? (
          <Totals.Block
            label={t('totals.priceElectricity')}
            indicator={{
              color: DSChartColors.GREY,
              full: true
            }}
          >
            <data value={data[activeDataPointIndex].price}>
              {t('common:units.currencyPerKwh', { value: formatAmount(data[activeDataPointIndex].price, 3) })}
            </data>
          </Totals.Block>
        ) : isProducer ? (
          <>
            <Totals.Block
              label={t('totals.highestPrice')}
              indicator={{
                icon: 'arrowRightTop',
                color: DSFunctionalColors.POSITIVE
              }}
            >
              <strong>{t('common:units.currencyPerKwh', { value: formatAmount(data[highestPriceIndexes[0]]?.price, 3) })}</strong>
            </Totals.Block>

            <Totals.Block
              label={t('totals.lowestPrice')}
              indicator={{
                icon: 'arrowRightBottom',
                color: DSFunctionalColors.NEGATIVE
              }}
            >
              <strong>{t('common:units.currencyPerKwh', { value: formatAmount(data[lowestPriceIndexes[0]]?.price, 3) })}</strong>
            </Totals.Block>
          </>
        ) : (
          <>
            <Totals.Block
              label={t('totals.lowestPrice')}
              indicator={{
                color: DSChartColors.GREEN,
                full: true
              }}
            >
              <strong>{t('common:units.currencyPerKwh', { value: formatAmount(Math.min(...data.map((entry) => entry.price)), 3) })}</strong>
            </Totals.Block>

            <Totals.Block label={t('totals.averagePrice')}>
              <strong>
                {t('common:units.currencyPerKwh', {
                  value: formatAmount(data.reduce((total: number, curr) => total + curr.price, 0) / data.length, 3)
                })}
              </strong>
            </Totals.Block>
          </>
        )}
      </Totals>

      <div className={classNames(styles['chart-wrapper'], { [styles.compact]: isCompact })}>
        {isTablet || isCompact ? <MarketPricesChartMobile {...chartProps} /> : <MarketPricesChartDesktop {...chartProps} />}
      </div>
    </>
  )
}

export default MarketPricesChart
