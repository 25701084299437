export enum routes {
  // AUTH (customer)
  LOGIN = '/login',
  REGISTER = '/register',
  ACTIVATE = '/activate/:token',
  REQUEST_NEW_PASSWORD = '/new-password',
  RESET_PASSWORD = '/reset/:token',

  // AUTH (sales)
  LOGIN_SALES = '/sales/login',
  ACTIVATE_SALES = '/sales/activate/:token',
  REQUEST_NEW_PASSWORD_SALES = '/sales/new-password',
  RESET_PASSWORD_SALES = '/sales/reset/:token',

  // AUTH (superuser)
  LOGIN_SUPER_USER = '/superuser/login',
  ACTIVATE_SUPER_USER = '/superuser/activate/:token',
  REQUEST_NEW_PASSWORD_SUPER_USER = '/superuser/new-password',
  RESET_PASSWORD_SUPER_USER = '/superuser/reset/:token',

  // CUSTOMER/PRODUCER PORTAL
  ACCOUNT_SELECTION = '/accounts',
  CUSTOMER_EMAIL = '/customer-email',

  MORE = '/more',

  OVERVIEW = '/overview',
  PRODUCER = '/producer',

  ADDRESSES = '/addresses',
  ADDRESS_DETAIL = '/addresses/:id',

  BILLING = '/billing',
  BILLING_INVOICES = '/billing/invoices',
  BILLING_INSTALMENT = '/billing/instalment',
  BILLING_EDIT_INSTALMENT = '/billing/instalment/edit',
  BILLING_SETTLEMENT = '/billing/settlement',
  BILLING_CYCLES = '/billing/billing-cycles',
  BILLING_CYCLE_DETAIL = '/billing/billing-cycles/:id',

  CONSUMPTION = '/consumption',
  CONSUMPTION_ELECTRICITY = '/consumption/electricity',
  CONSUMPTION_GAS = '/consumption/gas',
  CONSUMPTION_METER_READINGS = '/consumption/meter-readings',
  CONSUMPTION_EDIT_METER_READINGS = '/consumption/meter-readings/edit',

  MARKET_PRICES = '/market-prices',

  GUARANTEE_OF_ORIGIN = '/guarantee-of-origin',
  GUARANTEE_OF_ORIGIN_STATUS = '/guarantee-of-origin/status',

  ENERGY_OVERVIEW = '/energy-overview',

  REFERRAL = '/referral',
  REFERRAL_FRIENDS = '/referral/friends',
  REFERRAL_INFO = '/referral/info',

  CONTRACT = '/contract',
  CONTRACT_MY_CONTRACT = '/contract/my-contract',

  USER = '/user',
  USER_PERSONAL = '/user/personal',
  USER_CONTACT = '/user/contact',
  USER_EDIT_CONTACT = '/user/contact/edit',
  USER_PAYMENT = '/user/payment',
  USER_EDIT_PAYMENT = '/user/payment/edit',

  MOVE = '/move',

  SUPPORT = '/support',
  SUPPORT_FAQ = '/support/faq',
  SUPPORT_CONTACT = '/support/contact',

  EVENT_LOG = '/event-log',

  CONTRACTS = '/contracts',
  CONTRACTS_ADD = '/contracts/add',
  CONTRACTS_MOVE = '/contracts/move',
  CONTRACTS_TERMINATE = '/contracts/terminate',

  // SALES ROUTES
  SALES_HOME = '/sales/home',
  SALES_CONTRACTS_ADD = '/sales/contracts/add',
  SALES_DETAILS = '/sales/details/:contactId',
  SALES_EVENTS = '/sales/events'
}
