import { useStoreDispatch, useStoreSelector } from 'hooks/store.ts'
import { useMemo, useState } from 'react'
import { EditMeterReadingsFormInputs, MeterReadingInputLayoutContract } from './types.ts'
import { Direction, MeterReading, MeterReadingSource, MeterReadingsPayload } from 'types/contracts.ts'
import { ConfirmationModalProps } from './components/confirmation-modal/types.ts'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { selectCurrentContracts } from 'store/contact/selectors.ts'
import { determineElMeterReadingInputLayoutContract, exceedsCap, generateCreateMeterReadingRequest, hasLowerValue } from './utils.ts'
import { isActiveContract } from 'utils/contracts.ts'
import { registerLocale } from 'react-datepicker'
import { Language } from 'store/app/types.ts'
import { nlBE } from 'date-fns/locale/nl-BE'
import { fr } from 'date-fns/locale/fr'
import { saveMeterReadings } from 'api/contracts.ts'
import { updateMeterReadings } from 'store/contracts/slice.ts'
import { ProductType } from 'types/types.ts'
import styles from './EditMeterReadings.module.scss'
import { Banner, Button, DatePicker } from '@boltenergy-be/design-system'
import ConfirmationModal from './components/confirmation-modal/ConfirmationModal.tsx'
import { useNavigate } from 'react-router'
import { getLowerCaseLanguage } from 'utils/app.ts'
import MeterReadingCard from './components/meter-reading-card/MeterReadingCard.tsx'
import { boltApi } from 'store/queries/bolt-api'
import { TagTypes } from 'store/queries/bolt-api/types.ts'
import mixpanel from 'mixpanel-browser'
import { ConsumptionEvents } from 'types/tracking.ts'

const EditMeterReadings = () => {
  // REDUX STORE
  const { language } = useStoreSelector((store) => store.app)
  const contactStore = useStoreSelector((store) => store.contact)
  const {
    meterReadings: { data: meterReadingsData }
  } = useStoreSelector((store) => store.contracts)
  const dispatch = useStoreDispatch()

  // Local state
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [meterReadingsPayload, setMeterReadingsPayload] = useState<MeterReadingsPayload>()
  const [confirmationState, setConfirmationState] = useState<ConfirmationModalProps['state']>('LOW')

  // i18n
  const { t } = useTranslation('consumption')

  // Router
  const navigate = useNavigate()

  // DayJS
  const now = dayjs()

  // React Hook Form
  const hookForm = useForm<EditMeterReadingsFormInputs>({
    mode: 'onBlur',
    defaultValues: {
      date: now.toDate()
    }
  })
  const { control, handleSubmit } = hookForm

  // Contracts
  const { electricity, gas } = selectCurrentContracts(contactStore).serviceContracts

  // Memos
  const { allElOptional, electricity: electricityInputLayoutContract } = useMemo<MeterReadingInputLayoutContract>(
    () => determineElMeterReadingInputLayoutContract(electricity, meterReadingsData),
    [meterReadingsData, electricity]
  )

  // Constants
  const hasEffectiveGasContract = gas && isActiveContract(gas)

  // Locale for React Datepicker
  registerLocale(language, language === Language.NL ? nlBE : fr)

  /**
   * Handles the billshock call after confirmation
   *
   * @param {MeterReadingsPayload} meterReadingsPayload
   */
  const handleConfirm = async (meterReadingsPayload: MeterReadingsPayload) => {
    setLoading(true)

    const [electricitySuccess, gasSuccess]: boolean[] = await Promise.all([
      saveMeterReadings(generateCreateMeterReadingRequest(meterReadingsPayload, electricity)),
      ...(hasEffectiveGasContract ? [saveMeterReadings(generateCreateMeterReadingRequest(meterReadingsPayload, gas, true))] : [])
    ])
    if (electricitySuccess && (!hasEffectiveGasContract || gasSuccess)) {
      // Close modal
      navigate(-1)

      if (
        meterReadingsData?.electricity?.date &&
        dayjs(meterReadingsPayload?.date).isSameOrAfter(dayjs(meterReadingsData.electricity.date))
      ) {
        // Save new meter readings to store
        const newMeterReadings = {
          ...(meterReadingsPayload.electricity && {
            electricity: {
              ean: meterReadingsPayload.electricity.ean,
              date: meterReadingsPayload.date,
              source: MeterReadingSource.CLIENT_PROVIDED,
              consumption: meterReadingsPayload.electricity.consumption,
              injection: meterReadingsPayload.electricity.injection
            }
          }),
          ...(meterReadingsPayload.gas && {
            gas: {
              ean: meterReadingsPayload.gas.ean,
              date: meterReadingsPayload.date,
              source: MeterReadingSource.CLIENT_PROVIDED,
              consumption: meterReadingsPayload.gas?.consumption
            }
          })
        }

        dispatch(updateMeterReadings(newMeterReadings))
        dispatch(boltApi.util.invalidateTags([TagTypes.BILL_SHOCK, TagTypes.CONSUMPTION]))
        mixpanel.track(ConsumptionEvents.CONFIRM_ADD_METER_READING)
      }
    } else {
      setLoading(false)
      setError(true)
    }
  }

  /**
   * Handles the form submit after validation by React Hook Form
   *
   * @param {EditMeterReadingsFormInputs} data
   */
  const onSubmit = (data: EditMeterReadingsFormInputs) => {
    const meterReadingsPayload = {
      date: data.date.toISOString(),
      electricity: {
        ean: electricity.deliveryPoint.ean,
        consumption: {
          singleRate: data.electricity?.consumption?.total ?? undefined,
          exclNight: data.electricity?.consumption?.exclNight ?? undefined,
          ...((typeof data.electricity?.consumption?.day !== 'undefined' || typeof data.electricity.consumption?.night !== 'undefined') && {
            doubleRate: {
              day: data.electricity?.consumption?.day ?? undefined,
              night: data.electricity?.consumption?.night ?? undefined
            }
          })
        } as MeterReading,
        ...((typeof data.electricity.injection?.total !== 'undefined' ||
          typeof data.electricity.injection?.day !== 'undefined' ||
          typeof data.electricity.injection?.night !== 'undefined') && {
          injection: {
            singleRate: data.electricity.injection?.total ?? undefined,
            doubleRate: {
              day: data.electricity.injection?.day ?? undefined,
              night: data.electricity.injection?.night ?? undefined
            }
          }
        })
      },
      ...(hasEffectiveGasContract && {
        gas: {
          ean: gas.deliveryPoint.ean,
          consumption: {
            singleRate: data.gas?.total ?? undefined
          }
        }
      })
    }
    setMeterReadingsPayload(meterReadingsPayload)

    const hasLower = hasLowerValue(meterReadingsPayload, meterReadingsData)
    const hasHigher = exceedsCap(meterReadingsPayload, meterReadingsData)

    // Check if at least one value is lower than previous entry to show confirmation modal
    if (meterReadingsData && (hasLower || hasHigher)) {
      setIsConfirmationModalOpen(true)
      setConfirmationState(hasHigher && hasLower ? 'BOTH' : hasLower ? 'LOW' : 'HIGH')
    } else {
      // Proceed without confirmation
      handleConfirm(meterReadingsPayload)
    }
  }

  return (
    <>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="date"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => {
            return (
              <DatePicker
                label={t('editMeterReadings.form.date')}
                language={getLowerCaseLanguage(language)}
                maxDate={now.toDate()}
                selected={dayjs(value).toDate()}
                onChange={onChange}
                suffix={{ as: 'calendar', className: styles.calendar }}
              />
            )
          }}
        />

        <FormProvider {...hookForm}>
          {/* ELECTRICITY INPUTS */}
          {electricityInputLayoutContract.consumption && (
            <MeterReadingCard
              type={ProductType.ELECTRICITY}
              direction={Direction.CONSUMPTION}
              inputLayout={electricityInputLayoutContract}
              {...{ allElOptional }}
            />
          )}

          {/* INJECTION INPUTS */}
          {electricityInputLayoutContract.injection && (
            <MeterReadingCard
              type={ProductType.ELECTRICITY}
              direction={Direction.PRODUCTION}
              inputLayout={electricityInputLayoutContract}
              {...{ allElOptional }}
            />
          )}

          {/* GAS INPUTS */}
          {hasEffectiveGasContract && (
            <MeterReadingCard
              type={ProductType.GAS}
              direction={Direction.CONSUMPTION}
              inputLayout={electricityInputLayoutContract}
              {...{ allElOptional }}
            />
          )}
        </FormProvider>

        {error && (
          <Banner type="blocking">
            <span>{t('formFeedback.error', { ns: 'common' })}</span>
          </Banner>
        )}

        <div className={styles.actions}>
          <Button size="large" isFullwidth loading={loading}>
            {t('confirm', { ns: 'common' })}
          </Button>

          <Button type="button" size="large" isFullwidth variant="secondary" onClick={() => navigate(-1)}>
            {t('cancel', { ns: 'common' })}
          </Button>
        </div>
      </form>

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        state={confirmationState}
        setClose={() => setIsConfirmationModalOpen(false)}
        onSubmit={() => meterReadingsPayload && handleConfirm(meterReadingsPayload)}
      />
    </>
  )
}

export default EditMeterReadings
