import { FC } from 'react'
import { RenewableSourceCardProps as Props } from './types'
import { energyToIconName } from 'utils/mapping'
import styles from './RenewableSourceCard.module.scss'
import { Icon } from '@boltenergy-be/design-system'

const RenewableSourceCard: FC<Props> = ({ energyType, tag, pct, mwh }) => {
  return (
    <div className={styles.card}>
      <div className={styles.head}>
        <div className={styles.icon}>
          <Icon name={energyToIconName(energyType)} />
        </div>
        <div className={styles.tag}>{tag}</div>
      </div>
      <div className={styles.info}>
        <h3 className={styles.pct}>{pct}%</h3>
        <div className={styles.mwh}>{mwh} MWh</div>
      </div>
    </div>
  )
}

export default RenewableSourceCard
