import { UserTypes } from 'store/auth/types'
import { routes } from 'types/routes'

export const USER_TYPE_CONFIG: { [key in UserTypes]: { home: routes; login: routes } } = {
  [UserTypes.CUSTOMER]: {
    home: routes.ACCOUNT_SELECTION,
    login: routes.LOGIN
  },
  [UserTypes.SALES]: {
    home: routes.SALES_HOME,
    login: routes.LOGIN_SALES
  },
  [UserTypes.SUPER_USER]: {
    home: routes.CUSTOMER_EMAIL,
    login: routes.LOGIN_SUPER_USER
  }
}
