import { IndexesFormType } from 'components/IndexesFormInputs/types'
import { LanguageLowercase, MoveFlows } from 'store/app/types'
import { ReferralFormInputs } from 'features/contracts/referral/types.ts'
import { AddressWithCountryAndTownCode } from 'types/general.ts'
import { ContractDataWithIndexes } from 'types/contracts.ts'

// TYPES

export type TerminateContractRequest = {
  moveFlow: MoveFlows
  churnReason: ChurnReasons
  date: string // ISO
  deliveryAddress: AddressWithCountryAndTownCode
  digitalMeter: boolean
  document?: string
  email: string
  language: LanguageLowercase
  referral?: ReferralFormInputs
  contracts: ContractDataWithIndexes[]
}

export type TerminateDataInputs = {
  date: string
  docUpload: FileList | null
  digitalMeter: boolean
  indexes: IndexesFormType
}

export type ChurnReasonInputs = ChurnReasons | undefined

export type TerminateData = {
  [TerminateSteps.CHURN]: ChurnReasonInputs
  [TerminateSteps.METER_DETAILS]: TerminateDataInputs
  [TerminateSteps.REFERRAL]?: ReferralFormInputs
}

export type TerminateStepProps = {
  setNextStep: (currentStepData: CurrentStepData, nextStep: TerminateSteps) => void
  setCurrentStep: (step: TerminateSteps) => void
  terminateData: TerminateData
}

export type CurrentStepData = ChurnReasons | TerminateDataInputs | (ReferralFormInputs | undefined)

export type TerminateDataProps = TerminateStepProps

// ENUMS

export enum TerminateSteps {
  CHURN = 'churn',
  METER_DETAILS = 'meterDetails',
  REFERRAL = 'referral',
  OVERVIEW = 'overview',
  DONE = 'done'
}

export enum ChurnReasons {
  MOVE_IN_BELGIUM = 'Move in Belgium',
  MOVE_OUTSIDE_BELGIUM = 'Move outside Belgium',
  SUPPLIER_SWITCH = 'Supplier switch',
  HOME_INHABITABLE = 'Home inhabitable',
  DECEASE = 'Decease'
}
