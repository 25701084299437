import { useStoreSelector } from 'hooks/store'
import { useTranslation } from 'react-i18next'
import { selectCurrentAccount } from 'store/contact/selectors'
import styles from '../User.module.scss'
import parse from 'html-react-parser'
import { formatAddress } from 'utils/user'
import { routes } from 'types/routes'
import Link from 'components/Link/Link'
import AddressSwitcherBar from 'components/AddressSwitcherBar/AddressSwitcherBar'
import mixpanel from 'mixpanel-browser'
import { UserEvents } from 'types/tracking.ts'

const Contact = () => {
  // Redux
  const contactStore = useStoreSelector((store) => store.contact)
  const account = selectCurrentAccount(contactStore)

  // i18n
  const { t } = useTranslation('user')

  // Local constants
  const billingAddress = formatAddress(account.correspondenceAddress)

  return (
    <section className={styles['table-wrapper']}>
      <AddressSwitcherBar />

      <ul className={styles.table}>
        <li>
          <strong>{t('contact.fields.preferences.title')}</strong>
          {account.deliveryMode ? t(`contact.fields.preferences.receiving${account.deliveryMode}`) : '—'}
        </li>

        <li>
          <strong>{t('contact.fields.billing.title')}</strong>
          {billingAddress ? parse(billingAddress) : '—'}
        </li>
      </ul>

      <Link
        representation="button"
        isFullwidthMobile
        variant="secondary"
        className={styles.edit}
        href={routes.USER_EDIT_CONTACT}
        onClick={() => mixpanel.track(UserEvents.GO_TO_EDIT_CONTACT_INFO)}
      >
        {t('edit', { ns: 'common' })}
      </Link>
    </section>
  )
}

export default Contact
