import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { parseProducerImageUrl } from 'utils/producer.ts'
import styles from 'features/contracts/done/Done.module.scss'
import { DoneProps } from 'features/contracts/add/types.ts'
import { Button } from '@boltenergy-be/design-system'
import { routes } from 'types/routes.ts'
import { UserTypes } from 'store/auth/types.ts'
import { WidgetSlug } from 'types/feedbackWidget.ts'
import FeedbackWidget from 'components/FeedbackWidget/FeedbackWidget.tsx'
import { setMoveFlow } from 'store/app/slice.ts'
import Card from 'components/Card/Card.tsx'
import { useStoreSelector } from 'hooks/store.ts'
import FlowDoneCard from 'features/contracts/done/components/flow-done-card/FlowDoneCard.tsx'
import parse from 'html-react-parser'
import StartCard from 'features/contracts/done/components/start-card/StartCard.tsx'
import StopCard from 'features/contracts/done/components/stop-card/StopCard.tsx'
import { useLocation } from 'react-router'
import classNames from 'classnames'
import useOpenFeedbackWidget from 'hooks/useOpenFeedbackWidget'
import Link from 'components/Link/Link.tsx'
import { MoveFlows } from 'store/app/types.ts'
import { clearUser } from 'store/contact/slice.ts'
import { getLowerCaseLanguage } from 'utils/app.ts'

const Done = ({ producer }: DoneProps) => {
  // REDUX STORE
  const { language, move } = useStoreSelector((store) => store.app)
  const lowerCaseLanguage = getLowerCaseLanguage(language)
  const { userType } = useStoreSelector((store) => store.auth)
  const dispatch = useDispatch()

  // i18n
  const { t } = useTranslation('contracts')

  // Custom hook
  const { feedbackWidgetData, openFeedbackWidget } = useOpenFeedbackWidget({
    delay: 5,
    slug: WidgetSlug.MOVE_FLOW
  })

  // React router
  const navigate = useNavigate()
  const { pathname } = useLocation()

  // Constants
  const isSales = userType === UserTypes.SALES
  const isSuperUser = userType === UserTypes.SUPER_USER
  const isMove = pathname === routes.CONTRACTS_MOVE

  /**
   * Handles back button click
   */
  const handleBack = () => {
    if (isSales) {
      dispatch(clearUser())
      navigate(routes.SALES_HOME)
    } else {
      navigate(routes.ADDRESSES)
    }
  }

  useEffect(() => {
    if (pathname === routes.CONTRACTS_TERMINATE) {
      if (move.flow === MoveFlows.START) {
        dispatch(setMoveFlow(move.started ? MoveFlows.START_STOP : MoveFlows.STOP))
      }
    } else {
      if (isMove && move.flow === MoveFlows.START) {
        dispatch(setMoveFlow(move.stopped ? MoveFlows.STOP_START : MoveFlows.START_STOP))
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section
      className={classNames(styles.done, { [styles['two-columns']]: [MoveFlows.START_STOP, MoveFlows.STOP_START].includes(move.flow) })}
    >
      {/* START - PRODUCER CARD (ALWAYS FOR SALES) */}
      {producer && move.flow === MoveFlows.START && (
        <>
          <Card className={styles['producer-card']}>
            <div className={styles.content}>
              <Card.Title variant="h5">
                {t(`done.producer.${isSales || isSuperUser ? 'isSales' : 'isCustomer'}.title`, '', {
                  producer: producer.name[lowerCaseLanguage]
                })}
              </Card.Title>
              <p>{t(`done.producer.${isSales || isSuperUser ? 'isSales' : 'isCustomer'}.description`)}</p>
              <Button onClick={handleBack} className={styles.button}>
                {t(`done.producer.${isSales || isSuperUser ? 'isSales' : 'isCustomer'}.button`)}
              </Button>
            </div>
            <img
              src={parseProducerImageUrl(producer.images?.profile?.url, 600)}
              alt={producer.name[lowerCaseLanguage]}
              width={600}
              height={360}
            />
          </Card>

          {/* FeedbackWidget */}
          {feedbackWidgetData?.isActive && (
            <FeedbackWidget id={feedbackWidgetData._id} openFeedbackWidget={openFeedbackWidget} data={{ ...move }} />
          )}
        </>
      )}

      {/* START_STOP */}
      {move.flow === MoveFlows.START_STOP && (
        <>
          {/* NEW ADDRESS CARD */}
          <StartCard {...{ handleBack }} />

          {/* TERMINATE CARD */}
          <StopCard {...{ handleBack }} />
        </>
      )}

      {/* STOP_START */}
      {move.flow === MoveFlows.STOP_START && (
        <>
          {/* TERMINATE CARD */}
          <StopCard {...{ handleBack }} />

          {/* NEW ADDRESS CARD */}
          <StartCard {...{ handleBack }} />
        </>
      )}

      {/* STOP_START/START_STOP - ALL SET CARD */}
      {[MoveFlows.START_STOP, MoveFlows.STOP_START].includes(move.flow) && move.started && move.stopped && (
        <>
          <FlowDoneCard title={t('done.allSet.title')}>
            <p>{parse(t('done.allSet.description'))}</p>
            <p>{parse(t('done.allSet.support'))}</p>
            <Link href={routes.ADDRESSES} representation="button" className="mt-400">
              {t('done.allSet.action')}
            </Link>
          </FlowDoneCard>

          {/* FeedbackWidget */}
          {feedbackWidgetData?.isActive && (
            <FeedbackWidget id={feedbackWidgetData._id} openFeedbackWidget={openFeedbackWidget} data={{ ...move }} />
          )}
        </>
      )}

      {/* STOP - TERMINATE CARD */}
      {move.flow === MoveFlows.STOP && (
        <>
          <StopCard {...{ handleBack }} />

          {/* FeedbackWidget */}
          {feedbackWidgetData?.isActive && (
            <FeedbackWidget id={feedbackWidgetData._id} openFeedbackWidget={openFeedbackWidget} data={{ ...move }} />
          )}
        </>
      )}
    </section>
  )
}

export default Done
