import { Outlet } from 'react-router'
import { routes } from 'types/routes'
import { useTranslation } from 'react-i18next'
import PageLayout from 'layouts/page-layout/PageLayout.tsx'
import { PageTab } from 'layouts/page-layout/types.ts'

const Support = () => {
  // i18n
  const { t } = useTranslation('support')

  // Tabs
  const tabs: PageTab[] = [
    { label: t('contactTab'), path: routes.SUPPORT_CONTACT },
    { label: t('faqTab'), path: routes.SUPPORT_FAQ }
  ]

  return (
    <PageLayout title={t('nav.support', { ns: 'common' })} {...{ tabs }}>
      <Outlet />
    </PageLayout>
  )
}

export default Support
