import { UserTypes } from 'store/auth/types.ts'

/**
 * Determines the user type based on the pathname
 *
 * @param {string} pathname
 * @returns {UserTypes}
 */
export const determineAuthUserType = (pathname: string): UserTypes => {
  if (pathname.includes('/sales')) {
    return UserTypes.SALES
  }

  if (pathname.includes('/superuser')) {
    return UserTypes.SUPER_USER
  }

  return UserTypes.CUSTOMER
}
