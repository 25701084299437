import styles from 'features/contracts/done/Done.module.scss'
import parse from 'html-react-parser'
import { formatAddress } from 'utils/user.ts'
import dayjs from 'dayjs'
import AddressPicker from 'components/AddressPicker/AddressPicker.tsx'
import { Button, InlineMessage } from '@boltenergy-be/design-system'
import FlowDoneCard from 'features/contracts/done/components/flow-done-card/FlowDoneCard.tsx'
import { useStoreDispatch, useStoreSelector } from 'hooks/store.ts'
import mixpanel from 'mixpanel-browser'
import { ContractFlowEvents, ContractFlowStartPoints, ContractFlowTrackingTypes } from 'types/tracking.ts'
import { routes } from 'types/routes.ts'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { StartStopCardProps } from 'features/contracts/done/components/types.ts'
import { useTranslation } from 'react-i18next'
import Link from 'components/Link/Link.tsx'
import { MoveFlows } from 'store/app/types.ts'
import { selectActiveBillingContracts, selectCurrentContracts } from 'store/contact/selectors.ts'
import { selectContract } from 'store/contact/slice.ts'
import { resetContractsStore } from 'store/contracts/slice.ts'
import { AddressFormats } from 'types/format.ts'

const StopCard = ({ handleBack }: StartStopCardProps) => {
  // Redux
  const contactsStore = useStoreSelector((store) => store.contact)
  const { billingContracts } = contactsStore
  const { move } = useStoreSelector((store) => store.app)
  const dispatch = useStoreDispatch()

  // i18n
  const { t } = useTranslation('contracts')

  // React router
  const navigate = useNavigate()

  // Constants
  const availableContractsForTermination = selectActiveBillingContracts(billingContracts)
  const billingContract = selectCurrentContracts(contactsStore)
  const hideActions = !!([MoveFlows.START_STOP, MoveFlows.STOP_START].includes(move.flow) && move.stopped && move.started)

  // Local state
  const [selectedBillingContractId, setSelectedBillingContractId] = useState<string>(
    availableContractsForTermination[billingContract.contractNumber]
      ? billingContract.contractNumber
      : Object.keys(availableContractsForTermination)[0]
  )

  /**
   * Handles start terminate flow button click
   */
  const handleStartTerminateFlow = () => {
    // Switch selected contract if the user has selected a different one
    if (selectedBillingContractId !== billingContract.contractNumber) {
      dispatch(resetContractsStore())
      dispatch(selectContract(selectedBillingContractId))
    }

    mixpanel.track(ContractFlowEvents.START, {
      flow: move.flow,
      type: ContractFlowTrackingTypes.TERMINATE,
      from: ContractFlowStartPoints.FINISH_MOVE
    })

    // Go to terminate flow
    navigate(routes.CONTRACTS_TERMINATE)
  }

  return (
    <FlowDoneCard type="stopped" title={t(`done.stop.${move.stopped ? 'complete' : 'incomplete'}.title`)}>
      {move.stopped ? (
        <>
          <address className={styles.address}>{parse(formatAddress(move.stopped.address, AddressFormats.FULL_TWO_LINES) || '')}</address>
          <span>{t('done.stop.complete.startDate', { date: dayjs(move.stopped.date).format('DD MMMM YYYY') })}</span>
          <p>{t('done.stop.complete.description')}</p>
          <InlineMessage type="positive">{t('done.statuses.complete')}</InlineMessage>
          {!hideActions && (
            <div className={styles.actions}>
              <Link representation="button" href={routes.ADDRESSES}>
                {t('done.stop.complete.actions.toOverview')}
              </Link>
            </div>
          )}
        </>
      ) : (
        <>
          <p>{t('done.stop.incomplete.description')}</p>
          <AddressPicker
            label={t('done.stop.incomplete.addressPicker.label')}
            selectedContractId={selectedBillingContractId}
            setSelectedContractId={(contractId) => setSelectedBillingContractId(contractId)}
            options={availableContractsForTermination}
            error={{
              title: t('done.stop.incomplete.addressPicker.noValidContracts.title'),
              description: t('done.stop.incomplete.addressPicker.noValidContracts.description')
            }}
          />
          {Object.keys(availableContractsForTermination).length > 0 && (
            <div className={styles.actions}>
              <Button onClick={handleStartTerminateFlow}>{t('done.stop.incomplete.actions.startTerminate')}</Button>
              <Button onClick={handleBack} type="button" representation="link">
                {t('done.stop.incomplete.actions.postpone')}
              </Button>
            </div>
          )}
        </>
      )}
    </FlowDoneCard>
  )
}

export default StopCard
