import Link from 'components/Link/Link'
import Card from 'components/Card/Card'
import styles from './NoConnectionCard.module.scss'
import { useTranslation } from 'react-i18next'
import { routes } from 'types/routes'

const NoConnectionCard = () => {
  // i18n
  const { t } = useTranslation(['addresses'])

  return (
    <Card className={styles['no-connection']}>
      <small>{t('addresses:offtake.noGas')}</small>
      <Link href={routes.SUPPORT_CONTACT} variant="secondary">
        {t('addresses:offtake.contact')}
      </Link>
    </Card>
  )
}

export default NoConnectionCard
