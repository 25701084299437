import OverviewCard from 'pages/App/overview/overview-cards/OverviewCard.tsx'
import { routes } from 'types/routes.ts'
import { Icon } from '@boltenergy-be/design-system'
import styles from './GuaranteeOfOriginOverviewCard.module.scss'
import { useTranslation } from 'react-i18next'
import { useStoreSelector } from 'hooks/store.ts'
import { GoStatus } from 'types/contracts.ts'

const GuaranteeOfOriginOverviewCard = () => {
  // redux
  const { billingContracts } = useStoreSelector((store) => store.contact)
  const electricityContracts =
    (!!billingContracts && Object.values(billingContracts).map((billingContract) => billingContract.serviceContracts.electricity)) || []
  const hasInactiveProductionContracts = electricityContracts.some((c) => c.detail.goStatus === GoStatus.INACTIVE)

  // i18n
  const { t } = useTranslation('overview')

  // Hide card if all contracts are confirmed
  if (!hasInactiveProductionContracts) {
    return null
  }

  return (
    <OverviewCard className={styles.card} button={{ label: t('overview.guaranteeOfOrigin.button'), href: routes.GUARANTEE_OF_ORIGIN }}>
      <Icon name="exclamation" className={styles.icon} />
      <OverviewCard.Title>{t('overview.guaranteeOfOrigin.title')}</OverviewCard.Title>
      <p>{t('overview.guaranteeOfOrigin.description')}</p>
    </OverviewCard>
  )
}

export default GuaranteeOfOriginOverviewCard
