import { GetInvoicesStatusConfigPayload, InvoicesStatus } from './types.ts'

/**
 * Get the status of the invoices
 * @param {GetInvoicesStatusConfigPayload} props
 * @returns {InvoicesStatus}
 */
export const getInvoiceStatus = ({ balance, usesDirectDebit, hasExpiredInvoices }: GetInvoicesStatusConfigPayload): InvoicesStatus => {
  // Neutral balance
  if (balance === 0) return InvoicesStatus.BREAK_EVEN

  // Negative balance
  if (balance < 0) return InvoicesStatus.IS_GETTING_MONEY_BACK

  // Negative balance + direct debit + all invoices paid
  if (usesDirectDebit) return hasExpiredInvoices ? InvoicesStatus.DIRECT_DEBIT_WITH_UNPAID_INVOICES : InvoicesStatus.DIRECT_DEBIT

  // Negative balance
  return InvoicesStatus.HAS_UNPAID_INVOICES
}
