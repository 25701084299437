import { useEffect, useState } from 'react'
import classNames from 'classnames'
import styles from './SearchBar.module.scss'
import { SearchBarProps } from './types'
import { Button, TextField } from '@boltenergy-be/design-system'

const SearchBar = ({ className, placeholder, onInput, disabled }: SearchBarProps) => {
  // Local state
  const [value, setValue] = useState<string>('')

  // Sends the input value back to the parent on value change
  useEffect(() => {
    onInput(value)
  }, [onInput, value])

  return (
    <form className={classNames(styles['search-bar'], className)}>
      <div className={styles['search-input-wrapper']}>
        <TextField
          label=""
          name="search"
          role="searchbox"
          prefix={{ as: 'search' }}
          suffix={
            value
              ? { as: <Button size="small" type="reset" leadingIcon="cross" variant="tertiary" onClick={() => setValue('')} /> }
              : undefined
          }
          onChange={(event) => setValue(event.currentTarget.value)}
          {...{ placeholder, value, disabled }}
        />
      </div>
    </form>
  )
}

export default SearchBar
