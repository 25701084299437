import { createAsyncThunk } from '@reduxjs/toolkit'
import type { RootState } from 'store/index.ts'
import { stringify } from 'qs'
import { Methods, Response } from 'types/request.ts'
import { ApiGetContactsByEmailResponse, ApiGetMyContactResponse } from 'types/contacts.ts'
import { getAxiosRequestConfig, newRequest } from 'utils/request.ts'
import {
  GetContactErrorPayload,
  GetContactPayload,
  GetContactSuccessPayload,
  RejectValue,
  ThunkDefaultErrorMsg,
  UpdateBillingPayload,
  UpdateBillingSuccessPayload,
  UpdateContactPayload,
  UpdateContactResponse,
  UpdateInstalmentsPayload
} from 'store/contact/thunks/types.ts'
import { Language } from 'store/app/types.ts'
import { switchLanguage } from 'store/app/thunks'
import { ignoreGasOnlyContracts, processContact } from './utils.ts'
import { getLowerCaseLanguage } from 'utils/app.ts'
import { UpdateAccountPayload, UpdateAccountResponse } from 'store/queries/bolt-api/contacts/types.ts'
import { boltApi } from 'store/queries/bolt-api'
import { TagTypes } from 'store/queries/bolt-api/types.ts'
import i18next from 'i18next'
import Cookies from 'js-cookie'
import { popErrorToast, popSuccessToast } from '@boltenergy-be/design-system'

export const getMyContact = createAsyncThunk<GetContactSuccessPayload, void>('contact/getMyContact', async (_, thunkAPI) => {
  try {
    const { success, data, message, error, errorData }: Response<ApiGetMyContactResponse> = await newRequest(
      getAxiosRequestConfig(Methods.GET, '/contacts/me')
    )

    if (success && data) {
      // Set language cookie
      Cookies.set('language', getLowerCaseLanguage(data.contact.language), { expires: 365 })

      // Fetch current language and change it if it doesn't match the user language
      const lang: Language = (thunkAPI.getState() as RootState).app.language
      if (lang !== data.contact.language.toUpperCase()) thunkAPI.dispatch(switchLanguage({ language: data.contact.language }))

      // Ignore gas only contracts
      const { ignoredBillingContracts, updatedContact } = ignoreGasOnlyContracts(data.contact)

      const persistedSelected = (thunkAPI.getState() as RootState).contact.selected
      const processedContactData = processContact(updatedContact || data.contact, persistedSelected)

      return {
        ignoredBillingContracts,
        ...processedContactData
      }
    }

    return thunkAPI.rejectWithValue({
      error: message || error || ThunkDefaultErrorMsg.GET_MY_CONTACT,
      ...(errorData && { errorData })
    } satisfies GetContactErrorPayload)
  } catch (err) {
    const { message } = err as Error
    return thunkAPI.rejectWithValue(message)
  }
})

export const getContactByEmail = createAsyncThunk<GetContactSuccessPayload, GetContactPayload>(
  'contact/getContactByEmail',
  async ({ email }, thunkAPI) => {
    try {
      const query = stringify({ email, customers: true }, { skipNulls: true, addQueryPrefix: true })
      const { success, data, message, error, errorData }: Response<ApiGetContactsByEmailResponse> = await newRequest(
        getAxiosRequestConfig(Methods.GET, `/contacts${query}`)
      )

      if (success && !!data?.contacts?.length) {
        const contact = data.contacts[0]

        // Set language cookie
        Cookies.set('language', getLowerCaseLanguage(contact.language), { expires: 365 })

        // Fetch current language and change it if it doesn't match the user language
        const lang: Language = (thunkAPI.getState() as RootState).app.language
        if (lang !== contact.language.toUpperCase()) thunkAPI.dispatch(switchLanguage({ language: contact.language }))

        // Ignore gas only contracts
        const { ignoredBillingContracts, updatedContact } = ignoreGasOnlyContracts(contact)
        const processedContactData = processContact(updatedContact || contact)

        return {
          ignoredBillingContracts,
          ...processedContactData
        }
      }

      return thunkAPI.rejectWithValue({
        error: message || error || ThunkDefaultErrorMsg.GET_CONTACT_BY_EMAIL,
        ...(errorData && { errorData })
      } satisfies GetContactErrorPayload)
    } catch (err) {
      const { message } = err as Error
      return thunkAPI.rejectWithValue({
        error: message
      })
    }
  }
)

// UPDATE BILLING CONTRACT
export const updateBillingContract = createAsyncThunk<UpdateBillingSuccessPayload, UpdateBillingPayload, RejectValue>(
  'contact/updateBillingContract',
  async ({ billingContractId, updateBillingContractData }, thunkAPI) => {
    try {
      const { success, data, message }: Response<UpdateBillingSuccessPayload> = await newRequest(
        getAxiosRequestConfig(Methods.PATCH, `/contracts/${billingContractId}`, { contract: updateBillingContractData })
      )

      if (success && data) {
        return data
      } else {
        return thunkAPI.rejectWithValue(message || ThunkDefaultErrorMsg.UPDATE_BILLING_CONTRACT)
      }
    } catch (err) {
      const { message } = err as Error
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// UPDATE CONTACT
export const updateContact = createAsyncThunk<UpdateContactResponse, UpdateContactPayload, RejectValue>(
  'contact/updateContact',
  async ({ contactId, contact }, thunkAPI) => {
    try {
      const { success, data, message } = await newRequest<UpdateContactResponse>(
        getAxiosRequestConfig(Methods.PATCH, `/contacts/${contactId}`, { contact })
      )

      if (success && data) {
        return data
      } else {
        return thunkAPI.rejectWithValue(message || ThunkDefaultErrorMsg.UPDATE_CONTACT)
      }
    } catch (err) {
      const { message } = err as Error
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// UPDATE ACCOUNT
export const updateAccount = createAsyncThunk<UpdateAccountResponse, UpdateAccountPayload, RejectValue>(
  'contact/updateAccount',
  async ({ contactId, accountId, account }, thunkAPI) => {
    try {
      const { success, data, message } = await newRequest<UpdateAccountResponse>(
        getAxiosRequestConfig(Methods.PATCH, `/contacts/${contactId}/accounts/${accountId}`, { account })
      )

      if (success && data) {
        return data
      } else {
        return thunkAPI.rejectWithValue(message || ThunkDefaultErrorMsg.UPDATE_ACCOUNT)
      }
    } catch (err) {
      const { message } = err as Error
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// UPDATE INSTALMENT
export const updateInstalment = createAsyncThunk<UpdateInstalmentsPayload, UpdateInstalmentsPayload, RejectValue>(
  'contact/updateInstalment',
  async ({ billingContractId, customerNumber, electricity, gas }, thunkAPI) => {
    try {
      const { success, message }: Response = await newRequest(
        getAxiosRequestConfig(Methods.PATCH, `/contracts/${billingContractId}/instalment`, {
          electricityInstalment: electricity.detail.instalment,
          gasInstalment: gas?.detail.instalment
        })
      )

      if (success) {
        thunkAPI.dispatch(boltApi.util.invalidateTags([TagTypes.BILL_SHOCK]))
        popSuccessToast(i18next.t('editInstalment.form.success', { ns: 'billing' }))
        return { billingContractId, electricity, gas, customerNumber }
      } else {
        popErrorToast(i18next.t('editInstalment.form.error', { ns: 'billing' }))
        return thunkAPI.rejectWithValue(message || ThunkDefaultErrorMsg.UPDATE_INSTALMENT)
      }
    } catch (err) {
      popErrorToast(i18next.t('editInstalment.form.error', { ns: 'billing' }))
      const { message } = err as Error
      return thunkAPI.rejectWithValue(message)
    }
  }
)
