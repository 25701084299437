import { Language } from 'store/app/types'

export const DATE_FORMAT = 'DD/MM/YYYY'
export const DATE_FORMAT_LONG = 'D MMMM YYYY'
export const DATE_FORMAT_REACT_DATEPICKER = 'dd/MM/yyyy'

export const ISO_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss'

export const PARTNER_IDS = {
  PRODUCTION_BOLT_SUPER_USER: '0010900001mIpTAAA0',
  STAGING_BOLT_SUPER_USER: '0011q00001CH6FEAA1'
}

export const BOLT_PHONE_CONTACT = '02 899 33 00'
export const BOLT_EMAIL_CONTACT = {
  [Language.NL]: 'hallo@boltenergie.be',
  [Language.FR]: 'bonjour@boltenergie.be'
}
