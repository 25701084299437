import { NoInvoicesProps } from 'pages/App/billing/invoices/no-invoices/types.ts'
import styles from './NoInvoices.module.scss'
import { useTranslation } from 'react-i18next'
import { InvoiceTableTabs } from 'pages/App/billing/invoices/table-data/types.ts'
import { Button } from '@boltenergy-be/design-system'

const NoInvoices = ({ tab, setActiveTab }: NoInvoicesProps) => {
  // i18N
  const { t } = useTranslation('invoices')

  // constants
  const withImage = [InvoiceTableTabs.ALL_TRANSACTIONS, InvoiceTableTabs.ALL_INVOICES, InvoiceTableTabs.OUTSTANDING_INVOICES].includes(tab)

  return (
    <div className={styles.root}>
      {withImage && <img className={styles.image} src="/images/billing/no-invoices.png" alt="" />}
      <div className={styles['text-content']}>
        <h2 className={styles.title}>{t(`noInvoices.title.${tab as InvoiceTableTabs}`)}</h2>
        {tab === InvoiceTableTabs.OUTSTANDING_INVOICES && (
          <Button representation="link" className={styles.link} onClick={() => setActiveTab(InvoiceTableTabs.ALL_INVOICES)}>
            {t('noInvoices.link')}
          </Button>
        )}
      </div>
    </div>
  )
}

export default NoInvoices
