import { ProducerEnergyType } from 'types/producer.ts'

/**
 * Maps the energy type to a DS icon name
 *
 * @param {ProducerEnergyType} type
 * @returns {string}
 */
export const energyToIconName = (type: ProducerEnergyType) => {
  switch (type) {
    case ProducerEnergyType.SOLAR:
      return 'sun'

    case ProducerEnergyType.WATER:
      return 'water'

    case ProducerEnergyType.WIND:
      return 'wind'

    case ProducerEnergyType.BIO:
      return 'leaf'

    default:
      return 'wind'
  }
}
