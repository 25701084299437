import classNames from 'classnames'
import styles from 'pages/App/consumption/your-consumption/components/totals-block/TotalsBlock.module.scss'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'
import { PropsWithChildren } from 'react'
import TooltipOnHover from 'components/Tooltip/TooltipOnHover.tsx'
import { TotalsBlockProps, TotalsBlocksWrapperProps } from 'pages/App/consumption/your-consumption/components/totals-block/types.ts'
import { Icon } from '@boltenergy-be/design-system'

const Totals = ({ children, listOnMobile, compact }: TotalsBlocksWrapperProps) => {
  return <div className={classNames(styles.totals, { [styles['mobile-list']]: listOnMobile, [styles.compact]: !!compact })}>{children}</div>
}

const Content = ({ isLoading, children, label, addendum, indicator }: TotalsBlockProps) => {
  return (
    <section className={styles.block}>
      <header>
        {!!indicator && (
          <div className={styles['indicator-wrapper']}>
            {indicator?.icon ? (
              <Icon name={indicator.icon} className={classNames(styles.indicator, styles.icon, styles[indicator.color])} />
            ) : (
              <>
                {/* FULL */}
                {indicator?.full && <div className={styles.indicator} style={{ background: `var(--chart-${indicator.color})` }} />}

                {/* STRIPPED */}
                {indicator?.stripped && (
                  <div
                    className={classNames(styles.indicator, styles.stripped)}
                    style={{
                      background: `linear-gradient(-45deg, transparent 0 20%, var(--chart-${indicator.color}) 20% 40%, transparent 40% 60%, var(--chart-${indicator.color}) 60% 80%, transparent 80% 100%)`
                    }}
                  />
                )}
              </>
            )}
          </div>
        )}
        <h1 className={styles.label}>{label}</h1>
      </header>

      {isLoading ? (
        <LoadingSkeleton className="mb-100">
          <LoadingSkeleton.Rectangle height={20} width={140} />
        </LoadingSkeleton>
      ) : (
        children
      )}

      {!!addendum && <small className={styles.addendum}>{addendum}</small>}
    </section>
  )
}

const TotalsBlock = ({ tooltip, children, ...props }: PropsWithChildren<TotalsBlockProps>) => {
  return tooltip ? (
    <TooltipOnHover tooltipContent={tooltip} tooltipClassname={styles.tooltip}>
      <Content {...props}>{children || <span className={styles.empty}>—</span>}</Content>
    </TooltipOnHover>
  ) : (
    <Content {...props}>{children || <span className={styles.empty}>—</span>}</Content>
  )
}

Totals.Block = TotalsBlock

export default Totals
