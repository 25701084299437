import { routes } from 'types/routes.ts'
import styles from 'pages/App/overview/Overview.module.scss'
import ReferralDefaultImage from 'assets/images/referral/referral-default.jpg'
import { CardColors } from 'components/Card/types.ts'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'
import OverviewCard from 'pages/App/overview/overview-cards/OverviewCard.tsx'
import { useStoreSelector } from 'hooks/store.ts'
import { useGetActiveReferralActionQuery } from 'store/queries/cms-api'
import { useTranslation } from 'react-i18next'
import { DEFAULT_REFERRAL_AMOUNT } from 'constants/referral.ts'
import { selectContact } from 'store/contact/selectors.ts'
import { useGetContactReferralsQuery } from 'store/queries/bolt-api/contacts'
import { forwardRef } from 'react'
import Tag from 'components/Tag/Tag.tsx'

const ReferralOverviewCard = forwardRef<HTMLElement>((_, ref) => {
  // Redux store
  const { language } = useStoreSelector((store) => store.app)
  const { contact } = useStoreSelector((store) => store.contact)
  const { sfId } = selectContact({ contact })

  // Redux queries
  const { data: activeAction, isLoading: loadingAction } = useGetActiveReferralActionQuery({ language })
  const { data: referrals, isLoading: loadingReferrals } = useGetContactReferralsQuery(sfId)
  const isLoading = loadingAction || loadingReferrals

  // i18n
  const { t } = useTranslation(['overview', 'common'])

  // Constants
  const amount = activeAction?.attributes?.reduction || DEFAULT_REFERRAL_AMOUNT

  return (
    <OverviewCard
      ref={ref}
      isLoading={isLoading}
      button={{
        label: t(`overview.referral.button.${referrals?.length ? 'hasReferrals' : 'default'}`),
        href: routes.REFERRAL_INFO
      }}
      image={
        isLoading
          ? { loading: isLoading }
          : {
              className: activeAction ? styles['referral-image'] : styles['referral-image-default'],
              src: activeAction ? activeAction.attributes.image.data.attributes.url : ReferralDefaultImage,
              alt: 'Invite a friend'
            }
      }
      color={CardColors.LIGHT_BLUE}
    >
      {isLoading ? (
        <>
          <LoadingSkeleton.Title />
          <LoadingSkeleton.Paragraph lines={2} />
        </>
      ) : (
        <>
          {activeAction && <Tag color="lightblue">{t('common:temporaryAction')}</Tag>}
          <OverviewCard.Title>
            {activeAction
              ? activeAction?.attributes?.websiteHero?.title
              : referrals?.length
                ? t('overview.referral.title.hasReferrals', { referrals: referrals.length })
                : t('overview.referral.title.default', { amount })}
          </OverviewCard.Title>
        </>
      )}
    </OverviewCard>
  )
})

export default ReferralOverviewCard
