import { ProductType } from 'types/types.ts'
import { Direction, MeterIndexType } from 'types/contracts.ts'
import { getDigitalMeterRegisterName, hasDigitalMeter } from 'utils/contracts.ts'
import { TextField } from '@boltenergy-be/design-system'
import parse from 'html-react-parser'
import { useStoreSelector } from 'hooks/store.ts'
import { useFormContext } from 'react-hook-form'
import { EditMeterReadingsFormInputs } from 'pages/App/consumption/edit-meter-readings/types.ts'
import { selectCurrentContracts } from 'store/contact/selectors.ts'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MeterReadingInputFieldProps } from './types.ts'

const MeterReadingInputField = ({ type, direction, meterIndexType, allElOptional }: MeterReadingInputFieldProps) => {
  // Redux
  const { billingContracts, selected } = useStoreSelector((store) => store.contact)
  const {
    meterReadings: { data }
  } = useStoreSelector((store) => store.contracts)

  // Contracts
  const { electricity, gas } = selectCurrentContracts({ billingContracts, selected }).serviceContracts

  // i18n
  const { t } = useTranslation(['consumption', 'common'])

  // React Hook Form
  const { register, setValue, watch, formState } = useFormContext<EditMeterReadingsFormInputs>()
  const { errors } = formState
  const elekConsumptionTotal = watch('electricity.consumption.total')
  const elekConsumptionDay = watch('electricity.consumption.day')
  const elekConsumptionNight = watch('electricity.consumption.night')
  const elekConsumptionExclNight = watch('electricity.consumption.exclNight')

  // Memo's
  const noElekInput = useMemo<boolean>(
    () => [elekConsumptionTotal, elekConsumptionDay, elekConsumptionNight, elekConsumptionExclNight].every((value) => !value),
    [elekConsumptionDay, elekConsumptionExclNight, elekConsumptionNight, elekConsumptionTotal]
  )

  /**
   * Returns the correct meter reading entry based on the given product type & meter index type
   *
   * @param {ProductType} type
   * @param {Direction} direction
   * @param {MeterIndexType} meterIndexType
   * @returns {number|undefined}
   */
  const getMeterReadingEntry = (type: ProductType, direction: Direction, meterIndexType: MeterIndexType): number | undefined => {
    if (type === ProductType.GAS) return data?.gas?.consumption?.singleRate

    switch (meterIndexType) {
      case MeterIndexType.DAY:
        return data?.electricity?.[direction === Direction.CONSUMPTION ? 'consumption' : 'injection']?.doubleRate?.day

      case MeterIndexType.NIGHT:
        return data?.electricity?.[direction === Direction.CONSUMPTION ? 'consumption' : 'injection']?.doubleRate?.night

      case MeterIndexType.EXCL_NIGHT:
        return data?.electricity?.[direction === Direction.CONSUMPTION ? 'consumption' : 'injection']?.exclNight

      default:
        return data?.electricity?.[direction === Direction.CONSUMPTION ? 'consumption' : 'injection']?.singleRate
    }
  }

  // Constants
  const isGas = type === ProductType.GAS
  const directionType = direction === Direction.CONSUMPTION ? 'consumption' : 'injection'
  const meterReadingEntry = getMeterReadingEntry(type, direction, meterIndexType)
  const key = (isGas ? 'gas.total' : `electricity.${directionType}.${meterIndexType}`) as any
  const hasElectricityInputError = errors?.electricity?.[directionType]?.[meterIndexType]
  const indexLabel = isGas
    ? t('gas', { ns: 'common' })
    : meterIndexType !== MeterIndexType.TOTAL
      ? t(`editMeterReadings.form.meterIndex.${meterIndexType}`)
      : t(`editMeterReadings.form.${directionType}`)
  const fullLabel = isGas
    ? indexLabel
    : hasDigitalMeter(electricity)
      ? `${indexLabel} ${getDigitalMeterRegisterName(direction, meterIndexType)}`
      : indexLabel

  return (
    <TextField
      placeholder=""
      label={fullLabel}
      type="number"
      step="1"
      suffix={{ as: ',000' }}
      {...register(key, {
        valueAsNumber: true,
        required: !allElOptional || isGas ? true : noElekInput,
        min: 0,
        validate: {
          isDecimal: (value: number) => value?.toString()?.indexOf('.') === -1 && value?.toString()?.indexOf(',') === -1
        }
      })}
      onChange={(e) => {
        setValue(key, Number(e.target.value))
      }}
      onWheel={(e) => e.currentTarget.blur()}
      error={isGas ? !!errors?.gas?.total : !!hasElectricityInputError}
      description={
        <>
          <small>
            {t('common:units.textWithColon', { text: t('common:ean') })} {isGas ? gas?.deliveryPoint?.ean : electricity.deliveryPoint.ean}
          </small>
          <br />
          <small>
            {typeof meterReadingEntry !== 'undefined' ? (
              <>
                {t('common:units.textWithColon', { text: t('editMeterReadings.form.lastKnownMeterReading') })}{' '}
                {meterReadingEntry?.toFixed(0)} {isGas ? parse('m<sup>3</sup>') : 'kWh'}
              </>
            ) : (
              t('editMeterReadings.form.noMeterReading')
            )}
          </small>
        </>
      }
    />
  )
}

export default MeterReadingInputField
