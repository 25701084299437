import { WidgetSlug } from 'types/feedbackWidget.ts'

// USE WINDOW SIZE RETURN TYPE

export type UseWindowSizeReturnType = {
  width: number
  height: number
  isMobile: boolean
  isTablet: boolean
  isSmaller: boolean
}

// USE OPEN FEEDBACK WIDGET

export type UseOpenFeedbackWidget = {
  delay?: number
  slug: WidgetSlug
  startTimer?: boolean
  startOpen?: boolean
  skip?: boolean
}

// USE SHOULD ANIMATE

export type UseShouldAnimateProps = {
  hideCondition: boolean
  isLoading: boolean
}

export type UseShouldAnimateReturn = {
  visible: boolean
  setVisible: (isVisible: boolean) => void
  hideWithoutAnimation: boolean
  animationState: AnimationStates
}

export enum AnimationStates {
  IN = 'in',
  OUT = 'out',
  NONE = 'none'
}
