import { useTranslation } from 'react-i18next'
import { useStoreSelector } from 'hooks/store.ts'
import { GoStatus } from 'types/contracts.ts'
import { formatAddress } from 'utils/user.ts'
import { AddressFormats } from 'types/format.ts'
import Tag from 'components/Tag/Tag.tsx'
import styles from '../GuaranteeOfOrigin.module.scss'
import { Banner } from '@boltenergy-be/design-system'

const GuaranteeOfOriginStatus = () => {
  // Redux
  const { billingContracts } = useStoreSelector((store) => store.contact)
  const electricityContracts =
    (!!billingContracts && Object.values(billingContracts).map((billingContract) => billingContract.serviceContracts.electricity)) || []
  const serviceContractsWithGo = electricityContracts.filter((c) => c.detail.goStatus && c.detail.goStatus !== GoStatus.NA)

  // i18n
  const { t } = useTranslation('guaranteeOfOrigin')

  return serviceContractsWithGo?.length ? (
    <ul className={styles['go-list']}>
      {serviceContractsWithGo.map((serviceContract) => {
        const { goStatus } = serviceContract.detail
        return (
          goStatus &&
          goStatus !== GoStatus.NA && (
            <li key={serviceContract.contractNumber} className={styles.go}>
              <strong>{formatAddress(serviceContract.deliveryPoint.address, AddressFormats.STREET_NUMBER_BOX)}</strong>
              <span>{formatAddress(serviceContract.deliveryPoint.address, AddressFormats.POSTAL_CODE_TOWN)}</span>
              <Tag
                size="small"
                color={goStatus === GoStatus.CONFIRMED ? 'green' : 'red'}
                icons={goStatus === GoStatus.CONFIRMED ? 'check' : 'cross'}
              >
                {t(`status.myGoStatus.${goStatus}`)}
              </Tag>
            </li>
          )
        )
      })}
    </ul>
  ) : (
    <Banner title={t('status.noneFound')} />
  )
}

export default GuaranteeOfOriginStatus
