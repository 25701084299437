import { AddressWithCountryAndTownCode } from 'types/general.ts'

export type AppStore = {
  betaMode: boolean
  language: Language
  redirect: string | null
  showAllAddresses: boolean
  error: string | null
  urlSearchParams: {
    [key in UrlSearchParamsKeys]?: string
  }
  move: {
    flow: MoveFlows
    started?: MoveFlowState
    stopped?: MoveFlowState
  }
}

export type MoveFlowState = {
  address: AddressWithCountryAndTownCode
  date: string | null // ISO
}

export type SetMoveFlowStatePayload = {
  key: keyof Omit<AppStore['move'], 'flow'>
  state: MoveFlowState
}

// ENUMS

export enum Language {
  NL = 'NL',
  FR = 'FR'
}

export enum LanguageLowercase {
  NL = 'nl',
  FR = 'fr'
}

export enum UrlSearchParamsKeys {
  CONTACT_ID = 'contact_id',
  CUSTOMER_EMAIL = 'customer_email',
  FIXED_PRICE_RENEWAL_CONFIRMATION = 'fixed_price_renewal_confirmation'
}

export enum MoveFlows {
  START = 'start',
  START_STOP = 'start-stop',
  STOP_START = 'stop-start',
  STOP = 'stop'
}
