import { Button, Icon } from '@boltenergy-be/design-system'
import { EditingButtonProps } from './types'
import styles from './EditingButton.module.scss'
import { useTranslation } from 'react-i18next'
import TooltipOnHover from 'components/Tooltip/TooltipOnHover'

const EditingButton = ({ isDisabled, isEditing, setIsEditing, isLoading, onSubmit, identifier, onCancel }: EditingButtonProps) => {
  //i18n
  const { t } = useTranslation()

  return !isEditing ? (
    <li>
      <Button aria-label="edit" type="button" variant="tertiary" disabled={isDisabled} onClick={() => setIsEditing(identifier)}>
        <TooltipOnHover
          tooltipContent={t('details.tooltips.edit', { ns: 'sales' })}
          disabled={isDisabled}
          tooltipClassname={styles['tooltip-hover']}
        >
          <Icon name="pencil" />
        </TooltipOnHover>
      </Button>
    </li>
  ) : (
    <>
      <li>
        <Button
          variant="secondary"
          type="button"
          onClick={() => {
            onCancel()
            setIsEditing(undefined)
          }}
          disabled={isLoading}
        >
          {t('cancel')}
        </Button>
      </li>
      <li>
        <Button type="button" loading={isLoading} onClick={onSubmit}>
          {t('save')}
        </Button>
      </li>
    </>
  )
}

export default EditingButton
