import { useStoreSelector } from 'hooks/store'
import { useTranslation } from 'react-i18next'
import { selectCurrentAccount } from 'store/contact/selectors'
import styles from '../User.module.scss'
import { routes } from 'types/routes'
import Link from 'components/Link/Link'
import AddressSwitcherBar from 'components/AddressSwitcherBar/AddressSwitcherBar'
import mixpanel from 'mixpanel-browser'
import { UserEvents } from 'types/tracking.ts'
import { Banner } from '@boltenergy-be/design-system'

const Payment = () => {
  // Redux
  const contactStore = useStoreSelector((store) => store.contact)
  const account = selectCurrentAccount(contactStore)

  // i18n
  const { t } = useTranslation(['user', 'common'])

  return (
    <section className={styles['table-wrapper']}>
      <AddressSwitcherBar />

      {account.paymentDetails ? (
        <>
          <ul className={styles.table}>
            <li>
              <strong>{t('payment.fields.paymentMethod.title')}</strong>
              {account.paymentDetails.directDebit
                ? t('payment.fields.paymentMethod.directDebit')
                : t('payment.fields.paymentMethod.bankTransfer')}
            </li>

            <li>
              <strong>{t('payment.fields.financial.iban')}</strong>
              {account.paymentDetails.iban}
            </li>

            <li>
              <strong>{t('payment.fields.financial.mandate')}</strong>
              {account.paymentDetails.mandateNumber || '—'}
            </li>
          </ul>

          <Link
            representation="button"
            isFullwidthMobile
            className={styles.edit}
            variant="secondary"
            href={routes.USER_EDIT_PAYMENT}
            onClick={() => mixpanel.track(UserEvents.GO_TO_EDIT_PAYMENT_INFO)}
          >
            {t('edit', { ns: 'common' })}
          </Link>
        </>
      ) : (
        <Banner type="blocking" title={t('common:error')} />
      )}
    </section>
  )
}

export default Payment
