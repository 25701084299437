import { ReactNode } from 'react'

export enum CellVariant {
  DESCRIPTION = 'description',
  DATE = 'date',
  PAYMENT_AMOUNT = 'payment-amount',
  STATUS = 'status',
  PAYMENT_BUTTON = 'payment-button',
  INVOICE_DOWNLOAD = 'invoice-download',
  ACCOUNTING = 'accounting-info',
  PAYMENT_METHOD = 'payment-method',
  RUNNING_BALANCE = 'running-balance',
  OPTIONS = 'options',
  ACTION = 'action'
}

export enum CellColor {
  POSITIVE = 'positive',
  DEFAULT = 'default',
  NEGATIVE = 'negative',
  WARNING = 'warning',
  INFORMATIVE = 'informative'
}

export type CellType = {
  variant?: CellVariant
  children: ReactNode
  title?: string
  color?: CellColor
}
