import i18next from 'i18next'
import { ChartLabels } from 'components/Charts/types'
import { CHART_COLORS, getBarChartDefaults } from 'components/Charts/constants'
import { createChartDataArray, formatChartDateLabel } from 'utils/chartjs'
import { PreviousBillingCycle } from 'pages/App/billing/billing-cycles/types'
import { formatAmount } from 'utils/format'
import { getChartPattern, getSettlementColor } from '../utils'
import { externalTooltipHandler } from 'components/Charts/chart-tooltip/tooltip.ts'
import { Granularity } from 'types/contracts.ts'
import { BillingCycleChartOptions } from 'components/Charts/billing-cycle/types.ts'
import { TOOLTIP_CONFIG } from 'components/Charts/chart-tooltip/config.ts'
import { DSChartColors } from 'types/colors.ts'

export const getBillingCycleChartOptions = ({
  labels,
  language,
  isMobile,
  handleHoverDataPoint
}: Omit<BillingCycleChartOptions, 'minMax'>) => {
  const defaults = getBarChartDefaults({ isMobile })
  const {
    scales: { y, x },
    plugins
  } = defaults

  return {
    ...defaults,
    maintainAspectRatio: false,
    scales: {
      y: {
        ...y,
        ticks: {
          ...y.ticks,
          callback: (value: number) => formatAmount(value, 0)
        }
      },
      x: {
        ...x,
        ticks: {
          callback: (value: number) => {
            if (value === labels.length - 1) return labels[value]
            return formatChartDateLabel(labels[value], Granularity.MONTH, language)
          }
        }
      }
    },
    plugins: {
      ...plugins,
      yScaleTitle: {
        labels: {
          y: '€'
        }
      },
      annotation: {
        common: {
          drawTime: 'beforeDraw'
        },
        annotations: {
          lastColumn: {
            type: 'box',
            backgroundColor: CHART_COLORS.backgroundHighlight,
            borderWidth: 0,
            xMax: 0.5 + labels.length - 1,
            xMin: -0.5 + labels.length - 1
          }
        }
      },
      tooltip: {
        ...TOOLTIP_CONFIG,
        external: (context: any) => {
          const { tooltip } = context
          const currentDataPoint = tooltip?.dataPoints?.[0]?.dataIndex
          const hideTooltip = tooltip.opacity === 0
          handleHoverDataPoint(hideTooltip ? null : currentDataPoint)
          return externalTooltipHandler({ context, totalColumns: labels.length - 1, isMobile })
        },
        callbacks: {
          title: (context: any) =>
            context?.[0]?.dataIndex === labels.length - 1
              ? i18next.t('billingCycle.chart.settlement', { ns: 'billing' })
              : formatChartDateLabel(context?.[0]?.label, Granularity.MONTH, language, true)
        }
      }
    }
  }
}

export const getBillingCycleChartDatasets = (labels: ChartLabels, data: PreviousBillingCycle, isMobile?: boolean) => {
  const maxBarThickness = 94
  const borderRadius = isMobile ? 2 : 4

  return [
    {
      label: i18next.t('billingCycles.chart.legendPrevious.billedInstalment', { ns: 'billing' }),
      backgroundColor: CHART_COLORS.pink,
      data: createChartDataArray(
        labels.length,
        data.months.map(({ billedAmount }) => billedAmount)
      ),
      skipNull: true,
      maxBarThickness,
      borderRadius
    },
    {
      label: i18next.t('billingCycles.chart.legendPrevious.actualInstalment', { ns: 'billing' }),
      backgroundColor: getChartPattern(DSChartColors.GREEN),
      data: createChartDataArray(
        labels.length,
        data.months.map(({ actualPrice }) => actualPrice)
      ),
      skipNull: true,
      maxBarThickness,
      borderRadius
    },
    {
      label: i18next.t('billingCycles.chart.legendPrevious.settlement', { ns: 'billing' }),
      backgroundColor: getSettlementColor(data.settlement),
      data: createChartDataArray(labels.length, [data.settlement], labels.length - 1),
      skipNull: true,
      maxBarThickness,
      borderRadius
    }
  ]
}
