import axios from 'axios'
import { API_URL, CMS_URL } from 'constants/envs'
import { getAccessToken, getAccessTokenFromSession, getRefreshToken, setTokens } from './localStorageService'
import { getUserTypeLoginPage } from 'utils/user'
import { getRefreshTokenUrlForUserType, logout } from './app'
import { getUserType, identifyUserType } from 'utils/userType'
import { UserTypes } from 'store/auth/types'
import router from 'router/routing.tsx'
import Bugsnag from '@bugsnag/js'
import { RefreshTokensResponse } from 'store/queries/bolt-api/types.ts'
import { Response } from 'types/request.ts'
import { Token } from 'types/auth.ts'

let isRefreshing = false
let failedQueue: any[] = []

const processQueue = (error: Error | null, token: Token | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

/**
 * Returns the correct redirect URL for the given userType
 * @param {UserTypes} userType
 */
export const handleLogout = (userType: UserTypes) => {
  logout(() => router.navigate(getUserTypeLoginPage(userType)), userType)
}

// Request interceptor
axios.interceptors.request.use(
  (config) => {
    const accessTokenLight = getAccessTokenFromSession('access_token_light')
    const token = getAccessToken()

    config.baseURL = API_URL

    // Set necessary headers
    config.headers['Content-Type'] = 'application/json'
    config.headers['Accept'] = 'application/json'

    if (token && !accessTokenLight && !config.url?.includes('maps.googleapis.com') && !config.url?.includes(CMS_URL || 'localhost:1337')) {
      config.headers['Authorization'] = 'Bearer ' + token.id
    }

    return config
  },
  async (error) => {
    Bugsnag.leaveBreadcrumb('Request error (axios, l68)', error)
    return Promise.reject(error)
  }
)

// Response interceptor
axios.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config
    const userType = getUserType() || identifyUserType()
    const refreshTokenUrl = getRefreshTokenUrlForUserType(userType)

    if (originalRequest.url === `${API_URL}${refreshTokenUrl}`) {
      handleLogout(userType)
      Bugsnag.leaveBreadcrumb('Handling logout (axios, l86)', error)
      return Promise.reject(error)
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      // Parse data if necessary
      if (!!originalRequest?.data && typeof originalRequest.data === 'string') {
        originalRequest.data = JSON.parse(originalRequest.data)
      }

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject })
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token
            return axios(originalRequest)
          })
          .catch((err) => {
            Bugsnag.leaveBreadcrumb('Request error (axios, l104)', error)
            return Promise.reject(err)
          })
      }

      originalRequest._retry = true
      isRefreshing = true

      const refreshToken = getRefreshToken(userType)
      if (refreshToken) {
        return axios
          .post(`${API_URL}${refreshTokenUrl}`, {
            refreshToken: refreshToken.id
          })
          .then(({ status, data: responseData }) => {
            const { data } = responseData as Response<RefreshTokensResponse>

            if (status === 200 && data?.tokens) {
              const { token, refreshToken } = data.tokens
              setTokens({ token, refreshToken }, userType)

              axios.defaults.headers.common['Authorization'] = 'Bearer ' + getAccessToken()?.id

              processQueue(null, token)

              return axios(originalRequest)
            }

            handleLogout(userType)
            const error = new Error('Failed to refresh token')
            Bugsnag.leaveBreadcrumb('Failed to refresh token, logging out (axios l134)', error)
            return Promise.reject(error)
          })
          .catch((err: Error) => {
            processQueue(err, null)
            Bugsnag.leaveBreadcrumb('Request error (axios, l139)', error)
            return Promise.reject(err)
          })
          .finally(() => {
            isRefreshing = false
          })
      }
    }

    return Promise.reject(error)
  }
)

export default axios
