import { store } from 'store'
import { routes } from 'types/routes'
import { activateBetaMode, saveRedirect, saveUrlSearchParam } from 'store/app/slice'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { UrlSearchParamsKeys } from 'store/app/types'
import { BUGSNAG_API_KEY, IS_PROD_OR_BETA, IS_STAGING_OR_PREVIEW } from 'constants/envs.ts'
import { IGNORED_UNHANDLED_ERRORS } from 'constants/errors.ts'

/**
 * Executes start up functions
 */
export const startUp = (): void => {
  // Initialize tools & store subscription
  initializeBugsnag()

  // Save url search params if necessary
  saveUrlSearchParamsOnStartup()

  // Save redirect if necessary
  saveRequestedRouteAsRedirectOnStartup()
}

/**
 * Initializes the Bugsnag plugin
 */
const initializeBugsnag = () => {
  if (!Bugsnag.isStarted()) {
    Bugsnag.start({
      apiKey: BUGSNAG_API_KEY,
      plugins: [new BugsnagPluginReact()],
      releaseStage: IS_PROD_OR_BETA ? 'production' : IS_STAGING_OR_PREVIEW ? 'staging' : 'local',
      enabledReleaseStages: ['production'], // Only send errors in production (add 'staging' to send in dev/staging),
      onError: async (event) => {
        const error = event?.errors?.[0]

        if (error) {
          const errorMsg = `${error.errorClass}: ${error.errorMessage}`

          // Returns false if the error should be ignored
          // (https://docs.bugsnag.com/platforms/javascript/customizing-error-reports/#discarding-events)
          return !IGNORED_UNHANDLED_ERRORS.some((ignoredError) => errorMsg.includes(ignoredError))
        }

        return true
      }
    })
  }
}

/**
 * Saves the requested route as redirect in store on startup
 */
const saveRequestedRouteAsRedirectOnStartup = (): void => {
  const { pathname, search } = window.location

  const betaMode = new URLSearchParams(window.location.search).get('beta') === 'true'

  if (betaMode) {
    store.dispatch(activateBetaMode())
  }

  // Only save protected route paths as a redirect that are not /home
  if (
    ![
      '/',

      // Auth routes
      routes.LOGIN,
      routes.LOGIN_SALES,
      routes.LOGIN_SUPER_USER,
      routes.REGISTER,
      routes.REQUEST_NEW_PASSWORD,
      routes.REQUEST_NEW_PASSWORD_SALES,
      routes.REQUEST_NEW_PASSWORD_SUPER_USER,
      // activate & reset routes are checked with an includes() below because of the variable :token

      // Home views
      routes.ACCOUNT_SELECTION,
      routes.SALES_HOME,
      routes.CUSTOMER_EMAIL
    ].includes(pathname) &&
    !pathname.includes('activate') &&
    !pathname.includes('reset')
  ) {
    store.dispatch(saveRedirect(`${pathname}${search}`))
  }
}

/**
 * Checks the URL search params & saves them to the store if necessary
 */
const saveUrlSearchParamsOnStartup = () => {
  // Fetch the URL params
  const search = new URLSearchParams(window.location.search)

  if (search.has(UrlSearchParamsKeys.CONTACT_ID))
    store.dispatch(
      saveUrlSearchParam({
        key: UrlSearchParamsKeys.CONTACT_ID,
        value: search.get(UrlSearchParamsKeys.CONTACT_ID) ?? ''
      })
    )

  if (search.has(UrlSearchParamsKeys.CUSTOMER_EMAIL)) {
    store.dispatch(
      saveUrlSearchParam({
        key: UrlSearchParamsKeys.CUSTOMER_EMAIL,
        value: search.get(UrlSearchParamsKeys.CUSTOMER_EMAIL) ?? ''
      })
    )
  }

  if (search.has(UrlSearchParamsKeys.FIXED_PRICE_RENEWAL_CONFIRMATION)) {
    store.dispatch(
      saveUrlSearchParam({
        key: UrlSearchParamsKeys.FIXED_PRICE_RENEWAL_CONFIRMATION,
        value: search.get(UrlSearchParamsKeys.FIXED_PRICE_RENEWAL_CONFIRMATION) ?? ''
      })
    )
  }
}
