import { NavigationProps } from './types.ts'
import DesktopNavigation from './desktop-navigation/DesktopNavigation.tsx'
import useWindowSize from 'hooks/useWindowSize.tsx'
import BottomNavigation from './mobile-navigation/BottomNavigation.tsx'
import { SMALL_DESKTOP_BREAKPOINT } from 'constants/viewport.ts'

const MainNavigation = (props: NavigationProps) => {
  // Window size
  const { isSmaller } = useWindowSize(SMALL_DESKTOP_BREAKPOINT)

  return isSmaller ? <BottomNavigation {...props} /> : <DesktopNavigation {...props} />
}

export default MainNavigation
