import { InvoiceCells } from 'pages/App/billing/invoices/table-data/types.ts'
import { Button } from '@boltenergy-be/design-system'
import Link from 'components/Link/Link.tsx'
import mixpanel from 'mixpanel-browser'
import Modal from 'components/Modals/Modal/Modal.tsx'
import styles from './InvoiceEntryModal.module.scss'
import { InvoiceEntryModalProps } from './types.ts'
import { useTranslation } from 'react-i18next'
import { InvoiceEvents } from 'types/tracking.ts'
import { Fragment } from 'react'

const InvoiceEntryModal = ({ entry, ...modalProps }: InvoiceEntryModalProps) => {
  // i18n
  const { t } = useTranslation('invoices')

  return (
    <Modal
      {...modalProps}
      title={entry?.[InvoiceCells.INVOICE_NUMBER]?.data ? `#${entry[InvoiceCells.INVOICE_NUMBER]?.data}` : ''}
      modalRootEl={document.getElementById('modal-root')}
    >
      {entry ? (
        <Fragment>
          <div className={styles.content}>
            <small className="text-subdued">{entry[InvoiceCells.DOCUMENT_TYPE]?.content}</small>
            <small className="text-subdued">
              {entry[InvoiceCells.DATE]?.header}: {entry[InvoiceCells.DATE]?.content}
            </small>
            {entry[InvoiceCells.AMOUNT]?.content}
          </div>

          {/* ACTIONS */}
          {!entry[InvoiceCells.PAYMENT_ACTION]?.hidden && !!entry[InvoiceCells.PAYMENT_ACTION]?.data && (
            <Button isFullwidth tabIndex={0}>
              {t('cells.payOnline')}
            </Button>
          )}

          {entry[InvoiceCells.DOWNLOAD_ACTION]?.data && (
            <Link
              representation="button"
              isFullwidth
              variant="secondary"
              href={entry[InvoiceCells.DOWNLOAD_ACTION]?.data as string}
              download
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => mixpanel.track(InvoiceEvents.DOWNLOAD_PDF)}
            >
              {t('cells.viewPdf')}
            </Link>
          )}
        </Fragment>
      ) : null}
    </Modal>
  )
}

export default InvoiceEntryModal
