import { EnergyType, Granularity } from 'types/contracts.ts'
import { ProductType } from 'types/types.ts'
import { GranularityOptions } from 'pages/App/consumption/your-consumption/components/consumption-section/types.ts'
import { OpUnitType } from 'dayjs'

export type ConsumptionNavigationProps = {
  billingCycles: { [key: string]: NavigationItem }
  granularities: GranularityOptions
  isLoading: boolean
  disabledFilters?: boolean
  energyType: EnergyType
}

export type NavigationItem = {
  text: string
  id: string
  type: NavigationItemType
  periods?: {
    label: string
    defaultSelected: boolean
    value: string
  }[]
}

export type DatePickerConfig = {
  valueFormat: string
  viewFormat: string
  endOf: OpUnitType
}

export type ConsumptionNavigationForm = {
  view: Granularity | NavigationItemType.CYCLE
  period: string
  activeProduct: ProductType
}

// ENUMS

export enum NavigationItemType {
  CYCLE = 'BILLING_CYCLE',
  GRANULARITY = 'GRANULARITY'
}
