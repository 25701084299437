export enum FaqSlug {
  CONSUMPTION = 'consumption',
  COMMUNITY_SALES = 'community-sales',
  MOVE = 'move',
  TARIFFS = 'tariffs',
  ABOUT_BOLT = 'about-bolt',
  SWITCH = 'switch',
  PAYMENT = 'payment',
  INVOICES_AND_PAYMENTS = 'invoices-and-payments',
  MY_ADDRESSES = 'my-addresses',
  MY_DETAILS = 'my-details',
  MY_PRODUCER_B2C = 'my-producer-b2c',
  MY_PRODUCER_B2B = 'my-producer-b2b',
  ENERGY_OVERVIEW = 'energy-overview',
  MARKET_PRICES = 'market-prices'
}
