import styles from '../Charts.module.scss'
import { ExternalTooltipHandlerProps } from '../types.ts'

/**
 * create or get the tooltip HTML element
 * @param chart
 * @param {boolean} isMobile
 * @returns {HTMLDivElement}
 */
const getOrCreateTooltip = (chart: any, isMobile?: boolean): HTMLDivElement => {
  let tooltipEl: HTMLDivElement = chart.canvas.parentNode.querySelector(`div.${styles.tooltip}`)

  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.classList.add(styles.tooltip)

    chart.canvas.parentNode.appendChild(tooltipEl)
  }

  if (isMobile) {
    tooltipEl.classList.add(styles.mobile)
  } else {
    tooltipEl.classList.remove(styles.mobile)
  }

  return tooltipEl
}

/**
 * Handles the creation of the custom tooltip
 * @param {ExternalTooltipHandlerProps} props
 */
export const externalTooltipHandler = ({ context, totalColumns, isMobile }: ExternalTooltipHandlerProps) => {
  // Tooltip Element
  const { chart, tooltip } = context
  const isFirstDataset = tooltip?.dataPoints?.[0]?.dataIndex === 0
  const lastFewColumnIndexes = Math.ceil(totalColumns / 4)
  const isLastDatasets = tooltip?.dataPoints?.[0]?.dataIndex >= totalColumns - lastFewColumnIndexes

  if (typeof tooltip === 'undefined' || typeof tooltip.labelColors === 'undefined' || !tooltip.body?.[0]?.lines?.[0]?.length) return
  if (!tooltip?.labelColors[0]) return

  const tooltipEl = getOrCreateTooltip(chart, isMobile)

  // Move small triangle (caret) under tooltip if first element
  if (isFirstDataset) {
    tooltipEl.classList.add(styles.first)
  } else if (isLastDatasets) {
    tooltipEl.classList.add(styles.last)
  } else {
    tooltipEl.classList.remove(styles.first)
    tooltipEl.classList.remove(styles.last)
  }

  const container = document.createElement('div')
  container.classList.add(styles['tooltip-container'])

  const caret = document.createElement('div')
  caret.classList.add(styles.caret)

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0'
    return
  }

  // Set Text
  if (tooltip.body) {
    const heading = document.createElement('strong')
    heading.style.margin = '0px'

    const text = document.createTextNode(tooltip.title[0])

    heading.appendChild(text)
    container.appendChild(heading)

    // Remove old children
    while (tooltipEl.firstChild) {
      tooltipEl.firstChild.remove()
    }

    // Add new children
    tooltipEl.appendChild(caret)
    tooltipEl.appendChild(container)
  }

  const { offsetLeft: positionX } = chart.canvas
  const { boxes } = chart
  const xAxis: any = Object.values(boxes).find((box: any) => box.axis === 'x')

  // Display, position, and set styles for font
  tooltipEl.style.opacity = '1'
  tooltipEl.style.left = `${positionX + tooltip.caretX}px`
  tooltipEl.style.top = '0'
  tooltipEl.style.font = tooltip.options.bodyFont.string
  caret.style.height = chart.height - (xAxis?.height || 0) + 'px'
}
