import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxiosRequestConfig, newRequest } from 'utils/request'
import { Methods } from 'types/request'
import { SwitchLanguagePayload } from './types'
import i18n from 'i18next'
import { UserTypes } from 'store/auth/types'
import type { RootState } from 'store'
import { isAuthenticated } from 'utils/localStorageService'
import { getLowerCaseLanguage } from 'utils/app.ts'
import Cookies from 'js-cookie'

export const switchLanguage = createAsyncThunk<SwitchLanguagePayload, SwitchLanguagePayload, { rejectValue: string }>(
  'app/switchLanguage',
  async ({ language }, thunkAPI) => {
    try {
      const lowerCaseLanguage = getLowerCaseLanguage(language)
      // Change language in app
      await i18n.changeLanguage(lowerCaseLanguage)

      // Set language cookie
      Cookies.set('language', lowerCaseLanguage, { expires: 365 })

      // Constants
      const userType = (thunkAPI.getState() as RootState).auth.userType
      const contact = (thunkAPI.getState() as RootState).contact.contact
      const authenticatedUserTypes = isAuthenticated()

      // Change language in db if user is authenticated for customer users & _id can be found & and is a customer user
      if (authenticatedUserTypes[UserTypes.CUSTOMER] && contact && userType === UserTypes.CUSTOMER) {
        const { success, message } = await newRequest(
          getAxiosRequestConfig(Methods.PATCH, `/contacts/${contact.sfId}`, { contact: { language } })
        )

        if (!success) return thunkAPI.rejectWithValue(message ?? 'ApiError')
      }

      return { language }
    } catch (err) {
      const { message } = err as Error
      return thunkAPI.rejectWithValue(message)
    }
  }
)
