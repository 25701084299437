import styles from 'features/contracts/terminate/components/TerminateSideCard/TerminateSideCard.module.scss'
import { Button, Icon } from '@boltenergy-be/design-system'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'
import { useNavigate } from 'react-router'
import { useStoreDispatch, useStoreSelector } from 'hooks/store.ts'
import { useGetProductContentQuery } from 'store/queries/cms-api'
import { routes } from 'types/routes.ts'
import { saveRedirect } from 'store/app/slice.ts'
import { useTranslation } from 'react-i18next'
import { getProductFromProductCode } from 'utils/products.ts'
import { TerminateCardContentProps } from 'features/contracts/terminate/components/TerminateSideCard/types.ts'
import { selectCurrentAccount } from 'store/contact/selectors.ts'
import parse from 'html-react-parser'
import { formatAddress } from 'utils/user.ts'
import { AddressFormats } from 'types/format.ts'

const TerminateCardContent = ({ selectedContracts }: TerminateCardContentProps) => {
  // REDUX STORE
  const { language } = useStoreSelector((store) => store.app)
  const { billingContracts, accounts, selected } = useStoreSelector((store) => store.contact)
  const dispatch = useStoreDispatch()

  // Redux queries
  const { data: productsContent, isLoading: loadingProductsContent } = useGetProductContentQuery({ language })

  // React router
  const navigate = useNavigate()

  // i18n
  const { t } = useTranslation('contracts')

  // Constants
  const currentAccount = selectCurrentAccount({ accounts, selected })
  const { electricity, gas } = selectedContracts
  const { address } = electricity.deliveryPoint
  const product = getProductFromProductCode(electricity.detail.productCode)
  const hasOneAddress = Object.keys(billingContracts).length === 1

  /**
   * Handles the click on the change address button
   */
  const handleChangeAddressButtonClick = () => {
    dispatch(saveRedirect(routes.CONTRACTS_TERMINATE))
    navigate(routes.ACCOUNT_SELECTION)
  }

  return (
    <>
      <section className={styles.section}>
        <strong>{t('terminate.sideCard.address')}</strong>
        <div className={styles.address}>
          {parse(formatAddress(address, AddressFormats.FULL_TWO_LINES) || '')}
          {!hasOneAddress && (
            <Button representation="link" onClick={handleChangeAddressButtonClick}>
              {t('terminate.sideCard.editAddress')}
            </Button>
          )}
        </div>
      </section>

      <hr className={styles.divider} />

      <section className={styles.section}>
        <strong>{t('customerNumber', { ns: 'common' })}</strong>
        <span>
          {currentAccount.customerNumber}{' '}
          {currentAccount.company && <small className={styles.company}>({t('company', { ns: 'common' })})</small>}
        </span>
      </section>

      {product && (
        <>
          <hr className={styles.divider} />
          <section className={styles.section}>
            {loadingProductsContent ? (
              <LoadingSkeleton>
                <LoadingSkeleton.Title />
              </LoadingSkeleton>
            ) : (
              <strong>{productsContent?.[product]?.name}</strong>
            )}

            <ul className={styles.products}>
              <li>
                <Icon name="electricity" className="text-accent-brand" />
                {t('electricity', { ns: 'common' })}
              </li>

              {gas && (
                <li>
                  <Icon name="gas" className="text-accent-purple" />
                  {t('gas', { ns: 'common' })}
                </li>
              )}
            </ul>
          </section>
        </>
      )}
    </>
  )
}

export default TerminateCardContent
