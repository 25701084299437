import { useTranslation } from 'react-i18next'
import styles from 'pages/App/account-selection/AccountSelection.module.scss'
import { useStoreSelector } from 'hooks/store'
import { Banner, Heading } from '@boltenergy-be/design-system'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'
import AccountCard from 'pages/App/account-selection/account-card/AccountCard.tsx'
import PageLayout from 'layouts/page-layout/PageLayout.tsx'
import { UserTypes } from 'store/auth/types.ts'
import LanguageSwitchButton from 'components/LanguageSwitchButton/LanguageSwitchButton.tsx'

const AccountSelection = () => {
  // REDUX STORE
  const { contact, accounts, loading, ignoredBillingContracts } = useStoreSelector((store) => store.contact)
  const { userType } = useStoreSelector((store) => store.auth)

  // i18n
  const { t } = useTranslation('home')

  return (
    <PageLayout
      title={
        <div className={styles['title-wrapper']}>
          <Heading as="h1" variant="h3" className={styles.title}>
            {t('accountSelection.hey')}{' '}
            {contact?.firstName || (
              <LoadingSkeleton>
                <LoadingSkeleton.Rectangle width={220} height={32} />
              </LoadingSkeleton>
            )}
          </Heading>

          <LanguageSwitchButton />
        </div>
      }
      seoTitle={t('accountSelection.seoTitle')}
      description={t('accountSelection.description')}
      classes={{ body: styles.body, header: styles.header }}
    >
      <ul className={styles['accounts-list']}>
        {loading ? (
          <LoadingSkeleton as="li" className={styles['account-loading-card']}>
            <LoadingSkeleton.Rectangle width="100%" height={120} />
          </LoadingSkeleton>
        ) : (
          contact?.accounts &&
          contact.accounts.length &&
          [...contact?.accounts]
            .sort((a, b) => accounts[a].name.toUpperCase().localeCompare(accounts[b].name.toUpperCase()))
            .map((customerNumber) => <AccountCard account={accounts[customerNumber]} key={customerNumber} />)
        )}
      </ul>

      {ignoredBillingContracts.length > 0 && userType === UserTypes.SUPER_USER && (
        <Banner type="warning" title="The following addresses are hidden for the customer:" className="mt-500">
          <ul>
            {ignoredBillingContracts.map((contractId) => (
              <li key={contractId}>{contractId} - Gas only contract</li>
            ))}
          </ul>
        </Banner>
      )}
    </PageLayout>
  )
}

export default AccountSelection
