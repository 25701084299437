import { LanguageLowercase } from 'store/app/types.ts'

// TYPES

export type Response<T = undefined> = {
  success: boolean
  data?: T
  message?: string
  error?: string
  errorData?: string[]
}

export type PortalUserDataObject = {
  customerNumber: string
  email: string
  firstName: string
  language: LanguageLowercase
}

// ENUMS

export enum Methods {
  DELETE = 'DELETE',
  GET = 'GET',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT'
}
