import { TableDataRow } from 'components/Table/types.ts'

export enum EventLogCells {
  DATE = 'date',
  TYPE = 'type',
  UPDATER = 'updater',
  AUTH_MODEL = 'authModel',
  USER = 'user',
  JSON = 'json'
}

export type EventLogDataRow = TableDataRow<EventLogCells>
