import { NavigationItemType } from 'components/Charts/consumption-navigation/types.ts'
import { Granularity } from 'types/contracts.ts'
import dayjs from 'dayjs'
import { generateFromAndToValue } from 'pages/App/consumption/your-consumption/components/consumption-section/utils.ts'
import { VolumeSearchQueries } from 'pages/App/consumption/your-consumption/components/consumption-section/types.ts'
import { memoize } from 'proxy-memoize'
import { GenerateGetVolumesQuery } from 'pages/App/consumption/types.ts'

/**
 * Generate the query body for the getVolumes API
 * @param {GenerateGetVolumesQuery} params
 * @returns {Pick<VolumeSearchQueries, "from" | "until" | "granularity">}
 */
export const generateGetVolumesQuery = ({
  currentView,
  currentPeriod,
  billingCyclePeriods
}: GenerateGetVolumesQuery): Pick<VolumeSearchQueries, 'from' | 'until' | 'granularity'> => {
  if (currentView === NavigationItemType.CYCLE) {
    const cycle = billingCyclePeriods?.find(({ id }) => id === currentPeriod)

    return {
      granularity: Granularity.MONTH,
      from: dayjs(cycle?.startDate).startOf('day'),
      until: dayjs(cycle?.endDate).endOf('day')
    }
  } else {
    switch (currentView) {
      // Show usage for a complete year with MONTH granularity
      case Granularity.MONTH:
        return {
          granularity: Granularity.MONTH,
          ...generateFromAndToValue(currentPeriod, 'year')
        }

      // Show usage for a complete month with DAY granularity
      case Granularity.DAY:
        return {
          granularity: Granularity.DAY,
          ...generateFromAndToValue(currentPeriod, 'month')
        }

      // Show usage for a complete day with HOUR granularity
      case Granularity.HOUR:
        return {
          granularity: Granularity.HOUR,
          ...generateFromAndToValue(currentPeriod, 'day')
        }
    }
  }
}

export const memoizedGenerateGetVolumesQuery = memoize(generateGetVolumesQuery)
