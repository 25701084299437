import { PropsWithChildren } from 'react'
import { useLocation } from 'react-router'
import { DEBUGGING_ROUTER } from 'constants/envs.ts'

const DebugRouter = ({ children }: PropsWithChildren) => {
  const location = useLocation()
  if (DEBUGGING_ROUTER) {
    console.log(`Route: ${JSON.stringify(location, null, 2)}`)
  }

  return children
}

export default DebugRouter
