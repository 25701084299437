import { SortingDirection } from 'components/Table/types.ts'
import { InvoiceCells, InvoiceTableDataRow } from 'pages/App/billing/invoices/table-data/types.ts'
import { CustomerAccountingDocument } from 'types/types.ts'
import { store } from 'store/index.ts'
import { formatAddress } from 'utils/user.ts'

/**
 * Returns a paginated an array
 * @param {any[]} arr
 * @param {number }size
 */
export const paginate = (arr: any[], size: number) => {
  return arr.reduce((acc, val, i) => {
    const idx = Math.floor(i / size)
    const page = acc[idx] || (acc[idx] = [])
    page.push(val)
    return acc
  }, [])
}

/**
 * Returns the rawData from a given table cell.
 *
 * @param {RawDataType | undefined} rawData
 */
const getCellData = (rawData: unknown) => (typeof rawData === 'string' ? rawData.toUpperCase() : (rawData ?? ''))

/**
 * Sorts a Rows[] by one given key, in a 'asc' or 'desc' order.
 * @param {string} key
 * @param {SortingDirection} order
 */
export const sortRowsByKey =
  (key: InvoiceCells, order: SortingDirection = SortingDirection.ASC) =>
  (rowA: InvoiceTableDataRow, rowB: InvoiceTableDataRow) => {
    let comparison = 0
    const cellsA = rowA.entries[key]
    const cellsB = rowB.entries[key]

    if (!cellsA || !cellsB) return comparison

    const rawDataA = getCellData(cellsA.data)
    const rawDataB = getCellData(cellsB.data)

    if (rawDataA > rawDataB) {
      comparison = 1
    } else if (rawDataA < rawDataB) {
      comparison = -1
    }

    return order === SortingDirection.DESC ? comparison * -1 : comparison
  }

/**
 * Find the electricity contract address in the billing contracts dictionary based on the contract number
 * @param {CustomerAccountingDocument} document
 * @returns {string|null}
 */
export const getAddressByDocumentContractNumber = ({ document }: { document: CustomerAccountingDocument }): string | null => {
  if ('electricityContractNumber' in document) {
    const billingContracts = store.getState().contact.billingContracts
    const billingContract = Object.values(billingContracts).find(
      (contract) => contract.serviceContracts.electricity.contractNumber === document.electricityContractNumber
    )
    const electricityContract = billingContract?.serviceContracts.electricity

    if (electricityContract) {
      return formatAddress(electricityContract.deliveryPoint?.address)
    }
  }

  return null
}
