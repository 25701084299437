import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import styles from './Events.module.scss'
import SalesCard from 'components/SalesCard/SalesCard'
import { WEBSITE_URL } from 'constants/envs'
import { SalesEvent } from 'types/sales'
import { useGetSalesEventsQuery } from 'store/queries/bolt-api/sales'
import LoadingDots from 'components/LoadingDots/LoadingDots'
import { stringify } from 'qs'
import { useStoreSelector } from 'hooks/store.ts'
import PageLayout from 'layouts/page-layout/PageLayout.tsx'

const Events = () => {
  const { partner, ...salesUser } = useStoreSelector((store) => store.sales)
  const { data, isLoading, isError } = useGetSalesEventsQuery({ filterSignatures: false })

  // i18n
  const { t } = useTranslation(['home', 'common'])

  /**
   * Opens the event link of the given event
   *
   * @param {SalesEvent} event
   */
  const openEventLink = (event: SalesEvent) => {
    const query = stringify(
      {
        ...event.parameters,
        agent_id: salesUser?.agentId || '',
        event_id: event.eventId,
        event_name: event.eventName,
        partner_id: partner?.id || ''
      },
      { skipNulls: true, addQueryPrefix: true }
    )

    const url = `${WEBSITE_URL}/${salesUser?.language}${query}`
    window.open(url, '_blank')?.focus()
  }

  /**
   * Formats the arrays into a string if applicable
   *
   * @param {string[] | string} array
   */
  const formatArrays = (array: string[] | string) => {
    if (Array.isArray(array) && array.every((item) => typeof item === 'string')) {
      return array.join(' ')
    }
    return array
  }

  return (
    <PageLayout title={t('app.welcome')} classes={{ body: styles.wrapper }}>
      <section className={styles.events}>
        {isError && <p>{t('common:error')}</p>}

        {!!data && (
          <>
            {data?.events && data.events?.length > 0 ? (
              data.events
                .filter((event: SalesEvent) => !event.hidden)
                .map((event: SalesEvent) => (
                  <SalesCard key={event.eventId} extraClasses={styles['event-card']} onClick={() => openEventLink(event)}>
                    <strong className={styles['event-title']}>
                      {event.eventId} | {event.eventName}
                    </strong>
                    <div className={styles.parameters}>
                      <span>{formatArrays(event.parameters.simulation_so)}</span>
                      <span>{formatArrays(event.parameters.sign_channel)}</span>
                      <span>{formatArrays(event.parameters.simulation_type)}</span>
                    </div>
                    {(event.eventStartDate || event.eventEndDate) && (
                      <small className={styles['event-date']}>
                        {event.eventStartDate && dayjs(event.eventStartDate).format('DD/MM/YYYY')}
                        {event.eventStartDate && event.eventEndDate && ' - '}
                        {event.eventEndDate && dayjs(event.eventEndDate).format('DD/MM/YYYY')}
                      </small>
                    )}
                  </SalesCard>
                ))
            ) : (
              <p>{t('sales.home.noEvents')}</p>
            )}
          </>
        )}

        {!data && isLoading && <LoadingDots />}
      </section>
    </PageLayout>
  )
}

export default Events
