import { ReactNode, useLayoutEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import Slider, { Settings } from 'react-slick'
import styles from 'pages/App/energy-overview/EnergyOverview.module.scss'
import SliderArrow from 'components/Slider/ArrowButton/ArrowButton'
import { EnergyOverviewCarouselProps } from 'components/Slider/EnergyOverviewSlider/types'
import useWindowSize from 'hooks/useWindowSize'
import mixpanel from 'mixpanel-browser'
import { EnergyOverviewEvents } from 'types/tracking.ts'

const EnergyOverviewCarousel = ({ children, openFeedbackWidget, triggerConfetti, id, ...props }: EnergyOverviewCarouselProps) => {
  // Local state
  const [currentSlide, setCurrentSlide] = useState<number>(0)
  const [slideCount, setSlideCount] = useState<number>(0)

  // Ref
  const slider = useRef<any>(null)

  // Window size
  const { isMobile } = useWindowSize()

  useLayoutEffect(() => {
    if (slider?.current) {
      const amountOfSlides = slider.current.innerSlider.state.slideCount
      setSlideCount(amountOfSlides)
    }
  }, [slider?.current?.innerSlider?.state?.slideCount])

  const settings: Settings = {
    arrows: !isMobile,
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    ...props,
    draggable: isMobile,
    infinite: false,
    beforeChange: (_: number, nextSlide: number) => {
      mixpanel.track(EnergyOverviewEvents.GO_TO_SLIDE, { slide: nextSlide, id })
      setCurrentSlide(nextSlide)
    },
    appendDots: (dots: ReactNode): JSX.Element => (
      <nav>
        <ul className={styles.dots}>{dots}</ul>
      </nav>
    ),
    customPaging: (index) => (
      <div
        role="button"
        className={classNames(styles.dot, { [styles.active]: currentSlide === index })}
        aria-label={`slide ${index + 1}`}
      />
    ),
    nextArrow: <SliderArrow action="next" disabled={currentSlide + 1 === slideCount} />,
    prevArrow: <SliderArrow action="previous" disabled={currentSlide === 0} />,
    afterChange: (currentSlide: number) => {
      if (triggerConfetti && currentSlide + 1 === slideCount) {
        triggerConfetti(true)
        openFeedbackWidget()
      } else if (triggerConfetti && currentSlide === slideCount - 2) {
        triggerConfetti(false)
      }
    }
  }

  return (
    <Slider ref={slider} {...settings}>
      {children}
    </Slider>
  )
}

export default EnergyOverviewCarousel
