import { useState } from 'react'
import styles from './Carousel.module.scss'
import classNames from 'classnames'
import { Icon } from '@boltenergy-be/design-system'
import { CarouselProps } from 'components/Carousel/types.ts'
import { PropsWithClassName } from 'types/utility.ts'

const Carousel = ({ images, className }: PropsWithClassName<CarouselProps>) => {
  // Local state
  const [activeImageIdx, setActiveImage] = useState<number>(0)

  return (
    <div className={classNames(styles.carousel, className)}>
      <button
        className={classNames(styles.controls, styles.previous)}
        onClick={() => setActiveImage(activeImageIdx - 1)}
        disabled={activeImageIdx === 0}
      >
        <Icon name="chevronLeft" />
      </button>

      {images.map(({ src, alt }, idx) => (
        <img
          src={src}
          alt={`${alt} (${idx + 1})`}
          key={`${src}-${idx}`}
          className={styles['carousel-item']}
          data-active={activeImageIdx === idx}
        />
      ))}

      <button
        className={classNames(styles.controls, styles.next)}
        onClick={() => setActiveImage(activeImageIdx + 1)}
        disabled={activeImageIdx === images.length - 1}
      >
        <Icon name="chevronRight" />
      </button>

      {images.length > 1 && (
        <div className={styles.indicator}>
          {activeImageIdx + 1} / {images.length}
        </div>
      )}
    </div>
  )
}

export default Carousel
