import { RefObject, useEffect, useState } from 'react'

const useToggleWithClickOutside = (ref: RefObject<any>, customCloseHandler?: () => void) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  /**
   * Hides dropdown on click outside
   */
  useEffect(() => {
    const handleClickOutside = (e: Event) => {
      if (ref.current && !ref.current.contains(e.target as HTMLDivElement)) {
        if (typeof customCloseHandler === 'function') {
          customCloseHandler()
        } else {
          setIsOpen(false)
        }
      }
    }

    document.addEventListener('click', handleClickOutside, true)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  /**
   * Hides dropdown on ESC button press when dropdown is open
   */
  useEffect(() => {
    const handleEsc = (e: KeyboardEvent): void => {
      if (e.key === 'Escape') {
        if (typeof customCloseHandler === 'function') {
          customCloseHandler()
        } else {
          setIsOpen(false)
        }
      }
    }

    document.addEventListener('keydown', handleEsc)

    return () => {
      document.removeEventListener('keydown', handleEsc)
    }
  }, [isOpen])

  return { isOpen, setIsOpen }
}

export default useToggleWithClickOutside
