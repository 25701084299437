import { useStoreSelector } from 'hooks/store.ts'
import { selectCurrentContracts } from 'store/contact/selectors.ts'
import { determineAccessRights } from 'utils/contracts.ts'
import { useEffect, useState } from 'react'
import useOpenFeedbackWidget from 'hooks/useOpenFeedbackWidget.ts'
import { WidgetSlug } from 'types/feedbackWidget.ts'
import { useNavigate } from 'react-router'
import { routes } from 'types/routes.ts'
import styles from 'pages/App/market-prices/MarketPrices.module.scss'
import MarketPricesContent from 'pages/App/market-prices/market-prices-content/MarketPricesContent.tsx'
import { useTranslation } from 'react-i18next'
import FeedbackWidget from 'components/FeedbackWidget/FeedbackWidget.tsx'
import PageLayout from 'layouts/page-layout/PageLayout.tsx'
import { FaqSlug } from 'types/faq.ts'

const MarketPrices = () => {
  // Redux state
  const { billingContracts, selected } = useStoreSelector((store) => store.contact)
  const { electricity } = selectCurrentContracts({ billingContracts, selected }).serviceContracts

  // React router
  const navigate = useNavigate()

  // i18n
  const { t } = useTranslation('consumption')

  // Access rights
  const accessRights = determineAccessRights(electricity)

  // Local state
  const [startOpenFeedbackWidgetTimer, setStartFeedbackWidgetTimer] = useState<boolean>(false)

  // Custom hook to open feedback widget after 10 seconds
  const { feedbackWidgetData, openFeedbackWidget } = useOpenFeedbackWidget({
    delay: 10,
    slug: WidgetSlug.CONSUMPTION_DYNAMIC_TARIFF_RELEASE,
    startTimer: startOpenFeedbackWidgetTimer
  })

  useEffect(() => {
    if (accessRights.marketPrices.canAccess) {
      setStartFeedbackWidgetTimer(true)
    } else {
      navigate(routes.CONSUMPTION_ELECTRICITY)
    }
  }, [accessRights.marketPrices.canAccess])

  return (
    <>
      <PageLayout title={t('nav.marketPrices', { ns: 'common' })} faqCategories={[FaqSlug.MARKET_PRICES]}>
        <section className={styles['market-prices-section']}>
          <MarketPricesContent />
        </section>
      </PageLayout>

      {feedbackWidgetData?.isActive && <FeedbackWidget id={feedbackWidgetData._id} openFeedbackWidget={openFeedbackWidget} />}
    </>
  )
}

export default MarketPrices
