import { forwardRef, memo, PropsWithChildren } from 'react'
import { Banner, Button, Heading } from '@boltenergy-be/design-system'
import styles from './PageLayout.module.scss'
import { PageLayoutProps } from './types.ts'
import { useLocation } from 'react-router-dom'
import Subnav from 'components/Subnav/Subnav.tsx'
import SeoTags from 'components/SeoTags/SeoTags.tsx'
import { checkIsDown } from 'utils/app.ts'
import classNames from 'classnames'
import FaqButton from 'components/FaqButton/FaqButton.tsx'
import { useNavigate } from 'react-router'
import AddressSwitcherBar from 'components/AddressSwitcherBar/AddressSwitcherBar.tsx'
import parse from 'html-react-parser'

const PageLayout = memo(
  forwardRef<HTMLDivElement, PropsWithChildren<PageLayoutProps>>(
    ({ description, tabs, children, title, faqCategories, classes, seoTitle, actions, withBackButton, withSwitcher, banner }, ref) => {
      // React router
      const { pathname } = useLocation()
      const navigate = useNavigate()

      // Constants
      const { isDown, message } = checkIsDown()
      const activeTab = tabs?.length === 1 ? tabs[0] : tabs?.find((tab) => pathname.includes(tab.path))

      return (
        <>
          <SeoTags
            title={
              activeTab ? `${seoTitle ?? (title as string)} - ${activeTab.seoTitle || activeTab.label}` : (seoTitle ?? (title as string))
            }
          />

          {isDown && (
            <Banner type="informative" className={styles.maintenance}>
              <p>{message}</p>
            </Banner>
          )}

          <header className={classNames(styles.header, { [styles['with-description']]: !!description }, classes?.header)}>
            {withBackButton && <Button leadingIcon="arrowLeft" variant="tertiary" className={styles.back} onClick={() => navigate(-1)} />}

            <div className={styles['title-wrapper']}>
              {typeof title === 'string' ? (
                <Heading as="h1" variant="h3">
                  {title}
                </Heading>
              ) : (
                title
              )}
            </div>

            {(!!activeTab?.extraActions?.length || !!faqCategories?.length || !!actions) && (
              <div className={styles.actions}>
                {actions}
                {activeTab?.extraActions?.length && activeTab.extraActions.map((action, index) => <div key={index}>{action}</div>)}
                {!!faqCategories?.length && <FaqButton {...{ faqCategories }} />}
              </div>
            )}
          </header>

          {tabs && tabs.length > 1 && (
            <Subnav data-id="page-subnav" className={styles.subnav} tabs={tabs.map((tab) => ({ label: tab.label, path: tab.path }))} />
          )}

          <div ref={ref} className={classNames(styles.content, classes?.body)}>
            {!!banner && (
              <Banner type={banner.type} className={classNames(banner.className)}>
                {typeof banner.message === 'string' ? <p>{parse(banner.message)}</p> : banner.message}
              </Banner>
            )}

            {!!withSwitcher && <AddressSwitcherBar className="mb-500" />}

            {!!description &&
              (typeof description === 'string' ? (
                <p className={styles.description}>{parse(description)}</p>
              ) : (
                <div className={styles.description}>{description}</div>
              ))}

            {children}
          </div>
        </>
      )
    }
  )
)

PageLayout.displayName = 'PageLayout'

export default PageLayout
