import { UserTypes } from 'store/auth/types'
import { routes } from 'types/routes'
import { checkIsSales } from 'utils/sales'
import { USER_TYPE_CONFIG } from 'constants/userTypes'
import { Address } from 'types/general.ts'
import { capitalize } from './format.ts'
import { store } from 'store/index.ts'
import { getContactByEmail, getMyContact } from 'store/contact/thunks'
import { unwrapResult } from '@reduxjs/toolkit'
import { GetContactSuccessPayload } from 'store/contact/thunks/types.ts'
import { AddressFormats } from 'types/format.ts'

/**
 * returns the correct route of the user types' homepage
 *
 * @param {UserTypes} type
 * @return {string}
 */
export const getUserTypeHomepage = (type: UserTypes | null): string => {
  const { selected } = store.getState().contact
  switch (type) {
    case UserTypes.SALES:
      return USER_TYPE_CONFIG[UserTypes.SALES].home
    case UserTypes.SUPER_USER:
      return USER_TYPE_CONFIG[UserTypes.SUPER_USER].home
    case UserTypes.CUSTOMER:
      return selected.account && selected.billingContract ? routes.OVERVIEW : routes.ACCOUNT_SELECTION
    default:
      return checkIsSales() ? routes.LOGIN_SALES : routes.LOGIN
  }
}

/**
 * returns the correct route of the user types' homepage
 *
 * @param {UserTypes} type
 * @return {string}
 */
export const getUserTypeLoginPage = (type: UserTypes | null): string => {
  if (type) return USER_TYPE_CONFIG[type].login
  return checkIsSales() ? USER_TYPE_CONFIG[UserTypes.SALES].login : USER_TYPE_CONFIG[UserTypes.CUSTOMER].login
}

/**
 * Verifies if the given coords are not null/undefined and not of value 0
 * @param {(number | null)[]} coords
 * @returns {boolean}
 */
export const verifyCoordinates = (coords: (number | null)[]): boolean =>
  coords.every((coordinate) => typeof coordinate === 'number' && coordinate !== 0)

/**
 * Formats the given address into a string
 *
 * @param {Address} address
 * @param {AddressFormats} format
 * @returns string|null
 */
export const formatAddress = (address?: Address, format?: AddressFormats): string | null => {
  if (!address || Object.values(address).every((value) => !value)) {
    return null
  }

  const lowercaseStreetName = capitalize(address.streetName)
  const lowercaseTownName = capitalize(address.townName)
  const streetNumberBox = address.streetBox
    ? `${lowercaseStreetName} ${address.streetNumber} ${address.streetBox}`
    : `${lowercaseStreetName} ${address.streetNumber}`
  const postalCodeTown = `${address.postalCode} ${lowercaseTownName}`

  switch (format) {
    case AddressFormats.FULL_TWO_LINES: {
      return `${streetNumberBox}<br />${postalCodeTown}`
    }
    case AddressFormats.STREET_NUMBER_BOX: {
      return streetNumberBox
    }
    case AddressFormats.POSTAL_CODE_TOWN: {
      return postalCodeTown
    }

    case AddressFormats.FULL_SINGLE_LINE:
    default: {
      return `${streetNumberBox}, ${postalCodeTown}`
    }
  }
}

/**
 * Fetches the contact data based on the user type
 * @param {UserTypes.CUSTOMER | UserTypes.SUPER_USER} userType
 * @param {string} contactEmail
 * @returns {Promise<GetContactSuccessPayload | undefined>}
 */
export const fetchContactData = async (
  userType: UserTypes.CUSTOMER | UserTypes.SUPER_USER,
  contactEmail?: string
): Promise<GetContactSuccessPayload | undefined> => {
  try {
    if (userType === UserTypes.SUPER_USER) {
      const email = contactEmail || store.getState().auth.email
      if (email) {
        const res = await store.dispatch(getContactByEmail({ email }))
        return unwrapResult(res)
      }
    } else {
      const res = await store.dispatch(getMyContact())
      return unwrapResult(res)
    }

    return undefined
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    return undefined
  }
}
