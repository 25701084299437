import {
  CreateMeterReadingsRequest,
  Direction,
  Index,
  MeterReadings,
  MeterReadingsPayload,
  ServiceContractElectricity,
  UnknownServiceContract
} from 'types/contracts.ts'
import { TimeframeCode } from 'types/types.ts'
import { MeterReadingInputLayoutContract } from './types.ts'
import { FieldErrors } from 'react-hook-form'
import { EXTRA_CONSUMPTION_LIMIT_EL, EXTRA_CONSUMPTION_LIMIT_GAS } from 'pages/App/billing/billing-cycles/constants.ts'
import dayjs from 'dayjs'

/**
 * Determines which meter readings inputs should be shown & if they are optional or required
 *
 * @param {Contract} electricityContract
 * @param {MeterReadings} [meterReadings]
 * @returns {MeterReadingInputLayoutContract}
 */
export const determineElMeterReadingInputLayoutContract = (
  electricityContract: ServiceContractElectricity,
  meterReadings?: MeterReadings
): MeterReadingInputLayoutContract => {
  // Meter readings are returned by Transfo
  if (meterReadings && meterReadings.electricity && !!Object.keys(meterReadings.electricity.consumption).length) {
    const {
      consumption: { singleRate, exclNight, doubleRate },
      injection
    } = meterReadings.electricity

    return {
      allElOptional: false,
      electricity: {
        consumption: {
          day: typeof doubleRate?.day !== 'undefined',
          night: typeof doubleRate?.night !== 'undefined',
          exclNight: typeof exclNight !== 'undefined',
          total: typeof singleRate !== 'undefined'
        },
        ...(injection && {
          injection: {
            day: typeof injection.doubleRate?.day !== 'undefined',
            night: typeof injection.doubleRate?.night !== 'undefined',
            total: typeof injection.singleRate !== 'undefined'
          }
        })
      }
    }
  }

  // No meter readings returned = check previous indexes
  if (
    electricityContract.deliveryPoint?.previousIndex?.registers &&
    electricityContract.deliveryPoint?.previousIndex?.registers?.length > 0
  ) {
    const consumptionRegisters = electricityContract.deliveryPoint.previousIndex.registers.filter(
      (r) => r.direction === Direction.CONSUMPTION
    )
    const injectionRegisters = electricityContract.deliveryPoint.previousIndex.registers.filter((r) => r.direction === Direction.PRODUCTION)

    return {
      allElOptional: false,
      electricity: {
        consumption: {
          day: consumptionRegisters.some((r) => r.timeframeCode === TimeframeCode.HIGH),
          night: consumptionRegisters.some((r) => r.timeframeCode === TimeframeCode.LOW),
          exclNight: consumptionRegisters.some((r) => r.timeframeCode === TimeframeCode.NIGHT_EXCLUSIVE),
          total: consumptionRegisters.some((r) => r.timeframeCode === TimeframeCode.TOTAL_HOUR)
        },
        ...(injectionRegisters.length > 0 && {
          injection: {
            day: injectionRegisters.some((r) => r.timeframeCode === TimeframeCode.HIGH),
            night: injectionRegisters.some((r) => r.timeframeCode === TimeframeCode.LOW),
            total: injectionRegisters.some((r) => r.timeframeCode === TimeframeCode.TOTAL_HOUR)
          }
        })
      }
    }
  }

  // Both are not returned, show everything but optional
  return {
    allElOptional: true,
    electricity: {
      consumption: {
        day: true,
        night: true,
        exclNight: true,
        total: true
      }
    }
  }
}

let path = ''

/**
 * Recursive function that loops over the errorObject until it found the deepest nested object level where the error.type is
 * Returns an object with error.type as key and object paths as values
 * @param object
 * @param storeObj
 * @returns {{[p: string]: string[]}}
 */
const findErrorObject = (object: any, storeObj?: any): { [errorType: string]: string[] } =>
  Object.keys(object).reduce((errors: { [errorType: string]: string[] }, currKey) => {
    if (!!object[currKey] && typeof object[currKey] === 'object') {
      const errorType: string | undefined = object[currKey].type

      if (errorType) {
        const errorTypePath = path === '' ? currKey : `${path}.${currKey}`

        if (!errors[errorType]?.length) errors[errorType] = []
        errors[errorType].push(errorTypePath)
        return errors
      } else {
        path = path === '' ? currKey : `${path}.${currKey}`
        findErrorObject(object[currKey], storeObj)
      }
    }

    path = ''
    return errors
  }, storeObj)

/**
 * Generates the CreateMeterReadingRequest object
 *
 * @param {MeterReadingsPayload} data
 * @param {Contract} contract
 * @param {boolean} isGas
 * @returns {CreateMeterReadingsRequest}
 */
export const generateCreateMeterReadingRequest = (
  data: MeterReadingsPayload,
  contract: UnknownServiceContract,
  isGas?: boolean
): CreateMeterReadingsRequest => {
  return {
    date: data.date,
    contractNumber: contract.contractNumber,
    ean: contract.deliveryPoint.ean,
    indexes: isGas
      ? [
          // CONSUMPTION - SINGLE RATE
          ...(typeof data.gas?.consumption.singleRate !== 'undefined'
            ? [generateIndex(data.gas.consumption.singleRate, contract, TimeframeCode.TOTAL_HOUR, Direction.CONSUMPTION)]
            : [])
        ]
      : [
          // CONSUMPTION - SINGLE RATE
          ...(typeof data.electricity.consumption.singleRate !== 'undefined'
            ? [generateIndex(data.electricity.consumption.singleRate, contract, TimeframeCode.TOTAL_HOUR, Direction.CONSUMPTION)]
            : []),

          // CONSUMPTION - DAY
          ...(typeof data.electricity.consumption.doubleRate?.day !== 'undefined'
            ? [generateIndex(data.electricity.consumption.doubleRate.day, contract, TimeframeCode.HIGH, Direction.CONSUMPTION)]
            : []),

          // CONSUMPTION - NIGHT
          ...(typeof data.electricity.consumption.doubleRate?.night !== 'undefined'
            ? [generateIndex(data.electricity.consumption.doubleRate.night, contract, TimeframeCode.LOW, Direction.CONSUMPTION)]
            : []),

          // CONSUMPTION - NIGHT EXCLUSIVE
          ...(typeof data.electricity.consumption.exclNight !== 'undefined'
            ? [generateIndex(data.electricity.consumption.exclNight, contract, TimeframeCode.NIGHT_EXCLUSIVE, Direction.CONSUMPTION)]
            : []),

          // INJECTION - SINGLE RATE
          ...(typeof data.electricity.injection?.singleRate !== 'undefined'
            ? [generateIndex(data.electricity.injection.singleRate, contract, TimeframeCode.TOTAL_HOUR, Direction.PRODUCTION)]
            : []),

          // INJECTION - DAY
          ...(typeof data.electricity.injection?.doubleRate?.day !== 'undefined'
            ? [generateIndex(data.electricity.injection.doubleRate.day, contract, TimeframeCode.HIGH, Direction.PRODUCTION)]
            : []),

          // INJECTION - NIGHT
          ...(typeof data.electricity.injection?.doubleRate?.night !== 'undefined'
            ? [generateIndex(data.electricity.injection.doubleRate.night, contract, TimeframeCode.LOW, Direction.PRODUCTION)]
            : [])
        ]
  }
}

/**
 * Maps the index based on the given data
 *
 * @param {number} value
 * @param {Contract} contract
 * @param {TimeframeCode} timeframeCode
 * @param {Direction} direction
 * @returns {Index}
 */
const generateIndex = (value: number, contract: UnknownServiceContract, timeframeCode: TimeframeCode, direction: Direction): Index => {
  const meterNumber = contract?.deliveryPoint.previousIndex?.meterNumber
  const register = contract?.deliveryPoint?.previousIndex?.registers.find(
    (r) => r.timeframeCode === timeframeCode && r.direction === direction
  )

  return {
    meterNumber: meterNumber || '',
    registerName: register?.registerName || '',
    timeframeCode,
    value,
    direction
  }
}

/**
 * Generates an errors object for multiple fields
 *
 * @param {FieldErrors} errors
 * @returns {{[errorType: string]: string[]}}
 */
export const getMultiFieldErrors = (errors: FieldErrors): { [errorType: string]: string[] } => {
  const currErrors = {}
  return findErrorObject(errors, currErrors)
}

/**
 * Checks if at least one value is lower than previous entry of the meter reading date is same or after the last meter reading date
 *
 * @param {MeterReadings} [meterReadingsPayload]
 * @param {MeterReadings} [meterReadings]
 * @returns {boolean}
 */
export const hasLowerValue = (meterReadingsPayload: MeterReadingsPayload, meterReadings?: MeterReadings): boolean => {
  const meterReadingDateIsSameOrAfterLastElecMeterReadingDate = meterReadings?.electricity
    ? dayjs(meterReadingsPayload.date).isSameOrAfter(meterReadings.electricity.date)
    : false
  const meterReadingDateIsSameOrAfterLastGasMeterReadingDate = meterReadings?.gas
    ? dayjs(meterReadingsPayload.date).isSameOrAfter(meterReadings.gas.date)
    : false

  const singlRateIsLower =
    (meterReadingsPayload.electricity?.consumption?.singleRate ?? 0) < (meterReadings?.electricity?.consumption?.singleRate ?? 0)
  const exclNightIsLower =
    (meterReadingsPayload.electricity?.consumption?.exclNight ?? 0) < (meterReadings?.electricity?.consumption?.exclNight ?? 0)
  const doubleRateDayConsumptionIsLower =
    (meterReadingsPayload.electricity?.consumption?.doubleRate?.day ?? 0) < (meterReadings?.electricity?.consumption?.doubleRate?.day ?? 0)
  const doubleRateNighConsumptiontIsLower =
    (meterReadingsPayload.electricity?.consumption?.doubleRate?.night ?? 0) <
    (meterReadings?.electricity?.consumption?.doubleRate?.night ?? 0)
  const singleRateInjectionIsLower =
    (meterReadingsPayload.electricity?.injection?.singleRate ?? 0) < (meterReadings?.electricity?.injection?.singleRate ?? 0)
  const doubleRateDayInjectionIsLower =
    (meterReadingsPayload.electricity?.injection?.doubleRate?.day ?? 0) < (meterReadings?.electricity?.injection?.doubleRate?.day ?? 0)
  const doubleRateNightInjectionIsLower =
    (meterReadingsPayload.electricity?.injection?.doubleRate?.night ?? 0) < (meterReadings?.electricity?.injection?.doubleRate?.night ?? 0)
  const gasIsLower = (meterReadingsPayload.gas?.consumption?.singleRate ?? 0) < (meterReadings?.gas?.consumption?.singleRate ?? 0)

  return (
    (meterReadingDateIsSameOrAfterLastElecMeterReadingDate &&
      (singlRateIsLower ||
        exclNightIsLower ||
        doubleRateDayConsumptionIsLower ||
        doubleRateNighConsumptiontIsLower ||
        singleRateInjectionIsLower ||
        doubleRateDayInjectionIsLower ||
        doubleRateNightInjectionIsLower)) ||
    (meterReadingDateIsSameOrAfterLastGasMeterReadingDate && gasIsLower)
  )
}

/**
 * Checks if at least one value is higher than previous entry + a buffer
 *
 * @param {MeterReadings} [meterReadingsPayload]
 * @param {MeterReadings} [meterReadings]
 * @returns {boolean}
 */
export const exceedsCap = (meterReadingsPayload: MeterReadingsPayload, meterReadings?: MeterReadings): boolean =>
  (meterReadingsPayload.electricity?.consumption?.singleRate ?? 0) >
    (meterReadings?.electricity?.consumption?.singleRate ?? 0) + EXTRA_CONSUMPTION_LIMIT_EL ||
  (meterReadingsPayload.electricity?.consumption?.exclNight ?? 0) >
    (meterReadings?.electricity?.consumption?.exclNight ?? 0) + EXTRA_CONSUMPTION_LIMIT_EL ||
  (meterReadingsPayload.electricity?.consumption?.doubleRate?.day ?? 0) >
    (meterReadings?.electricity?.consumption?.doubleRate?.day ?? 0) + EXTRA_CONSUMPTION_LIMIT_EL ||
  (meterReadingsPayload.electricity?.consumption?.doubleRate?.night ?? 0) >
    (meterReadings?.electricity?.consumption?.doubleRate?.night ?? 0) + EXTRA_CONSUMPTION_LIMIT_EL ||
  (meterReadingsPayload.electricity?.injection?.singleRate ?? 0) >
    (meterReadings?.electricity?.injection?.singleRate ?? 0) + EXTRA_CONSUMPTION_LIMIT_EL ||
  (meterReadingsPayload.electricity?.injection?.doubleRate?.day ?? 0) >
    (meterReadings?.electricity?.injection?.doubleRate?.day ?? 0) + EXTRA_CONSUMPTION_LIMIT_EL ||
  (meterReadingsPayload.electricity?.injection?.doubleRate?.night ?? 0) >
    (meterReadings?.electricity?.injection?.doubleRate?.night ?? 0) + EXTRA_CONSUMPTION_LIMIT_EL ||
  (meterReadingsPayload?.gas?.consumption?.singleRate ?? 0) >
    (meterReadings?.gas?.consumption?.singleRate ?? 0) + EXTRA_CONSUMPTION_LIMIT_GAS
