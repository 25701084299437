import { PropsWithChildren } from 'react'
import ModalWrapper from '../ModalWrapper'
import styles from './Modal.module.scss'
import { CombinedModalProps } from './types'
import { Heading, Button } from '@boltenergy-be/design-system'
import classNames from 'classnames'

const Modal = ({
  withCloseButton = true,
  children,
  setClose,
  variant = 'rounded',
  className,
  ...remainingProps
}: PropsWithChildren<CombinedModalProps>) => {
  const props = { withCloseButton, ...remainingProps }

  return (
    <ModalWrapper {...props} className={classNames(styles.modal, styles[variant], className)} setClose={setClose}>
      <header className={styles.header}>
        {'title' in remainingProps && <Heading as="h5">{remainingProps.title}</Heading>}
        <Button type="button" onClick={setClose} leadingIcon="cross" variant="secondary" />
      </header>
      {children}
    </ModalWrapper>
  )
}

export default Modal
