import Card from 'components/Card/Card.tsx'
import { Button } from '@boltenergy-be/design-system'
import Link from 'components/Link/Link.tsx'
import classNames from 'classnames'
import styles from './OverviewCard.module.scss'
import { OverviewCardProps } from './types.ts'
import { CardTitleProps } from 'components/Card/types.ts'
import mixpanel from 'mixpanel-browser'
import { OverviewEvents } from 'types/tracking.ts'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { AnimationStates } from 'hooks/types.ts'

const Title = ({ children, variant = 'h4', weight = 700, ...props }: CardTitleProps) => (
  <Card.Title {...{ variant, weight, ...props }}>{children}</Card.Title>
)

const OverviewCard = forwardRef<HTMLElement, OverviewCardProps>(
  ({ button, children, className, isLoading, animation = AnimationStates.IN, setHidden, ...cardProps }, ref) => {
    // Ref
    const cardRef = useRef<HTMLElement>(null)

    // Local state
    const [localAnimation, setLocalAnimation] = useState<AnimationStates | null>(
      animation === AnimationStates.NONE ? null : animation || null
    )

    // Update the local animation & trigger a re-animate on all the next siblings
    useEffect(() => {
      let delay = 0

      /**
       * Recursive function to repeat the out & in animation
       * @param {HTMLElement | null} el
       */
      const reAnimateNextSiblings = (el: HTMLElement | null) => {
        if (el && el.nodeName === 'SECTION') {
          el.classList.remove(styles['animate-in'])
          el.classList.remove(styles['animate-out'])
          void el.offsetWidth
          el.classList.add(styles['animate-out'])
          el.onanimationend = () => {
            el.style.animationDelay = `${delay * 100}ms`
            el.classList.remove(styles['animate-out'])
            el.classList.add(styles['animate-in'])
            el.onanimationend = null
            delay = delay + 1
          }

          reAnimateNextSiblings(el.nextElementSibling as HTMLElement)
        }
      }

      setLocalAnimation(animation)

      if (animation === AnimationStates.OUT) {
        reAnimateNextSiblings(cardRef?.current?.nextElementSibling as HTMLElement)
      }
    }, [animation])

    // Use to combine forwardRef with the local cardRef
    useImperativeHandle(ref, () => cardRef.current as HTMLElement, [])

    return (
      <Card
        as="section"
        className={classNames(
          styles.card,
          {
            [styles.loading]: isLoading,
            [styles['animate-in']]: localAnimation === AnimationStates.IN,
            [styles['animate-out']]: localAnimation === AnimationStates.OUT
          },
          className
        )}
        {...cardProps}
        ref={cardRef}
        onAnimationEnd={() => {
          if (localAnimation === AnimationStates.OUT) {
            setHidden?.()
          } else {
            setLocalAnimation(null)
          }
        }}
      >
        {children}

        {button.href ? (
          <Link
            loading={isLoading}
            isFullwidth
            trailingIcon="chevronRight"
            variant="secondary"
            representation="button"
            className={styles.cta}
            href={button.href}
            onClick={() => mixpanel.track(OverviewEvents.CLICK_OVERVIEW_CARD, { id: button.href })}
          >
            {button.label}
          </Link>
        ) : (
          <Button
            loading={isLoading}
            isFullwidth
            trailingIcon="chevronRight"
            variant="secondary"
            className={styles.cta}
            onClick={button.onClick}
          >
            {button.label}
          </Button>
        )}
      </Card>
    )
  }
)

const OverviewCardWithSubcomponents = Object.assign(OverviewCard, { Title })

export default OverviewCardWithSubcomponents
