import PageLayout from 'layouts/page-layout/PageLayout.tsx'
import { useTranslation } from 'react-i18next'
import { useStoreSelector } from 'hooks/store.ts'
import { Navigate, Outlet, useLocation } from 'react-router'
import { routes } from 'types/routes.ts'
import styles from './GuaranteeOfOrigin.module.scss'

const GuaranteeOfOrigin = () => {
  // Redux
  const { isProducer } = useStoreSelector((store) => store.contact)

  // i18n
  const { t } = useTranslation('guaranteeOfOrigin')

  // Router
  const { pathname } = useLocation()

  return !isProducer ? (
    <Navigate to={routes.OVERVIEW} replace />
  ) : (
    <PageLayout
      title={t('title')}
      description={t(`${pathname === routes.GUARANTEE_OF_ORIGIN_STATUS ? 'status' : 'what'}.description`)}
      tabs={[
        { label: t('tabs.what'), path: routes.GUARANTEE_OF_ORIGIN },
        { label: t('tabs.status'), path: routes.GUARANTEE_OF_ORIGIN_STATUS }
      ]}
      classes={{ body: styles.body }}
    >
      <Outlet />
    </PageLayout>
  )
}

export default GuaranteeOfOrigin
