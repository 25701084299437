import { useTranslation } from 'react-i18next'
import StepList from 'components/StepList/StepList.tsx'
import { Region } from 'api/types.ts'
import { useState } from 'react'
import { useStoreSelector } from 'hooks/store.ts'
import { selectCurrentContracts } from 'store/contact/selectors.ts'
import { Heading, Icon, Toggle } from '@boltenergy-be/design-system'
import Card from 'components/Card/Card.tsx'
import { CardColors } from 'components/Card/types.ts'
import styles from '../GuaranteeOfOrigin.module.scss'
import Link from 'components/Link/Link.tsx'
import Modal from 'components/Modals/Modal/Modal.tsx'
import Video from 'components/Video/Video.tsx'
import { GO_INFO_URLS } from 'pages/App/guarantee-of-origin/what/constants.ts'

const GuaranteeOfOriginWhat = () => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)
  const { selected, billingContracts } = useStoreSelector((store) => store.contact)
  const { electricity } = selectCurrentContracts({ selected, billingContracts }).serviceContracts

  // i18n
  const { t } = useTranslation(['guaranteeOfOrigin', 'common'])

  // Local state
  const [region, setRegion] = useState<Region>(electricity.deliveryPoint.region || Region.FLANDERS)
  const [videoModalOpen, setVideoModalOpen] = useState<boolean>(false)

  // Constants
  const infoUrl = typeof GO_INFO_URLS[region] === 'string' ? GO_INFO_URLS[region] : GO_INFO_URLS[region][language]

  return (
    <>
      <Card as="section" padding={200} className={styles['video-card']} color={CardColors.SECONDARY}>
        <figure>
          <button className={styles['play-btn']} onClick={() => setVideoModalOpen(true)}>
            <img
              src={`https://res.cloudinary.com/bolt-energie/video/upload/v1733229964/website/videos/guarantee-of-origin-${language.toLowerCase()}.jpg`}
              alt=""
            />
            <div className={styles.icon}>
              <Icon name="play" />
            </div>
          </button>

          <figcaption>{t('what.video.title')}</figcaption>
        </figure>
      </Card>

      <Card as="section" className={styles['go-info-card']}>
        <Heading as="h1" variant="h4">
          {t('what.actions.title')}
        </Heading>

        <Toggle
          active={region}
          onClick={(r) => setRegion(r)}
          options={Object.values(Region).map((r) => ({ label: t(`common:regions.${r}`), value: r }))}
          className={styles.toggle}
          optionClassName={styles.option}
        />

        <StepList>
          {[1, 2, 3].map((key) => {
            const typedKey = key.toString() as '1' | '2' | '3'
            return (
              <li key={key}>
                <strong>{t(`what.actions.${region}.steps.${typedKey}.title`)}</strong>
                <p>{t(`what.actions.${region}.steps.${typedKey}.description`)}</p>
              </li>
            )
          })}
        </StepList>

        <Link representation="button" isFullwidth href={infoUrl} external>
          {t(`what.actions.${region}.button`)}
        </Link>
      </Card>

      <Modal variant="video" isOpen={videoModalOpen} setClose={() => setVideoModalOpen(false)}>
        <Video publicId={`website/videos/guarantee-of-origin-${language.toLowerCase()}`} />
      </Modal>
    </>
  )
}

export default GuaranteeOfOriginWhat
