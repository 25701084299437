import { useTranslation } from 'react-i18next'
import ModalWrapper from 'components/Modals/ModalWrapper.tsx'
import { Button } from '@boltenergy-be/design-system'
import { ConfirmationModalProps } from 'pages/App/consumption/edit-meter-readings/components/confirmation-modal/types.ts'
import styles from 'pages/App/consumption/edit-meter-readings/components/confirmation-modal/ConfirmationModal.module.scss'
import resources from 'translations/dutch'

const ConfirmationModal = ({ isOpen, state, setClose, onSubmit }: ConfirmationModalProps) => {
  // i18n
  const { t } = useTranslation('consumption')

  return (
    <ModalWrapper isStacked isOpen={isOpen} setClose={setClose} className={styles.container}>
      <div className={styles.content}>
        <h3>{t('editMeterReadings.confirmationModal.title')}</h3>
        <p className={styles.description}>
          {t(
            `editMeterReadings.confirmationModal.description.${
              state.toLowerCase() as keyof typeof resources.consumption.editMeterReadings.confirmationModal.description
            }`
          )}
        </p>
      </div>
      <div className={styles['actions-container']}>
        <Button variant="tertiary" size="small" onClick={() => setClose()}>
          {t('cancel', { ns: 'common' })}
        </Button>
        <Button
          size="small"
          onClick={async () => {
            onSubmit()
            setClose()
          }}
        >
          {t('confirm', { ns: 'common' })}
        </Button>
      </div>
    </ModalWrapper>
  )
}

export default ConfirmationModal
