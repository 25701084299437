import styles from './AccountCard.module.scss'
import Tag from 'components/Tag/Tag.tsx'
import { Button, Icon } from '@boltenergy-be/design-system'
import { formatAddress } from 'utils/user.ts'
import Card from 'components/Card/Card.tsx'
import { deleteRedirect } from 'store/app/slice.ts'
import { routes } from 'types/routes.ts'
import { useStoreDispatch, useStoreSelector } from 'hooks/store.ts'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { resetContractsStore } from 'store/contracts/slice.ts'
import { selectContract } from 'store/contact/slice.ts'
import { AccountCardProps } from './types.ts'
import { useState } from 'react'
import { AccountRecordTypeIds } from 'types/contacts.ts'
import { groupBillingContractsByActiveServiceContract, isActiveContract } from 'utils/contracts.ts'
import Cookies from 'js-cookie'
import dayjs from 'dayjs'

const AccountCard = ({ account }: AccountCardProps) => {
  // REDUX STORE
  const { redirect } = useStoreSelector((store) => store.app)
  const { billingContracts } = useStoreSelector((store) => store.contact)
  const dispatch = useStoreDispatch()

  // React Router
  const navigate = useNavigate()

  // i18n
  const { t } = useTranslation(['home', 'common'])

  // Local state
  const [isOpen, setIsOpen] = useState<boolean | null>(account.billingContracts.length > 1 ? false : null)

  // Constants
  const { active, inactive } = groupBillingContractsByActiveServiceContract(account.billingContracts, billingContracts)

  /**
   * Select contract in store
   * @param {string} contractNumber
   */
  const selectContractInStore = (contractNumber: string) => {
    dispatch(selectContract(contractNumber))
    dispatch(resetContractsStore())
  }

  /**
   * Handles the click on a contract/address
   * @param {string} selectedBillingCustomerNumber
   * @returns void
   */
  const handleSelectContract = (selectedBillingCustomerNumber: string) => {
    Cookies.set(account.customerNumber, selectedBillingCustomerNumber)
    selectContractInStore(selectedBillingCustomerNumber)

    // Define where to redirect: if redirect string in AppStore present, use that. Else redirect to overview
    if (redirect) {
      navigate(redirect)
      // Clear redirect from AppStore
      dispatch(deleteRedirect())
    } else {
      navigate(routes.OVERVIEW)
    }
  }

  /**
   * Selects the first active contract or first contract
   */
  const handleClickAccountInfo = () => {
    const contractCookiesCleared = Cookies.get('contract-cookies-cleared')

    // Clear the contract cookies if not already done. Necessary due to the new contract selection logic
    if (!contractCookiesCleared) {
      Cookies.remove(account.customerNumber)
      Cookies.set('contract-cookies-cleared', dayjs().format('YYYY-MM-DD'), { expires: 365 })
    }

    const lastOpenedContract = Cookies.get(account.customerNumber)

    if (lastOpenedContract) {
      handleSelectContract(lastOpenedContract)
    } else {
      // Select first active contract or first contract
      const contract =
        account.billingContracts.find((c) => isActiveContract(billingContracts[c].serviceContracts.electricity)) ||
        account.billingContracts[0]
      Cookies.set(account.customerNumber, contract)
      handleSelectContract(contract)
    }
  }

  return (
    <Card as="li" key={account.customerNumber} className={styles['account-card']} padding={{ desktop: 500, mobile: 400 }}>
      <button onClick={handleClickAccountInfo} className={styles.info}>
        <strong className={styles.name}>{account.name}</strong>
        <Icon name="chevronRight" className={styles.chevron} />
        <small className="grid-col-full">{account.customerNumber}</small>
        {account.recordType === AccountRecordTypeIds.PRODUCER && (
          <Tag size="small" className="grid-col-full" color="yellow" icons="electricity">
            {t('accountSelection.producer')}
          </Tag>
        )}
      </button>

      <hr />

      {account.billingContracts.length === 1 ? (
        <Button
          representation="link"
          variant="tertiary"
          onClick={() => handleSelectContract(billingContracts[account.billingContracts[0]].contractNumber)}
          className={styles.address}
          title={formatAddress(billingContracts[account.billingContracts[0]].serviceContracts.electricity.deliveryPoint.address) || ''}
        >
          {formatAddress(billingContracts[account.billingContracts[0]].serviceContracts.electricity.deliveryPoint.address)}
        </Button>
      ) : !isOpen ? (
        <Button
          representation="link"
          variant="tertiary"
          onClick={() => setIsOpen(!isOpen)}
          className={styles.toggle}
          trailingIcon="chevronDown"
        >
          {t('accountSelection.showMore', { count: account.billingContracts.length })}
        </Button>
      ) : (
        <>
          {active.length > 0 && (
            <ul className={styles.contracts}>
              {active.map((billingContractNumber) => (
                <li key={billingContractNumber}>
                  <Button
                    representation="link"
                    variant="tertiary"
                    onClick={() => handleSelectContract(billingContractNumber)}
                    className={styles.address}
                    title={formatAddress(billingContracts[billingContractNumber].serviceContracts.electricity.deliveryPoint.address) || ''}
                  >
                    {formatAddress(billingContracts[billingContractNumber].serviceContracts.electricity.deliveryPoint.address)}
                  </Button>
                </li>
              ))}
            </ul>
          )}

          {inactive.length > 0 && (
            <div>
              <small className={styles.divider}>{t('common:inactiveAddresses')}</small>
              <ul className={styles.contracts}>
                {inactive.map((billingContractNumber) => (
                  <li key={billingContractNumber}>
                    <Button
                      representation="link"
                      variant="tertiary"
                      onClick={() => handleSelectContract(billingContractNumber)}
                      className={styles.address}
                      title={
                        formatAddress(billingContracts[billingContractNumber].serviceContracts.electricity.deliveryPoint.address) || ''
                      }
                    >
                      {formatAddress(billingContracts[billingContractNumber].serviceContracts.electricity.deliveryPoint.address)}
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      )}
    </Card>
  )
}

export default AccountCard
