import ProducerMap from 'components/Map/ProducerMap'
import { GMAP_KEY } from 'constants/envs'
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'
import styles from './Producer.module.scss'
import { verifyCoordinates } from 'utils/user'
import { useStoreSelector } from 'hooks/store.ts'
import { selectCurrentAccount, selectCurrentContracts, selectProducerId } from 'store/contact/selectors.ts'
import { useGetParentProducerBySlugQuery, useGetProducerByIdQuery } from 'store/queries/bolt-api/producers'
import { useEffect, useMemo, useState } from 'react'
import Geocode from 'react-geocode'
import { log } from 'utils/logging.ts'
import { MapsLocation } from 'components/Map/types.ts'
import { getLowerCaseLanguage } from 'utils/app.ts'
import PageLayout from 'layouts/page-layout/PageLayout.tsx'
import { Button, Icon, Heading } from '@boltenergy-be/design-system'
import Link from 'components/Link/Link'
import useWindowSize from 'hooks/useWindowSize'
import ProducerFooter from './producer-footer/ProducerFooter'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton'
import { FaqSlug } from 'types/faq.ts'
import classNames from 'classnames'
import mixpanel from 'mixpanel-browser'
import { ProducerEvents } from 'types/tracking.ts'
import Carousel from 'components/Carousel/Carousel.tsx'
import { CarouselProps } from 'components/Carousel/types.ts'

const Producer = () => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)
  const { billingContracts, selected, accounts, isProducer } = useStoreSelector((store) => store.contact)
  const lowerCaseLanguage = getLowerCaseLanguage(language)
  const billingContract = selectCurrentContracts({ billingContracts, selected })
  const { electricity } = billingContract.serviceContracts
  const selectedAccount = selectCurrentAccount({ selected, accounts })
  const producerId = isProducer && selectedAccount.producerId ? selectedAccount.producerId : selectProducerId(billingContract)

  // Redux Queries
  const { data, isLoading } = useGetProducerByIdQuery({ producerIdOrSlug: producerId, full: true })
  const { data: parentProducer, isLoading: parentProducerLoading } = useGetParentProducerBySlugQuery(data?.producer?.parentSlug || '', {
    skip: !data?.producer?.parentSlug
  })

  // i18n
  const { t } = useTranslation(['producer', 'common'])

  // Window size
  const { isTablet } = useWindowSize()

  // Local state
  const [location, setLocation] = useState<MapsLocation | undefined>(undefined)
  const [readingFullDescription, setReadingFullDescription] = useState<boolean>(false)

  // Constants
  const hasConsumerMap =
    !isProducer &&
    !!data?.producer?.coordinates &&
    !!location &&
    verifyCoordinates([data.producer.coordinates?.lat && data.producer.coordinates?.long])
  const hasProducerMap =
    isProducer && data?.producer?.parentSlug
      ? !!parentProducer?.locations &&
        verifyCoordinates(parentProducer.locations.flatMap((loc) => [loc.coordinates.lat, loc.coordinates.long]))
      : !!data?.producer?.coordinates && verifyCoordinates([data.producer.coordinates?.lat && data.producer.coordinates?.long])

  const producerImages = useMemo<CarouselProps['images']>(() => {
    const images: CarouselProps['images'] = []

    if (data?.producer?.images?.profile?.url) {
      images.push({ src: data.producer.images.profile.url, alt: t('alt', { producer: data.producer.name[lowerCaseLanguage] }) })
    }

    if (data?.producer?.images?.header?.url) {
      images.push({ src: data.producer.images.header.url, alt: t('alt', { producer: data.producer.name[lowerCaseLanguage] }) })
    }

    if (data?.producer?.images?.extra) {
      data.producer.images.extra.forEach((img) => {
        if (img.url) images.push({ src: img.url, alt: t('alt', { producer: data?.producer?.name[lowerCaseLanguage] }) })
      })
    }

    return images
  }, [data?.producer?.images, lowerCaseLanguage, t, data?.producer?.name])

  // Track page opened event
  useEffect(() => {
    mixpanel.track(ProducerEvents.PAGE_OPENED)
  }, [])

  /**
   * Fetches the coordinates of the user location
   * @returns {Promise<void>}
   */
  const getLocationCoords = async () => {
    try {
      Geocode.setApiKey(GMAP_KEY as string)
      const { address } = electricity.deliveryPoint
      const addressParam = `${address.streetName} ${address.streetNumber} ${address.postalCode} ${address.townName}`
      const { results } = await Geocode.fromAddress(addressParam)
      if (results) setLocation(results[0].geometry.location)
    } catch (err) {
      log({
        error: err as Error,
        identifier: '[Geocode:fromAddress]'
      })
    }
  }

  // Fetch location coords on pageload
  useEffect(() => {
    if (GMAP_KEY) getLocationCoords()
  }, [])

  return (
    <>
      {isTablet && (
        <section className={styles['cover-image']}>
          {isLoading ? (
            <LoadingSkeleton.Rectangle width="100%" aspectRatio="16/9" className={styles.carousel} />
          ) : (
            <Carousel className={styles.carousel} images={producerImages} />
          )}
        </section>
      )}

      <PageLayout
        title={t(`title.${isProducer ? 'producer' : 'consumer'}`)}
        classes={{ body: styles.container }}
        faqCategories={isProducer ? [FaqSlug.MY_PRODUCER_B2B] : [FaqSlug.MY_PRODUCER_B2C]}
        withSwitcher
      >
        <div className={styles['left-content']}>
          {isLoading ? (
            <LoadingSkeleton>
              <LoadingSkeleton.Title />
              <LoadingSkeleton.Paragraph lines={5} />
            </LoadingSkeleton>
          ) : (
            !!data?.producer && (
              <>
                <section className={styles['brief-info']}>
                  <Heading as="h2" variant="h4">
                    {data.producer.name[lowerCaseLanguage]}
                  </Heading>
                  {!data.producer.facts?.[lowerCaseLanguage].every((fact) => !fact) && (
                    <>
                      <strong>{t('inShort')}</strong>
                      <ul className={styles['facts-list']}>
                        {data.producer.facts?.[lowerCaseLanguage].map(
                          (fact, index) =>
                            !!fact && (
                              <li key={index}>
                                <Icon name="check" />
                                {fact}
                              </li>
                            )
                        )}
                      </ul>
                    </>
                  )}
                </section>

                {!!data.producer.longDescription?.[lowerCaseLanguage] && (
                  <section className={styles.description}>
                    <div className={classNames(styles['long-description'], { [styles.open]: readingFullDescription })}>
                      {parse(data.producer.longDescription?.[lowerCaseLanguage]?.replaceAll('style=', 'data-style='))}
                    </div>

                    <Button
                      representation="link"
                      variant="secondary"
                      onClick={() => setReadingFullDescription(!readingFullDescription)}
                      trailingIcon={readingFullDescription ? 'chevronUp' : 'chevronDown'}
                    >
                      {readingFullDescription ? t('readLess', { ns: 'common' }) : t('readMore', { ns: 'common' })}
                    </Button>
                  </section>
                )}

                {!!data.producer.companyDescription?.[lowerCaseLanguage] && (
                  <section className={styles.company}>
                    <Heading as="h2" variant="h4">
                      {t('about')}
                    </Heading>

                    <div className={styles.logo}>
                      <img src={data.producer.images?.logo?.url} alt={data.producer.images?.logo?.name} />
                    </div>

                    <div className={styles['company-content']}>
                      <p>{parse(data.producer.companyDescription?.[lowerCaseLanguage])}</p>

                      {data.producer.website && (
                        <Link external href={data.producer.website} variant="secondary" className="mt-200">
                          {t('website')}
                        </Link>
                      )}
                    </div>
                  </section>
                )}

                {(hasProducerMap || hasConsumerMap) && (
                  <section className={styles['gmap-wrapper']}>
                    <Heading as="h2" variant="h4">
                      {t(isProducer ? 'yourLocations' : 'locations')}
                    </Heading>
                    <div className={styles.gmap}>
                      {hasProducerMap ? (
                        parentProducerLoading ? (
                          <LoadingSkeleton>
                            <LoadingSkeleton.Rectangle width="100%" aspectRatio="1/1" />
                          </LoadingSkeleton>
                        ) : (
                          <ProducerMap
                            producerLocations={
                              parentProducer
                                ? parentProducer?.locations.map((childProducer) => ({
                                    lat: childProducer.coordinates.lat,
                                    lng: childProducer.coordinates.long,
                                    energyType: childProducer.energyType
                                  }))
                                : [
                                    {
                                      lat: data.producer.coordinates.lat,
                                      lng: data.producer.coordinates.long,
                                      energyType: data.producer.energyType
                                    }
                                  ]
                            }
                          />
                        )
                      ) : (
                        <ProducerMap
                          producerLocation={{
                            lat: data.producer.coordinates.lat,
                            lng: data.producer.coordinates.long
                          }}
                          userLocation={location!}
                          producerEnergyType={data.producer.energyType}
                        />
                      )}
                    </div>
                  </section>
                )}
              </>
            )
          )}
        </div>

        <aside className={styles['image-wrapper']}>
          {isLoading ? (
            <LoadingSkeleton.Rectangle width="100%" aspectRatio="1/1" />
          ) : (
            <>
              {!isTablet && <Carousel className={styles.carousel} images={producerImages} />}
              {isProducer && <ProducerFooter />}
            </>
          )}
        </aside>
      </PageLayout>
    </>
  )
}

export default Producer
