import styles from './InfoCard.module.scss'
import { InfoCardProps } from './types'
import { useStoreSelector } from 'hooks/store'
import { Language, LanguageLowercase } from 'store/app/types'
import { useTranslation } from 'react-i18next'
import { Electricity, Gas } from 'assets/svg'
import ProducerCardLayout from '../ProducerCardLayout/ProducerCardLayout'
import { useGetProductContentQuery } from 'store/queries/cms-api'
import { AddContractSteps } from 'features/contracts/add/types'
import { getProductFromProductCode } from 'utils/products'
import PriceDisplay from '../PriceDisplay/PriceDisplay'
import { Product } from 'types/products'
import { Heading } from '@boltenergy-be/design-system'
import { SimulationType } from 'types/contacts.ts'
import { getProducerLocation } from 'utils/producer.ts'
import { getLowerCaseLanguage } from '../../../../../utils/app.ts'

const InfoCardContent = ({ contractData, currentStep }: InfoCardProps) => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)

  // Redux queries
  const { data: productContent } = useGetProductContentQuery({ language })

  // I18n
  const { t } = useTranslation('producer')

  // Constants
  const producer = contractData[AddContractSteps.PRODUCER]
  const { needsGas } = contractData[AddContractSteps.ADDRESS]
  const { simulationType, instalments } = contractData[AddContractSteps.INSTALMENT_DATA]
  const totalInstalment = (instalments.electricity || 0) + (needsGas ? instalments.gas || 0 : 0)
  const product = getProductFromProductCode(contractData[AddContractSteps.PRODUCT].electricity)
  const productName =
    [
      AddContractSteps.INSTALMENT_DATA,
      AddContractSteps.METER_DETAILS,
      AddContractSteps.CUSTOMER_DATA,
      AddContractSteps.CONFIRMATION
    ].includes(currentStep) && product
      ? `Bolt ${productContent?.[product]?.name}`
      : undefined
  const showPrice = [AddContractSteps.METER_DETAILS, AddContractSteps.CUSTOMER_DATA, AddContractSteps.CONFIRMATION].includes(currentStep)

  return (
    <ProducerCardLayout className={styles['card-content']} hideProducerLocation {...{ producer }}>
      {/* SELECTED PRODUCER NAME */}
      <section className={styles['el-product']}>
        <Electricity isFilled />
        <strong>
          {t('energyTypeAndLocation', '{{ energyType }} uit {{ location }} van', {
            energyType:
              language === Language.FR
                ? t(`energyType.${producer?.energyType || 'Solar'}`).toLowerCase()
                : t(`energyType.${producer?.energyType || 'Solar'}`),
            location: getProducerLocation(getLowerCaseLanguage(language), producer?.location)
          })}
        </strong>
        <Heading as="h3" variant="h5" className="mt-100">
          {producer?.name[language.toLowerCase() as LanguageLowercase]}
        </Heading>
      </section>

      {needsGas && (
        <>
          <hr />
          <section className={styles['gas-product']}>
            <Gas isFilled />
            <strong>{t('gasFromMarket', 'Gas van de groothandelsmarkt', { ns: 'common' })}</strong>
          </section>
        </>
      )}

      {productName && (
        <>
          <hr />
          <section className={styles.product}>
            <Heading as="h4" variant="h5">
              {productName}
            </Heading>
            {showPrice && (
              <>
                {simulationType === SimulationType.EAV ? (
                  <p>{t('add.sidecard.instalment.EAV', { ns: 'contracts' })}</p>
                ) : (
                  <PriceDisplay amount={totalInstalment} label={product === Product.GO ? t('perYear', { ns: 'common' }) : undefined} />
                )}
              </>
            )}
          </section>
        </>
      )}
    </ProducerCardLayout>
  )
}

export default InfoCardContent
