import { useEffect, useMemo } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { routes } from 'types/routes'
import { AuthProducer } from './types'
import styles from './Auth.module.scss'
import SeoTags from 'components/SeoTags/SeoTags'
import AuthSlider from 'components/Slider/AuthSlider/AuthSlider'
import SmallProducerCard from 'components/SmallProducerCard/SmallProducerCard'
import { authProducers } from './constants'
import { shuffleArray } from 'utils/objects'
import useWindowSize from 'hooks/useWindowSize'
import LanguageSwitchBar from 'components/LanguageSwitchBar/LanguageSwitchBar'
import { SMALL_DESKTOP_BREAKPOINT } from 'constants/viewport.ts'
import { Outlet } from 'react-router'
import { useStoreSelector } from 'hooks/store.ts'
import { isAuthenticated } from 'utils/localStorageService.ts'
import { UserTypes } from 'store/auth/types.ts'
import { USER_TYPE_CONFIG } from 'constants/userTypes.ts'
import { setUserType } from 'utils/userType.ts'
import { getLowerCaseLanguage } from 'utils/app'
import { Flag } from '@boltenergy-be/design-system'
import { IS_BETA } from 'constants/envs.ts'

const Auth = () => {
  // Redux store
  const { language } = useStoreSelector((store) => store.app)

  // i18n
  const { t } = useTranslation('auth')

  // React Router
  const { pathname } = useLocation()
  const navigate = useNavigate()

  // Window size
  const { isSmaller } = useWindowSize(SMALL_DESKTOP_BREAKPOINT)

  // Constants
  const shuffledProducers = useMemo<AuthProducer[]>(() => shuffleArray(authProducers), [])

  /**
   * Get page title
   * @returns {string}
   */
  const getPageTitle = (): string => {
    switch (pathname) {
      case routes.RESET_PASSWORD:
      case routes.REQUEST_NEW_PASSWORD:
      case routes.RESET_PASSWORD_SALES:
      case routes.REQUEST_NEW_PASSWORD_SALES:
        return t('seo.reset')
      case routes.REGISTER:
        return t('seo.register')
      default:
        return t('seo.login')
    }
  }

  useEffect(() => {
    const authenticatedUserTypes = isAuthenticated()
    const routesByUserType: { [key in UserTypes]: (routes | string)[] } = {
      [UserTypes.CUSTOMER]: [USER_TYPE_CONFIG.customer.login, routes.REGISTER],
      [UserTypes.SUPER_USER]: [USER_TYPE_CONFIG.superuser.login],
      [UserTypes.SALES]: [USER_TYPE_CONFIG.sales.login]
    }

    const routesToMatch = [
      ...routesByUserType[UserTypes.CUSTOMER],
      ...routesByUserType[UserTypes.SUPER_USER],
      ...routesByUserType[UserTypes.SALES]
    ]

    if (routesToMatch.includes(pathname as routes)) {
      for (const userType in routesByUserType) {
        // If the userType is already authenticated and the URL is the correct one, redirect to the correct page & set the userType
        if (authenticatedUserTypes[userType as UserTypes] && routesByUserType[userType as UserTypes].includes(pathname as routes)) {
          setUserType(userType as UserTypes)
          navigate(USER_TYPE_CONFIG[userType as UserTypes].home, { replace: true })
          break
        }
      }
    }
  }, [pathname])

  return (
    <>
      <SeoTags title={getPageTitle()} />

      <main className={styles['auth-wrapper']}>
        <section className={styles.auth}>
          <LanguageSwitchBar redirectUrl={`https://boltenergie.be/${getLowerCaseLanguage(language)}`} />

          {IS_BETA && <Flag title={t('betaFlag')} type="informative" className={styles.flag} />}

          <div className={styles['auth-content']}>
            <Outlet />
          </div>
        </section>

        {!isSmaller && (
          <aside className={styles.producers}>
            <AuthSlider producers={shuffledProducers}>
              {shuffledProducers.map((producer) => {
                return (
                  <div key={producer[language].name} className={styles.slide}>
                    <SmallProducerCard
                      inverted
                      className={styles['producer-info']}
                      title={producer[language].name}
                      energyType={producer.energyType}
                      location={producer[language].location}
                      badge={t('tag.producer', { ns: 'producer' })}
                    />
                    <img
                      className={classNames(styles['slide-img'], 'subtle-zoom-animation')}
                      style={{ objectPosition: producer.objectPosition }}
                      src={producer.publicImgUrl}
                      alt={producer[language].imgAlt}
                    />
                  </div>
                )
              })}
            </AuthSlider>
          </aside>
        )}
      </main>
    </>
  )
}

export default Auth
