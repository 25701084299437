import { Banner, Form, Heading, Select, TextField } from '@boltenergy-be/design-system'
import CollapsibleCard from 'components/CollapsibleCard/CollapsibleCard'
import { useTranslation } from 'react-i18next'
import styles from './Details.module.scss'
import { AccountDetailsProps } from './types'
import DataBlock from 'features/contracts/add/components/DataBlock/DataBlock'
import SubnavWithoutNavigation from 'components/Subnav/SubNavWithoutNavigation'
import EditingButton from 'components/EditingButton/EditingButton'
import { useState } from 'react'
import { useUpdateAccountMutation } from 'store/queries/bolt-api/contacts'
import { FormProvider, useForm } from 'react-hook-form'
import classNames from 'classnames'
import { validateBelgianEnterpriseNumber } from 'utils/validation.ts'
import { Account, AccountType, LegalForm } from 'types/contacts.ts'
import { Toggle, popErrorToast, popSuccessToast } from '@boltenergy-be/design-system'
import resources from 'translations/dutch'
import { maskWithAsterisk } from 'utils/format.ts'

const AccountDetails = ({
  account,
  changeSelectedAccount,
  tabs,
  activeEditing,
  setActiveEditing,
  contactId,
  identifier
}: AccountDetailsProps) => {
  // i18n
  const { t } = useTranslation(['sales', 'common', 'validation'])

  // React Hook Form
  const hookForm = useForm<Partial<Account>>({
    defaultValues: account
  })
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors, dirtyFields }
  } = hookForm

  // Local constants
  const isEditing = activeEditing === identifier

  // Local state
  const [nameWarning, setNameWarning] = useState(false)
  const [isCompany, setIsCompany] = useState(!!account.company)

  // Mutation
  const [updateAccount, { isLoading }] = useUpdateAccountMutation()

  /**
   * Updates the Account object in the API
   *
   * @param {Partial<Account>} data
   */
  const onSave = async (data: Partial<Account>) => {
    // Set the name warning if the first or last name have been updated
    setNameWarning(!!dirtyFields.firstName || !!dirtyFields.lastName)

    try {
      // Update the account
      const updatedAccount = await updateAccount({
        account: {
          ...data,
          ...(isCompany && data.company ? { company: data.company } : { company: undefined })
        },
        accountId: account.sfId,
        contactId: contactId || ''
      }).unwrap()

      // Check if the account was updated
      if (updatedAccount) {
        // Remove the editing state
        setActiveEditing(undefined)

        // Show success toast
        popSuccessToast(t('details.toast.success'))
      } else {
        popErrorToast(t('error', { ns: 'common' }))
      }
    } catch (error) {
      popErrorToast(t('error', { ns: 'common' }))
    }
  }

  return (
    <section>
      <header className={styles.heading}>
        <SubnavWithoutNavigation tabs={tabs} activeTabPath={account.sfId} setActiveTab={changeSelectedAccount} className={styles.subnav} />
        <menu className={styles.actions}>
          <EditingButton
            isDisabled={account.type !== AccountType.PROSPECT || !!activeEditing}
            setIsEditing={setActiveEditing}
            onSubmit={handleSubmit(onSave)}
            onCancel={() => {
              reset()
              setIsCompany(!!account.company)
            }}
            {...{ identifier, isEditing, isLoading }}
          />
        </menu>
      </header>

      <CollapsibleCard
        titleSection={
          <Heading as="h4" className={styles.title}>
            {t('details.customer.title')}
          </Heading>
        }
        contentClassName={styles['details-block']}
      >
        {nameWarning && (
          <Banner
            type="warning"
            className={classNames(styles['warning-banner'], styles['full-width'])}
            title={t('details.customer.warning.title')}
          >
            {t('details.customer.warning.description')}
          </Banner>
        )}

        <FormProvider {...hookForm}>
          <Form>
            <span className="grid-col-full">
              <DataBlock label={t('details.customer.customerType')} withMargin={false}>
                {isEditing ? (
                  <>
                    <Toggle
                      options={[
                        { label: t('details.customer.professional'), value: true },
                        { label: t('details.customer.residential'), value: false }
                      ]}
                      onClick={(value) => {
                        setIsCompany(value)
                      }}
                      active={isCompany}
                    />
                  </>
                ) : (
                  <small>{account.company ? t('details.customer.professional') : t('details.customer.residential')}</small>
                )}
              </DataBlock>
            </span>

            {/* First Name */}
            <TextField
              readonly={!isEditing ? watch('firstName') : undefined}
              {...register('firstName', { required: t('validation:required') })}
              label={t('details.contactData.firstName')}
              error={errors.firstName?.message}
            />

            {/* Last Name */}
            <TextField
              readonly={!isEditing ? watch('lastName') : undefined}
              {...register('lastName', { required: t('validation:required') })}
              label={t('details.contactData.lastName')}
              error={errors.lastName?.message}
            />

            {isCompany && (
              <>
                <TextField
                  readonly={!isEditing ? watch('company.name') : undefined}
                  {...register('company.name', { required: t('validation:required') })}
                  label={t('details.customer.companyName')}
                  error={errors.company?.name?.message}
                />

                {/* Legal form */}
                <Select
                  readonly={
                    !isEditing
                      ? t(`common:legalForms.${watch('company.legalForm') as keyof typeof resources.common.legalForms}`)
                      : undefined
                  }
                  {...register('company.legalForm', { required: t('validation:required') })}
                  label={t('details.customer.form')}
                  error={errors.company?.legalForm?.message}
                >
                  {Object.values(LegalForm)
                    .sort((a, b) => a.localeCompare(b))
                    .map((value) => {
                      const label = t(`common:legalForms.${value as keyof typeof resources.common.legalForms}`)

                      return (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      )
                    })}
                </Select>

                <TextField
                  readonly={!isEditing ? maskWithAsterisk(watch('company.enterpriseNumber')) : undefined}
                  {...register('company.enterpriseNumber', {
                    required: t('validation:required'),
                    validate: (value: string | undefined) =>
                      value && (validateBelgianEnterpriseNumber(value) || t('validation:invalid.enterpriseNumber'))
                  })}
                  label={t('details.customer.enterpriseNumber')}
                  error={errors.company?.enterpriseNumber?.message}
                />

                {/* VAT */}
                <Select
                  readonly={!isEditing ? (watch('company.vatApplication') ? t('common:yes') : t('common:no')) : undefined}
                  {...register('company.vatApplication', { required: t('validation:required') })}
                  label={t('details.customer.requiresVat')}
                  error={errors.company?.vatApplication?.message}
                  onChange={(e) => setValue('company.vatApplication', e.target.value === 'true')}
                >
                  <option value="true">{t('common:yes')}</option>
                  <option value="false">{t('common:no')}</option>
                </Select>
              </>
            )}
          </Form>
        </FormProvider>
      </CollapsibleCard>
    </section>
  )
}

export default AccountDetails
