import { PropsWithChildren, useEffect, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import classNames from 'classnames'
import { ModalProps } from './types'
import styles from './ModalWrapper.module.scss'
import { createPortal } from 'react-dom'
import useToggleWithClickOutside from 'hooks/useToggleWithClickOutside.ts'
import FocusTrap from 'focus-trap-react'

const ModalWrapperBase = ({ isStacked, isOpen, setClose, className, children, withBackdrop = true }: PropsWithChildren<ModalProps>) => {
  const modalRef = useRef<HTMLDialogElement>(null)
  useToggleWithClickOutside(modalRef, setClose)

  /**
   * Stop scroll on body if modal is open
   */
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll')
    }

    return () => document.body.classList.remove('no-scroll')
  }, [isOpen])

  return (
    <>
      <CSSTransition nodeRef={modalRef} in={isOpen} timeout={300} classNames="fade-in-scale-up" unmountOnExit>
        <div className={classNames(styles.container, { [styles.stacked]: isStacked })}>
          <FocusTrap>
            <dialog ref={modalRef} className={classNames(styles.modal, className)} open={isOpen}>
              {children}
            </dialog>
          </FocusTrap>
        </div>
      </CSSTransition>
      {withBackdrop && (
        <CSSTransition in={isOpen} timeout={300} classNames="fade-in" unmountOnExit>
          <div className={classNames(styles.backdrop, { [styles.stacked]: isStacked })} />
        </CSSTransition>
      )}
    </>
  )
}

const ModalWrapper = ({ withCloseButton = true, modalRootEl, ...remaingProps }: PropsWithChildren<ModalProps>) => {
  const props = { withCloseButton, ...remaingProps }

  if (modalRootEl) {
    return createPortal(<ModalWrapperBase {...props} />, modalRootEl)
  }

  return <ModalWrapperBase {...props} />
}

export default ModalWrapper
