import styles from 'components/Table/Table.module.scss'
import { Button, Icon } from '@boltenergy-be/design-system'
import { SortingDirection, TableHeaderProps } from 'components/Table/types.ts'
import classNames from 'classnames'

const TableHead = ({ header, hidden, cellKey, sorting, onSort }: TableHeaderProps) => {
  return (
    <th {...{ hidden }}>
      {header && (
        <div className={styles['th-layout']}>
          <strong>{header}</strong>

          <div className={styles['sorting-controls']}>
            <Button
              variant={sorting?.key === cellKey && sorting?.direction === SortingDirection.ASC ? 'secondary' : 'tertiary'}
              className={classNames(styles['control-button'], {
                [styles.active]: sorting?.key === cellKey && sorting?.direction === SortingDirection.ASC
              })}
              onClick={() => onSort?.({ key: cellKey, direction: SortingDirection.ASC })}
            >
              <Icon name="chevronUp" />
            </Button>
            <Button
              variant={sorting?.key === cellKey && sorting?.direction === SortingDirection.DESC ? 'secondary' : 'tertiary'}
              className={classNames(styles['control-button'], {
                [styles.active]: sorting?.key === cellKey && sorting?.direction === SortingDirection.DESC
              })}
              onClick={() => onSort?.({ key: cellKey, direction: SortingDirection.DESC })}
            >
              <Icon name="chevronDown" />
            </Button>
          </div>
        </div>
      )}
    </th>
  )
}

export default TableHead
