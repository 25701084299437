import { RootState } from 'store/index.ts'
import { BillingContract } from 'types/contracts.ts'
import { memoize } from 'proxy-memoize'
import { NormalizedAccount, NormalizedContact, NormalizedEntities } from 'store/contact/types.ts'
import { isActiveContract } from 'utils/contracts.ts'

/**
 * Select the current billing contract
 * @description Memoized selector to get the current billing contract and prevent re-renders.
 * @throws {} Will throw an error if used outside the `RouteWithSelectedContract` wrapper.
 * Usage of this route wrapper can be found in `router/routing.tsx`.
 * @param {Pick<RootState['contacts'], 'selected' | 'billingContracts'>} state
 * @returns {NonNullable<BillingContract>}
 */
export const selectCurrentContracts = memoize<Pick<RootState['contact'], 'selected' | 'billingContracts'>, NonNullable<BillingContract>>(
  (state) => {
    const { selected, billingContracts } = state
    return billingContracts[selected.billingContract] as NonNullable<BillingContract>
  }
)

/**
 * Select the producer id
 * @description selector to get the producer id from the electricity contract to prevent repeating deeply nested selectors.
 * @param {BillingContract} billingContract
 * @returns {NonNullable<BillingContract>}
 */
export const selectProducerId = (billingContract: BillingContract): string => {
  return billingContract.serviceContracts?.electricity.detail.producer
}

/**
 * Filter active billing contracts
 * @description Filters active billing contracts from the given list of billing contracts.
 * @param {billingContract[]} billingContracts
 * @returns {[key: string]: BillingContract}
 */
export const selectActiveBillingContracts = (billingContracts: { [key: string]: BillingContract }) => {
  const activeBillingContracts: NormalizedEntities['billingContracts'] = {}

  for (const [key, billingContract] of Object.entries(billingContracts)) {
    if (isActiveContract(billingContract.serviceContracts.electricity)) {
      activeBillingContracts[key] = billingContract
    }
  }

  return activeBillingContracts
}

/**
 * Select the current account
 * @description Memoized selector to get the current account and prevent re-renders.
 * @throws {} Will throw an error if used outside the `RouteWithContracts` wrapper.
 * Usage of this route wrapper can be found in `router/routing.tsx`.
 * @param {Pick<RootState['contacts'], 'accounts' | 'selected'>} state
 * @returns {NonNullable<Account>}
 */
export const selectCurrentAccount = memoize<Pick<RootState['contact'], 'accounts' | 'selected'>, NonNullable<NormalizedAccount>>(
  (state) => {
    const { selected, accounts } = state
    return accounts[selected.account] as NonNullable<NormalizedAccount>
  }
)

/**
 * Select the current contact
 * @description Memoized selector to get the current contact and prevent re-renders.
 * @throws {} Will throw an error if used outside the `AppContainer` wrapper.
 * @param {RootState} state
 * @returns {NonNullable<Contact>}
 */
export const selectContact = memoize<Pick<RootState['contact'], 'contact'>, NonNullable<NormalizedContact>>((state) => {
  const { contact } = state
  return contact as NonNullable<NormalizedContact>
})
