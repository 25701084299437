import { useMatches } from 'react-router-dom'

import { LayoutMatch } from '../layouts/types.ts'

const useGetRouteLayout = () => {
  const matches = useMatches() as LayoutMatch[]
  const layout = matches.filter((match) => Boolean(match.handle?.layout))?.map((match) => match.handle.layout)[0]
  return { layout }
}

export default useGetRouteLayout
