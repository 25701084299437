import styles from './MeterReadingCard.module.scss'
import { Banner, Heading, Icon } from '@boltenergy-be/design-system'
import Card from 'components/Card/Card.tsx'
import { ProductType } from 'types/types.ts'
import { Direction, MeterIndexType } from 'types/contracts.ts'
import { useTranslation } from 'react-i18next'
import MeterReadingInputField from '../meter-reading-input-field/MeterReadingInputField.tsx'
import { MeterReadingCardProps } from './types.ts'
import { getMultiFieldErrors } from 'pages/App/consumption/edit-meter-readings/utils.ts'
import resources from 'translations/dutch'
import { useFormContext } from 'react-hook-form'
import { EditMeterReadingsFormInputs } from 'pages/App/consumption/edit-meter-readings/types.ts'

const MeterReadingCard = ({ type, direction, inputLayout, allElOptional }: MeterReadingCardProps) => {
  // i18n
  const { t } = useTranslation('consumption')

  // React Hook Form
  const { formState } = useFormContext<EditMeterReadingsFormInputs>()

  // Constants
  const isInjection = direction === Direction.PRODUCTION && type === ProductType.ELECTRICITY
  const isGas = type === ProductType.GAS
  const cardErrors = isGas ? formState?.errors?.[type] : formState?.errors?.[type]?.[isInjection ? 'injection' : 'consumption']
  const currentInputLayout = inputLayout[isInjection ? 'injection' : 'consumption']

  return (
    <Card as="section" className={styles.group}>
      <header>
        <Heading as="h1" variant="h6">
          {isInjection ? t('editMeterReadings.form.injection') : t(type, { ns: 'common' })}
        </Heading>
        <Icon name={isInjection ? 'solarPanel' : type} />
      </header>

      {isGas ? (
        <MeterReadingInputField {...{ type, direction }} meterIndexType={MeterIndexType.TOTAL} />
      ) : (
        currentInputLayout &&
        Object.keys(currentInputLayout).map(
          (meterIndexType) =>
            currentInputLayout[meterIndexType as keyof typeof currentInputLayout] && (
              <MeterReadingInputField
                key={`${direction}-${meterIndexType}`}
                meterIndexType={meterIndexType as MeterIndexType}
                {...{ type, direction }}
              />
            )
        )
      )}

      {cardErrors && Object.keys(cardErrors).length !== 0 && (
        <Banner type="blocking">
          <ul>
            {Object.keys(getMultiFieldErrors(formState.errors)).map((errorType) => (
              <span key={errorType}>
                {allElOptional && errorType === 'required'
                  ? t(`editMeterReadings.form.validation.${isGas ? 'atleastOneRequiredAndGas' : 'atleastOneRequired'}`)
                  : t(
                      `editMeterReadings.form.validation.${errorType as keyof typeof resources.consumption.editMeterReadings.form.validation}`,
                      t('editMeterReadings.form.validation.fallback')
                    )}
              </span>
            ))}
          </ul>
        </Banner>
      )}
    </Card>
  )
}

export default MeterReadingCard
