import Cell from '../cell/Cell.tsx'
import { CellColor, CellVariant } from '../cell/types.ts'
import { AccountingInfoProps } from '../accounting-info/types.ts'

const AccountingInfo = ({ document }: AccountingInfoProps) => {
  // constants
  const matchingNumber = document.matchingNumber ?? 0

  if (matchingNumber < 0) return <Cell color={CellColor.NEGATIVE}>{matchingNumber}</Cell>
  if (matchingNumber > 0)
    return (
      <Cell color={CellColor.POSITIVE} variant={CellVariant.ACCOUNTING}>
        {matchingNumber}
      </Cell>
    )

  // matchingNumber === 0
  return null
}

export default AccountingInfo
