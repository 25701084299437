import { CardColors } from 'components/Card/types.ts'
import OverviewCard from 'pages/App/overview/overview-cards/OverviewCard.tsx'
import { useTranslation } from 'react-i18next'
import { Accordion, Drawer } from '@boltenergy-be/design-system'
import StepList from 'components/StepList/StepList.tsx'
import useWindowSize from 'hooks/useWindowSize.tsx'
import { useState } from 'react'
import { BetaDrawerListKeys, WelcomeDrawerListKeys } from 'pages/App/overview/overview-cards/welcome/types.ts'
import dayjs from 'dayjs'
import { useStoreSelector } from 'hooks/store.ts'
import { selectCurrentContracts } from 'store/contact/selectors.ts'

const WelcomeOverviewCard = () => {
  // Redux
  const { selected, billingContracts } = useStoreSelector((store) => store.contact)
  const contract = selectCurrentContracts({ selected, billingContracts })?.serviceContracts?.electricity

  // i18n
  const { t } = useTranslation('overview')

  // Window size
  const { isTablet } = useWindowSize()

  // Local state
  const [newPortalDrawerOpen, setNewPortalDrawerOpen] = useState<boolean>(false)

  // Constants
  const canShowNewPortalContent = dayjs().isBefore(dayjs('2025-01-01'))
  const hasBeenSubscribedForMoreThanOneMonth = dayjs().subtract(1, 'month').isBefore(dayjs(contract?.detail?.subscriptionDate))

  if (!canShowNewPortalContent && !hasBeenSubscribedForMoreThanOneMonth) {
    return null
  }

  return (
    <>
      <OverviewCard
        button={{
          label: t(`overview.${!hasBeenSubscribedForMoreThanOneMonth ? 'newPortal' : 'welcome'}.button`),
          onClick: () => setNewPortalDrawerOpen(true),
          id: !hasBeenSubscribedForMoreThanOneMonth ? 'beta-welcome' : 'welcome'
        }}
        color={CardColors.PINK}
      >
        <OverviewCard.Title>{t(`overview.${!hasBeenSubscribedForMoreThanOneMonth ? 'newPortal' : 'welcome'}.title`)}</OverviewCard.Title>
        <p>{t(`overview.${!hasBeenSubscribedForMoreThanOneMonth ? 'newPortal' : 'welcome'}.description`)}</p>
      </OverviewCard>

      <Drawer
        isOpen={newPortalDrawerOpen}
        title={t(`overview.${!hasBeenSubscribedForMoreThanOneMonth ? 'newPortal' : 'welcome'}.drawer.title`)}
        onRequestClose={() => setNewPortalDrawerOpen(false)}
      >
        <p>{t(`overview.${!hasBeenSubscribedForMoreThanOneMonth ? 'newPortal' : 'welcome'}.drawer.description`)}</p>

        {!hasBeenSubscribedForMoreThanOneMonth ? (
          <Accordion
            variant={isTablet ? 'transparent' : 'filled'}
            items={Array.from({ length: 3 }).map((_, index) => ({
              title: t(`overview.newPortal.drawer.faq.${(index + 1) as BetaDrawerListKeys}.question`),
              children: t(`overview.newPortal.drawer.faq.${(index + 1) as BetaDrawerListKeys}.answer`)
            }))}
          />
        ) : (
          <StepList>
            {Array.from({ length: 6 }).map((_, index) => (
              <li key={index}>
                <strong>{t(`overview.welcome.drawer.steps.${(index + 1) as WelcomeDrawerListKeys}.title`)}</strong>
                <p>{t(`overview.welcome.drawer.steps.${(index + 1) as WelcomeDrawerListKeys}.description`)}</p>
              </li>
            ))}
          </StepList>
        )}
      </Drawer>
    </>
  )
}

export default WelcomeOverviewCard
