import styles from 'features/contracts/done/components/flow-done-card/FlowDoneCard.module.scss'
import Card from 'components/Card/Card.tsx'
import { AddAddress, TerminateAddress } from 'assets/svg/contracts'
import { ContractsIconAccentColors } from 'assets/svg/contracts/types.ts'
import { PropsWithChildren } from 'react'
import { FlowCardProps } from 'features/contracts/done/components/flow-done-card/types.ts'
import classNames from 'classnames'

const FlowDoneCard = ({ type, title, children }: PropsWithChildren<FlowCardProps>) => {
  return (
    <Card padding={600} border={false} className={classNames(styles['flow-done-card'], { [styles.fullwidth]: !type })}>
      {!!type && (
        <>
          {type === 'started' ? (
            <AddAddress accent={ContractsIconAccentColors.PRIMARY} />
          ) : (
            <TerminateAddress accent={ContractsIconAccentColors.PRIMARY} />
          )}
        </>
      )}

      <Card.Title as="h3" variant="h5">
        {title}
      </Card.Title>

      {children}
    </Card>
  )
}

export default FlowDoneCard
