import { NavigationItemContentProps } from 'layouts/components/navigation/navigation-item-content/types.ts'
import NavigationItemContent from 'layouts/components/navigation/navigation-item-content/NavigationItemContent.tsx'
import { Link } from 'react-router-dom'
import styles from './CycleLink.module.scss'
import mixpanel from 'mixpanel-browser'
import { BillingEvents } from 'types/tracking.ts'

type CycleLinkProps = { href: string } & NavigationItemContentProps

const CycleLink = ({ href, ...content }: CycleLinkProps) => {
  return (
    <Link
      to={href}
      className={styles['cycle-link']}
      onClick={() => mixpanel.track(BillingEvents.OPEN_BILLING_CYCLE_DETAIL, { cycle: href.includes('current') ? 'current' : 'previous' })}
    >
      <NavigationItemContent isMore {...content} iconClass={styles.icon} />
    </Link>
  )
}

export default CycleLink
