import { Banner, Form, Heading, popErrorToast, popSuccessToast, TextField } from '@boltenergy-be/design-system'
import { useTranslation } from 'react-i18next'
import styles from './Details.module.scss'
import CollapsibleCard from 'components/CollapsibleCard/CollapsibleCard'
import { useState } from 'react'
import EditingButton from 'components/EditingButton/EditingButton'
import { useUpdateSalesContactMutation } from 'store/queries/bolt-api/contacts'
import { ContactDetailsProps } from './types'
import { FormProvider, useForm } from 'react-hook-form'
import classNames from 'classnames'
import { ContactStatus, Contact } from 'types/contacts.ts'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { Response } from 'types/request.ts'
import { ContactsResponseCodes } from 'types/errorCodes.ts'
import { maskWithAsterisk } from 'utils/format.ts'
import Phone from 'components/FormFields/Phone/Phone.tsx'

const ContactDetails = ({ contact, activeEditing, setActiveEditing, identifier }: ContactDetailsProps) => {
  // i18n
  const { t } = useTranslation(['sales', 'common', 'validation'])

  // React Hook Form
  const hookForm = useForm<Partial<Contact>>({
    defaultValues: contact
  })
  const {
    clearErrors,
    formState: { errors, dirtyFields },
    handleSubmit,
    register,
    setError,
    watch,
    reset
  } = hookForm
  const watchPhone = watch('phone')

  // Local constants
  const isEditing = activeEditing === identifier

  // Local state
  const [nameWarning, setNameWarning] = useState(false)

  // Mutation
  const [updateContact, { isLoading }] = useUpdateSalesContactMutation()

  /**
   * Updates the Contact object in the API
   *
   * @param {Partial<Contact>} data
   */
  const onSave = async (data: Partial<Contact>) => {
    // Clear errors
    clearErrors('email')

    // Set the name warning if the first or last name have been updated
    setNameWarning(!!dirtyFields.firstName || !!dirtyFields.lastName)

    try {
      // Update the contact
      const updatedContact = await updateContact({ contact: { ...data, sfId: contact.sfId } }).unwrap()

      // Check if the contact was updated
      if (updatedContact) {
        // Remove the editing state
        setActiveEditing(undefined)

        // Show success toast
        popSuccessToast(t('details.toast.success'))
      } else {
        popErrorToast(t('error', { ns: 'common' }))
      }
    } catch (error) {
      if (((error as FetchBaseQueryError).data as Response).message === ContactsResponseCodes.CONTACT_EMAIL_IN_USE) {
        setError('email', { type: 'emailInUse', message: t('common:emailInUse') })
      } else {
        popErrorToast(t('error', { ns: 'common' }))
      }
    }
  }

  return (
    <section>
      <menu className={classNames(styles.heading, styles.actions)}>
        <EditingButton
          isDisabled={contact.status === ContactStatus.CUSTOMER || !!activeEditing}
          setIsEditing={setActiveEditing}
          onSubmit={handleSubmit(onSave)}
          onCancel={reset}
          {...{ identifier, isEditing, isLoading }}
        />
      </menu>

      <CollapsibleCard
        titleSection={
          <Heading as="h4" className={styles.title}>
            {t('details.contactData.title')}
          </Heading>
        }
        contentClassName={styles['details-block']}
      >
        {nameWarning && (
          <Banner
            type="warning"
            className={classNames(styles['warning-banner'], styles['full-width'])}
            title={t('details.customer.warning.title')}
          >
            {t('details.customer.warning.description')}
          </Banner>
        )}

        <FormProvider {...hookForm}>
          <Form>
            {/* First Name */}
            <TextField
              readonly={!isEditing ? watch('firstName') : undefined}
              {...register('firstName', { required: t('validation:required') })}
              label={t('details.contactData.firstName')}
              error={errors.firstName?.message}
            />

            {/* Last Name */}
            <TextField
              readonly={!isEditing ? watch('lastName') : undefined}
              {...register('lastName', { required: t('validation:required') })}
              label={t('details.contactData.lastName')}
              error={errors.lastName?.message}
            />

            {/* Phone */}
            <Phone
              label={t('details.contactData.phone')}
              readonly={!isEditing ? maskWithAsterisk(watchPhone) : undefined}
              hookFormIdentifier="phone"
              {...{ hookForm }}
            />

            {/* Email */}
            <TextField
              readonly={!isEditing ? maskWithAsterisk(watch('email')) : undefined}
              type="email"
              {...register('email', { required: t('validation:required') })}
              label={t('details.contactData.email')}
              error={errors.email?.message}
            />
          </Form>
        </FormProvider>
      </CollapsibleCard>
    </section>
  )
}

export default ContactDetails
