import { useEffect, useState } from 'react'
import { UseWindowSizeReturnType } from './types'
import { checkIsMobile, checkIsTablet } from 'utils/app.ts'

/**
 * Custom hook that returns the current width, height & device checks
 * From website TODO: move to bolt/design-system
 *
 * @returns {UseWindowSizeReturnType}
 */
const useWindowSize = (customWidth?: number): UseWindowSizeReturnType => {
  // Local state
  const [size, setSize] = useState({
    height: 0,
    width: 0
  })

  /**
   * Triggered on every layout change (scroll, resize, ...)
   */
  useEffect(() => {
    const updateSize = () => {
      setSize({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }

    window.addEventListener('resize', updateSize)

    updateSize()

    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [])

  return {
    ...size,
    isMobile: checkIsMobile(size.width),
    isTablet: checkIsTablet(size.width),
    isSmaller: customWidth ? size.width < customWidth : false
  }
}

export default useWindowSize
