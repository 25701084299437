import { useEffect, useState } from 'react'
import { UserTypes } from 'store/auth/types'
import { useGetFeedbackWidgetBySlugQuery } from 'store/queries/bolt-api/feedback-widgets'
import { useStoreSelector } from './store'
import { UseOpenFeedbackWidget } from 'hooks/types.ts'

/**
 * Custom hook to trigger the feedback widget after x seconds
 *
 * @param {UseOpenFeedbackWidget} params
 */
const useOpenFeedbackWidget = ({ delay, slug, startTimer = true, startOpen, skip }: UseOpenFeedbackWidget) => {
  // Local state
  const [openFeedbackWidget, setOpenFeedbackWidget] = useState<boolean>(!skip && !!startOpen)

  // Redux
  const { userType } = useStoreSelector((store) => store.auth)

  // Redux query
  const { data: feedbackWidgetData } = useGetFeedbackWidgetBySlugQuery({ slug }, { skip })

  // Constants
  const isSales = userType === UserTypes.SALES
  const isSuperUser = userType === UserTypes.SUPER_USER

  // Open feedback widget after x seconds
  useEffect(() => {
    if (!skip && typeof delay === 'number' && startTimer && !isSales && !isSuperUser) {
      const triggerWidget = () => {
        setTimeout(() => {
          setOpenFeedbackWidget(true)
        }, delay * 1000)
      }

      triggerWidget()

      // Clean up
      return () => triggerWidget()
    }
    return
  }, [isSales, isSuperUser, delay, skip, startTimer])

  return { openFeedbackWidget, feedbackWidgetData }
}

export default useOpenFeedbackWidget
