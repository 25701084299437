import { Button } from '@boltenergy-be/design-system'
import { useStoreDispatch } from 'hooks/store'
import { Language } from 'store/app/types'
import { switchLanguage } from 'store/app/thunks'
import styles from './LanguageSwitchButton.module.scss'
import { useTranslation } from 'react-i18next'
import mixpanel from 'mixpanel-browser'
import { CommonTrackingEvents } from 'types/tracking.ts'

const LanguageSwitchButton = () => {
  // Redux store
  const dispatch = useStoreDispatch()

  // i18n
  const { i18n } = useTranslation()

  /**
   * Handles the switch language action
   */
  const handleSwitchLanguage = () => {
    mixpanel.track(CommonTrackingEvents.TOGGLE_LANGUAGE, { language: i18n.language.toUpperCase() })
    dispatch(
      switchLanguage({
        language: i18n.language.toUpperCase() === Language.NL ? Language.FR : Language.NL
      })
    )
  }
  return (
    <Button variant="tertiary" leadingIcon="globe" onClick={handleSwitchLanguage} className={styles['lang-btn']}>
      {i18n.language.toUpperCase()}
    </Button>
  )
}

export default LanguageSwitchButton
