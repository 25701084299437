import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'
import StackedInvoicesView from '../invoices-table/stacked-invoices-view/StackedInvoicesView.tsx'
import { Table } from 'components/Table/Table.tsx'
import styles from '../invoices-table/InvoiceTable.module.scss'
import useWindowSize from 'hooks/useWindowSize.tsx'
import { InvoiceCells, InvoiceTableDataRow } from 'pages/App/billing/invoices/table-data/types.ts'

const TableLoadingSkeleton = () => {
  // Window size
  const { isMobile } = useWindowSize()

  // Constants
  const placeholderRows: InvoiceTableDataRow[] = Array.from({ length: 4 }, (_, idx) => ({
    id: idx.toString(),
    uuid: crypto.randomUUID(),
    entries: {
      [InvoiceCells.STATUS]: { content: <LoadingSkeleton.Paragraph lines={1} />, header: '', data: null }
    }
  }))

  return (
    <LoadingSkeleton>
      {isMobile ? <StackedInvoicesView data={placeholderRows} loading /> : <Table data={placeholderRows} className={styles.table} />}
    </LoadingSkeleton>
  )
}

export default TableLoadingSkeleton
