import { useEffect, useState } from 'react'
import classNames from 'classnames'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import localeData from 'dayjs/plugin/localeData'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { registerLocale } from 'react-datepicker'
import FormButtons from 'features/contracts/components/form-buttons/FormButtons'
import { dateToString, getStartDateRange, isValidEAN } from 'utils/addContract'
import { Language } from 'store/app/types'
import {
  AddContractStepProps,
  AddContractSteps,
  CounterTypes,
  EanCaptureType,
  MeterDataFormInputs,
  MeterType,
  Situation,
  StartDateRange
} from '../../types.ts'
import { DATE_FORMAT_REACT_DATEPICKER } from 'constants/constants'
import contractsStyles from '../../../Contracts.module.scss'
import { nlBE } from 'date-fns/locale/nl-BE'
import { fr } from 'date-fns/locale/fr'
import { getEanData } from 'api/addContract'
import { UserTypes } from 'store/auth/types'
import {
  Banner,
  Button,
  Form,
  Heading,
  TextField,
  Toggle,
  CheckboxList,
  Checkbox,
  Select,
  DatePicker,
  RadioList,
  Radio,
  InlineMessage
} from '@boltenergy-be/design-system'
import { log } from 'utils/logging.ts'
import { Flow } from 'types/logging.ts'
import { Region } from 'api/types.ts'
import { checkHasMeterAndNumberInputs } from 'utils/contracts.ts'
import 'dayjs/locale/nl'
import 'dayjs/locale/fr'
import { ContractFlowTrackingTypes } from 'types/tracking.ts'
import DocumentUpload from 'features/contracts/components/document-upload/DocumentUpload.tsx'
import PreviousInhabitantForm from '../../components/PreviousInhabitantForm/PreviousInhabitantForm.tsx'
import { ContractStatus, EnergyType } from 'types/contracts.ts'
import { useStoreSelector } from 'hooks/store.ts'

// Day.js
dayjs.extend(localeData)
dayjs.extend(isBetween)

const MeterDetailsStep = ({ isMove, addContractData, setNextStep, eanLookupData }: AddContractStepProps) => {
  // REDUX STORE
  const { language } = useStoreSelector((store) => store.app)
  const { userType } = useStoreSelector((store) => store.auth)
  const { billingContracts } = useStoreSelector((store) => store.contact)

  // i18n
  const { t } = useTranslation('contracts')

  // Local state
  const [loading, setLoading] = useState<boolean>(false)
  const [showContractStartDatePicker, setShowContractStartDatePicker] = useState<boolean>(
    !!addContractData.meterDetails.customContractStartDate
  )
  const [switchDate, setSwitchDate] = useState<string>()
  const [startDateRange, setStartDateRange] = useState<StartDateRange>(getStartDateRange(isMove ? Situation.MOVE : Situation.SWITCH))
  const [gasEanAtElectricityEanField, setGasEanAtElectricityEanField] = useState<boolean>(false)
  const [electricityEanAtGasEanField, setElectricityEanAtGasEanField] = useState<boolean>(false)

  // Locale for React Datepicker
  registerLocale(language, language === Language.NL ? nlBE : fr)

  // Load the correct locale for Day.js
  useEffect(() => {
    dayjs.locale(language === Language.NL ? 'nl' : 'fr')
  }, [language])

  // React Hook Form
  const hookForm = useForm<MeterDataFormInputs>({
    mode: 'onBlur',
    defaultValues: {
      ...addContractData[AddContractSteps.METER_DETAILS],
      situation: isMove ? Situation.MOVE : Situation.SWITCH,
      docUpload: addContractData[AddContractSteps.METER_DETAILS].docUpload ?? null
    }
  })
  const {
    watch,
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = hookForm
  const watchContractStartDate = watch('contractStartDate')
  const watchCounterType = watch('counterType')
  const watchEanCaptureType = watch('eanCaptureType')
  const watchElectricityEan = watch('electricity.ean')
  const watchElectricityExclNightEan = watch('electricity.eanExclNight')
  const watchGasEan = watch('gas.ean')
  const watchHasExclNight = watch('hasExclNight')
  const watchHasExclNightEan = watch('hasExclNightEan')
  const watchHasSolarPanels = watch('hasSolarPanels')
  const watchMeterType = watch('meterType')
  const watchSituation = watch('situation')
  const watchDynamicTariff = watch('dynamicTariff')
  const watchWalloniaSolarPanelsSince2024 = watch('walloniaSolarPanelsSince2024')

  // Constants
  const { initial, min, max } = startDateRange
  const showMeterReadAndNumberInputs = checkHasMeterAndNumberInputs({
    situation: watchSituation,
    counterType: watchCounterType,
    contractStartDate: watchContractStartDate
  })
  const { needsGas, region } = addContractData[AddContractSteps.ADDRESS]
  const isSuperUser = userType === UserTypes.SUPER_USER
  const isMeterReadingMonthVisible =
    watchHasSolarPanels && watchSituation === Situation.SWITCH
      ? region === Region.WALLONIA
        ? true
        : watchCounterType === CounterTypes.ANALOG
      : false

  // Date constants
  const maxDate = switchDate && dayjs(switchDate).isAfter(max) ? switchDate : max
  const minDate = switchDate && dayjs(switchDate).isBefore(min) ? switchDate : min

  /**
   * Sets the sitation state to the one in the addContractData state to persist state when going back
   */
  useEffect(() => {
    if (addContractData[AddContractSteps.METER_DETAILS].situation === Situation.SWITCH && isMove) {
      setValue('situation', Situation.MOVE)
    } else {
      setValue('situation', addContractData[AddContractSteps.METER_DETAILS].situation)
    }
  }, [addContractData, isMove, setValue])

  /**
   * Reset contract start date to initial when situation is new connection or switch
   */
  useEffect(() => {
    if (watchSituation === Situation.NEW_CONNECTION || watchSituation === Situation.SWITCH) {
      setValue('contractStartDate', initial.toISOString())
    }
  }, [initial, setValue, watchSituation])

  /**
   * Checks if the given contract start date is between the min & max allowed dates
   *
   * @param {Date} contractStartDate
   * @returns boolean
   */
  const contractStartDateIsBetweenMinAndMax = (contractStartDate: string) => {
    const startDate = dayjs(minDate).subtract(1, 'day')
    const endDate = dayjs(maxDate).add(1, 'day')

    return dayjs(contractStartDate).isBetween(startDate, endDate, 'day')
  }

  /**
   * Checks if the given EAN is already used
   *
   * @param {string} ean
   * @param {EnergyType} energyType
   * @returns {boolean}
   */
  const eanIsAlreadyUsed = (ean: string, energyType: EnergyType): boolean => {
    return Object.values(billingContracts)
      .map((billingContract) => billingContract.serviceContracts[energyType === EnergyType.GAS ? 'gas' : 'electricity'])
      .some(
        (contract) =>
          !!contract &&
          [
            ContractStatus.ACTIVE,
            ContractStatus.EFFECTIVE,
            ContractStatus.ILC_ONGOING,
            ContractStatus.INACTIVE,
            ContractStatus.REJECTED
          ].includes(contract.detail.status) &&
          contract.deliveryPoint.ean === ean
      )
  }

  /**
   * Handles the situation change & sets the new contract start date
   */
  useEffect(() => {
    const newStartDateRange = getStartDateRange(watchSituation)
    setStartDateRange(newStartDateRange)
    setValue('contractStartDate', newStartDateRange.initial.toISOString())
  }, [setValue, watchSituation])

  /**
   * Handles the submit after validation by React Hook Form
   *
   * @param {MeterDataFormInputs} data
   */
  const onSubmit = async (data: MeterDataFormInputs) => {
    setLoading(true)
    setSwitchDate('')

    try {
      // Only check EAN data if the user knows the meter details
      const contractStartDate = dayjs(data.contractStartDate).format('YYYY-MM-DD')

      // Fetch the EAN data
      const [electricityEanData, gasEanData] = await Promise.all([
        getEanData(EnergyType.ELECTRICITY, data.electricity.ean.toString(), contractStartDate),
        ...(data.gas?.ean ? [getEanData(EnergyType.GAS, data.gas.ean.toString(), contractStartDate)] : [])
      ])

      if (electricityEanData) {
        // Three scenarios to be blocked
        // 1. Gas EAN filled in at electricity
        const gasEanAtElec = electricityEanData.energyType === EnergyType.GAS
        // 2. Electricity EAN filled in at gas
        const elecEanAtGas = gasEanData?.energyType === EnergyType.ELECTRICITY
        // 3. Invalid contract start date & no document uploaded
        const contractStartDateIsInvalid = electricityEanData.invalidContractStartDate && (!data.docUpload || data.docUpload?.length === 0)

        setGasEanAtElectricityEanField(gasEanAtElec)
        setElectricityEanAtGasEanField(elecEanAtGas)
        if (electricityEanData.switchDate && contractStartDateIsInvalid) {
          setSwitchDate(electricityEanData.switchDate)
          if (!isMove) {
            setValue('situation', Situation.MOVE)
          }
          // Scroll to top (where form is located)
          window.scrollTo(0, 0)
        }

        if (!gasEanAtElec && !elecEanAtGas && !contractStartDateIsInvalid) {
          saveDataAndGoToNextStep(data)
        }
      } else {
        saveDataAndGoToNextStep(data)
      }
    } catch (error) {
      log({ error: error as Error, identifier: `[${Flow.CONTRACT}:submitMeterDetails]` })
    } finally {
      setLoading(false)
    }
  }

  /**
   * Saves the data & goes to the next step
   *
   * @param {MeterDataFormInputs} data
   */
  const saveDataAndGoToNextStep = (data: MeterDataFormInputs) => {
    if (showContractStartDatePicker) {
      // Add custom key to data object to save "custom contract start date" state
      data.customContractStartDate = true
    } else {
      delete data.customContractStartDate
    }

    // Delete docUpload if situation is not move or if situation is move but no doc is selected
    if (data.situation !== Situation.MOVE || (data.situation === Situation.MOVE && data.docUpload?.length === 0)) {
      delete data.docUpload
    }

    setNextStep(data, AddContractSteps.CUSTOMER_DATA)
  }

  /**
   * Updates the Ean Capture Type input to "Overwritten by customer"
   */
  const updateEanCaptureTypeToOverwrittenIfNecessary = () => {
    if (watchEanCaptureType === EanCaptureType.PREFILLED) {
      setValue('eanCaptureType', EanCaptureType.OVERWRITTEN)
    }
  }

  /**
   * Handles the meter reading month select
   *
   * @param {number} selectedMonthIndex
   */
  const handleMeterReadingMonthSelectChange = (selectedMonthIndex: number): void => {
    if (!isNaN(selectedMonthIndex)) {
      // Define the start date
      let startDate = dayjs()
        .month(selectedMonthIndex - 1)
        .date(15)
        .hour(12)

      // Check if start date is in the future and add a year if not
      if (startDate.isBefore(dayjs())) startDate = startDate.add(1, 'year')

      // Show the contract start date picker
      setShowContractStartDatePicker(true)

      // Set the value in RHF values
      setValue('contractStartDate', startDate.toISOString())
    }
  }

  /**
   * Add EAN lookup data to form data
   */
  useEffect(() => {
    if (eanLookupData) {
      const { electricity, gas } = eanLookupData

      if (electricity || gas) {
        setValue('eanCaptureType', EanCaptureType.PREFILLED)

        if (electricity) setValue('electricity.ean', electricity)
        if (gas) setValue('gas.ean', gas)
      } else {
        setValue('eanCaptureType', EanCaptureType.NOT_FOUND)
      }
    }
  }, [eanLookupData])

  return (
    <section>
      <Heading as="h1" variant="h4" className="mb-300">
        {t('add.steps.meterDetails.title')}
      </Heading>

      <Form onSubmit={handleSubmit(onSubmit)} className={contractsStyles['narrow-grid']}>
        {/* MY SITUATION */}

        {isMove ? (
          <fieldset className="grid-col-full">
            <legend className={contractsStyles['fieldset-legend']}>{t('add.steps.meterDetails.form.situation.moveLabel')}</legend>
            <Toggle
              isFullwidth
              active={watchSituation}
              options={[
                { value: Situation.MOVE, label: t('yes', { ns: 'common' }) },
                { value: Situation.NEW_CONNECTION, label: t('no', { ns: 'common' }) }
              ]}
              onClick={(value) => setValue('situation', value)}
            />
          </fieldset>
        ) : (
          <RadioList label={t('add.steps.meterDetails.form.situation.addLabel')}>
            <Radio
              name="situation"
              label={t(`add.steps.meterDetails.form.situation.options.${Situation.SWITCH}`)}
              id={Situation.SWITCH}
              value={Situation.SWITCH}
              checked={watchSituation === Situation.SWITCH}
              onChange={(e) => setValue('situation', e.currentTarget.value as Situation)}
            />
            <Radio
              name="situation"
              label={t(`add.steps.meterDetails.form.situation.options.${Situation.MOVE}`)}
              id={Situation.MOVE}
              value={Situation.MOVE}
              checked={watchSituation === Situation.MOVE}
              onChange={(e) => setValue('situation', e.currentTarget.value as Situation)}
            />
            <Radio
              name="situation"
              label={t(`add.steps.meterDetails.form.situation.options.${Situation.NEW_CONNECTION}`)}
              id={Situation.NEW_CONNECTION}
              value={Situation.NEW_CONNECTION}
              checked={watchSituation === Situation.NEW_CONNECTION}
              onChange={(e) => setValue('situation', e.currentTarget.value as Situation)}
            />
          </RadioList>
        )}

        {/* METER READING MONTH */}
        {isMeterReadingMonthVisible && (
          <>
            <Select
              label={t('add.steps.meterDetails.form.meterReadingMonth.label')}
              id="meterReadingMonth"
              {...register('meterReadingMonth', { required: t('required', { ns: 'validation' }) })}
              onChange={(e) => handleMeterReadingMonthSelectChange(parseInt(e.currentTarget.value))}
              error={errors.meterReadingMonth?.message}
            >
              <option value="" disabled>
                {t('add.steps.meterDetails.form.meterReadingMonth.select')}
              </option>
              {dayjs.months().map((month, index) => (
                <option key={index} value={index + 1}>
                  {month}
                </option>
              ))}
            </Select>

            <p>{t('add.steps.meterDetails.form.meterReadingMonth.explanation')}</p>
          </>
        )}

        <fieldset>
          <legend className={contractsStyles['fieldset-legend']}>{t('add.steps.meterDetails.form.contractStartDate.label')}</legend>

          {/* CONTRACT START DATE */}
          {!showContractStartDatePicker || watchSituation === Situation.NEW_CONNECTION ? (
            <p className={contractsStyles.description}>
              {watchSituation === Situation.NEW_CONNECTION ? (
                t('add.steps.meterDetails.form.contractStartDate.values.newConnection')
              ) : (
                <>
                  {dateToString(initial)}
                  <Button
                    type="button"
                    representation="link"
                    className={contractsStyles.link}
                    onClick={() => setShowContractStartDatePicker(true)}
                  >
                    {t('edit', { ns: 'common' })}
                  </Button>
                </>
              )}
            </p>
          ) : (
            <div>
              <Controller
                name="contractStartDate"
                control={control}
                rules={{
                  required: true,
                  validate: (value) => (value ? contractStartDateIsBetweenMinAndMax(value) : false)
                }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <DatePicker
                      error={errors.contractStartDate?.message}
                      placeholderText={t('add.steps.meterDetails.form.contractStartDate.placeholder')}
                      language={language.toLowerCase() as 'nl' | 'fr'}
                      selected={dayjs(value ?? initial).toDate()}
                      disabledKeyboardNavigation
                      maxDate={new Date(maxDate)}
                      minDate={new Date(minDate)}
                      dateFormat={DATE_FORMAT_REACT_DATEPICKER}
                      prefix={{ as: 'calendar' }}
                      onChange={(date) => onChange(dayjs(date as string).format('YYYY-MM-DD'))}
                    />
                  )
                }}
              />
              <Button
                type="button"
                representation="link"
                className={classNames(contractsStyles.link, contractsStyles['no-margin'], 'mt-200')}
                onClick={() => {
                  setValue('contractStartDate', initial.toISOString()) // Reset contract start date back to initial
                  setShowContractStartDatePicker(false)
                }}
              >
                {t('add.steps.meterDetails.form.contractStartDate.standard')}
              </Button>
            </div>
          )}

          {watchSituation === Situation.MOVE && showContractStartDatePicker && switchDate !== watchContractStartDate && (
            <Banner type="informative" className={contractsStyles['info-block-container']}>
              {t('add.steps.meterDetails.form.contractStartDate.attentionMove')}
            </Banner>
          )}

          {switchDate && switchDate !== watchContractStartDate && userType === UserTypes.SALES && watchSituation === Situation.MOVE ? (
            <Banner className={contractsStyles['overlap-warning']} type="warning">
              <span className={contractsStyles['warning-content']}>
                {t('add.steps.meterDetails.form.previousInhabitant.warning', { date: dayjs(switchDate).format('DD/MM/YYYY') })}
              </span>
            </Banner>
          ) : (
            switchDate &&
            switchDate !== watchContractStartDate && (
              <div className={contractsStyles['info-block-container']}>
                <span className={classNames('help-block text-negative')}>
                  {t('add.steps.meterDetails.form.contractStartDate.wrongSwitchDate', {
                    date: dayjs(switchDate).format('DD/MM/YYYY')
                  })}
                </span>
              </div>
            )
          )}
        </fieldset>

        {/* PREVIOUS INHABITANT */}
        {switchDate && switchDate !== watchContractStartDate && watchSituation === Situation.MOVE && userType === UserTypes.SALES && (
          <FormProvider {...hookForm}>
            <PreviousInhabitantForm />
          </FormProvider>
        )}

        {/* DOC UPLOAD */}
        {watchSituation === Situation.MOVE && (
          <FormProvider {...hookForm}>
            <DocumentUpload />
          </FormProvider>
        )}

        {/* HAS SOLAR PANELS */}
        <fieldset>
          <legend className={contractsStyles['fieldset-legend']}>{t('add.steps.meterDetails.form.hasSolarPanels')}</legend>

          <Toggle
            isFullwidth
            active={watchHasSolarPanels}
            options={[
              { value: true, label: t('yes', { ns: 'common' }) },
              { value: false, label: t('no', { ns: 'common' }) }
            ]}
            onClick={(value) => setValue('hasSolarPanels', value)}
          />
        </fieldset>

        {/* COUNTER TYPE */}
        <fieldset>
          <legend className={contractsStyles['fieldset-legend']}>{t('add.steps.meterDetails.form.counterType')}</legend>
          <Toggle
            isFullwidth
            active={watchCounterType}
            options={[
              { value: CounterTypes.DIGITAL, label: t('yes', { ns: 'common' }) },
              { value: CounterTypes.ANALOG, label: t('no', { ns: 'common' }) }
            ]}
            onClick={(value) => {
              setValue('counterType', value)
              if (value === CounterTypes.ANALOG) {
                setValue('dynamicTariff', false)
                if (addContractData['address'].region === Region.WALLONIA) {
                  setValue('walloniaSolarPanelsSince2024', false)
                }
              }
            }}
          />
        </fieldset>

        {/* DYNAMIC TARIFF */}
        {watchCounterType === CounterTypes.DIGITAL && addContractData['address'].region === Region.FLANDERS && !watchHasExclNight && (
          <fieldset>
            <legend className={contractsStyles['fieldset-legend']}>
              {t('add.steps.meterDetails.form.dynamicTariff.label', 'Wil je graag dynamische prijzen?')}
            </legend>

            <Toggle
              isFullwidth
              className="mb-400"
              active={watchDynamicTariff}
              options={[
                { label: t('common:yes', 'Ja'), value: true },
                { label: t('common:no', 'Nee'), value: false }
              ]}
              onClick={(value) => setValue('dynamicTariff', value)}
            />
            <p>
              {t(
                'add.steps.meterDetails.form.dynamicTariff.description',
                'Met de optie dynamische prijzen zal je elektriciteitsverbruik volgens de marktprijs per uur afgerekend worden. Door je energieverbruik goed te plannen, kan je besparen. Je kan dit op elk moment stopzetten.'
              )}{' '}
              <a
                href={
                  language === Language.NL ? 'https://boltenergie.be/dynamische-prijzen' : 'https://www.boltenergie.be/fr/prix-dynamiques'
                }
                target="_blank"
                rel="noreferrer noopener"
              >
                {t('readMore', { ns: 'common' })}.
              </a>
            </p>
          </fieldset>
        )}

        <hr className={classNames(contractsStyles.divider, 'grid-col-full')} />

        <Heading as="h3" variant="h4" className="grid-col-full">
          {t('add.steps.meterDetails.form.electricity.title')}
        </Heading>

        <CheckboxList label="">
          {/* ELECTRICITY METER TYPE */}
          <Checkbox
            name="meterType"
            label={t('add.steps.meterDetails.form.electricity.meterType')}
            id="meterType"
            checked={watchMeterType === MeterType.DOUBLE_RATE}
            onChange={(e) => setValue('meterType', e.currentTarget.checked ? MeterType.DOUBLE_RATE : MeterType.SINGLE_RATE)}
          />

          {/* HAS EXCLUSIVE NIGHT METER (LOCAL STATE) */}
          <Checkbox
            name="hasExclNight"
            label={t('add.steps.meterDetails.form.electricity.hasExclNight')}
            id="hasExclNight"
            checked={watchHasExclNight}
            onChange={(e) => setValue('hasExclNight', e.currentTarget.checked)}
          />

          {/* WALLONIA SOLAR PANELS INSTALLED SINCE 2024*/}
          {addContractData['address'].region === Region.WALLONIA && watchCounterType === CounterTypes.DIGITAL && watchHasSolarPanels && (
            <Checkbox
              name="walloniaSolarPanelsSince2024"
              label={t('add.steps.meterDetails.form.electricity.walloniaSolarPanelsSince2024')}
              id="walloniaSolarPanelsSince2024"
              checked={watchWalloniaSolarPanelsSince2024}
              onChange={(e) => setValue('walloniaSolarPanelsSince2024', e.currentTarget.checked)}
            />
          )}
        </CheckboxList>

        {/* ELECTRICITY EAN */}
        <TextField
          id="electricityEan"
          label={t('add.steps.meterDetails.form.electricity.EAN')}
          placeholder="54144..."
          inputMode="numeric"
          {...register('electricity.ean', {
            required: t('required', { ns: 'validation' }),
            validate: {
              valid: (value) => isValidEAN(value as string) || t('invalid.EAN', { ns: 'validation' }),
              ...(!isSuperUser && {
                notAlreadyUsed: (value) =>
                  !eanIsAlreadyUsed(value as string, EnergyType.ELECTRICITY) || t('invalid.EANAlreadyUsed', { ns: 'validation' })
              })
            }
          })}
          error={errors?.electricity?.ean?.message}
          onChange={() => updateEanCaptureTypeToOverwrittenIfNecessary()}
        />
        {watchEanCaptureType === EanCaptureType.PREFILLED && !!watchElectricityEan && (
          <InlineMessage type="positive" className="grid-col-full">
            {t('add.steps.meterDetails.form.eanPrefilled')}
          </InlineMessage>
        )}
        {gasEanAtElectricityEanField && (
          <InlineMessage type="negative" className="grid-col-full">
            {t('invalid.gasEanAtElectricityEanField', { ns: 'validation' })}
          </InlineMessage>
        )}
        {isSuperUser && eanIsAlreadyUsed(watchElectricityEan as string, EnergyType.ELECTRICITY) && (
          <InlineMessage type="warning" className="grid-col-full">
            {t('invalid.EANAlreadyUsed', { ns: 'validation' })}
          </InlineMessage>
        )}

        {showMeterReadAndNumberInputs && (
          <>
            {watchMeterType === MeterType.SINGLE_RATE ? (
              <>
                {/* ELECTRICITY METER READING TOTAL (SINGLE RATE) */}
                <TextField
                  id="electricityTotalSingle"
                  label={t('add.steps.meterDetails.form.electricity.total')}
                  placeholder="123456"
                  inputMode="numeric"
                  {...register('electricity.total', {
                    required: t('required', { ns: 'validation' }),
                    validate: {
                      isNotEan: (value) => (!!value && !isValidEAN(value.toString(10))) || t('invalid.looksLikeEan', { ns: 'validation' }),
                      valid: (value) => (!!value && value > 0) || t('invalid.meterReading', { ns: 'validation' })
                    }
                  })}
                  suffix={{ as: ',000', className: contractsStyles.bordered }}
                  error={errors?.electricity?.total?.message}
                />
              </>
            ) : (
              <>
                {/* ELECTRICITY METER READING DAY (DOUBLE RATE) */}
                <TextField
                  id="electricityTotalDoubleDay"
                  label={t('add.steps.meterDetails.form.electricity.day')}
                  inputMode="numeric"
                  {...register('electricity.day', {
                    required: t('required', { ns: 'validation' }),
                    validate: {
                      isNotEan: (value) => (!!value && !isValidEAN(value.toString(10))) || t('invalid.looksLikeEan', { ns: 'validation' }),
                      valid: (value) => (!!value && value > 0) || t('invalid.meterReading', { ns: 'validation' })
                    }
                  })}
                  suffix={{ as: ',000', className: contractsStyles.bordered }}
                  error={errors?.electricity?.day?.message}
                />

                {/* ELECTRICITY METER READING NIGHT (DOUBLE RATE) */}
                <TextField
                  id="electricityTotalDoubleNight"
                  label={t('add.steps.meterDetails.form.electricity.night')}
                  inputMode="numeric"
                  {...register('electricity.night', {
                    required: t('required', { ns: 'validation' }),
                    validate: {
                      isNotEan: (value) => (!!value && !isValidEAN(value.toString(10))) || t('invalid.looksLikeEan', { ns: 'validation' }),
                      valid: (value) => (!!value && value > 0) || t('invalid.meterReading', { ns: 'validation' })
                    }
                  })}
                  suffix={{ as: ',000', className: contractsStyles.bordered }}
                  error={errors?.electricity?.night?.message}
                />
              </>
            )}

            {/* ELECTRICITY METER NUMBER */}
            <TextField
              id="electricityMeterNumber"
              label={t('add.steps.meterDetails.form.electricity.meterNumber')}
              inputMode="numeric"
              {...register('electricity.meterNumber', {
                required: t('required', { ns: 'validation' }),
                validate: {
                  valid: (value) => (!!value && !isValidEAN(value.toString(10))) || t('invalid.looksLikeEan', { ns: 'validation' })
                }
              })}
              error={errors?.electricity?.meterNumber?.message}
            />

            {watchHasExclNight && (
              <>
                <Checkbox
                  name="hasExclNightEan"
                  label={t('add.steps.meterDetails.form.electricity.hasExclNightEanNumber')}
                  id="hasExclNightEan"
                  checked={watchHasExclNightEan}
                  onChange={(e) => setValue('hasExclNightEan', e.target.checked)}
                />
                {/* ELECTRICITY EXCL. NIGHT EAN NUMBER */}
                {watchHasExclNightEan && (
                  <>
                    <TextField
                      id="electricityEanExclNight"
                      label={t('add.steps.meterDetails.form.electricity.exclEAN')}
                      placeholder="54144..."
                      {...register('electricity.eanExclNight', {
                        required: t('required', { ns: 'validation' }),
                        validate: {
                          valid: (value) => isValidEAN(value as string) || t('invalid.EAN', { ns: 'validation' }),
                          ...(!isSuperUser && {
                            notAlreadyUsed: (value) =>
                              !eanIsAlreadyUsed(value as string, EnergyType.ELECTRICITY) ||
                              t('invalid.EANAlreadyUsed', { ns: 'validation' })
                          })
                        }
                      })}
                      error={errors?.electricity?.eanExclNight?.message}
                    />
                    {isSuperUser && eanIsAlreadyUsed(watchElectricityExclNightEan as string, EnergyType.ELECTRICITY) && (
                      <InlineMessage type="warning">{t('invalid.EANAlreadyUsed', { ns: 'validation' })}</InlineMessage>
                    )}
                  </>
                )}

                {/* ELECTRICITY METER EXCL. NIGHT */}
                <TextField
                  id="electricityTotalExclNight"
                  label={t('add.steps.meterDetails.form.electricity.exclNight')}
                  inputMode="numeric"
                  {...register('electricity.exclNight', {
                    required: t('required', { ns: 'validation' }),
                    validate: {
                      isNotEan: (value) => (!!value && !isValidEAN(value.toString(10))) || t('invalid.looksLikeEan', { ns: 'validation' }),
                      valid: (value) => (!!value && value > 0) || t('invalid.meterReading', { ns: 'validation' })
                    }
                  })}
                  suffix={{ as: ',000', className: contractsStyles.bordered }}
                  error={errors?.electricity?.exclNight?.message}
                />
              </>
            )}
          </>
        )}

        {needsGas && (
          <>
            <hr className={classNames(contractsStyles.divider, 'grid-col-full')} />

            <Heading as="h3" variant="h4" className="grid-col-full">
              {t('add.steps.meterDetails.form.gas.title')}
            </Heading>

            {/* GAS EAN */}
            <TextField
              id="gas.ean"
              label={t('add.steps.meterDetails.form.gas.EAN')}
              placeholder="54144..."
              {...register('gas.ean', {
                required: t('required', { ns: 'validation' }),
                validate: {
                  valid: (value) => isValidEAN(value as string) || t('invalid.EAN', { ns: 'validation' }),
                  ...(!isSuperUser && {
                    notAlreadyUsed: (value) =>
                      !eanIsAlreadyUsed(value as string, EnergyType.GAS) || t('invalid.EANAlreadyUsed', { ns: 'validation' })
                  })
                }
              })}
              error={errors?.gas?.ean?.message}
              onChange={() => {
                setElectricityEanAtGasEanField(false)
                updateEanCaptureTypeToOverwrittenIfNecessary()
              }}
            />
            {watchEanCaptureType === EanCaptureType.PREFILLED && !!watchGasEan && (
              <InlineMessage type="positive" className="grid-col-full">
                {t('add.steps.meterDetails.form.eanPrefilled')}
              </InlineMessage>
            )}
            {electricityEanAtGasEanField && (
              <InlineMessage type="negative" className="grid-col-full">
                {t('invalid.electricityEanAtGasEanField', { ns: 'validation' })}
              </InlineMessage>
            )}
            {isSuperUser && eanIsAlreadyUsed(watchGasEan as string, EnergyType.GAS) && (
              <InlineMessage type="warning" className="grid-col-full">
                {t('invalid.EANAlreadyUsed', { ns: 'validation' })}
              </InlineMessage>
            )}

            {showMeterReadAndNumberInputs && (
              <>
                {/* GAS METER READING TOTAL */}
                <TextField
                  id="gasTotal"
                  label={t('add.steps.meterDetails.form.gas.total')}
                  inputMode="numeric"
                  {...register('gas.total', {
                    required: t('required', { ns: 'validation' }),
                    validate: {
                      isNotEan: (value) => (!!value && !isValidEAN(value.toString(10))) || t('invalid.looksLikeEan', { ns: 'validation' }),
                      valid: (value) => (!!value && value > 0) || t('invalid.meterReading', { ns: 'validation' })
                    }
                  })}
                  suffix={{ as: ',000', className: contractsStyles.bordered }}
                  error={errors?.gas?.total?.message}
                />

                {/* GAS METER NUMBER */}
                <TextField
                  id="gasMeterNumber"
                  label={t('add.steps.meterDetails.form.gas.meterNumber')}
                  inputMode="numeric"
                  {...register('gas.meterNumber', {
                    required: t('required', { ns: 'validation' }),
                    validate: {
                      valid: (value) => (!!value && !isValidEAN(value.toString(10))) || t('invalid.looksLikeEan', { ns: 'validation' })
                    }
                  })}
                  error={errors?.gas?.meterNumber?.message}
                />
              </>
            )}
          </>
        )}

        <FormButtons trackingId={ContractFlowTrackingTypes.ADD} currentStep={AddContractSteps.METER_DETAILS} loading={loading} />
      </Form>
    </section>
  )
}

export default MeterDetailsStep
