// Steps
import AddressStep from './steps/Address/AddressStep.tsx'
import ProducerStep from './steps/Producer/ProducerStep.tsx'
import InstalmentStep from './steps/Instalment/InstalmentStep.tsx'
import MeterDetailsStep from './steps/MeterDetails/MeterDetailsStep.tsx'
import CustomerDataStep from './steps/CustomerData/CustomerDataStep.tsx'
import OverviewStep from './steps/Overview/OverviewStep.tsx'
import Done from 'features/contracts/done/Done.tsx'
import ReferralStep from 'features/contracts/referral/ReferralStep.tsx'

// Other imports
import { ReactElement, useEffect, useState } from 'react'
import { useBeforeunload } from 'react-beforeunload'
import { eanLookup } from 'api/addContract'
import { ADD_CONTRACT_FLOW, GET_INITIAL_ADD_CONTRACT_DATA, MOVE_CONTRACT_FLOW } from './constants.ts'
import styles from '../Contracts.module.scss'
import { AddContractData, AddContractSteps, CurrentStepData } from './types.ts'
import { EanLookupResponseBody } from 'api/types'
import { UserTypes } from 'store/auth/types'
import { routes } from 'types/routes'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import ProductStep from './steps/Product/ProductStep'
import InfoCard from './components/InfoCard/InfoCard'
import { useLocation } from 'react-router'
import ContractsLayout from '../layout/ContractsLayout.tsx'
import { ContractFlows } from '../layout/types.ts'
import { useTranslation } from 'react-i18next'
import { AddressWithCountryAndTownCode } from 'types/general.ts'
import { getLowerCaseLanguage } from 'utils/app.ts'
import { producersBoltApi } from 'store/queries/bolt-api/producers'
import ConditionalCard from 'components/ConditionalCard/ConditionalCard.tsx'
import useWindowSize from 'hooks/useWindowSize.tsx'
import classnames from 'classnames'

const Add = () => {
  // REDUX STORE
  const { userType } = useStoreSelector((store) => store.auth)
  const { language } = useStoreSelector((store) => store.app)
  const { accounts, selected, contact } = useStoreSelector((store) => store.contact)
  const dispatch = useStoreDispatch()

  // i18n
  const { t } = useTranslation('contracts')

  // Window size
  const { isTablet } = useWindowSize()

  // Local state
  const [currentStep, setCurrentStep] = useState<AddContractSteps>(AddContractSteps.ADDRESS)
  const [addContractData, setAddContractData] = useState<AddContractData>(
    GET_INITIAL_ADD_CONTRACT_DATA(language, accounts[selected.account], contact)
  )
  const [eanLookupData, setEanLookupData] = useState<EanLookupResponseBody>()

  // React Router
  const { pathname } = useLocation()

  // Constants
  const isMove = pathname === routes.CONTRACTS_MOVE
  const isSales = userType === UserTypes.SALES
  const steps = isMove ? MOVE_CONTRACT_FLOW : ADD_CONTRACT_FLOW

  // Shows dialog when user tries to reload the page without confirming
  useBeforeunload((e: Event) => {
    if (currentStep !== AddContractSteps.DONE) e.preventDefault()
  })

  /**
   * Fetches producers on page load
   */
  useEffect(() => {
    // Initiate query
    const { unsubscribe } = dispatch(producersBoltApi.endpoints.getProducers.initiate())

    // Clean up function
    return unsubscribe
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Scroll to top of page every time currentStep changes
   */
  useEffect(() => {
    window.scrollTo({ top: 0 })

    return () => {
      window.scrollTo({ top: 0 })
    }
  }, [currentStep])

  /**
   * Saves the current step data & goes to the next (given) step
   *
   * @param {CurrentStepData} currentStepData
   * @param {AddContractSteps} nextStep
   */
  const setNextStep = (currentStepData: CurrentStepData, nextStep: AddContractSteps) => {
    setAddContractData({
      ...addContractData,
      [currentStep]: currentStepData
    })
    setCurrentStep(nextStep)
  }

  /**
   * Handles the EAN lookup for the given address
   */
  const handleEanLookup = async (deliveryAddress: AddressWithCountryAndTownCode) => {
    const eanLookupData = await eanLookup(getLowerCaseLanguage(language), deliveryAddress)

    setEanLookupData(eanLookupData)
  }

  /**
   * Returns the right component for the current step
   */
  const getCurrentStepComponent = (): ReactElement | null => {
    switch (currentStep) {
      //  STEP 1 - Address
      case AddContractSteps.ADDRESS:
        return <AddressStep {...{ isMove, isSales, addContractData, setAddContractData, setCurrentStep, setNextStep, handleEanLookup }} />

      // EXTRA MOVE STEP - Referral
      case AddContractSteps.REFERRAL:
        return (
          <ReferralStep
            defaultValues={addContractData[AddContractSteps.REFERRAL]}
            setCurrentStep={(step) => setCurrentStep((step as AddContractSteps) || AddContractSteps.PRODUCER)}
            setNextStep={(data) => setNextStep(data, AddContractSteps.PRODUCER)}
          />
        )

      // STEP 2 - Producer
      case AddContractSteps.PRODUCER:
        return <ProducerStep {...{ isMove, addContractData, setAddContractData, setCurrentStep, setNextStep }} />

      // STEP 3 - Product
      case AddContractSteps.PRODUCT:
        return <ProductStep {...{ isMove, addContractData, setAddContractData, setCurrentStep, setNextStep }} />

      // STEP 4 - Instalment
      case AddContractSteps.INSTALMENT_DATA:
        return <InstalmentStep {...{ isMove, isSales, addContractData, setAddContractData, setCurrentStep, setNextStep }} />

      // STEP 5 - Meter details
      case AddContractSteps.METER_DETAILS:
        return <MeterDetailsStep {...{ isMove, addContractData, setAddContractData, setCurrentStep, setNextStep, eanLookupData }} />

      // STEP 6 - Customer data
      case AddContractSteps.CUSTOMER_DATA:
        return <CustomerDataStep {...{ isMove, isSales, addContractData, setAddContractData, setCurrentStep, setNextStep }} />

      // STEP 7 - Confirmation
      case AddContractSteps.CONFIRMATION:
        return <OverviewStep {...{ isMove, isSales, addContractData, setAddContractData, setCurrentStep, setNextStep }} />

      default:
        return null
    }
  }

  return (
    <ContractsLayout flow={ContractFlows.ADD} label={t('header.labels.add')} {...{ steps, currentStep, setCurrentStep }}>
      {currentStep === AddContractSteps.DONE ? (
        <Done producer={addContractData.producer} />
      ) : (
        <ConditionalCard
          asCard={!isTablet}
          className={classnames(styles['content-section'], {
            [styles.stretch]: currentStep === AddContractSteps.PRODUCER
          })}
        >
          {getCurrentStepComponent()}
        </ConditionalCard>
      )}

      {![AddContractSteps.ADDRESS, AddContractSteps.REFERRAL, AddContractSteps.PRODUCER, AddContractSteps.DONE].includes(currentStep) && (
        <InfoCard contractData={addContractData} {...{ currentStep }} />
      )}
    </ContractsLayout>
  )
}

export default Add
