import { useMemo } from 'react'
import styles from 'components/Charts/Charts.module.scss'
import { Chart } from 'react-chartjs-2'
import { ConsumptionChartProps } from './types'
import { getConsumptionChartOptions } from './chartjs.config'
import { useStoreSelector } from 'hooks/store.ts'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'
import classNames from 'classnames'

const ConsumptionChartDesktop = ({
  activeEnergyType,
  chartData,
  isFetchingVolumes,
  labels,
  minMaxData,
  priceHistoryModifier,
  granularity,
  setHoverDataPointIndex,
  compact
}: ConsumptionChartProps) => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)

  const options = useMemo<any>(
    () =>
      getConsumptionChartOptions({
        labels,
        handleHoverDataPoint: setHoverDataPointIndex,
        historyModifier: priceHistoryModifier,
        granularity,
        language,
        minMax: minMaxData,
        fuelType: activeEnergyType,
        isMobile: compact
      }),
    [labels, setHoverDataPointIndex, priceHistoryModifier, granularity, language, minMaxData, activeEnergyType, compact]
  )

  return !isFetchingVolumes ? (
    <div className={classNames(styles['chart-wrapper'], { [styles.compact]: !!compact })}>
      <Chart type="bar" options={options} data={chartData} className={styles.chart} />
    </div>
  ) : (
    <LoadingSkeleton>
      <LoadingSkeleton.Rectangle height="100%" />
    </LoadingSkeleton>
  )
}

export default ConsumptionChartDesktop
