import { SubnavProps } from './types'
import classNames from 'classnames'
import { Link, useLocation } from 'react-router-dom'
import styles from './Subnav.module.scss'
import parse from 'html-react-parser'
import { useEffect, useRef } from 'react'
import mixpanel from 'mixpanel-browser'
import { CommonTrackingEvents } from 'types/tracking.ts'

const Subnav = ({ tabs, className }: SubnavProps) => {
  // Router
  const location = useLocation()

  // Refs
  const navRef = useRef<HTMLElement>(null)
  const linkRefs = useRef<(HTMLAnchorElement | null)[]>([])

  useEffect(() => {
    linkRefs.current.forEach((link) => {
      if (navRef?.current && link && location.pathname.includes(link.pathname)) {
        navRef.current.scrollLeft = link.offsetLeft
      }
    })
  }, [location.pathname])

  return (
    <>
      <nav ref={navRef} className={classNames(styles.subnav, className)}>
        {tabs.map((tab, index) => (
          <Link
            ref={(el) => (linkRefs.current[index] = el)}
            key={`subnav-${index}`}
            className={classNames(styles['subnav-item'], {
              [styles.active]: location.pathname === tab.path
            })}
            to={tab.path}
            onClick={() => {
              mixpanel.track(CommonTrackingEvents.SWITCH_SUBNAV_TAB, { tab: tab.path })
            }}
          >
            {typeof tab.label === 'string' ? parse(tab.label) : tab.label}
          </Link>
        ))}
      </nav>
    </>
  )
}

export default Subnav
