import { PropsWithChildren } from 'react'
import { Helmet } from 'react-helmet-async'
import { SeoTagsProps } from './types'

const SeoTags = ({ title, children }: PropsWithChildren<SeoTagsProps>) => {
  return (
    <Helmet>
      <title>{title} | My Bolt</title>
      {children}
    </Helmet>
  )
}

export default SeoTags
