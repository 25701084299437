import { useMemo, useRef } from 'react'
import styles from 'components/Charts/Charts.module.scss'
import { Bar } from 'react-chartjs-2'
import { getBillshockChartOptions } from 'components/Charts/billing-cycle/current/chartjs.config'
import { BillingCycleChartProps } from '../types'
import { useStoreSelector } from 'hooks/store.ts'

const CurrentBillingCycleChartDesktop = ({ chartData, minMaxData, labels, setHoverDataPointIndex }: BillingCycleChartProps) => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)

  // Reference
  const chartRef = useRef<never>(null)

  // Options
  const options = useMemo<any>(
    () => getBillshockChartOptions({ labels, language, minMax: minMaxData, isMobile: false, handleHoverDataPoint: setHoverDataPointIndex }),
    [labels, language, minMaxData, setHoverDataPointIndex]
  )

  return (
    <div className={styles['chart-wrapper']}>
      <Bar options={options} data={chartData} className={styles.chart} ref={chartRef} />
    </div>
  )
}

export default CurrentBillingCycleChartDesktop
