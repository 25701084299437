import { Suspense, useState, useEffect } from 'react'
import GoogleMapReact from 'google-map-react'
import styles from './mapStyles.json'
import { GMAP_KEY } from 'constants/envs'
import { ProducerMapProps } from './types'
import Marker from './Marker/Marker'
import { energyToIconName } from 'utils/mapping.ts'
import { getAverage } from 'utils/number.ts'

const ProducerMap = ({ producerLocation, userLocation, producerEnergyType, producerLocations }: ProducerMapProps) => {
  // Local state
  const [map, setMap] = useState(null)
  const [maps, setMaps] = useState(null)
  const [line, setLine] = useState<any>(null)

  // Constants
  const isProducerProfile = !!producerLocations
  const centerCoord = {
    lat: getAverage(isProducerProfile ? producerLocations?.map((loc) => loc.lat) : [producerLocation.lat, userLocation.lat]),
    lng: getAverage(isProducerProfile ? producerLocations?.map((loc) => loc.lng) : [producerLocation.lng, userLocation.lng])
  }

  /**
   * Triggered everytime the map, maps, producerLocation or userLocation changes
   */
  useEffect(() => {
    /**
     * Draws line from the producer location to the user location
     *
     * @param map
     * @param maps
     */
    const drawLine = (map: any, maps: any) => {
      if (line) line.setMap(null)

      const lineCoordinates = [producerLocation, userLocation]
      const polyLine = new maps.Polyline({
        path: lineCoordinates,
        strokeColor: 'hsl(167, 72%, 49%)',
        strokeOpacity: 1.0,
        strokeWeight: 3
      })

      setLine(polyLine)
      polyLine.setMap(map)
    }

    if (map && maps && !!userLocation && !!producerLocation) {
      drawLine(map, maps)
    }
  }, [line, map, maps, producerLocation, userLocation])

  return (
    <Suspense fallback={<div className="card" />}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: GMAP_KEY || '' }}
        center={centerCoord}
        defaultZoom={8}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          setMap(map)
          setMaps(maps)
        }}
        options={{
          styles,
          scrollwheel: false,
          disableDoubleClickZoom: true,
          draggable: !isProducerProfile, // disable due to bug with drag/zoom on producer markers
          zoomControl: !isProducerProfile, // disable due to bug with drag/zoom on producer markers
          minZoom: 4,
          maxZoom: 18,
          fullscreenControl: false,
          gestureHandling: 'cooperative'
        }}
      >
        {isProducerProfile ? (
          producerLocations?.map((loc, idx) => (
            <Marker key={idx} icon={loc.energyType ? energyToIconName(loc.energyType) : 'electricity'} lat={loc.lat} lng={loc.lng} />
          ))
        ) : (
          <>
            <Marker
              icon={producerEnergyType ? energyToIconName(producerEnergyType) : 'electricity'}
              lat={producerLocation.lat}
              lng={producerLocation.lng}
            />
            {userLocation && <Marker icon="home" lat={userLocation.lat} lng={userLocation.lng} />}
          </>
        )}
      </GoogleMapReact>
    </Suspense>
  )
}

export default ProducerMap
