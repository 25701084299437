import { useRef } from 'react'
import { InfoModalProps } from 'components/EnergyOverview/InfoModal/types'
import styles from './InfoModal.module.scss'
import { Close } from 'assets/svg'
import useToggleWithClickOutside from 'hooks/useToggleWithClickOutside.ts'

// Make sure the parent has position: relative for the backdrop
const InfoModal = ({ isOpen, closeFn, children }: InfoModalProps) => {
  // Ref
  const modal = useRef<HTMLDialogElement>(null)
  useToggleWithClickOutside(modal, closeFn)

  return (
    <>
      {isOpen && <div className={styles.backdrop} />}
      <dialog ref={modal} className={styles.dialog} open={isOpen}>
        {children}
        <form method="dialog">
          <button aria-label="close" className={styles.close} onClick={closeFn}>
            <Close className={styles.icon} />
          </button>
        </form>
      </dialog>
    </>
  )
}

export default InfoModal
