import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './AddressSwitcher.module.scss'
import { resetContractsStore } from 'store/contracts/slice.ts'
import { useStoreDispatch, useStoreSelector } from 'hooks/store.ts'
import { selectContract } from 'store/contact/slice.ts'
import { formatAddress } from 'utils/user.ts'
import { Button } from '@boltenergy-be/design-system'
import Modal from 'components/Modals/Modal/Modal.tsx'
import { AddressSwitcherProps } from './types.ts'
import { setShowAllAddresses, toggleShowAllAddresses } from 'store/app/slice.ts'
import { useLocation } from 'react-router-dom'
import { ALL_ADDRESSES_ONLY_ROUTES, CAN_SHOW_ALL_ADDRESSES_ROUTES } from 'components/AddressSwitcher/constants.ts'
import TooltipOnHover from 'components/Tooltip/TooltipOnHover.tsx'
import { PropsWithClassName } from 'types/utility.ts'
import classNames from 'classnames'
import Cookies from 'js-cookie'
import { groupBillingContractsByActiveServiceContract } from 'utils/contracts.ts'

const modalRoot = document.getElementById('modal-root')

const AddressSwitcher = ({ isMobile, className, containerClassName }: PropsWithClassName<AddressSwitcherProps>) => {
  // Redux
  const { showAllAddresses } = useStoreSelector((store) => store.app)
  const { billingContracts, accounts, selected } = useStoreSelector((store) => store.contact)
  const accountContracts = accounts?.[selected?.account]?.billingContracts
  const billingContract = billingContracts[selected?.billingContract]
  const dispatch = useStoreDispatch()

  // Router
  const { pathname } = useLocation()

  // Local state
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  // i18n
  const { t } = useTranslation()

  // Local constants
  const allAddressesOnly = ALL_ADDRESSES_ONLY_ROUTES.some((route) => pathname.includes(route))
  const canShowAllAddresses = allAddressesOnly || CAN_SHOW_ALL_ADDRESSES_ROUTES.some((route) => pathname.includes(route))
  const showAddressSwitcher = accountContracts?.length > 1
  const { inactive, active } = groupBillingContractsByActiveServiceContract(accountContracts, billingContracts)

  /**
   * Handles the tile click
   *
   * @param {string} contractNumber
   * @returns void
   */
  const handleSwitchAddress = (contractNumber: string) => {
    dispatch(selectContract(contractNumber))
    dispatch(resetContractsStore())
    if (selected?.account) Cookies.set(selected.account, contractNumber)

    // Disable the show all addresses if it's enabled
    if (showAllAddresses) dispatch(toggleShowAllAddresses())

    // Close modal
    setModalOpen(false)
  }

  // Set all addresses
  useEffect(() => {
    // Activate all addresses only when disabled on a allAddressOnly route
    if (allAddressesOnly && !showAllAddresses) dispatch(setShowAllAddresses(true))

    // Disable all addresses only when it's enabled and not possible
    if (showAllAddresses && !canShowAllAddresses) dispatch(setShowAllAddresses(false))
  }, [allAddressesOnly, showAllAddresses])

  /**
   * Handles the show all addresses click
   */
  const handleShowAllAddresses = () => {
    dispatch(toggleShowAllAddresses())
    setModalOpen(false)
  }

  return billingContract && showAddressSwitcher && accountContracts?.length > 1 ? (
    <>
      <TooltipOnHover
        tooltipContent={t('addressSwitcher.switchUnavailable')}
        containerStyles={classNames(styles['tooltip-container'], containerClassName)}
        disabled={!allAddressesOnly}
      >
        <Button
          data-id="switch-address"
          type="button"
          variant={isMobile ? 'tertiary' : 'layered'}
          className={classNames(styles['address-switch-trigger'], className)}
          onClick={() => setModalOpen(true)}
          leadingIcon="shuffle"
          disabled={allAddressesOnly}
        >
          {canShowAllAddresses && showAllAddresses
            ? t('addressSwitcher.allAddresses')
            : formatAddress(billingContract.serviceContracts.electricity.deliveryPoint.address)}
        </Button>
      </TooltipOnHover>

      <Modal
        title={t('addressSwitcher.title')}
        className={styles.modal}
        isOpen={modalOpen}
        modalRootEl={modalRoot}
        setClose={() => setModalOpen(false)}
      >
        <p>{t('addressSwitcher.description')}</p>
        <hr />
        <ul>
          {active.map((contractNumber) => {
            return (
              <li key={contractNumber} className={styles.address}>
                <Button
                  disabled={allAddressesOnly}
                  variant="tertiary"
                  type="button"
                  active={!showAllAddresses && billingContract.contractNumber === contractNumber}
                  className={styles.btn}
                  onClick={() => handleSwitchAddress(contractNumber)}
                >
                  {formatAddress(billingContracts[contractNumber].serviceContracts.electricity.deliveryPoint.address)}
                </Button>
              </li>
            )
          })}
          <li className={styles['all-addresses']}>
            <Button
              variant="tertiary"
              type="button"
              className={styles.btn}
              active={showAllAddresses}
              disabled={!canShowAllAddresses}
              onClick={handleShowAllAddresses}
            >
              <strong>{t('addressSwitcher.allAddresses')}</strong>
              {!canShowAllAddresses && <small>{t('addressSwitcher.allAddressesUnavailable')}</small>}
            </Button>
          </li>
        </ul>

        {inactive.length > 0 && (
          <>
            <hr />
            <div>
              <small className={styles.divider}>{t('inactiveAddresses')}</small>
              <ul>
                {inactive.map((contractNumber) => {
                  return (
                    <li key={contractNumber} className={styles.address}>
                      <Button
                        disabled={allAddressesOnly}
                        variant="tertiary"
                        type="button"
                        active={!showAllAddresses && billingContract.contractNumber === contractNumber}
                        className={styles.btn}
                        onClick={() => handleSwitchAddress(contractNumber)}
                      >
                        {formatAddress(billingContracts[contractNumber].serviceContracts.electricity.deliveryPoint.address)}
                      </Button>
                    </li>
                  )
                })}
              </ul>
            </div>
          </>
        )}
      </Modal>
    </>
  ) : null
}

export default AddressSwitcher
