import { MarkerProps } from '../types'
import styles from './Marker.module.scss'
import { Icon } from '@boltenergy-be/design-system'

const Marker = ({ icon, text }: MarkerProps) => (
  <div className={styles.marker}>
    <Icon name={icon} className="text-primary" />
    {text}
  </div>
)

export default Marker
