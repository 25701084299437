// ------------------------------
// GENERAL API RESPONSE CODES
// These are the general response codes that can be used across all endpoints.
// ------------------------------
export enum ApiResponseCodes {
  // 400
  BAD_REQUEST = 'BOLT-400',
  ALREADY_CUSTOMER = 'BOLT-400-001',
  NOT_A_CUSTOMER = 'BOLT-400-002',
  REQUESTED_PRODUCER_FULL = 'BOLT-400-003',
  ALREADY_EXISTS = 'BOLT-400-004',

  // 401
  UNAUTHORIZED = 'BOLT-401',

  // 403
  FORBIDDEN = 'BOLT-403-001',

  // 404
  NOT_FOUND = 'BOLT-404',

  // 500
  INTERNAL_SERVER_ERROR = 'BOLT-500'
}

// CONTACTS ROOT ENDPOINT RESPONSE CODES (/contacts)

export enum ContactsResponseCodes {
  // 400
  CONTACT_EMAIL_IN_USE = 'BOLT-CT-400-001',

  // 403
  USER_CANNOT_UPDATE_PROSPECT_CONTACT = 'BOLT-CT-403-001',
  USER_CANNOT_UPDATE_PROSPECT_ACCOUNT = 'BOLT-CT-403-002',
  SALES_CANNOT_UPDATE_CUSTOMER_CONTACT = 'BOLT-CT-403-101',
  SALES_CANNOT_UPDATE_CUSTOMER_ACCOUNT = 'BOLT-CT-403-102',
  SALES_CANNOT_UPDATE_CLOSED_OPPORTUNITY = 'BOLT-CT-403-103',

  // 404
  NO_ACCOUNTS_FOUND = 'BOLT-CT-404-001',
  NO_CUSTOMER_ACCOUNTS_FOUND = 'BOLT-CT-404-002',
  NO_PROSPECT_ACCOUNTS_FOUND = 'BOLT-CT-404-003',
  NO_OPPORTUNITIES_FOUND = 'BOLT-CT-404-004',
  NO_PRODUCER_FOUND = 'BOLT-CT-404-005'
}

// CONTRACTS ROOT ENDPOINT RESPONSE CODES (/contracts)

export enum ContractResponseCodes {
  // 404
  CONTRACT_NOT_FOUND = 'BOLT-CO-404-001',
  ENERGY_COMMUNITY_MEMBERSHIP_NOT_FOUND = 'BOLT-CO-404-002',
  LINKED_ENERGY_COMMUNITY_NOT_FOUND = 'BOLT-CO-404-003',
  CONTRACT_INVOICES_NOT_FOUND = 'BOLT-CO-404-004',
  MERCATOR_CUSTOMER_NOT_FOUND = 'BOLT-CO-404-005',
  NO_VOLUMES = 'BOLT-CO-404-006',
  NO_USABLE_VOLUMES = 'BOLT-CO-404-007',

  // 400
  NO_SMR3_VOLUMES_AVAILABLE_YET = 'BOLT-CO-400-004'
}

export enum AuthErrorCodes {
  REFRESH_TOKEN_EXPIRED = 'jwt expired'
}
