import { Contact } from 'types/contacts.ts'
import { NormalizedEntities } from 'store/contact/types.ts'
import { memoize } from 'proxy-memoize'
import { GetContactSuccessPayload } from 'store/contact/thunks/types.ts'
import { findFirstBillingContractWithActiveElectricityContract, hasMultipleActiveElectricityContracts } from 'utils/contracts.ts'
import Cookies from 'js-cookie'

/**
 * Ignore gas only contracts
 * @param {Contact} contact
 * @returns {{ignoredBillingContracts: string[], updatedContact?: Contact}}
 */
export const ignoreGasOnlyContracts = (contact: Contact): { ignoredBillingContracts: string[]; updatedContact?: Contact } => {
  const ignoredBillingContracts = new Set<string>()
  let updatedContact: Contact | undefined = undefined

  contact.accounts?.forEach((account) => {
    account.billingContracts?.forEach((contract) => {
      if (!contract.serviceContracts?.electricity) {
        ignoredBillingContracts.add(contract.contractNumber)

        // Remove cookies for ignored contracts
        if (Cookies.get(contract.contractNumber)) {
          Cookies.remove(contract.contractNumber)
        }
      }
    })
  })

  // Update the contact data to remove the ignored contracts
  if (ignoredBillingContracts.size > 0) {
    updatedContact = {
      ...contact,
      accounts: contact.accounts?.map((account) => ({
        ...account,
        billingContracts: account.billingContracts?.filter((contract) => !ignoredBillingContracts.has(contract.contractNumber))
      }))
    }
  }

  return { ignoredBillingContracts: Array.from(ignoredBillingContracts), updatedContact }
}

/**
 * Process contact data for getMyContact & getContactByEmail thunks
 * @param {Contact} contact
 * @param {GetContactSuccessPayload['selected']} cachedSelection - Comes from redux-persist (src/store/index.ts), saved in session storage.
 * @returns {GetContactSuccessPayload}
 */
export const processContact = (
  contact: Contact,
  cachedSelection?: GetContactSuccessPayload['selected']
): Omit<GetContactSuccessPayload, 'ignoredBillingContracts'> => {
  // Normalize data for store
  const normalizedStoreData = memoizedNormalizeContactData(contact)
  const { billingContracts } = normalizedStoreData

  if (Object.keys(billingContracts).length === 1 || !hasMultipleActiveElectricityContracts(billingContracts)) {
    const previouslySelectedItem =
      cachedSelection?.billingContract && cachedSelection?.billingContract in billingContracts
        ? billingContracts[cachedSelection.billingContract]
        : undefined
    const firstActiveItem = findFirstBillingContractWithActiveElectricityContract(billingContracts)
    const firstContract = Object.values(billingContracts)[0]
    const selectedContract = previouslySelectedItem || firstActiveItem || firstContract

    return {
      normalizedStoreData,
      selected: {
        account: selectedContract.customerNumber,
        billingContract: selectedContract.contractNumber
      }
    } satisfies Omit<GetContactSuccessPayload, 'ignoredBillingContracts'>
  }

  return {
    normalizedStoreData,
    selected: cachedSelection?.billingContract && cachedSelection?.billingContract in billingContracts ? cachedSelection : undefined
  } satisfies Omit<GetContactSuccessPayload, 'ignoredBillingContracts'>
}

/**
 * Convert API response to normalized store entities
 * @param {Contact} contact
 * @returns {NormalizedEntities}
 */
export const normalizeContactData = (contact: Contact): NormalizedEntities => {
  // Normalize data for store
  const normalizedContact: NormalizedEntities['contact'] = {
    ...contact,
    accounts: contact.accounts?.map((account) => account.customerNumber) || []
  }
  const accounts: NormalizedEntities['accounts'] = {}
  const billingContracts: NormalizedEntities['billingContracts'] = {}
  const opportunities: NormalizedEntities['opportunities'] = {}

  contact.accounts?.forEach((account) => {
    const { customerNumber } = account

    accounts[customerNumber] = {
      ...account,
      billingContracts: account.billingContracts?.map((contract) => contract.contractNumber) || [],
      opportunities: account.opportunities?.map((opportunity) => opportunity.sfId) || []
    }

    account.billingContracts?.forEach((contract) => {
      billingContracts[contract.contractNumber] = contract
    })

    account.opportunities?.forEach((opportunity) => {
      opportunities[opportunity.sfId] = opportunity
    })
  })

  return {
    contact: normalizedContact,
    accounts,
    billingContracts,
    opportunities
  }
}

/**
 * Memoized version of normalizeContactsData
 */
export const memoizedNormalizeContactData = memoize<Contact, NormalizedEntities>(normalizeContactData)
