import { useTranslation } from 'react-i18next'
import { ProducerLocationProps } from 'components/ProducerLocation/types'
import classNames from 'classnames'
import styles from './ProducerLocation.module.scss'
import { energyToIconName } from 'utils/mapping'
import Tag from 'components/Tag/Tag'

const ProducerLocation = ({ energyType, energyName, location, boxed, className }: ProducerLocationProps) => {
  // i18n
  const { t } = useTranslation('producer')

  return (
    <div className={classNames(styles.wrapper, { [styles.boxed]: boxed }, className)}>
      <Tag size="small" icons={energyToIconName(energyType)} color="green">
        {energyName || t(`energyType.${energyType}`)}
      </Tag>
      {location && (
        <Tag size="small" icons="location" color="green">
          {location}
        </Tag>
      )}
    </div>
  )
}

export default ProducerLocation
