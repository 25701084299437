import { createSlice, isFulfilled, isPending, isRejected, PayloadAction } from '@reduxjs/toolkit'
import { initialContactState } from 'store/contact/initial.ts'
import {
  getContactByEmail,
  getMyContact,
  updateAccount,
  updateBillingContract,
  updateContact,
  updateInstalment
} from 'store/contact/thunks'
import { ContactStore } from 'store/contact/types.ts'
import { BillingContract } from 'types/contracts.ts'
import { normalizeContactData } from 'store/contact/thunks/utils.ts'
import { GetContactErrorPayload } from 'store/contact/thunks/types.ts'
import { AccountRecordTypeIds } from 'types/contacts.ts'

export const contactSlice = createSlice({
  name: 'contact',
  initialState: initialContactState satisfies ContactStore as ContactStore,
  reducers: {
    selectContract: (state, { payload }: PayloadAction<BillingContract['contractNumber']>) => {
      const billingContract = state.billingContracts[payload]
      const currentAccount = state.accounts[billingContract.customerNumber]

      return {
        ...state,
        isProducer: currentAccount?.recordType === AccountRecordTypeIds.PRODUCER,
        selected: {
          ...state.selected,
          billingContract: billingContract.contractNumber,
          account: billingContract.customerNumber
        }
      }
    },

    clearUser: () => {
      return initialContactState
    }
  },
  extraReducers: (builder) => {
    builder.addCase(updateContact.fulfilled, (state, { payload }) => {
      const normalizedData = normalizeContactData(payload.contact)

      return {
        ...state,
        success: true,
        loading: false,
        contact: normalizedData.contact
      }
    })

    builder.addCase(updateAccount.fulfilled, (state, { payload }) => {
      const updatedAccount = payload.account

      return {
        ...state,
        success: true,
        loading: false,
        accounts: {
          ...state.accounts,
          [updatedAccount.customerNumber]: {
            ...state.accounts[updatedAccount.customerNumber],
            ...updatedAccount
          }
        }
      }
    })

    builder.addCase(updateBillingContract.fulfilled, (state, { payload }) => {
      const updatedPartialBillingContract = payload.billingContract

      return {
        ...state,
        success: true,
        loading: false,
        billingContracts: {
          ...state.billingContracts,
          [updatedPartialBillingContract.contractNumber]: {
            ...state.billingContracts[updatedPartialBillingContract.contractNumber],
            ...updatedPartialBillingContract
          }
        }
      }
    })

    builder.addCase(updateInstalment.fulfilled, (state, { payload }) => {
      const { billingContractId, electricity, gas } = payload

      return {
        ...state,
        success: true,
        loading: false,
        billingContracts: {
          ...state.billingContracts,
          [billingContractId]: {
            ...state.billingContracts[billingContractId],
            serviceContracts: {
              electricity,
              gas
            }
          }
        }
      }
    })

    // IS PENDING
    builder.addMatcher(
      isPending(getMyContact, getContactByEmail, updateContact, updateAccount, updateBillingContract, updateInstalment),
      (state) => {
        return {
          ...state,
          loading: true,
          error: null,
          success: false
        }
      }
    )

    // IS FULFILLED
    builder.addMatcher(isFulfilled(getMyContact, getContactByEmail), (state, { payload }) => {
      return {
        ...state,
        ...payload.normalizedStoreData,
        ignoredBillingContracts: payload.ignoredBillingContracts,
        selected: payload.selected || state.selected,
        initialLoaded: true,
        loading: false,
        error: null
      } satisfies ContactStore
    })

    // IS REJECTED
    builder.addMatcher(
      isRejected(getMyContact, getContactByEmail, updateContact, updateAccount, updateBillingContract, updateInstalment),
      (state, { payload }) => {
        const { error, errorData } = payload as GetContactErrorPayload

        return {
          ...state,
          error,
          errorData: errorData || [],
          loading: false
        }
      }
    )
  }
})

export const { selectContract, clearUser } = contactSlice.actions

export default contactSlice.reducer
