import styles from './PageLoading.module.scss'
import { useStoreSelector } from 'hooks/store.ts'
import { useTranslation } from 'react-i18next'
import { PageLoadingProps } from 'components/PageLoading/types.ts'
import { Button, Loader } from '@boltenergy-be/design-system'
import { logout } from 'utils/app.ts'
import { getUserTypeLoginPage } from 'utils/user.ts'
import { useNavigate } from 'react-router'
import { IS_PROD_OR_BETA } from 'constants/envs.ts'

const PageLoading = ({ isLoading, showError }: PageLoadingProps) => {
  // Redux
  const { error } = useStoreSelector((store) => store.contact)
  const { userType } = useStoreSelector((store) => store.auth)

  // i18n
  const { t } = useTranslation()

  // Router
  const navigate = useNavigate()

  return (
    <div className={styles.loading}>
      {!showError && isLoading && <Loader size={32} className={styles.loader} />}
      {(showError || error) && (
        <>
          <p>{t('error')}</p>
          {!IS_PROD_OR_BETA && (
            <Button variant="secondary" onClick={() => logout(() => navigate(getUserTypeLoginPage(userType)))}>
              {t('nav.logout')}
            </Button>
          )}
        </>
      )}
    </div>
  )
}

export default PageLoading
