import { CardColors } from 'components/Card/types.ts'
import { InvoicesStatus, InvoiceStatusTextKeys } from './types.ts'

export const INVOICE_STATUS_CONFIG: Record<InvoicesStatus, { color: CardColors; textKey: InvoiceStatusTextKeys }> = {
  [InvoicesStatus.BREAK_EVEN]: {
    color: CardColors.BRAND,
    textKey: 'neutral'
  },
  [InvoicesStatus.IS_GETTING_MONEY_BACK]: {
    color: CardColors.BRAND,
    textKey: 'open'
  },
  [InvoicesStatus.HAS_UNPAID_INVOICES]: {
    color: CardColors.YELLOW,
    textKey: 'open'
  },
  [InvoicesStatus.DIRECT_DEBIT]: {
    color: CardColors.BRAND,
    textKey: 'directDebit'
  },
  [InvoicesStatus.DIRECT_DEBIT_WITH_UNPAID_INVOICES]: {
    color: CardColors.YELLOW,
    textKey: 'open'
  }
}
