import dayjs from 'dayjs'
import { DATE_FORMAT } from 'constants/constants.ts'
import { useTranslation } from 'react-i18next'
import { HeadingProps } from './types.ts'
import styles from './Heading.module.scss'
import { Heading as DSHeading } from '@boltenergy-be/design-system'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'
import { useParams } from 'react-router-dom'
import { formatAddress } from 'utils/user.ts'
import classNames from 'classnames'

const Heading = ({ isLoading, electricityContract, ...props }: HeadingProps) => {
  // i18n
  const { t } = useTranslation('billing')

  // URL Param
  const { id } = useParams<{ id: string }>()

  // Constants
  const isCurrentPeriod = id === 'current'
  const startDate = isCurrentPeriod ? props.currentBillingCycle?.settlement.startDate : props.previousBillingCycle?.startDate
  const endDate = isCurrentPeriod ? props.currentBillingCycle?.settlement.endDate : props.previousBillingCycle?.endDate

  return (
    <header className={styles.header}>
      <DSHeading as="h1" variant="h3" weight={400}>
        {t(`billingCycles.${isCurrentPeriod ? 'current' : 'previous'}.title`)}
      </DSHeading>

      {isLoading ? (
        <LoadingSkeleton className={styles.loader}>
          <LoadingSkeleton.Rectangle height={30} width={100} /> – <LoadingSkeleton.Rectangle height={30} width={100} />
        </LoadingSkeleton>
      ) : (
        <small>
          {dayjs(startDate).format(DATE_FORMAT)} – {dayjs(endDate).format(DATE_FORMAT)}
        </small>
      )}

      {electricityContract && (
        <small className={classNames('truncate', styles.address)}>{formatAddress(electricityContract.deliveryPoint.address)}</small>
      )}
    </header>
  )
}

export default Heading
