import { useMemo, useRef } from 'react'
import styles from 'components/Charts/Charts.module.scss'
import { Bar } from 'react-chartjs-2'
import { BillingCycleChartProps } from '../types'
import { getBillingCycleChartOptions } from './chartjs.config'
import { useStoreSelector } from 'hooks/store.ts'

const PreviousBillingCycleChartDesktop = ({ chartData, labels, setHoverDataPointIndex }: Omit<BillingCycleChartProps, 'minMaxData'>) => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)

  // Reference
  const chartRef = useRef<never>(null)

  const options = useMemo<any>(
    () => getBillingCycleChartOptions({ labels, language, isMobile: false, handleHoverDataPoint: setHoverDataPointIndex }),
    [labels, language, setHoverDataPointIndex]
  )

  return (
    <div className={styles['chart-wrapper']}>
      <Bar options={options} data={chartData} className={styles.chart} ref={chartRef} />
    </div>
  )
}

export default PreviousBillingCycleChartDesktop
