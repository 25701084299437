import { AuthTokens } from 'types/auth.ts'

// TAGS
export enum TagTypes {
  ACCOUNT = 'account',
  CONTACT = 'contact',
  OPPORTUNITY = 'opportunity',
  CASE = 'case',
  EVENT = 'event',
  BILL_SHOCK = 'bill-shock',
  CONSUMPTION = 'consumption'
}

// REFRESH TOKENS

export type RefreshTokensResponse = {
  tokens: AuthTokens
}
