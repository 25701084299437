import { ApiResponseCodes, ContractResponseCodes } from 'types/errorCodes.ts'
import { TransfoErrorCodes } from 'types/billShock.ts'

export const HANDLED_ERRORS: string[] = [
  ...Object.values(TransfoErrorCodes),
  ContractResponseCodes.NO_VOLUMES,
  ContractResponseCodes.NO_USABLE_VOLUMES,
  ApiResponseCodes.NOT_A_CUSTOMER,
  ApiResponseCodes.REQUESTED_PRODUCER_FULL
]

export const IGNORED_UNHANDLED_ERRORS: string[] = [
  'Object Not Found Matching Id:1, MethodName:update, ParamCount:4', // This error is thrown by Outlook crawlers and is not relevant
  "undefined is not an object (evaluating 'a.L')" // An error thrown by Google Translate primarily on Chrome Mobile iOS. Not relevant, blocking or fixable by us.
]
