import { useTranslation } from 'react-i18next'
import styles from './Home.module.scss'
import { ButtonLoadingIndicator } from '@boltenergy-be/design-system'
import ReportingStatsCard from 'components/ReportingStatsCard/ReportingStatsCard'
import CasesCard from 'components/CasesCard/CasesCard'
import OpportunitiesCard from 'components/OpportunitiesCard/OpportunitiesCard'
import { useStoreSelector } from 'hooks/store.ts'
import PageLayout from 'layouts/page-layout/PageLayout.tsx'

const Home = () => {
  const { loading } = useStoreSelector((store) => store.contact)

  // i18n
  const { t } = useTranslation(['home', 'common'])

  return (
    <PageLayout title={`${t('app.welcome')}!`}>
      {loading ? (
        <ButtonLoadingIndicator />
      ) : (
        <div className={styles.wrapper}>
          <OpportunitiesCard />

          <CasesCard />

          <ReportingStatsCard />
        </div>
      )}
    </PageLayout>
  )
}

export default Home
