import { AuthProducer } from './types'
import { Language } from 'store/app/types.ts'
import { ProducerEnergyType } from 'types/producer.ts'

export const authProducers: AuthProducer[] = [
  {
    id: 'stephane',
    publicImgUrl: '/images/auth/stephane.jpg',
    energyType: ProducerEnergyType.WATER,
    objectPosition: 'left center',
    [Language.NL]: {
      name: 'Stéphane',
      imgAlt: 'Foto van energie-opwekker Stéphane',
      location: 'Hun'
    },
    [Language.FR]: {
      name: 'Stéphane',
      imgAlt: "Photo du producteur d'énergie Stéphane",
      location: 'Hun'
    }
  },
  {
    id: 'family-gansbeke',
    publicImgUrl: '/images/auth/familie-gansbeke.jpg',
    energyType: ProducerEnergyType.BIO,
    objectPosition: 'center center',
    [Language.NL]: {
      name: 'Familie Gansbeke',
      imgAlt: 'Foto van energie-opwekker familie Gansbeke',
      location: 'Eksaarde'
    },
    [Language.FR]: {
      name: 'La famille Gansbeke',
      imgAlt: "Photo du producteur d'énergie la famille Gansbeke",
      location: 'Eksaarde'
    }
  },
  {
    id: 'noel-co',
    publicImgUrl: '/images/auth/noel-co.jpg',
    energyType: ProducerEnergyType.SOLAR,
    objectPosition: 'right center',
    [Language.NL]: {
      name: 'Noël & Co',
      imgAlt: 'Foto van energie-opwekker Noël & co',
      location: 'Ternat'
    },
    [Language.FR]: {
      name: 'Noël & Co',
      imgAlt: "Photo du producteur d'énergie Noël & co",
      location: 'Ternat'
    }
  },
  {
    id: 'ken-kristof',
    publicImgUrl: '/images/auth/ken-kristof.jpg',
    energyType: ProducerEnergyType.SOLAR,
    objectPosition: 'right center',
    [Language.NL]: {
      name: 'Ken en Kristof',
      imgAlt: 'Foto van energie-opwekker Ken en Kristof',
      location: 'Kasterlee'
    },
    [Language.FR]: {
      name: 'Ken et Kristof',
      imgAlt: "Photo du producteur d'énergie Ken et Kristof",
      location: 'Kasterlee'
    }
  },
  {
    id: 'dominique-kathleen-zonen',
    publicImgUrl: '/images/auth/dominique-kathleen-zonen.jpg',
    energyType: ProducerEnergyType.WIND,
    objectPosition: 'center center',
    [Language.NL]: {
      name: 'Dominique, Kathleen en zonen',
      imgAlt: 'Foto van energie-opwekker Dominique, Kathleen en zonen',
      location: 'Gistel'
    },
    [Language.FR]: {
      name: 'Dominique, Kathleen et leurs fils',
      imgAlt: "Photo du producteur d'énergie Dominique, Kathleen et leurs fils",
      location: 'Gistel'
    }
  }
]
