import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxiosRequestConfig, newRequest } from 'utils/request.ts'
import { Methods, Response } from 'types/request.ts'
import { GetSalesPartnerByIdResponse, GetSalesUserDataResponse } from 'types/sales.ts'
import { Language } from 'store/app/types.ts'
import { RootState } from 'store/index.ts'
import { switchLanguage } from 'store/app/thunks'
import Bugsnag from '@bugsnag/js'
import { RejectValue, GetCustomersForSalesPartnerPayload, GetSalesUserDataSuccessPayload } from './types.ts'
import { getLowerCaseLanguage } from 'utils/app.ts'
import Cookies from 'js-cookie'

// TODO: Refactor to new structure once API is ready, previous res = GetUserDataResponseData
export const getCustomersForSalesPartner = createAsyncThunk<unknown, GetCustomersForSalesPartnerPayload, RejectValue>(
  'users/sales/getCustomersForSalesPartner',
  async ({ email, partnerId }, thunkAPI) => {
    try {
      const { success, data, message }: Response<unknown> = await newRequest(
        getAxiosRequestConfig(Methods.GET, `/partners/${partnerId}/customers?customerEmail=${encodeURIComponent(email)}`)
      )

      if (!success) {
        return thunkAPI.rejectWithValue(message ?? 'getCustomersForSalesPartnerError')
      }

      return data
    } catch (err) {
      const { message } = err as Error
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getSalesUserData = createAsyncThunk<GetSalesUserDataSuccessPayload, undefined, RejectValue>(
  'users/sales/getSalesUserData',
  async (_, thunkAPI) => {
    try {
      const {
        success: userSuccess,
        data: userData,
        message: userMessage
      } = await newRequest<GetSalesUserDataResponse>(getAxiosRequestConfig(Methods.GET, '/sales/users/me'))
      if (!userSuccess || !userData) {
        return thunkAPI.rejectWithValue(userMessage ?? 'error')
      }
      const { user }: GetSalesUserDataResponse = userData

      const {
        success: partnerSuccess,
        data: partnerData,
        message: partnerMessage
      }: Response<GetSalesPartnerByIdResponse> = await newRequest(getAxiosRequestConfig(Methods.GET, `/partners/${user.partnerId}`))
      if (!partnerSuccess || !partnerData?.partner) {
        return thunkAPI.rejectWithValue(partnerMessage ?? 'getPartnerByIdError')
      }
      const { partner } = partnerData

      // Set language cookie
      Cookies.set('language', getLowerCaseLanguage(user.language), { expires: 365 })
      const upperCaseLanguage = user.language.toUpperCase() as Language

      // Fetch current language and change it if it doesn't match the user language
      const lang = (thunkAPI.getState() as RootState).app.language
      if (lang !== upperCaseLanguage) thunkAPI.dispatch(switchLanguage({ language: upperCaseLanguage }))

      // Add user data to Bugsnag
      Bugsnag.addMetadata('salesUser', {
        email: user.email
      })

      return {
        _id: user._id,
        agentId: user.agentId,
        activated: user.activated,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        language: user.language,
        partner
      } satisfies GetSalesUserDataSuccessPayload
    } catch (err) {
      const { message } = err as Error
      return thunkAPI.rejectWithValue(message)
    }
  }
)
