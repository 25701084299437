import { FaqSlug } from 'types/faq'
import { BiLingualValue } from 'types/types'

export const FAQ_SLUGS: { [key in FaqSlug]: BiLingualValue<string> } = {
  [FaqSlug.CONSUMPTION]: {
    nl: 'verbruik-2',
    fr: 'consommation-2'
  },
  [FaqSlug.COMMUNITY_SALES]: {
    nl: 'vrienden-uitnodigen',
    fr: 'Inviter-un-ami'
  },
  [FaqSlug.MOVE]: {
    nl: 'verhuizen',
    fr: 'demenager'
  },
  [FaqSlug.TARIFFS]: {
    nl: 'tarieven',
    fr: 'tarifs'
  },
  [FaqSlug.ABOUT_BOLT]: {
    nl: 'bolt-over',
    fr: 'a-propos-de-Bolt'
  },
  [FaqSlug.SWITCH]: {
    nl: 'overstappen-2',
    fr: 'changer-de-fournisseur-2'
  },
  [FaqSlug.PAYMENT]: {
    nl: 'betalen',
    fr: 'regler-sa-facture'
  },
  [FaqSlug.INVOICES_AND_PAYMENTS]: {
    nl: 'facturen',
    fr: 'factures-et-paiements'
  },
  [FaqSlug.MY_ADDRESSES]: {
    nl: 'adressen',
    fr: 'adresses'
  },
  [FaqSlug.MY_DETAILS]: {
    nl: 'gegevens',
    fr: 'mes-informations'
  },
  [FaqSlug.MY_PRODUCER_B2C]: {
    nl: 'energie-opwekker',
    fr: 'producteurs-d-energie'
  },
  [FaqSlug.MY_PRODUCER_B2B]: {
    nl: 'energie-opwekker-b2b',
    fr: 'producteurs-d-energie-b2b'
  },
  [FaqSlug.ENERGY_OVERVIEW]: {
    nl: 'impact',
    fr: 'apercu-de-mon-energie'
  },
  [FaqSlug.MARKET_PRICES]: {
    nl: 'prijzen-vandaag-en-morgen',
    fr: 'les-prix-aujourdhui-et-demain'
  }
}
