import styles from '../Navigation.module.scss'
import { NavigationProps } from '../types.ts'
import NavigationItem from '../navigation-item/NavigationItem.tsx'
import { useTranslation } from 'react-i18next'
import { routes } from 'types/routes.ts'
import { useLocation } from 'react-router-dom'
import AddressSwitcher from 'components/AddressSwitcher/AdressSwitcher.tsx'
import { useLayoutEffect } from 'react'
import { useStoreSelector } from 'hooks/store.ts'
import classNames from 'classnames'

const BottomNavigation = ({ handleLogout, isSales }: NavigationProps) => {
  // i18n
  const { t } = useTranslation()
  const { isProducer, accounts, selected } = useStoreSelector((store) => store.contact)
  const accountContracts = accounts?.[selected?.account]?.billingContracts

  // Router
  const { pathname } = useLocation()
  const routesWithAddressSwitcher = [routes.CONSUMPTION, routes.BILLING, routes.PRODUCER, routes.USER_CONTACT, routes.USER_PAYMENT]
  const hasAddressSwitcher = routesWithAddressSwitcher.some((route) => pathname.includes(route)) && accountContracts?.length > 1

  useLayoutEffect(() => {
    const variableSelector = '--bottom-bar-height'
    const bottomBarHeight = getComputedStyle(document.documentElement).getPropertyValue(variableSelector)

    // Only update CSS variable if necessary
    if (bottomBarHeight !== '70px' && !hasAddressSwitcher) {
      document.documentElement.style.setProperty(variableSelector, '70px')
    } else if (bottomBarHeight !== '140px' && hasAddressSwitcher) {
      document.documentElement.style.setProperty(variableSelector, '140px')
    }
  }, [hasAddressSwitcher])

  return (
    <nav className={styles['bottom-navigation']} aria-labelledby="main">
      {hasAddressSwitcher && <AddressSwitcher isMobile />}
      <ul className={classNames(styles['nav-items-list'], { [styles.sales]: isSales })}>
        {isSales ? (
          <>
            <NavigationItem icon="homeSmileOutline" activeIcon="homeSmile" text={t('nav.overview')} url={routes.SALES_HOME} />
            <NavigationItem icon="fileOutline" activeIcon="file" text={t('nav.events')} url={routes.SALES_EVENTS} />
            <NavigationItem icon="homeOutline" activeIcon="home" text={t('nav.address')} url={routes.SALES_CONTRACTS_ADD} />
            <NavigationItem icon="logOut" text={t('nav.logout')} onClick={handleLogout} id="logout" />
          </>
        ) : (
          <>
            <NavigationItem icon="homeSmileOutline" activeIcon="homeSmile" text={t('nav.overview')} url={routes.OVERVIEW} />
            <NavigationItem
              icon="chartsOutline"
              activeIcon="charts"
              text={t(`nav.${isProducer ? 'injection' : 'usage'}`)}
              extraActiveRoutes={[routes.CONSUMPTION]}
              url={routes.CONSUMPTION_ELECTRICITY}
            />
            <NavigationItem
              icon="fileOutline"
              activeIcon="file"
              text={t('nav.billing')}
              extraActiveRoutes={[routes.BILLING]}
              url={routes.BILLING_INVOICES}
            />
            <NavigationItem icon="menu" text={t('nav.more')} url={routes.MORE} />
          </>
        )}
      </ul>
    </nav>
  )
}

export default BottomNavigation
