import { ContactStore } from './types.ts'

export const initialContactState: ContactStore = {
  loading: false,
  error: null,
  errorData: [],
  initialLoaded: false,
  isProducer: false,
  selected: {
    account: '',
    billingContract: '',
    opportunity: ''
  },
  contact: undefined,
  accounts: {},
  billingContracts: {},
  opportunities: {},
  ignoredBillingContracts: []
}
