import Card from 'components/Card/Card'
import ReportingCardsGroup from './ReportingCardsGroup/ReportingCardsGroup'
import { Heading, ButtonLoadingIndicator, DatePicker } from '@boltenergy-be/design-system'
import { useTranslation } from 'react-i18next'
import styles from './ReportingStatsCard.module.scss'
import { SalesOpportunity } from 'types/sales'
import { useState } from 'react'
import SearchBar from 'components/Search/SearchBar'
import OpportunitiesListItem from 'components/OpportunitiesListItem/OpportunitiesListItem'
import { SearchOpportunityTypes } from 'api/types'
import dayjs from 'dayjs'
import { useGetSalesOpportunitiesQuery, useGetSalesOpportunitiesStatsQuery } from 'store/queries/bolt-api/sales'
import ListWithSeparator from 'components/ListWithSeparator/ListWithSeparator'
import { useStoreSelector } from 'hooks/store.ts'
import { getLowerCaseLanguage } from 'utils/app.ts'

const ReportingStatsCard = () => {
  // i18n
  const { t } = useTranslation('sales')

  // REDUX
  const { language } = useStoreSelector((store) => store.app)

  // Local constants
  const currentDate = dayjs()
  const minDate = currentDate.subtract(30, 'day')

  // Local state
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([minDate.toDate(), currentDate.toDate()])
  const [startDate, endDate] = dateRange
  const [activeReport, setActiveReport] = useState<SearchOpportunityTypes | undefined>(undefined)
  const [searchQuery, setSearchQuery] = useState<string>('')

  /**
   * Function to set the data for the reports
   *
   * @param status {OpportunityStage}
   */
  const onReportClick = (status: SearchOpportunityTypes) => {
    setActiveReport(status !== activeReport ? status : undefined)
  }

  /**
   * Function to correct the time offset correction for react datepicker range issue
   * https://github.com/Hacker0x01/react-datepicker/issues/1018
   *
   * @param date {Date}
   * @returns {string}
   */
  const correctTimeOffset = (date: Date) => {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().slice(0, 10)
  }

  const { data: opportunityStats, isFetching } = useGetSalesOpportunitiesStatsQuery(
    {
      from: startDate ? correctTimeOffset(startDate) : undefined,
      until: endDate ? correctTimeOffset(endDate) : undefined
    },
    { skip: !startDate || !endDate }
  )

  const { data: opportunityData, isFetching: isLoadingOpportunities } = useGetSalesOpportunitiesQuery(
    {
      searchQuery,
      from: startDate ? correctTimeOffset(startDate) : undefined,
      until: endDate ? correctTimeOffset(endDate) : undefined,
      filter: activeReport
    },
    { skip: !activeReport }
  )

  return (
    <Card as="section" className={styles['reports-card']}>
      <header className={styles.header}>
        <div className={styles['header-text']}>
          <Heading as="h2" variant="h4">
            {t('overview.report.title')}
          </Heading>
          <p>{t('overview.report.description')}</p>
        </div>
        <div className={styles['datepicker-wrapper']}>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            portalId="root-portal"
            name="dateRange"
            language={getLowerCaseLanguage(language)}
            selectsRange
            minDate={minDate.toDate()}
            maxDate={currentDate.toDate()}
            startDate={startDate || undefined}
            endDate={endDate || undefined}
            onChange={(update) => {
              setDateRange(update as [Date | null, Date | null])
            }}
            prefix={{ as: 'calendar' }}
          />
        </div>
      </header>

      {/* REPORT STATS OVERVIEW */}
      <ReportingCardsGroup data={opportunityStats} isLoading={isFetching} onReportClick={onReportClick} />

      {/* ACTIVE REPORT DATA */}
      {activeReport && (
        <>
          <SearchBar
            className={styles.search}
            placeholder={t('overview.opportunities.search.searchbar')}
            onInput={(value) => {
              setSearchQuery(value)
            }}
          />
          {isLoadingOpportunities ? (
            <div className={styles['loading-wrapper']}>
              <ButtonLoadingIndicator />
            </div>
          ) : opportunityData && opportunityData?.length > 0 && activeReport ? (
            <ListWithSeparator>
              {opportunityData.map((opportunity: SalesOpportunity, index: number) => (
                <OpportunitiesListItem
                  key={index}
                  opportunity={opportunity}
                  isClickable={activeReport !== SearchOpportunityTypes.TERMINATED}
                />
              ))}
            </ListWithSeparator>
          ) : (
            <div className={styles['loading-wrapper']}>{t('overview.report.noResults')}</div>
          )}
        </>
      )}
    </Card>
  )
}

export default ReportingStatsCard
