import classNames from 'classnames'
import styles from '../Navigation.module.scss'
import { Link, useLocation } from 'react-router-dom'
import { memo } from 'react'
import { NavigationItemProps } from '../types.ts'
import NavigationItemContent from '../navigation-item-content/NavigationItemContent.tsx'
import { CommonTrackingEvents, NavigationTypes } from 'types/tracking.ts'
import mixpanel from 'mixpanel-browser'

const NavigationItem = memo(
  ({ extraActiveRoutes, url, onClick, isMore, isActive, id, activeIcon, ...contentProps }: NavigationItemProps) => {
    // React Router
    const { pathname } = useLocation()

    // Constants
    const isActiveItem = url ? pathname.includes(url) || extraActiveRoutes?.some((route) => pathname.includes(route)) : isActive
    const icon = isActiveItem && !!activeIcon ? activeIcon : contentProps.icon

    /**
     * Tracks navigation item click
     * @param {string} id
     */
    const trackNavigationItem = (id: string) => {
      mixpanel.track(CommonTrackingEvents.CLICK_NAVIGATION_ITEM, { id, type: isMore ? NavigationTypes.MORE : NavigationTypes.MAIN })
    }

    return (
      <li className={styles['navigation-item']}>
        {url ? (
          <Link
            to={url}
            className={classNames(styles.item, {
              [styles.more]: isMore,
              [styles.selected]: isActiveItem
            })}
            onClick={() => {
              onClick?.()
              trackNavigationItem(url)
            }}
          >
            <NavigationItemContent {...contentProps} {...{ isMore, icon }} iconClass={styles.icon} />
          </Link>
        ) : (
          <button
            className={classNames(styles.item, {
              [styles.more]: isMore,
              [styles.selected]: isActiveItem
            })}
            onClick={() => {
              onClick?.()
              trackNavigationItem(id)
            }}
          >
            <NavigationItemContent {...contentProps} {...{ isMore, icon }} iconClass={styles.icon} />
          </button>
        )}
      </li>
    )
  }
)

NavigationItem.displayName = 'SidebarItem'

export default NavigationItem
