import styles from './LinkAndShareCard.module.scss'
import { useTranslation } from 'react-i18next'
import { LinkAndShareCardProps } from 'pages/App/referral/components/LinkAndShareCard/types'
import parse from 'html-react-parser'
import Card from 'components/Card/Card.tsx'
import { Button, Form } from '@boltenergy-be/design-system'
import Link from 'components/Link/Link.tsx'
import { useEffect, useState } from 'react'
import { useStoreSelector } from 'hooks/store.ts'
import { selectCurrentAccount } from 'store/contact/selectors.ts'
import classNames from 'classnames'
import mixpanel from 'mixpanel-browser'
import { ReferralEvents } from 'types/tracking.ts'

const LinkAndShareCard = ({ amount, shareLink = '', hasError, activeAction }: LinkAndShareCardProps) => {
  // Redux
  const { accounts, selected } = useStoreSelector((store) => store.contact)
  const { firstName } = selectCurrentAccount({ accounts, selected })

  // i18n
  const { t } = useTranslation(['referral', 'common'])

  // Local state
  const [copied, setCopied] = useState<boolean>(false)

  /**
   * Copies the share link to the users clipboard
   */
  const copyToClipboard = async () => {
    mixpanel.track(ReferralEvents.COPY)
    await navigator.clipboard.writeText(shareLink)
    setCopied(true)
  }

  // Revert copied state after 5 seconds
  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 5000)
    }
  }, [copied])

  // %0D%0A equals new line in email
  const sharing = {
    subject:
      activeAction?.attributes?.sharing?.subject ||
      t('share.subject', {
        firstName,
        reduction: amount
      }),
    body: (activeAction?.attributes?.sharing?.body || t('share.body', { reduction: amount })) + ` ${shareLink}`
  }

  // for messenger app: fb-messenger://share?link=${encodeURIComponent(shareLink)}&app_id=${FACEBOOK_APP_ID}
  // for browser: https://www.facebook.com/dialog/share?link=${encodeURI(shareLink)}&app_id=${messengerAppId}
  // Temporary disabled due to fb-messenger not working & browser share giving fb error
  //  const messengerShareLink = `https://www.facebook.com/dialog/send?link=${encodeURI(shareLink)}&app_id=${FACEBOOK_APP_ID}
  //  &redirect_uri=${window.location.href}`

  // for whatsapp app: whatsapp://send?text=
  // for whatsapp web: https://api.whatsapp.com/send/?text=
  const whatsappShareLink = `https://api.whatsapp.com/send/?text=${encodeURIComponent(sharing.subject as string)}%20${encodeURIComponent(
    shareLink
  )}`

  return (
    <Card as="section" className={styles['link-card']}>
      <Card.Title variant="h4">{t('share.heading')}</Card.Title>

      {hasError ? (
        <div>
          <p>{parse(t('common:error'))}</p>
        </div>
      ) : (
        <>
          <Form className={styles['link-group']}>
            <div className={classNames('grid-col-full', styles.link)}>
              <p>{t('share.link.label')}</p>
              <span>{shareLink}</span>
            </div>

            <Button type="button" onClick={copyToClipboard} className={styles.copy}>
              {t(`share.link.${copied ? 'copied' : 'copy'}`)}
            </Button>
          </Form>

          <div className={styles['link-card-share']}>
            <p>{t('share.orThrough')}</p>
            <div className={styles['share-buttons']}>
              {/* MAIL */}
              <Link
                href={`mailto:?subject=${encodeURIComponent(sharing.subject as string)}&body=${encodeURIComponent(sharing.body)}`}
                as="a"
                representation="button"
                className={styles['share-btn']}
                target="_blank"
                rel="noreferrer"
                leadingIcon="envelope"
                variant="secondary"
                onClick={() => mixpanel.track(ReferralEvents.SHARE, { channel: 'email' })}
              />

              {/* FB/Messenger */}
              {/*<Link*/}
              {/*  href={messengerShareLink}*/}
              {/*  as="a"*/}
              {/*  representation="button"*/}
              {/*  className={styles['share-btn']}*/}
              {/*  target="_blank"*/}
              {/*  rel="noreferrer"*/}
              {/*  leadingIcon="messenger"*/}
              {/*  variant="secondary"*/}
              {/*  onClick={() => mixpanel.track(ReferralEvents.SHARE, { channel: 'messenger' })}*/}
              {/*/>*/}

              {/* WHATSAPP */}
              <Link
                href={whatsappShareLink}
                as="a"
                representation="button"
                className={styles['share-btn']}
                target="_blank"
                rel="noreferrer"
                leadingIcon="whatsappFill"
                variant="secondary"
                onClick={() => mixpanel.track(ReferralEvents.SHARE, { channel: 'whatsapp' })}
              />
            </div>
          </div>
        </>
      )}
    </Card>
  )
}

export default LinkAndShareCard
