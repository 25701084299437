import i18next from 'i18next'
import { ScriptableLineSegmentContext } from 'chart.js'
import { CHART_COLORS, getBarChartDefaults } from 'components/Charts/constants'
import { createChartDataArray, formatChartDateLabel } from 'utils/chartjs'
import { capitalizeFirstLetter, formatAmount } from 'utils/format'
import { ConsumptionChartOptions, GetConsumptionChartDatasets } from './types'
import dayjs from 'dayjs'
import { EnergyType } from 'types/contracts.ts'
import { externalTooltipHandler } from 'components/Charts/chart-tooltip/tooltip.ts'
import { TOOLTIP_CONFIG } from 'components/Charts/chart-tooltip/config.ts'
import { getChartPattern } from 'components/Charts/billing-cycle/utils.ts'
import { DSChartColors } from 'types/colors.ts'

/**
 * Get consumption chart options
 * @param {ConsumptionChartOptions} params
 */
export const getConsumptionChartOptions = ({
  granularity,
  handleHoverDataPoint,
  historyModifier,
  isMobile,
  labels,
  language,
  minMax
}: ConsumptionChartOptions) => {
  const defaults = getBarChartDefaults({ isMobile })
  const {
    scales: { y, x },
    plugins
  } = defaults

  return {
    ...defaults,
    maintainAspectRatio: false,
    scales: {
      y: {
        ...y,
        suggestedMin: minMax?.minDataValue,
        suggestedMax: minMax?.maxDataValue,
        stacked: true,
        ticks: {
          ...y.ticks,
          callback: (value: number) => formatAmount(value, 0).toString()
        }
      },
      y1: {
        suggestedMin: minMax?.minDataValue,
        suggestedMax: minMax?.maxDataValue,
        type: 'linear',
        display: false,
        position: 'left',
        grace: '20%',
        border: { display: false },
        ticks: {
          ...y.ticks,
          callback: (value: number) =>
            value / historyModifier === 0
              ? '0'
              : `${(value / historyModifier)?.toLocaleString('nl-BE', { maximumFractionDigits: 3, minimumFractionDigits: 3 })}`
        },
        grid: {
          drawBorder: false,
          drawTicks: false,
          drawOnChartArea: false // only want the grid lines for one axis to show up
        }
      },
      x: {
        ...x,
        stacked: true,
        ticks: {
          ...x.ticks,
          callback: (value: number) => {
            return formatChartDateLabel(labels[value], granularity, language)
          }
        }
      }
    },
    plugins: {
      ...plugins,
      yScaleTitle: {
        labels: {
          y: 'kWh'
        }
      },
      tooltip: {
        ...TOOLTIP_CONFIG,
        external: (context: any) => {
          const { tooltip } = context
          const currentDataPoint = tooltip?.dataPoints?.[0]?.dataIndex
          const hideTooltip = tooltip.opacity === 0
          handleHoverDataPoint(hideTooltip ? null : currentDataPoint)
          return externalTooltipHandler({ context, totalColumns: labels.length, isMobile })
        },
        callbacks: {
          title: (context: any) => `${capitalizeFirstLetter(formatChartDateLabel(context?.[0]?.label, granularity, language, true))}`
        }
      }
    }
  }
}

export const getConsumptionChartDatasets = ({
  labels,
  data,
  type,
  showInjection,
  showEstimatedUsage,
  historyModifier,
  isMobile,
  hideHistory,
  hasSmartMeter
}: GetConsumptionChartDatasets) => {
  const borderRadius = isMobile ? 4 : 2
  const maxBarThickness = 94
  const isElek = type === EnergyType.ELECTRICITY

  const lineChart = {
    id: 'history',
    type: 'line' as const,
    label: i18next.t('consumption:chart.legend.marketPrice'),
    fill: false,
    data: createChartDataArray(
      labels?.length,
      data.map(({ history }) => {
        const value = (type === EnergyType.ELECTRICITY ? history?.electricity : history?.gas) || 0
        return typeof value === 'number' ? value * historyModifier : null
      })
    ),
    borderColor: CHART_COLORS.blue,
    tension: 0,
    pointBorderWidth: 0,
    pointRadius: 1,
    pointHoverRadius: 1,
    borderWidth: 2,
    borderCapStyle: 'round',
    pointBackgroundColor: CHART_COLORS.blue,
    pointStyle: 'rectRot',
    spanGaps: true,
    segment: {
      borderColor: ({ p0, p1 }: ScriptableLineSegmentContext) => (p0.skip || p1.skip ? 'transparent' : undefined),
      borderDash: ({ p1DataIndex }: ScriptableLineSegmentContext) => {
        const isInFuture = dayjs(labels[p1DataIndex]).isAfter(dayjs())
        return isInFuture ? [4, 8] : undefined
      }
    }
  }

  const barCharts = [
    {
      id: 'consumption-billed',
      type: 'bar' as const,
      label: i18next.t('consumption:chart.legend.usage'),
      backgroundColor: isElek ? CHART_COLORS.green : CHART_COLORS.purple,
      data: createChartDataArray(
        labels?.length,
        data.map(({ consumption }) => consumption.billed || 0)
      ),
      skipNull: true,
      hideNullTooltip: true,
      borderSkipped: 'middle',
      maxBarThickness,
      borderRadius,
      yAxisID: 'y'
    },
    ...(showEstimatedUsage && !!data?.some((entry) => entry.consumption.unbilled)
      ? [
          {
            id: 'consumption-unbilled',
            type: 'bar' as const,
            label: i18next.t(`consumption:chart.legend.${!hasSmartMeter ? 'estimated' : 'unbilled'}`),
            backgroundColor: getChartPattern(isElek ? DSChartColors.GREEN : DSChartColors.PURPLE),
            data: createChartDataArray(
              labels?.length,
              data.map(({ consumption }) => consumption.unbilled || 0)
            ),
            skipNull: true,
            hideNullTooltip: true,
            borderSkipped: 'middle',
            maxBarThickness,
            borderRadius,
            yAxisID: 'y'
          }
        ]
      : []),
    ...(isElek && showInjection
      ? [
          {
            id: 'injection-billed',
            type: 'bar' as const,
            label: i18next.t('consumption:chart.legend.injection'),
            backgroundColor: CHART_COLORS.yellow,
            data: createChartDataArray(
              labels?.length,
              data.map(({ injection }) => injection?.billed || 0)
            ),
            skipNull: true,
            hideNullTooltip: true,
            borderSkipped: 'middle',
            maxBarThickness,
            borderRadius,
            yAxisID: 'y'
          }
        ]
      : []),
    ...(isElek && showEstimatedUsage && !!data?.some((entry) => entry.injection?.unbilled) && showInjection
      ? [
          {
            id: 'injection-unbilled',
            type: 'bar' as const,
            label: i18next.t(`consumption:chart.legend.${!hasSmartMeter ? 'estimated' : 'unbilled'}`),
            backgroundColor: getChartPattern(DSChartColors.YELLOW),
            data: createChartDataArray(
              labels?.length,
              data.map(({ injection }) => injection?.unbilled || 0)
            ),
            skipNull: true,
            hideNullTooltip: true,
            borderSkipped: 'middle',
            maxBarThickness,
            borderRadius,
            yAxisID: 'y'
          }
        ]
      : [])
  ]
  return !hideHistory ? [lineChart, ...barCharts] : barCharts
}
