import { PropsWithChildren, useEffect, useState } from 'react'
import styles from './TooltipOnClick.module.scss'
import { TooltipOnHoverProps } from './types'
import { usePopper } from 'react-popper'
import classNames from 'classnames'

const TooltipOnHover = ({
  disabled,
  tooltipContent,
  containerStyles,
  children,
  tooltipClassname,
  delay
}: PropsWithChildren<TooltipOnHoverProps>) => {
  const [tooltipIsOpen, setTooltipOpen] = useState<boolean>(false)
  const [wrapperElement, setWrapperElement] = useState<HTMLDivElement | null>(null)
  const [tooltipElement, setTooltipElement] = useState<HTMLDivElement | null>(null)
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null)

  // Popper
  const { styles: popperStyles, attributes } = usePopper(wrapperElement, tooltipElement, {
    placement: 'top',
    modifiers: [
      { name: 'arrow', options: { element: arrowElement, padding: 10 } },
      { name: 'offset', options: { offset: [0, 0] } },
      { name: 'preventOverflow', options: { altAxis: true, padding: 10 } },
      { name: 'flip', options: { padding: { top: 40 } } }
    ]
  })

  /**
   * Mouse enter event listener
   */
  useEffect(() => {
    const onEnter = (): void => {
      if (!disabled) {
        if (delay) {
          setTimeout(() => {
            setTooltipOpen(true)
          }, delay)
        } else {
          setTooltipOpen(true)
        }
      }
    }

    wrapperElement?.addEventListener('mouseover', onEnter)

    return () => {
      wrapperElement?.removeEventListener('mouseover', onEnter)
    }
  }, [disabled, wrapperElement])

  /**
   * Mouse leave event listener
   */
  useEffect(() => {
    const onLeave = (): void => {
      setTooltipOpen(false)
    }

    wrapperElement?.addEventListener('mouseleave', onLeave)

    return () => {
      wrapperElement?.removeEventListener('mouseleave', onLeave)
    }
  }, [wrapperElement])

  return (
    <div className={classNames(styles['trigger-container'], containerStyles)}>
      <div ref={setWrapperElement}>{children}</div>
      {tooltipIsOpen && (
        <div
          ref={setTooltipElement}
          className={classNames(styles.tooltip, tooltipClassname)}
          style={{ ...popperStyles.popper }}
          {...attributes.popper}
        >
          {tooltipContent}
          <div className={styles.arrow} style={popperStyles.arrow} ref={setArrowElement} />
        </div>
      )}
    </div>
  )
}

export default TooltipOnHover
