import { useEffect, useState } from 'react'
import { EMAIL } from 'constants/regex'
import { routes } from 'types/routes'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { register as registerUser } from 'store/auth/thunks'
import { RegisterFormFieldsType } from './types'
import styles from '../Auth.module.scss'
import NeedHelp from 'pages/Auth/components/NeedHelp/NeedHelp'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import { Banner, Button, Form, Heading, TextField } from '@boltenergy-be/design-system'
import Link from 'components/Link/Link.tsx'
import { IS_BETA } from 'constants/envs.ts'
import { useNavigate } from 'react-router'

const Register = () => {
  // REDUX STORE
  const { loading, error } = useStoreSelector((store) => store.auth)
  const dispatch = useStoreDispatch()

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<RegisterFormFieldsType>({ mode: 'onBlur' })

  // Router
  const navigate = useNavigate()

  // i18n
  const { t } = useTranslation('auth')

  // Local state
  const [submitted, setSubmitted] = useState<boolean>(false)

  /**
   * Handles the form submit after validation
   *
   * @param {RegisterFormFieldsType} data
   */
  const onSubmit = async (data: RegisterFormFieldsType) => {
    // Register the user
    await dispatch(registerUser({ email: data.email.toLowerCase().trim() }))

    setSubmitted(true)
  }

  // Redirect to login if in beta (registration page inaccessible on beta)
  useEffect(() => {
    if (IS_BETA) {
      navigate(routes.LOGIN)
    }
  }, [])

  return (
    <>
      <div className={styles['auth-body']}>
        <header className={styles.header}>
          <Heading as="h1" variant="h3">
            {t('titles.register')}
          </Heading>
          <p className={styles.description}>{t('descriptions.register')}</p>
        </header>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            id="email"
            label={t('fields.email')}
            autoComplete="off"
            // Disable lastpass autofill
            data-lpignore="true"
            placeholder={t('fields.email')}
            {...register('email', {
              required: t('required', { ns: 'validation' }),
              pattern: { value: EMAIL, message: t('invalid.email', { ns: 'validation' }) }
            })}
            error={errors.email?.message}
          />

          <Button isFullwidth disabled={submitted && (!error || error === '')} loading={loading}>
            {t('buttons.register.submit')}
          </Button>
        </Form>

        {!loading && submitted && (
          <Banner className={styles.banner} type="positive">
            {t('messages.successEmail')}
          </Banner>
        )}

        <div className={styles['links-list']}>
          <Link as="a" representation="button" variant="secondary" href="https://www.boltenergie.be?simulation=1" target="_blank">
            {t('buttons.register.notACustomer')}
          </Link>

          <Link representation="link" variant="secondary" href={routes.LOGIN}>
            {t('buttons.backToLogin')}
          </Link>
        </div>
      </div>

      <footer className={styles['auth-footer']}>
        <NeedHelp />
      </footer>
    </>
  )
}

export default Register
