import { ReactElement, useEffect, useState } from 'react'
import { IndexesFormInputsProps, IndexesFormType } from './types'
import { useTranslation } from 'react-i18next'
import { ProductType, Register, TimeframeCode } from 'types/types'
import { useFormContext } from 'react-hook-form'
import { enumKeys } from 'utils/objects'
import { Direction, MeterReadingSource } from 'types/contracts'
import styles from './IndexesFormInputs.module.scss'
import { EXTRA_CONSUMPTION_LIMIT_EL, EXTRA_CONSUMPTION_LIMIT_GAS } from 'pages/App/billing/billing-cycles/constants'
import { Heading, Icon, TextField } from '@boltenergy-be/design-system'
import { useStoreSelector } from 'hooks/store.ts'

const IndexesFormsInputs = ({
  serviceContracts,
  meterReadingsData,
  shouldRenderAllInputsIfNoPreviousIndexes,
  showEan = false,
  showLastMeterReadingByDgo = false,
  explicitRequired = false,
  setLowerCheck,
  setHigherCheck
}: IndexesFormInputsProps) => {
  // REDUX STORE
  const {
    meterReadings: { data, loading }
  } = useStoreSelector((store) => store.contracts)

  // React hook form context
  const {
    register,
    formState: { errors }
  } = useFormContext<{ indexes: IndexesFormType }>()

  // i18n
  const { t } = useTranslation()

  // Local state
  const [indexStateCheck, setIndexStateCheck] = useState<{ [key: string]: 'LOW' | 'HIGH' | 'OK' }>({})

  // Constants
  const meterReadings = meterReadingsData ?? data
  const { electricity, gas } = serviceContracts
  const hidden = !electricity.deliveryPoint.previousIndex && !shouldRenderAllInputsIfNoPreviousIndexes

  /**
   * Returns the correct meter reading entry based on the given product type & timeframe code
   *
   * @param {ProductType} type
   * @param {Direction} direction
   * @param {TimeframeCode} timeframeCode
   * @returns {number|undefined}
   */
  const getMeterReadingEntry = (type: ProductType, direction: Direction, timeframeCode: TimeframeCode): number | undefined => {
    if (type === ProductType.GAS) return meterReadings?.gas?.consumption?.singleRate

    switch (timeframeCode) {
      case TimeframeCode.HIGH:
        return meterReadings?.electricity?.[direction === Direction.CONSUMPTION ? 'consumption' : 'injection']?.doubleRate?.day

      case TimeframeCode.LOW:
        return meterReadings?.electricity?.[direction === Direction.CONSUMPTION ? 'consumption' : 'injection']?.doubleRate?.night

      case TimeframeCode.NIGHT_EXCLUSIVE:
        return meterReadings?.electricity?.[direction === Direction.CONSUMPTION ? 'consumption' : 'injection']?.exclNight

      default:
        return meterReadings?.electricity?.[direction === Direction.CONSUMPTION ? 'consumption' : 'injection']?.singleRate
    }
  }

  /**
   * Checks if one of the given values is too high or low
   */
  useEffect(() => {
    const states = Object.values(indexStateCheck)
    if (states.every((value) => value === 'OK')) {
      setLowerCheck?.(false)
      setHigherCheck?.(false)
    } else {
      setLowerCheck?.(states.some((value) => value === 'LOW'))
      setHigherCheck?.(states.some((value) => value === 'HIGH'))
    }
  }, [indexStateCheck, setHigherCheck, setLowerCheck])

  /**
   * Returns the register form group for the given type, register & index
   *
   * @param {ProductType} type
   * @param {Direction} direction
   * @param {TimeframeCode} timeframeCode
   * @param {number} index
   * @param {boolean} required
   * @returns {ReactElement}
   */
  const getRegisterFormGroup = (
    type: ProductType,
    direction: Direction,
    timeframeCode: TimeframeCode,
    index: number,
    required: boolean
  ): ReactElement => {
    const isGas = type === ProductType.GAS

    return (
      <div className="form-group" key={index}>
        <label htmlFor={`indexes.${type}.${index}`} className={styles['index-label']}>
          <span>
            {t(`${direction === Direction.PRODUCTION ? 'injection' : type}`)}{' '}
            {timeframeCode !== TimeframeCode.TOTAL_HOUR && t(`indexes.${timeframeCode as 'HIGH' | 'LOW' | 'NIGHT_EXCLUSIVE'}`)}
            {!isGas && (
              <>{[TimeframeCode.LOW, TimeframeCode.NIGHT_EXCLUSIVE].includes(timeframeCode) ? <Icon name="moon" /> : <Icon name="sun" />}</>
            )}
          </span>

          {showEan && (
            <span className={styles.ean}>
              {t('ean')}: {(isGas ? gas : electricity)?.deliveryPoint?.ean}
            </span>
          )}
        </label>

        <TextField
          id={`indexes.${type}.${index}`}
          label=""
          {...register(`indexes.${type}.${index}`, {
            required: required ? t('required', { ns: 'validation' }) : false,
            min: {
              value: 0,
              message: t('min', { min: 0, ns: 'validation' })
            }
          })}
          error={errors?.indexes?.[type]?.[index]?.message}
          grid="full"
          suffix={{ as: ',000', className: styles.bordered }}
          onWheel={(e) => e.currentTarget.blur()}
          onBlur={(event) => {
            if (
              showLastMeterReadingByDgo &&
              meterReadings?.[type]?.source === MeterReadingSource.EOD_DGO &&
              (setLowerCheck || setHigherCheck)
            ) {
              const meterReadingEntry = getMeterReadingEntry(type, direction, timeframeCode)
              const key = `${type}-${direction}-${timeframeCode}`
              if (event.target.valueAsNumber < (meterReadingEntry ?? 0)) {
                setIndexStateCheck({ ...indexStateCheck, [key]: 'LOW' })
              } else if (
                event.target.valueAsNumber >
                (meterReadingEntry ?? 0) + (isGas ? EXTRA_CONSUMPTION_LIMIT_GAS : EXTRA_CONSUMPTION_LIMIT_EL)
              ) {
                setIndexStateCheck({ ...indexStateCheck, [key]: 'HIGH' })
              } else {
                setIndexStateCheck({ ...indexStateCheck, [key]: 'OK' })
              }
            }
          }}
        />

        {showLastMeterReadingByDgo && !loading && meterReadings?.[type]?.source === MeterReadingSource.EOD_DGO && (
          <div className={styles['last-meter-reading']}>
            <span>
              <span className={styles['last-meter-reading-label']}>{t('lastKnownMeterReading')}:</span>{' '}
              {getMeterReadingEntry(type, direction, timeframeCode)?.toFixed(0)}
            </span>
          </div>
        )}
      </div>
    )
  }

  return !hidden ? (
    <div className={styles.indexes}>
      {electricity.deliveryPoint.previousIndex ? (
        <>
          <div className={styles.electricity}>
            <Heading as="h3" variant="h6" weight={600}>
              {t('indexes.title')} {t('electricity').toLowerCase()}
            </Heading>

            {electricity.deliveryPoint.previousIndex.registers
              .filter((indexRegister: Register) => indexRegister.timeframeCode !== TimeframeCode.NOT_USED)
              .map((indexRegister: Register, index: number) =>
                getRegisterFormGroup(
                  ProductType.ELECTRICITY,
                  indexRegister.direction,
                  indexRegister.timeframeCode,
                  index,
                  explicitRequired ?? true
                )
              )}
          </div>

          {gas && (
            <div className={styles.gas}>
              <Heading as="h3" variant="h6" weight={600}>
                {t('indexes.title')} {t('gas').toLowerCase()}
              </Heading>

              {gas?.deliveryPoint?.previousIndex?.registers
                .filter((indexRegister: Register) => indexRegister.timeframeCode !== TimeframeCode.NOT_USED)
                .map((indexRegister: Register, index: number) =>
                  getRegisterFormGroup(
                    ProductType.GAS,
                    indexRegister.direction,
                    indexRegister.timeframeCode,
                    index,
                    explicitRequired ?? true
                  )
                )}
            </div>
          )}
        </>
      ) : (
        shouldRenderAllInputsIfNoPreviousIndexes && (
          <>
            <div className={styles.electricity}>
              <Heading as="h3" variant="h6" weight={600}>
                {t('indexes.title')} {t('electricity').toLowerCase()}
              </Heading>

              {enumKeys(TimeframeCode)
                .filter((key) => TimeframeCode[key] !== TimeframeCode.NOT_USED)
                .map((key, index: number) =>
                  getRegisterFormGroup(ProductType.ELECTRICITY, Direction.CONSUMPTION, TimeframeCode[key], index, explicitRequired ?? false)
                )}
            </div>

            {gas && (
              <div className={styles.gas}>
                <h3>
                  {t('indexes.title')} {t('gas').toLowerCase()}
                </h3>

                {getRegisterFormGroup(ProductType.GAS, Direction.CONSUMPTION, TimeframeCode.TOTAL_HOUR, 0, false)}
              </div>
            )}
          </>
        )
      )}
    </div>
  ) : null
}

export default IndexesFormsInputs
