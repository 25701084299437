import styles from './ArrowButton.module.scss'
import { ArrowButtonProps } from 'components/Slider/ArrowButton/types'
import classNames from 'classnames'
import { Icon } from '@boltenergy-be/design-system'

const SliderArrow = ({ action, onClick, disabled }: ArrowButtonProps) => {
  return (
    <button
      className={classNames(styles.arrow, { [styles.previous]: action === 'previous' }, { [styles.next]: action === 'next' })}
      onClick={onClick}
      disabled={disabled}
    >
      {action === 'previous' ? <Icon name="chevronLeft" /> : <Icon name="chevronRight" />}
    </button>
  )
}

export default SliderArrow
