import parse from 'html-react-parser'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import mixpanel from 'mixpanel-browser'
import styles from './Index.module.scss'
import { Button, Heading } from '@boltenergy-be/design-system'
import { routes } from 'types/routes'
import { UserTypes } from 'store/auth/types'
import { AddAddress, TerminateAddress } from 'assets/svg/contracts'
import { isInactiveContract } from 'utils/contracts'
import Card from 'components/Card/Card.tsx'
import ContractsLayout from 'features/contracts/layout/ContractsLayout.tsx'
import { ContractsIconAccentColors } from 'assets/svg/contracts/types.ts'
import { formatAddress } from 'utils/user.ts'
import { useStoreDispatch, useStoreSelector } from 'hooks/store.ts'
import { setMoveFlow } from 'store/app/slice.ts'
import { ContractFlowEvents, ContractFlowStartPoints, ContractFlowTrackingTypes } from 'types/tracking.ts'
import { MoveFlows } from 'store/app/types.ts'
import classNames from 'classnames'
import { useEffect } from 'react'
import { IS_BETA } from 'constants/envs.ts'
import { AddressFormats } from 'types/format.ts'

const Index = () => {
  // Redux
  const { userType } = useStoreSelector((store) => store.auth)
  const { move } = useStoreSelector((store) => store.app)
  const { selected, billingContracts } = useStoreSelector((store) => store.contact)
  const isSales = userType === UserTypes.SALES
  const dispatch = useStoreDispatch()

  // Contracts
  const contracts = !isSales ? billingContracts[selected.billingContract] : undefined
  const inactiveContract = contracts?.serviceContracts?.electricity ? isInactiveContract(contracts.serviceContracts.electricity) : false

  // i18n
  const { t } = useTranslation('contracts')

  // React router
  const navigate = useNavigate()

  // Redirect to login if in beta (contracts flow inaccessible on beta)
  useEffect(() => {
    if (IS_BETA) {
      navigate(routes.LOGIN)
    }
  }, [])

  /**
   * Tracks the contract flow from the contract overview with a custom flow
   * @param {string} flow
   * @param {(typeof ContractFlowTrackingTypes} type
   */
  const trackByFlow = (flow: string, type: ContractFlowTrackingTypes) => {
    mixpanel.track(ContractFlowEvents.START, {
      flow,
      type,
      from: ContractFlowStartPoints.CONTRACT_OVERVIEW
    })
  }

  return (
    <ContractsLayout label={t('header.labels.move')} flow={undefined}>
      <section className={styles.container}>
        <header className="grid-col-full">
          <Heading as="h1" variant="h4">
            {t('index.title')}
          </Heading>
          <p>{t('index.description')}</p>
        </header>

        <Card border={false} className={styles['action-card']}>
          <header>
            <AddAddress accent={ContractsIconAccentColors.PRIMARY} />
            <Heading as="h3" variant="h5">
              {t('index.move.title')}
            </Heading>
          </header>
          <p>{t('index.move.description')}</p>
          <Button
            isFullwidth
            className={styles.button}
            onClick={() => {
              const flow = MoveFlows.START_STOP
              trackByFlow(flow, ContractFlowTrackingTypes.ADD)
              dispatch(setMoveFlow(flow))
              navigate(isSales ? routes.SALES_CONTRACTS_ADD : routes.CONTRACTS_MOVE)
            }}
          >
            {t('index.move.action')}
          </Button>
        </Card>

        {!isSales && !inactiveContract && (
          <Card border={false} className={styles['action-card']}>
            <header>
              <TerminateAddress accent={ContractsIconAccentColors.PRIMARY} />
              <Heading as="h3" variant="h5">
                {t('index.terminate.title')}
              </Heading>
            </header>
            <p>{t('index.terminate.description')}</p>

            {!!contracts?.serviceContracts.electricity?.deliveryPoint?.address && (
              <address className={styles.address}>
                {parse(formatAddress(contracts.serviceContracts.electricity.deliveryPoint.address, AddressFormats.FULL_TWO_LINES) || '')}
              </address>
            )}

            <Button
              isFullwidth
              className={styles.link}
              onClick={() => {
                const flow = !move.started ? MoveFlows.STOP_START : MoveFlows.START_STOP
                trackByFlow(flow, ContractFlowTrackingTypes.TERMINATE)
                dispatch(setMoveFlow(flow))
                navigate(routes.CONTRACTS_TERMINATE)
              }}
            >
              {t('index.terminate.action')}
            </Button>
          </Card>
        )}

        <small className={classNames(styles.support, 'grid-col-full')}>{parse(t('index.support'))}</small>
      </section>
    </ContractsLayout>
  )
}

export default Index
