import { useMemo } from 'react'
import styles from 'components/Charts/Charts.module.scss'
import { Chart } from 'react-chartjs-2'
import { ConsumptionChartProps } from './types'
import { getConsumptionChartOptions } from './chartjs.config'
import { useStoreSelector } from 'hooks/store.ts'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'
import classNames from 'classnames'

const ConsumptionChartMobile = ({
  activeEnergyType,
  chartData,
  isFetchingVolumes,
  labels,
  minMaxData,
  priceHistoryModifier,
  granularity,
  setHoverDataPointIndex,
  compact
}: ConsumptionChartProps) => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)

  const options = useMemo<any>(
    () =>
      getConsumptionChartOptions({
        labels,
        handleHoverDataPoint: setHoverDataPointIndex,
        historyModifier: priceHistoryModifier,
        granularity,
        language,
        minMax: minMaxData,
        isMobile: true,
        fuelType: activeEnergyType
      }),
    [activeEnergyType, granularity, setHoverDataPointIndex, labels, language, minMaxData, priceHistoryModifier]
  )

  return !isFetchingVolumes ? (
    <div className={classNames(styles['chart-wrapper'], { [styles.compact]: !!compact })}>
      <Chart type="bar" options={options} data={chartData} className={styles.chart} />
    </div>
  ) : (
    <LoadingSkeleton>
      <LoadingSkeleton.Rectangle aspectRatio="2.5 / 1" />
    </LoadingSkeleton>
  )
}

export default ConsumptionChartMobile
