import { TransfoErrorCodes } from 'types/billShock.ts'
import { Banner } from '@boltenergy-be/design-system'
import { useTranslation } from 'react-i18next'
import { BillshockErrorProps } from './types.ts'

const BillshockError = ({ error }: BillshockErrorProps) => {
  // i18n
  const { t } = useTranslation()

  // Constants
  const code = typeof error === 'object' ? ('status' in error ? error.status.toString() : error.code) : error

  return typeof code === 'string' &&
    [
      TransfoErrorCodes.E304_CONTRACT_TOO_YOUNG,
      TransfoErrorCodes.E431_NO_INVOICE_FOUND,
      TransfoErrorCodes.E442_NEAR_METERREADING_MONTH,
      TransfoErrorCodes.E443_NO_FUTURE_INSTALMENTS,
      TransfoErrorCodes.E454_MISSING_SERVICE_DELIVERIES
    ].includes(code as TransfoErrorCodes) ? (
    <Banner type="informative">
      {t(
        `transfoErrors.${
          code as
            | TransfoErrorCodes.E304_CONTRACT_TOO_YOUNG
            | TransfoErrorCodes.E431_NO_INVOICE_FOUND
            | TransfoErrorCodes.E442_NEAR_METERREADING_MONTH
            | TransfoErrorCodes.E443_NO_FUTURE_INSTALMENTS
            | TransfoErrorCodes.E454_MISSING_SERVICE_DELIVERIES
        }`
      )}
    </Banner>
  ) : (
    <Banner type="warning">
      <p>{t('errorTryLater')}</p>
    </Banner>
  )
}

export default BillshockError
