import { Heading, Icon } from '@boltenergy-be/design-system'
import Card from 'components/Card/Card.tsx'
import { formatAmount } from 'utils/format.ts'
import Link from 'components/Link/Link.tsx'
import { routes } from 'types/routes.ts'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'
import styles from './UsageCard.module.scss'
import { UsageCardProps, UsageConfig } from './types.ts'
import { useTranslation } from 'react-i18next'
import { Fragment } from 'react'
import mixpanel from 'mixpanel-browser'
import { BillingEvents } from 'types/tracking.ts'

const UsageCard = ({ title, electricity, injection, gas, canAddMeterReadings, isLoading }: UsageCardProps) => {
  // i18n
  const { t } = useTranslation()

  // Config
  const USAGE_CONFIG: UsageConfig = {
    electricity: {
      color: '--accent-brand',
      icon: 'electricity',
      label: t('electricity'),
      value: electricity
    },
    injection: {
      color: '--accent-yellow',
      icon: 'solarPanel',
      label: t('injection'),
      value: injection
    },
    gas: {
      color: '--accent-purple',
      icon: 'gas',
      label: t('gas'),
      value: gas
    }
  }

  return (
    <Card as="section" className={styles.card} padding={{ desktop: 600, mobile: 400 }}>
      <Heading as="h1" variant="h6" weight={600}>
        {title}
      </Heading>

      {isLoading ? (
        <LoadingSkeleton>
          <LoadingSkeleton.Rectangle height={56} />
        </LoadingSkeleton>
      ) : (
        Object.values(USAGE_CONFIG).map(
          ({ color, icon, label, value }) =>
            typeof value === 'number' && (
              <Fragment key={label}>
                <div className={styles.entry}>
                  <Icon name={icon} style={{ color: `var(${color})` }} />
                  <strong>{label}</strong>
                  <data value={value}>{formatAmount(value)} kWh</data>
                </div>
                <hr />
              </Fragment>
            )
        )
      )}

      {canAddMeterReadings && (
        <Link
          href={routes.CONSUMPTION_EDIT_METER_READINGS}
          loading={isLoading}
          representation="button"
          variant="secondary"
          isFullwidth
          className={styles['meter-readings']}
          onClick={() => mixpanel.track(BillingEvents.GO_TO_ADD_METER_READING)}
        >
          {t('billingCycle.usage.addMeterReadings', { ns: 'billing' })}
        </Link>
      )}
    </Card>
  )
}

export default UsageCard
