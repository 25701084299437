import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './utils'
import { TagTypes } from './types'

export const boltApi = createApi({
  reducerPath: 'boltApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: Object.values(TagTypes),
  endpoints: () => ({}) // Endpoints are injected in the subfolders. Naming is based on the API route.
})
