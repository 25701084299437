import classNames from 'classnames'
import styles from './OpportunitiesListItem.module.scss'
import { Icon } from '@boltenergy-be/design-system'
import { opportunityListItemProps } from './types'
import { useNavigate } from 'react-router-dom'
import { routes } from 'types/routes'
import MappedContractStatus from 'pages/Sales/components/mapped-contract-status/MappedContractStatus.tsx'
import { OpportunityStage } from 'types/contacts.ts'

const OpportunitiesListItem = ({ opportunity, isClickable = true }: opportunityListItemProps) => {
  // React router
  const navigate = useNavigate()

  /**
   * Returns the color classname based on the stage
   *
   * @param {OpportunityStage} stage
   * @returns {string}
   */
  const getStageStatusColorClassName = (stage: OpportunityStage): string | undefined => {
    switch (stage) {
      // Warning
      case OpportunityStage.REGISTRATION_STARTED:
      case OpportunityStage.TO_BE_CONFIRMED:
      case OpportunityStage.NO_EAN:
        return styles.warning

      // Negative
      case OpportunityStage.CLOSED_LOST:
        return styles.negative

      default:
        return undefined
    }
  }

  /**
   * Function to handle the key press event
   *
   * @param {React.KeyboardEvent<HTMLDivElement>} event - click event
   * @param {contactId} contactId - id of the clicked contact
   */
  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>, contactId: string) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onOpportunityClick(contactId)
    }
  }

  /**
   * Function to determine where to redirect when clicking on an opportunity
   *
   * @param {string} contactId
   */
  const onOpportunityClick = (contactId: string) => {
    if (isClickable) {
      navigate(routes.SALES_DETAILS.replace(':contactId', contactId))
    }
  }

  return (
    <div
      className={styles['opportunity-interactive-element']}
      role="button"
      tabIndex={0}
      onClick={() => onOpportunityClick(opportunity.contactId)}
      onKeyDown={(event) => handleKeyPress(event, opportunity.contactId)}
    >
      <div className={styles['opportunity-owner']}>
        <strong className={styles['item-owner']}>{opportunity.name}</strong>
        <small>{opportunity.postalCode}</small>
      </div>

      <div>
        {opportunity.serviceContracts.electricity.ean && (
          <div className={styles.icon}>
            <Icon name="electricity" />
            <small>{opportunity.serviceContracts.electricity.ean}</small>
          </div>
        )}
        {opportunity.serviceContracts.gas?.ean && (
          <div className={styles.icon}>
            <Icon name="gas" />
            <small>{opportunity.serviceContracts.gas?.ean}</small>
          </div>
        )}
      </div>

      {opportunity.stage === 'Closed Won' ? (
        <div className={styles['opportunity-status']}>
          <MappedContractStatus status={opportunity.serviceContracts.electricity.status} />

          {opportunity.serviceContracts.gas && <MappedContractStatus status={opportunity.serviceContracts.gas.status} />}
        </div>
      ) : (
        <strong className={classNames(styles['opportunity-status'], getStageStatusColorClassName(opportunity.stage))}>
          {opportunity.stage}
        </strong>
      )}
    </div>
  )
}

export default OpportunitiesListItem
