import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import mixpanel from 'mixpanel-browser'
import { ProductType } from 'types/types.ts'
import dayjs from 'dayjs'
import styles from './OfftakeAddressDetails.module.scss'
import { Banner, Button, Heading, Toggle } from '@boltenergy-be/design-system'
import { routes } from 'types/routes.ts'
import parse from 'html-react-parser'
import { isInactiveContract } from 'utils/contracts.ts'
import { Language, MoveFlows } from 'store/app/types.ts'
import { useGetProductContentQuery } from 'store/queries/cms-api'
import { getProductFromProductCode } from 'utils/products.ts'
import Card from 'components/Card/Card.tsx'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'
import Charts from 'assets/svg/Charts.tsx'
import {
  ContractFlowEvents,
  ContractFlowStartPoints,
  ContractFlowTrackingTypes,
  SmartMeterOptionEvents,
  SmartMeterOptionTrackingParams
} from 'types/tracking.ts'
import SmartMeterOption from 'pages/App/addresses/offtake-address/components/SmartMeterOption/SmartMeterOption'
import { SmartMeterOptions } from 'pages/App/addresses/offtake-address/types'
import { Calendar } from 'assets/svg'
import { patchServiceContract } from 'api/contracts.ts'
import { log } from 'utils/logging.ts'
import { Flow } from 'types/logging.ts'
import Bugsnag from '@bugsnag/js'
import {
  checkHasSmartMeterOptionsForContract,
  checkIsDynamicTariffEligible,
  checkIsMonthlyBilling,
  checkIsMonthlyBillingEligible
} from 'utils/smartBillingOptions.ts'
import { IS_BETA, WEBSITE_URL } from 'constants/envs.ts'
import { useStoreDispatch, useStoreSelector } from 'hooks/store.ts'
import { setMoveFlow } from 'store/app/slice.ts'
import Link from 'components/Link/Link.tsx'
import { selectCurrentAccount } from 'store/contact/selectors.ts'
import { EnergyType, UnknownServiceContract } from 'types/contracts.ts'
import { AddressDetailProps } from '../types'
import { useNavigate } from 'react-router-dom'
import ContractDetailView from './components/ContractDetailView/ContractDetailView'
import useWindowSize from 'hooks/useWindowSize'
import NoConnectionCard from './components/NoConnectionCard/NoConnectionCard'
import Modal from 'components/Modals/Modal/Modal'
import { getLowerCaseLanguage } from 'utils/app.ts'

const OfftakeAddressDetails = ({ contract }: AddressDetailProps) => {
  // REDUX STORE
  const { language } = useStoreSelector((store) => store.app)
  const contactStore = useStoreSelector((store) => store.contact)
  const dispatch = useStoreDispatch()

  // Redux queries
  const { data: productsContent, isLoading: loadingProductsContent } = useGetProductContentQuery({ language })

  // i18n
  const { t } = useTranslation(['contract', 'addresses'])

  // React router
  const navigate = useNavigate()

  // Custom hooks
  const { isTablet } = useWindowSize()

  // Local state
  const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false)
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false)
  const [isErrored, setIsErrored] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [extraOptionsEditable, setExtraOptionsEditable] = useState<boolean>(false)
  const [selectedEnergyType, setSelectedEnergyType] = useState<ProductType>(ProductType.ELECTRICITY)

  // Constants
  const account = selectCurrentAccount(contactStore)
  const { serviceContracts } = contract
  const { electricity, gas } = serviceContracts
  const inactiveContract = isInactiveContract(electricity)
  const elProduct = getProductFromProductCode(electricity.detail.productCode)
  const hasExtraSmartMeterOptions = checkHasSmartMeterOptionsForContract(electricity)
  const isDynamicTariffEligible = checkIsDynamicTariffEligible({ serviceContracts, product: elProduct })
  const isMonthlyBillingEligible = checkIsMonthlyBillingEligible({ serviceContracts, product: elProduct })
  const isDynamicTariff = electricity.detail.dynamicTariff
  const isMonthlyBilling = checkIsMonthlyBilling(isMonthlyBillingEligible, electricity, gas)
  const containerRef = useRef<HTMLDivElement>(null)

  // Local form state
  const [options, setOptions] = useState<{ monthly: boolean; dynamic: boolean }>({
    dynamic: !!isDynamicTariff,
    monthly: !!isMonthlyBilling
  })

  /**
   * Activates or deactivates the monthly billing & dynamic tariff
   */
  const updateSmartMeterOptions = async () => {
    setIsLoading(true)

    // Track event in mixpanel
    mixpanel.track(SmartMeterOptionEvents.EDIT_OPTIONS_CONFIRMED, {
      [SmartMeterOptions.MONTHLY_BILLING]: options.monthly,
      [SmartMeterOptions.DYNAMIC_TARIFF]: options.dynamic
    })

    try {
      const contractsToUpdate: UnknownServiceContract[] = [electricity]

      // Only update gas contract if it exists and is eligible for monthly billing (gas contracts can't have dynamic)
      if (gas && isMonthlyBillingEligible.gas) {
        contractsToUpdate.push(gas)
      }

      Bugsnag.addMetadata('contractsToUpdate', contractsToUpdate)

      const res = await Promise.all(
        contractsToUpdate.map((contract) => {
          // Define body
          const body =
            contract.detail.energyType === EnergyType.ELECTRICITY
              ? {
                  monthlyBilling: options.monthly,
                  dynamicTariff: options.dynamic
                }
              : {
                  monthlyBilling: options.monthly
                }

          // Add body to the meta data (for logging if error)
          Bugsnag.addMetadata('requestData', body)

          // Return the patch request promise
          return patchServiceContract(contract.contractNumber, body)
        })
      )

      if (res.every(({ success }) => success)) {
        setIsConfirmed(true)
      } else {
        setIsErrored(true)
        resetOptions()

        const msg = res.find(({ message }) => message)?.message

        log({
          error: 'ApiError',
          metaData: {
            data: {
              message: msg
            }
          },
          identifier: `[${Flow.CONTRACT}:patchServiceContract]`
        })
      }
    } catch (error) {
      setIsErrored(true)
      resetOptions()

      log({ error: error as Error, identifier: `[${Flow.CONTRACT}:patchServiceContract]` })
    }

    setConfirmationModalOpen(false)
    setExtraOptionsEditable(false)
    setIsLoading(false)
  }

  /**
   * Resets the options for the contracts
   */
  const resetOptions = () => {
    setOptions({
      dynamic: !!isDynamicTariff,
      monthly: !!isMonthlyBilling
    })
  }

  /**
   * Checks the difference between the current options and the original options
   * @returns {{dynamic: boolean, monthly: boolean}}
   */
  const checkOptionsDifferenceWithOriginal = () => {
    return {
      monthly: isMonthlyBilling !== options.monthly,
      dynamic: isDynamicTariff !== options.dynamic
    }
  }

  return (
    <>
      {/* MY CONNECTIONS BLOCK */}
      <section className={styles.connections}>
        <header className={styles.header}>
          <small>{t('myContract.currentPlan')}</small>
          {!elProduct || loadingProductsContent ? (
            <LoadingSkeleton className={styles['product-loader']}>
              <LoadingSkeleton.Rectangle width={240} height={20} />
            </LoadingSkeleton>
          ) : (
            <Heading as="h5">Bolt {productsContent?.[elProduct]?.name}</Heading>
          )}
        </header>

        <Card className={styles.card}>
          {isTablet && (
            <Toggle
              className={styles.toggle}
              options={[
                { label: t('electricity', { ns: 'common' }), value: ProductType.ELECTRICITY },
                { label: t('gas', { ns: 'common' }), value: ProductType.GAS }
              ]}
              onClick={(value) => {
                setSelectedEnergyType(value !== ProductType.GAS ? ProductType.ELECTRICITY : ProductType.GAS)
              }}
              active={selectedEnergyType}
            />
          )}

          {isTablet ? (
            <>
              {serviceContracts[selectedEnergyType] ? (
                <ContractDetailView serviceContract={serviceContracts[selectedEnergyType]!} />
              ) : (
                <NoConnectionCard />
              )}
            </>
          ) : (
            <div className={styles['contract-table-wrapper']}>
              <section>
                <Heading as="h1" variant="h5">
                  {t('electricity', { ns: 'common' })}
                </Heading>
                <ContractDetailView serviceContract={electricity} />
              </section>
              <section>
                <Heading as="h1" variant="h5">
                  {t('gas', { ns: 'common' })}
                </Heading>
                {gas ? <ContractDetailView serviceContract={gas} /> : <NoConnectionCard />}
              </section>
            </div>
          )}
        </Card>
      </section>

      {/* SOCIAL TARIFF */}
      {!!account?.attestations?.length && account.attestations.some((attestation) => attestation.ean === electricity.deliveryPoint.ean) && (
        <Banner as="section" type="informative" title={t('myContract.socialTarif.title')} className={styles['social-tariff']}>
          <p>{t('myContract.socialTarif.description')}:</p>

          <ul className="my-200">
            {account.attestations.map((attestation, index) => (
              <li key={index}>
                {dayjs(attestation.fromDate).format('DD/MM/YYYY')} {t('myContract.socialTarif.till')}{' '}
                {dayjs(attestation.toDate).format('DD/MM/YYYY')}
              </li>
            ))}
          </ul>

          <Link
            href={
              language === Language.NL
                ? 'https://economie.fgov.be/nl/themas/energie/energieprijzen/sociaal-tarief-voor-energie'
                : 'https://economie.fgov.be/fr/themes/energie/prix-de-lenergie/tarif-social-pour-lenergie'
            }
            external
            variant="secondary"
            className={styles['more-info']}
          >
            {t('myContract.socialTarif.moreInfo')}
          </Link>
        </Banner>
      )}

      {hasExtraSmartMeterOptions && (
        <section className={styles['smart-meter-options']}>
          <Heading as="h5" weight={400}>
            {t('myContract.smartMeterOptions.title')}
          </Heading>

          {/* DYNAMIC TARIFF */}
          {isDynamicTariffEligible && (
            <SmartMeterOption
              title={t('myContract.smartMeterOptions.dynamicTariff.title')}
              option={SmartMeterOptions.DYNAMIC_TARIFF}
              icon={<Charts />}
              isActive={options.dynamic}
              readMoreUrl={`${WEBSITE_URL}/${getLowerCaseLanguage(language)}/dynamic-prices`}
              onChange={(state) => {
                mixpanel.track(SmartMeterOptionEvents.EDIT_OPTION_TOGGLED, {
                  [SmartMeterOptionTrackingParams.OPTION]: SmartMeterOptions.DYNAMIC_TARIFF,
                  [SmartMeterOptionTrackingParams.NEW_VALUE]: options.dynamic
                })

                setOptions({ ...options, dynamic: state })
              }}
              editable={extraOptionsEditable}
            />
          )}

          {/* MONTHLY BILLING */}
          {isMonthlyBillingEligible.electricity && (
            <SmartMeterOption
              title={t(`myContract.smartMeterOptions.monthlyBilling.title.${isMonthlyBillingEligible.gas ? 'elAndGas' : 'elOnly'}`)}
              option={SmartMeterOptions.MONTHLY_BILLING}
              icon={<Calendar />}
              isActive={options.monthly}
              editable={extraOptionsEditable}
              readMoreUrl={`${WEBSITE_URL}/${getLowerCaseLanguage(language)}/dynamic-prices`}
              onChange={(state) => {
                mixpanel.track(SmartMeterOptionEvents.EDIT_OPTION_TOGGLED, {
                  [SmartMeterOptionTrackingParams.OPTION]: SmartMeterOptions.MONTHLY_BILLING,
                  [SmartMeterOptionTrackingParams.NEW_VALUE]: options.monthly
                })

                setOptions({ ...options, monthly: state })
              }}
            />
          )}

          {(isConfirmed || isErrored) && (
            <Banner
              type={isErrored ? 'blocking' : 'positive'}
              title={isErrored ? t('myContract.smartMeterOptions.error.title') : t('myContract.smartMeterOptions.confirmation.title')}
            >
              <p>
                {parse(
                  isErrored
                    ? t('myContract.smartMeterOptions.error.description')
                    : t('myContract.smartMeterOptions.confirmation.description')
                )}
              </p>
            </Banner>
          )}
        </section>
      )}

      <section className={styles.actions}>
        {hasExtraSmartMeterOptions && (
          <Button
            isFullwidth
            variant={extraOptionsEditable ? 'primary' : 'secondary'}
            onClick={() => {
              if (extraOptionsEditable) {
                const differences = checkOptionsDifferenceWithOriginal()
                const sameAsOriginal = Object.values(differences).every((value) => !value)

                if (sameAsOriginal) {
                  mixpanel.track(SmartMeterOptionEvents.EDIT_OPTIONS_CANCELED, {
                    [SmartMeterOptions.MONTHLY_BILLING]: options.monthly,
                    [SmartMeterOptions.DYNAMIC_TARIFF]: options.dynamic,
                    [SmartMeterOptionTrackingParams.SAME_AS_ORIGINAL]: sameAsOriginal
                  })

                  setExtraOptionsEditable(false)
                } else {
                  mixpanel.track(SmartMeterOptionEvents.EDIT_OPTIONS_CONFIRMATION_OPENED, {
                    [SmartMeterOptions.MONTHLY_BILLING]: options.monthly,
                    [SmartMeterOptions.DYNAMIC_TARIFF]: options.dynamic
                  })

                  setConfirmationModalOpen(true)
                }
              } else {
                mixpanel.track(SmartMeterOptionEvents.EDIT_OPTIONS_STARTED, {
                  [SmartMeterOptions.MONTHLY_BILLING]: options.monthly,
                  [SmartMeterOptions.DYNAMIC_TARIFF]: options.dynamic
                })

                setExtraOptionsEditable(true)
              }
            }}
          >
            {t(`myContract.smartMeterOptions.actions.${extraOptionsEditable ? 'save' : 'edit'}`)}
          </Button>
        )}

        {!IS_BETA && (
          <Button
            isFullwidth
            disabled={extraOptionsEditable}
            leadingIcon="homePlus"
            variant="secondary"
            onClick={() => {
              const flow = MoveFlows.START
              mixpanel.track(ContractFlowEvents.START, {
                flow,
                type: ContractFlowTrackingTypes.ADD,
                from: ContractFlowStartPoints.ADDRESS_DETAIL
              })
              dispatch(setMoveFlow(flow))
              navigate(routes.CONTRACTS_ADD)
            }}
          >
            {t('myContract.addContract')}
          </Button>
        )}

        {!IS_BETA && !inactiveContract && (
          <Button
            isFullwidth
            destructive
            disabled={extraOptionsEditable}
            leadingIcon="homeCross"
            variant="secondary"
            onClick={() => {
              const flow = MoveFlows.STOP
              mixpanel.track(ContractFlowEvents.START, {
                flow,
                type: ContractFlowTrackingTypes.TERMINATE,
                from: ContractFlowStartPoints.ADDRESS_DETAIL
              })
              dispatch(setMoveFlow(flow))
              navigate(routes.CONTRACTS_TERMINATE)
            }}
          >
            {t('myContract.terminateContract')}
          </Button>
        )}
      </section>

      {/* CONFIRMATION MODAL */}
      <Modal
        variant="rounded"
        title={t('myContract.modal.update.title')}
        isOpen={confirmationModalOpen}
        setClose={() => setConfirmationModalOpen(false)}
        modalRootEl={containerRef.current}
        isStacked
      >
        {isDynamicTariffEligible && checkOptionsDifferenceWithOriginal().dynamic && (
          <div>
            <Heading as="h3" variant="h6" className="mb-200">
              {t(`myContract.modal.update.dynamicTariff.${options.dynamic ? 'activate' : 'deactivate'}.title`)}
            </Heading>
            <p>{parse(t(`myContract.modal.update.dynamicTariff.${options.dynamic ? 'activate' : 'deactivate'}.description`))}</p>
          </div>
        )}

        {isMonthlyBillingEligible && checkOptionsDifferenceWithOriginal().monthly && (
          <div>
            <Heading as="h3" variant="h6" className="mb-200">
              {t(`myContract.modal.update.monthlyBilling.${options.monthly ? 'activate' : 'deactivate'}.title`)}
            </Heading>
            <p>{parse(t(`myContract.modal.update.monthlyBilling.${options.monthly ? 'activate' : 'deactivate'}.description`))}</p>
          </div>
        )}

        <p>{parse(t('myContract.modal.update.subtext'))}</p>

        <div className={styles['actions-group']}>
          <Button loading={isLoading} onClick={updateSmartMeterOptions}>
            {t('myContract.modal.confirm')}
          </Button>

          <Button
            variant="secondary"
            disabled={isLoading}
            onClick={() => {
              mixpanel.track(SmartMeterOptionEvents.EDIT_OPTIONS_CANCELED, {
                [SmartMeterOptions.MONTHLY_BILLING]: options.monthly,
                [SmartMeterOptions.DYNAMIC_TARIFF]: options.dynamic
              })

              setConfirmationModalOpen(false)
            }}
          >
            {t('myContract.modal.cancel')}
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default OfftakeAddressDetails
