import { Outlet } from 'react-router'
import { routes } from 'types/routes'
import { useTranslation } from 'react-i18next'
import { PageTab } from 'layouts/page-layout/types.ts'
import PageLayout from 'layouts/page-layout/PageLayout.tsx'
import EditLayout from 'layouts/edit-layout/EditLayout'
import useGetRouteLayout from 'hooks/useGetRouteLayout.ts'
import { Layouts } from 'layouts/types.ts'
import { FaqSlug } from 'types/faq.ts'

const User = () => {
  // i18n
  const { t } = useTranslation('user')

  // Check layout from routing
  const { layout } = useGetRouteLayout()

  const tabs: PageTab[] = [
    { label: t('tabTitles.personal'), path: routes.USER_PERSONAL },
    { label: t('tabTitles.payment'), path: routes.USER_PAYMENT },
    { label: t('tabTitles.contact'), path: routes.USER_CONTACT }
  ]

  return layout === Layouts.EDIT_LAYOUT ? (
    <EditLayout title={t('nav.user', { ns: 'common' })}>
      <Outlet />
    </EditLayout>
  ) : (
    <PageLayout title={t('nav.user', { ns: 'common' })} {...{ tabs }} faqCategories={[FaqSlug.MY_DETAILS]}>
      <Outlet />
    </PageLayout>
  )
}

export default User
