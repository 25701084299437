import { PreviousBillingCycle } from 'pages/App/billing/billing-cycles/types'

// REQUEST & RESPONSE TYPES

export type GetBillShockResponseData = {
  billShock?: BillShock
  meterReadingMonth?: number
}

export type GetPreviousBillingCycleResponseData = {
  period?: PreviousBillingCycle
}

export type GetPreviousBillingCyclePeriodsResponseData = {
  periods?: PreviousBillingCyclePeriod[]
}

// GENERAL BILLSHOCK TYPES

export type BillShock = {
  computeDate: string // ISO
  instalment: {
    current: number
    proposed: number
    minimum: number
    estimatedRatio: {
      electricity: number
      gas: number
    }
  }
  past: BillShockPastInstalment[]
  future: BillShockFutureInstalment[]
  settlement: {
    estimatedTotalAmount: number
    billShockAmount: number
  } & SettlementDefaultProps
  meters: {
    electricity?: BillShockMeter
    gas?: BillShockMeter
  }
}

export type BillShockPastInstalment = {
  datetime: string // ISO
  billedInstalment: number
}

export type BillShockFutureInstalment = {
  datetime: string // ISO
  plannedInstalment: number
  proposedInstalment: number
}

export type BillShockMeter = {
  lastMeterReadingDate: string
  totalConsumption: number
  totalInjection?: number
}

export type CurrentInstalment = {
  amount: number
  ratio: number
}

export type BillShockError = {
  code?: TransfoErrorCodes
  request?: boolean
}

export type SettlementDefaultProps = {
  startDate: string // ISO
  endDate: string // ISO
}

export type PreviousBillingCyclePeriod = {
  id: string
  fuel?: {
    electricity: boolean
    gas: boolean
  }
} & SettlementDefaultProps

// ENUMS

export enum TransfoErrorCodes {
  E304_CONTRACT_TOO_YOUNG = 'E304',
  E311_NO_DATA_AVAILABLE_FOR_FUTURE_DATES = 'E311',
  E431_NO_INVOICE_FOUND = 'E431',
  E442_NEAR_METERREADING_MONTH = 'E442',
  E443_NO_FUTURE_INSTALMENTS = 'E443',
  E444_MISSING_DECENTRALIZED_PRODUCTION = 'E444',
  E454_MISSING_SERVICE_DELIVERIES = 'E454',
  E456_DELIVERY_OUT_OF_SCOPE = 'E456'
}
