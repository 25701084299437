import { useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import annotationPlugin from 'chartjs-plugin-annotation'
import { BarElement, CategoryScale, Chart as ChartJS, LinearScale, LineController, LineElement, PointElement, Tooltip } from 'chart.js'
import { ConsumptionChartProps, ConsumptionWrapperProps } from './types'
import { MinMaxData } from 'components/Charts/types'
import { getConsumptionChartDatasets } from './chartjs.config'
import { calculateMinMaxConsumptionData, getMemoizedHistoryModifier } from './utils'
import ConsumptionChartDesktop from './ChartDesktop'
import ConsumptionChartMobile from './ChartMobile'
import { useStoreSelector } from 'hooks/store.ts'
import { yScaleTitle } from 'components/Charts/plugins.ts'
import { selectCurrentContracts } from 'store/contact/selectors.ts'
import { hasSmartMeter } from 'utils/contracts.ts'
import { EnergyType } from 'types/contracts.ts'
import Totals from 'pages/App/consumption/your-consumption/components/totals-block/TotalsBlock.tsx'
import { formatAmount } from 'utils/format.ts'
import { useTranslation } from 'react-i18next'
import { DSChartColors } from 'types/colors.ts'
import { VolumeEntry } from 'pages/App/consumption/your-consumption/components/consumption-section/types.ts'

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, LineController, Tooltip, annotationPlugin, yScaleTitle)

const ConsumptionChart = ({
  compact,
  activeEnergyType,
  data: rawData,
  totals,
  isFetchingVolumes,
  granularity,
  hasInjection,
  isTablet,
  showEstimatedUsage,
  hidePriceHistory
}: ConsumptionWrapperProps) => {
  // Redux
  const { selected, billingContracts, isProducer } = useStoreSelector((store) => store.contact)

  // i18n
  const { t } = useTranslation(['consumption', 'common'])

  // Local state
  const [activeDataPointIndex, setActiveDataPointIndex] = useState<number | null>(null)

  // Contracts
  const { electricity } = selectCurrentContracts({ selected, billingContracts }).serviceContracts
  const data = isProducer
    ? rawData.map(
        (entry: VolumeEntry): VolumeEntry => ({
          ...entry,
          injection: {
            billed: Math.abs(entry.injection?.billed || 0),
            unbilled: Math.abs(entry.injection?.unbilled || 0)
          }
        })
      )
    : rawData

  // Memoized constants
  const priceHistoryModifier = getMemoizedHistoryModifier({ dataset: data, type: activeEnergyType, isProducer })
  const months = useMemo<string[]>(() => data.map((consumption) => dayjs(consumption.datetime).toISOString()), [data])
  const minMaxData = useMemo<MinMaxData>(
    () => calculateMinMaxConsumptionData(data, activeEnergyType, priceHistoryModifier, isProducer),
    [data, activeEnergyType, priceHistoryModifier, isProducer]
  )

  const chartData = useMemo<any>(
    () => ({
      labels: months,
      datasets: getConsumptionChartDatasets({
        labels: months,
        data,
        type: activeEnergyType,
        showInjection: hasInjection,
        showEstimatedUsage,
        historyModifier: priceHistoryModifier,
        hideHistory: hidePriceHistory || data.every((entry) => !entry.history[activeEnergyType === EnergyType.GAS ? 'gas' : 'electricity']),
        hasSmartMeter: hasSmartMeter(electricity)
      })
    }),
    [compact, months, data, activeEnergyType, hasInjection, showEstimatedUsage, priceHistoryModifier, hidePriceHistory, electricity]
  )

  const chartProps: ConsumptionChartProps = {
    activeEnergyType,
    chartData,
    isFetchingVolumes,
    labels: months,
    minMaxData,
    priceHistoryModifier,
    granularity,
    setHoverDataPointIndex: setActiveDataPointIndex,
    compact
  }

  /**
   * Get active chart data based on index and key
   * @param {number} index
   * @param {"consumption" | "injection" | "history"} key
   * @returns {number}
   */
  const getActiveChartData = (index: number, key: 'consumption' | 'injection' | 'history'): number => {
    if (key === 'history') return data[index].history[activeEnergyType === EnergyType.GAS ? 'gas' : 'electricity'] || 0
    return (data[index][key]?.billed || 0) + (data[index][key]?.unbilled || 0)
  }

  // Reset active data point index if data is not available
  useEffect(() => {
    if (isFetchingVolumes || (typeof activeDataPointIndex === 'number' && !data[activeDataPointIndex])) {
      setActiveDataPointIndex(null)
    }
  }, [activeDataPointIndex, data, isFetchingVolumes])

  return (
    <>
      <Totals {...{ compact }}>
        {typeof activeDataPointIndex === 'number' && !!data[activeDataPointIndex] ? (
          <>
            {/* Total consumption */}
            {!isProducer && (
              <Totals.Block
                tooltip={t(`chart.totals.consumption.tooltip.${activeEnergyType}`)}
                isLoading={isFetchingVolumes}
                label={t('chart.totals.consumption.text')}
                indicator={{
                  color: activeEnergyType === EnergyType.GAS ? DSChartColors.PURPLE : DSChartColors.GREEN,
                  full: !!data[activeDataPointIndex].consumption.billed || !data[activeDataPointIndex].consumption.unbilled,
                  stripped: !!data[activeDataPointIndex].consumption.unbilled
                }}
                addendum={data[activeDataPointIndex]?.consumption?.unbilled ? t('chart.totals.injection.addendum') : undefined}
              >
                <data value={getActiveChartData(activeDataPointIndex, 'consumption')}>
                  {formatAmount(getActiveChartData(activeDataPointIndex, 'consumption'), 0)} kWh
                </data>
              </Totals.Block>
            )}

            {/* Total injection */}
            {!!totals.totalInjection && (
              <Totals.Block
                tooltip={t('chart.totals.injection.tooltip')}
                isLoading={isFetchingVolumes}
                label={t('chart.totals.injection.text')}
                indicator={{
                  color: DSChartColors.YELLOW,
                  full: !!data[activeDataPointIndex]?.injection?.billed || !data[activeDataPointIndex]?.injection?.unbilled,
                  stripped: !!data[activeDataPointIndex]?.injection?.unbilled
                }}
                addendum={data[activeDataPointIndex]?.injection?.unbilled ? t('chart.totals.injection.addendum') : undefined}
              >
                <data value={Math.abs(getActiveChartData(activeDataPointIndex, 'injection'))}>
                  {formatAmount(Math.abs(getActiveChartData(activeDataPointIndex, 'injection')), 0)} kWh
                </data>
              </Totals.Block>
            )}

            {/* Price history */}
            {!hidePriceHistory && (
              <Totals.Block
                tooltip={t(`chart.totals.price.tooltip.${activeEnergyType}`)}
                isLoading={isFetchingVolumes}
                label={t('chart.totals.price.text')}
                indicator={{
                  color: DSChartColors.BLUE,
                  full: true
                }}
                addendum={t('chart.totals.price.addendum')}
              >
                <data value={getActiveChartData(activeDataPointIndex, 'history')}>
                  {t('common:units.currencyPerKwh', {
                    value: formatAmount(getActiveChartData(activeDataPointIndex, 'history'), 3)
                  })}
                </data>
              </Totals.Block>
            )}
          </>
        ) : (
          <>
            {/* Total consumption */}
            {!isProducer && (
              <Totals.Block
                tooltip={t(`chart.totals.consumption.tooltip.${activeEnergyType}`)}
                isLoading={isFetchingVolumes}
                label={t('chart.totals.consumption.text')}
                indicator={{
                  color: activeEnergyType === EnergyType.GAS ? DSChartColors.PURPLE : DSChartColors.GREEN,
                  full: true
                }}
              >
                <data value={totals.totalConsumption}>{formatAmount(totals.totalConsumption, 0)} kWh</data>
              </Totals.Block>
            )}

            {/* Total injection */}
            {!!totals.totalInjection && (
              <Totals.Block
                tooltip={t('chart.totals.injection.tooltip')}
                isLoading={isFetchingVolumes}
                label={t('chart.totals.injection.text')}
                indicator={{
                  color: DSChartColors.YELLOW,
                  full: true
                }}
              >
                <data value={Math.abs(totals.totalInjection)}>{formatAmount(Math.abs(totals.totalInjection), 0)} kWh</data>
              </Totals.Block>
            )}

            {/* Price history */}
            {!hidePriceHistory && (
              <Totals.Block
                tooltip={t(`chart.totals.price.tooltip.${activeEnergyType}`)}
                isLoading={isFetchingVolumes}
                label={t('chart.totals.price.text')}
                indicator={{
                  color: DSChartColors.BLUE,
                  full: true
                }}
              >
                <strong>—</strong>
              </Totals.Block>
            )}
          </>
        )}
      </Totals>

      {/* CHART */}
      {isTablet ? <ConsumptionChartMobile {...chartProps} /> : <ConsumptionChartDesktop {...chartProps} />}
    </>
  )
}

export default ConsumptionChart
