import OverviewCard from 'pages/App/overview/overview-cards/OverviewCard.tsx'
import { routes } from 'types/routes.ts'
import ConsumptionSection from 'pages/App/consumption/your-consumption/components/consumption-section/ConsumptionSection.tsx'
import { EnergyType } from 'types/contracts.ts'
import { UsageOverviewCardProps } from 'pages/App/overview/overview-cards/usage/types.ts'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatAddress } from 'utils/user.ts'
import { useStoreSelector } from 'hooks/store.ts'

const UsageOverviewCard = ({ accessRights, electricityContract }: UsageOverviewCardProps) => {
  // Redux store
  const { isProducer } = useStoreSelector((state) => state.contact)

  // i18n
  const { t } = useTranslation('overview')

  // Local state
  const [hasErrored, setHasErrored] = useState<boolean>(false)

  // Conditionally hide
  if (!accessRights.consumption.showContent || hasErrored) return null

  return (
    <OverviewCard
      button={{
        label: t('overview.consumption.button'),
        href: routes.CONSUMPTION
      }}
    >
      <header>
        <OverviewCard.Title>{t(`overview.consumption.title.${isProducer ? 'producer' : 'consumer'}`)}</OverviewCard.Title>
        <small className="text-subdued truncate">{formatAddress(electricityContract.deliveryPoint.address)}</small>
      </header>

      <ConsumptionSection compact energyType={EnergyType.ELECTRICITY} setHide={(v) => setHasErrored(v)} />
    </OverviewCard>
  )
}

export default UsageOverviewCard
