import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate } from 'react-router'
import { routes } from 'types/routes'
import { determineAccessRights } from 'utils/contracts'
import { useStoreSelector } from 'hooks/store'
import { selectCurrentContracts } from 'store/contact/selectors.ts'
import { PageTab } from 'layouts/page-layout/types.ts'
import PageLayout from 'layouts/page-layout/PageLayout.tsx'
import useGetRouteLayout from 'hooks/useGetRouteLayout.ts'
import { useLocation, useParams } from 'react-router-dom'
import { Layouts } from 'layouts/types.ts'
import { FaqSlug } from 'types/faq.ts'
import { useEffect } from 'react'

const Billing = () => {
  // URL Param
  const { id } = useParams<{ id: string }>()

  // Redux
  const { billingContracts, selected } = useStoreSelector((store) => store.contact)

  // Contracts
  const billingContract = selectCurrentContracts({ billingContracts, selected })
  const { electricity } = billingContract.serviceContracts

  // Layout
  const { layout } = useGetRouteLayout()

  // Access rights
  const accessRights = determineAccessRights(electricity)

  // i18n
  const { t } = useTranslation('billing')

  // Router
  const { pathname } = useLocation()
  const navigate = useNavigate()

  /**
   * Generates the tabs & return them
   *
   * @return {PageTab[]}
   */
  const getTabs = (): PageTab[] => {
    const tabs: PageTab[] = [{ label: t('tabs.invoices'), path: routes.BILLING_INVOICES }]

    // Push billing cycles tab if allowed
    if (accessRights.billingCycles.canAccess) {
      tabs.push({ label: t('tabs.billingCycles'), path: routes.BILLING_CYCLES })
    }

    // Push instalment tab if allowed
    if (accessRights.instalment.canAccess) {
      tabs.push({ label: t('tabs.instalment'), path: routes.BILLING_INSTALMENT })
    }

    return tabs
  }

  // Constants
  const withSwitcher: string[] = [routes.BILLING_INSTALMENT, routes.BILLING_CYCLES]

  // Redirect to invoices if the user lands on the general billing route
  useEffect(() => {
    if (pathname === routes.BILLING) {
      navigate(routes.BILLING_INVOICES, { replace: true })
    }
  }, [pathname, navigate])

  return layout === Layouts.EDIT_LAYOUT || id ? (
    <Outlet />
  ) : (
    <PageLayout
      title={t('nav.billing', { ns: 'common' })}
      tabs={getTabs()}
      description={pathname.includes(routes.BILLING_INSTALMENT) ? t('instalment.description') : undefined}
      faqCategories={[FaqSlug.INVOICES_AND_PAYMENTS]}
      withSwitcher={withSwitcher.includes(pathname)}
    >
      <Outlet />
    </PageLayout>
  )
}

export default Billing
