import { ButtonLoadingIndicator, Heading, Icon } from '@boltenergy-be/design-system'
import Card from 'components/Card/Card'
import { SalesOpportunity } from 'types/sales'
import styles from './OpportunitiesCard.module.scss'
import { useTranslation } from 'react-i18next'
import SearchBar from 'components/Search/SearchBar'
import { useState } from 'react'
import OpportunitiesListItem from 'components/OpportunitiesListItem/OpportunitiesListItem'
import { useGetSalesOpportunitiesQuery } from 'store/queries/bolt-api/sales'
import ListWithSeparator from 'components/ListWithSeparator/ListWithSeparator'

const OpportunitiesCard = () => {
  // i18n
  const { t } = useTranslation('sales')

  // Local state
  const [searchQuery, setSearchQuery] = useState<string>('')

  // Fetch data
  const { data: opportunityData, isFetching } = useGetSalesOpportunitiesQuery({ searchQuery }, { skip: !searchQuery })

  return (
    <Card className={styles.opportunities}>
      <Heading as="h2" variant="h5">
        {t('overview.opportunities.title')}
      </Heading>
      <SearchBar
        className={styles.search}
        placeholder={t('overview.opportunities.search.searchbar')}
        onInput={(value) => {
          //if value consists of numbers, wait until 8 before triggering search
          if (value.length < 8 && !isNaN(Number(value)) && value !== '') {
            return
          }
          setSearchQuery(value)
        }}
      />
      {isFetching ? (
        <div className={styles['loading-wrapper']}>
          <ButtonLoadingIndicator />
        </div>
      ) : opportunityData && opportunityData.length > 0 && searchQuery ? (
        <div className={styles['opportunity-list']}>
          <ListWithSeparator>
            {opportunityData.map((opportunity: SalesOpportunity, index: number) => (
              <OpportunitiesListItem key={index} {...{ opportunity }} />
            ))}
          </ListWithSeparator>
        </div>
      ) : (
        <div className={styles['search-default']}>
          <div className={styles['icon-wrapper']}>
            <Icon className={styles.icon} name="search" />
          </div>
          <strong className={styles['search-header']}>
            {t(`overview.opportunities.search.${searchQuery ? 'noResultsTitle' : 'emptyTitle'}`)}
          </strong>
          <small className={styles['search-description']}>
            {t(`overview.opportunities.search.${searchQuery ? 'noResultsDescription' : 'emptyDescription'}`)}
          </small>
        </div>
      )}
    </Card>
  )
}

export default OpportunitiesCard
