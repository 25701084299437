import { useTranslation } from 'react-i18next'
import styles from '../Details.module.scss'
import { SimulationDetailsProps } from '../types'
import DataBlock from 'features/contracts/add/components/DataBlock/DataBlock'
import { maskWithAsterisk } from 'utils/format'
import classNames from 'classnames'

const SimulationDetails = ({ opportunity }: SimulationDetailsProps) => {
  // i18n
  const { t } = useTranslation('sales')

  return (
    <>
      <div className={classNames({ [styles['double-column']]: opportunity.forcedAmount })}>
        <DataBlock label={t('details.simulation.pricePerMonth')} withMargin={false}>
          {opportunity.pricePerMonthInclVat}
        </DataBlock>
        {opportunity.forcedAmount && <DataBlock label={t('details.simulation.forcedAmount')}>{opportunity.forcedAmount}</DataBlock>}
      </div>
      <DataBlock label={t('details.simulation.paymentMethod')} withMargin={false}>
        {t(`details.simulation.paymentMethodOptions.${opportunity.paymentMode}`)}
      </DataBlock>
      <DataBlock label={t('details.simulation.IBAN')} withMargin={false}>
        {maskWithAsterisk(opportunity.ibanNumber)}
      </DataBlock>
      <DataBlock label={t('details.simulation.address.street')}>{opportunity?.address?.streetName}</DataBlock>
      <div className={styles['double-column']}>
        <DataBlock label={t('details.simulation.address.number')} withMargin={false}>
          {opportunity?.address?.streetNumber}
        </DataBlock>
        <DataBlock label={t('details.simulation.address.addition')} withMargin={false}>
          {opportunity?.address?.streetBox}
        </DataBlock>
      </div>
      <DataBlock label={t('details.simulation.address.postalCode')} withMargin={false}>
        {opportunity?.address?.postalCode?.toString()}
      </DataBlock>

      <DataBlock label={t('details.simulation.address.locality')} withMargin={false}>
        {opportunity.address.townName}
      </DataBlock>
    </>
  )
}

export default SimulationDetails
