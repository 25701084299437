import i18next from 'i18next'
import { formatAmount } from 'utils/format'
import { createChartDataArray, formatChartDateLabel } from 'utils/chartjs'
import { CHART_COLORS, getBarChartDefaults } from 'components/Charts/constants'
import { ChartLabels } from 'components/Charts/types'
import { externalTooltipHandler } from 'components/Charts/chart-tooltip/tooltip.ts'
import { BillShock } from 'types/billShock'
import { CycleViews } from 'pages/App/billing/billing-cycles/types'
import { getChartPattern, getSettlementColor } from '../utils'
import { Granularity } from 'types/contracts.ts'
import { TOOLTIP_CONFIG } from 'components/Charts/chart-tooltip/config.ts'
import { BillingCycleChartOptions } from '../types.ts'
import { DSChartColors } from 'types/colors.ts'

export const getBillshockChartOptions = ({ labels, language, minMax, isMobile, handleHoverDataPoint }: BillingCycleChartOptions) => {
  const defaults = getBarChartDefaults({ isMobile })
  const {
    scales: { y, x },
    plugins
  } = defaults

  return {
    ...defaults,
    maintainAspectRatio: false,
    scales: {
      y: {
        ...y,
        suggestedMin: minMax?.minDataValue,
        suggestedMax: minMax?.maxDataValue,
        ticks: {
          ...y.ticks,
          callback: (value: number) => formatAmount(value, 0)
        }
      },
      x: {
        ...x,
        ticks: {
          font: x.ticks.font,
          callback: (value: number) => {
            if (value === labels.length - 1) return labels[value]
            return formatChartDateLabel(labels[value], Granularity.MONTH, language)
          },
          color: x.ticks.color
        }
      }
    },
    plugins: {
      ...plugins,
      yScaleTitle: {
        labels: {
          y: '€'
        }
      },
      annotation: {
        common: {
          drawTime: 'beforeDraw'
        },
        annotations: {
          lastColumn: {
            type: 'box',
            backgroundColor: CHART_COLORS.backgroundHighlight,
            borderWidth: 0,
            xMax: 0.5 + labels.length - 1,
            xMin: -0.5 + labels.length - 1
          }
        }
      },
      tooltip: {
        // Disable the on-canvas tooltip
        ...TOOLTIP_CONFIG,
        external: (context: any) => {
          const { tooltip } = context
          const currentDataPoint = tooltip?.dataPoints?.[0]?.dataIndex
          const hideTooltip = tooltip.opacity === 0
          handleHoverDataPoint(hideTooltip ? null : currentDataPoint)
          return externalTooltipHandler({
            context,
            totalColumns: labels.length,
            isMobile
          })
        },
        callbacks: {
          title: (context: any) =>
            context?.[0]?.dataIndex === labels.length - 1
              ? i18next.t('billingCycle.chart.settlement', { ns: 'billing' })
              : formatChartDateLabel(context?.[0]?.label, Granularity.MONTH, language, true)
        }
      }
    }
  }
}

export const getBillingCycleChartDatasets = (
  labels: ChartLabels,
  data: BillShock,
  activePeriod: CycleViews,
  isMobile?: boolean,
  adjustedBillShockAmount?: number
) => {
  const settlementColor = getSettlementColor(activePeriod === CycleViews.CURRENT ? data.settlement.billShockAmount : 0)
  const maxBarThickness = 94
  const borderRadius = isMobile ? 2 : 4

  return [
    {
      label: i18next.t('billing:billingCycle.chart.tooltips.billedInstalment'),
      backgroundColor: CHART_COLORS.pink,
      data: createChartDataArray(
        labels.length,
        data.past.map((instalment) => instalment.billedInstalment)
      ),
      skipNull: true,
      maxBarThickness,
      borderRadius
    },
    {
      label: i18next.t(
        `billing:billingCycle.chart.tooltips.${activePeriod === CycleViews.CURRENT ? 'plannedInstalment' : 'proposedInstalment'}`
      ),
      backgroundColor: getChartPattern(activePeriod === CycleViews.CURRENT ? DSChartColors.PINK : DSChartColors.LIGHTBLUE),
      data: createChartDataArray(
        labels.length,
        data.future.map((instalment) =>
          activePeriod === CycleViews.CURRENT ? instalment.plannedInstalment : instalment.proposedInstalment
        ),
        data.past.length
      ),
      skipNull: true,
      maxBarThickness,
      borderRadius
    },
    {
      label: i18next.t('billing:billingCycle.chart.tooltips.billShockAmount'),
      backgroundColor: settlementColor,
      data: createChartDataArray(
        labels.length,
        [
          activePeriod === CycleViews.PROPOSED ? (adjustedBillShockAmount ? adjustedBillShockAmount : -10) : data.settlement.billShockAmount
        ],
        labels.length - 1
      ),
      skipNull: true,
      maxBarThickness,
      borderRadius
    }
  ]
}
