// Styles
import '@boltenergy-be/design-system/dist/assets/foundations.css'
import 'slick-carousel/slick/slick.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'styles/app.scss'

// Dependencies
import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { persistor, store } from 'store'
import GeneralErrorHandler from 'components/GeneralErrorHandler/GeneralErrorHandler.tsx'
import { RouterProvider } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import Bugsnag from '@bugsnag/js'
import { startUp } from 'utils/startUp.ts'
import mixpanel from 'mixpanel-browser'
import { IS_BETA, IS_PROD_OR_BETA, MIXPANEL_TOKEN } from 'constants/envs.ts'
import TagManager from 'react-gtm-module'
import router from 'router/routing.tsx'

import 'translations/i18next.ts'
import { ToastContainer } from '@boltenergy-be/design-system'
import('dayjs/locale/nl')
import('dayjs/locale/fr')

// Execute start up functions
startUp()

// Create Bugsnag error boundary
const BugsnagErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React)

// Initialize Mixpanel
mixpanel.init(MIXPANEL_TOKEN, {
  debug: !IS_PROD_OR_BETA,
  ignore_dnt: !IS_PROD_OR_BETA,
  loaded: () => {
    if (IS_BETA) {
      mixpanel.track('beta')
    }
  }
})

// Define GTM settings
const tagManagerArgs = {
  gtmId: IS_PROD_OR_BETA ? 'GTM-PLCVTWQ' : 'GTM-PJR5QWB'
}

// Initialize GTM
TagManager.initialize(tagManagerArgs)

function App() {
  return (
    <React.StrictMode>
      <BugsnagErrorBoundary>
        <HelmetProvider>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <GeneralErrorHandler>
                <RouterProvider router={router} />
                <ToastContainer />
              </GeneralErrorHandler>
            </PersistGate>
          </Provider>
        </HelmetProvider>
      </BugsnagErrorBoundary>
    </React.StrictMode>
  )
}

export default App
