import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FormButtons from 'features/contracts/components/form-buttons/FormButtons'
import LoadingDots from 'components/LoadingDots/LoadingDots'
import SelectProducerModal from '../../components/SelectProducerModal/SelectProducerModal'
import SelectProducerCard from 'features/contracts/add/components/SelectProducerCard/SelectProducerCard'
import { AddContractStepProps, AddContractSteps } from '../../types'
import styles from './ProducerStep.module.scss'
import { Banner, Button, Heading } from '@boltenergy-be/design-system'
import { useGetPersonalizedProducersQuery } from 'store/queries/bolt-api/producers'
import { ContractFlowTrackingTypes } from 'types/tracking.ts'
import { useStoreSelector } from 'hooks/store.ts'
import { getLowerCaseLanguage } from 'utils/app.ts'
import { Producer } from 'types/producer.ts'

const ProducerStep = ({ setNextStep, addContractData, setAddContractData }: AddContractStepProps) => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)
  const lowerCaseLanguage = getLowerCaseLanguage(language)

  // Redux queries
  const {
    data: personalizedProducers,
    isLoading,
    isError
  } = useGetPersonalizedProducersQuery({ address: addContractData.address.deliveryAddress })

  // Local state
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  // i18n
  const { t } = useTranslation('contracts')

  /**
   * Update producer in registration data state object
   * @param {Producer} producer
   */
  const handleSelectProducer = useCallback(
    (producer: Producer) => {
      if (typeof setAddContractData !== 'undefined') {
        setAddContractData({
          ...addContractData,
          producer
        })
      }
    },
    [addContractData, setAddContractData]
  )

  // Set producer from personalized producers if available
  useEffect(() => {
    if (
      !addContractData?.[AddContractSteps.PRODUCER] &&
      personalizedProducers?.[lowerCaseLanguage] &&
      Object.values(personalizedProducers[lowerCaseLanguage]).some((p) => !!p)
    ) {
      if (personalizedProducers[lowerCaseLanguage].popular) handleSelectProducer(personalizedProducers[lowerCaseLanguage].popular)
      else if (personalizedProducers[lowerCaseLanguage].nearest) handleSelectProducer(personalizedProducers[lowerCaseLanguage].nearest)
      else if (personalizedProducers[lowerCaseLanguage].recent) handleSelectProducer(personalizedProducers[lowerCaseLanguage].recent)
    }
  }, [personalizedProducers, addContractData, lowerCaseLanguage, handleSelectProducer])

  return (
    <section className={styles['producer-step']}>
      <header>
        <Heading as="h1" variant="h4">
          {t('add.steps.producer.title')}
        </Heading>
        <p className={styles['step-description']}>{t('add.steps.producer.description')}</p>
      </header>

      {isLoading ? (
        <LoadingDots /> // TODO: replace with loading skeleton
      ) : (
        <>
          {!isError && personalizedProducers ? (
            <>
              <ul className={styles['producers-container']}>
                {personalizedProducers[lowerCaseLanguage].popular && (
                  <li className={styles['producer-card-container']}>
                    <SelectProducerCard
                      tag={t('add.steps.producer.categories.popular')}
                      key={personalizedProducers[lowerCaseLanguage].popular?.id}
                      producer={personalizedProducers[lowerCaseLanguage].popular}
                      activeProducerId={addContractData.producer?.id || ''}
                      handleSelectProducer={() => handleSelectProducer(personalizedProducers[lowerCaseLanguage].popular)}
                    />
                  </li>
                )}
                {personalizedProducers[lowerCaseLanguage].nearest && (
                  <li className={styles['producer-card-container']}>
                    <SelectProducerCard
                      tag={t('add.steps.producer.categories.nearest')}
                      key={personalizedProducers[lowerCaseLanguage].nearest?.id}
                      producer={personalizedProducers[lowerCaseLanguage].nearest}
                      activeProducerId={addContractData.producer?.id || ''}
                      handleSelectProducer={() => handleSelectProducer(personalizedProducers[lowerCaseLanguage].nearest)}
                    />
                  </li>
                )}
                {personalizedProducers[lowerCaseLanguage].recent && (
                  <li className={styles['producer-card-container']}>
                    <SelectProducerCard
                      tag={t('add.steps.producer.categories.recent')}
                      key={personalizedProducers[lowerCaseLanguage].recent?.id}
                      producer={personalizedProducers[lowerCaseLanguage].recent}
                      activeProducerId={addContractData.producer?.id || ''}
                      handleSelectProducer={() => handleSelectProducer(personalizedProducers[lowerCaseLanguage].recent)}
                    />
                  </li>
                )}
              </ul>

              <Button className="mx-auto" variant="tertiary" onClick={() => setIsModalOpen(true)}>
                {t('add.steps.producer.buttonViewAll')}
              </Button>
            </>
          ) : (
            <Banner type="blocking">{t('errorTryLater', { ns: 'common' })}</Banner>
          )}
        </>
      )}

      {!isLoading && (
        <FormButtons
          currentStep={AddContractSteps.PRODUCER}
          submitDisabled={isError || !addContractData.producer}
          trackingId={ContractFlowTrackingTypes.ADD}
          onSubmit={() => {
            if (addContractData.producer) setNextStep(addContractData.producer, AddContractSteps.PRODUCT)
          }}
        />
      )}

      {/* EDIT PRODUCER MODAL */}
      <SelectProducerModal
        isOpen={isModalOpen}
        setClose={() => setIsModalOpen(false)}
        addContractData={addContractData}
        setAddContractData={setAddContractData}
      />
    </section>
  )
}

export default ProducerStep
