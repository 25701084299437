import { Navigate, Outlet } from 'react-router'
import { useStoreSelector } from 'hooks/store.ts'
import PageLoading from 'components/PageLoading/PageLoading.tsx'
import { routes } from 'types/routes.ts'

const RouteWithSelectedContract = () => {
  // Redux
  const { loading, initialLoaded, error, selected, contact, accounts, billingContracts } = useStoreSelector((store) => store.contact)

  // Constants
  const noAccount = !selected.account && !accounts[selected.account]
  const noBillingContract = !selected.billingContract && !billingContracts[selected.billingContract]
  const noSelectedData = !contact && noAccount && noBillingContract

  if (!initialLoaded) {
    // Loading & error state
    return <PageLoading isLoading={loading} showError={!!error} />
  }

  // Redirect to welcome page if multiple contracts are available without a selected contract
  if (Object.keys(billingContracts).length > 1 && (!selected.billingContract || !billingContracts[selected.billingContract])) {
    return <Navigate to={routes.ACCOUNT_SELECTION} />
  }

  // Redirect to welcome page if no data is selected
  if (noSelectedData) return <Navigate to={routes.ACCOUNT_SELECTION} />

  // Render when data is available
  return <Outlet />
}

export default RouteWithSelectedContract
