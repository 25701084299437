import { createAsyncThunk } from '@reduxjs/toolkit'
import { GetMeterReadingsPayload, GetMeterReadingsSuccessPayload, RejectValue } from './types'
import { newRequest } from 'utils/request'
import { generateGetMeterReadingsAxiosRequestConfig } from 'api/contracts'
import { Response } from 'types/request'
import { GetLastMeterReadingResponseData } from 'types/contracts'

export const getMeterReadings = createAsyncThunk<GetMeterReadingsSuccessPayload, GetMeterReadingsPayload, RejectValue>(
  'contracts/getMeterReadings',
  async ({ electricityContractNumber, gasContractNumber }, thunkAPI) => {
    try {
      const [electricity, gas] = await Promise.all([
        newRequest(generateGetMeterReadingsAxiosRequestConfig(electricityContractNumber)),
        ...(gasContractNumber ? [newRequest(generateGetMeterReadingsAxiosRequestConfig(gasContractNumber))] : [])
      ])

      const typedEl: Response<GetLastMeterReadingResponseData> = electricity as Response<GetLastMeterReadingResponseData>
      const typedGas: Response<GetLastMeterReadingResponseData> = gas as Response<GetLastMeterReadingResponseData>

      if (typedEl.success && typedEl?.data?.lastMeterReading) {
        return {
          meterReadings: {
            electricity: typedEl.data.lastMeterReading,
            ...(typedGas?.success &&
              typedGas?.data && {
                gas: typedGas.data.lastMeterReading
              })
          }
        }
      }

      return thunkAPI.rejectWithValue(typedEl.message || typedGas.message || 'getMeterReadings')
    } catch (error) {
      const { message } = error as Error
      return thunkAPI.rejectWithValue(message)
    }
  }
)
