import { UserTypes } from 'store/auth/types'
import { Navigate } from 'react-router'
import App from 'pages/App/App.tsx'
import Auth from 'pages/Auth/Auth.tsx'
import SalesApp from 'pages/Sales/SalesApp.tsx'
import Login from 'pages/Auth/Login/Login'
import Register from 'pages/Auth/Register/Register'
import ResetPassword from 'pages/Auth/ResetPassword/ResetPassword'
import ProtectedRoute from 'router/ProtectedRoute/ProtectedRoute'
import Home from 'pages/Sales/home/Home'
import AccountSelection from 'pages/App/account-selection/AccountSelection.tsx'
import CustomerEmail from 'pages/App/customer-email/CustomerEmail'
import Overview from 'pages/App/overview/Overview'
import Producer from 'pages/App/producer/Producer.tsx'
import Billing from 'pages/App/billing/Billing'
import Invoices from 'pages/App/billing/invoices/Invoices'
import EditInstalment from 'pages/App/billing/edit-instalment/EditInstalment.tsx'
import BillingCyclesOverview from 'pages/App/billing/billing-cycles/BillingCyclesOverview.tsx'
import Consumption from 'pages/App/consumption/Consumption'
import User from 'pages/App/user/User'
import Support from 'pages/App/support/Support'
import EventLog from 'pages/App/event-log/EventLog'
import { createBrowserRouter } from 'react-router-dom'
import EnergyOverview from 'pages/App/energy-overview/EnergyOverview'
import MeterReadings from 'pages/App/consumption/meter-readings/MeterReadings'
import Personal from 'pages/App/user/personal/Personal'
import SupportContact from 'pages/App/support/contact/Contact'
import Payment from 'pages/App/user/payment/Payment'
import Faq from 'pages/App/support/faq/Faq'
import Add from 'features/contracts/add/Add'
import ContractsIndex from 'features/contracts/index/Index'
import Terminate from 'features/contracts/terminate/Terminate'
import { isAuthenticated } from 'utils/localStorageService'
import Friends from 'pages/App/referral/invited-friends/InvitedFriends.tsx'
import Addresses from 'pages/App/addresses/Addresses.tsx'
import Contact from 'pages/App/user/contact/Contact.tsx'
import Move from 'pages/App/move/Move.tsx'
import RootLayout from './RootLayout.tsx'
import PageLoading from 'components/PageLoading/PageLoading.tsx'
import Details from 'pages/Sales/details/Details'
import Events from 'pages/Sales/events/Events'
import RouteWithSelectedContract from 'router/RouteWithSelectedContract/RouteWithSelectedContract.tsx'
import ElectricityConsumption from 'pages/App/consumption/your-consumption/electricity/ElectricityConsumption.tsx'
import GasConsumption from 'pages/App/consumption/your-consumption/gas/GasConsumption.tsx'
import MarketPrices from 'pages/App/market-prices/MarketPrices.tsx'
import ContactForm from 'pages/App/user/contact-form/ContactForm.tsx'
import PaymentForm from 'pages/App/user/payment-form/PaymentForm.tsx'
import EditMeterReadings from 'pages/App/consumption/edit-meter-readings/EditMeterReadings.tsx'
import Referral from 'pages/App/referral/Referral.tsx'
import HowItWorks from 'pages/App/referral/how-it-works/HowItWorks.tsx'
import { routes } from 'types/routes.ts'
import RedirectToIndex from 'pages/RedirectToIndex.tsx'
import BillingCycleDetail from 'pages/App/billing/billing-cycle-detail/BillingCycleDetail.tsx'
import Instalment from 'pages/App/billing/instalment/Instalment.tsx'
import AddressDetail from 'pages/App/addresses/AddressDetail.tsx'
import { Layouts } from 'layouts/types.ts'
import MoreNavigation from 'layouts/components/navigation/mobile-navigation/MoreNavigation.tsx'
import GuaranteeOfOrigin from 'pages/App/guarantee-of-origin'
import GuaranteeOfOriginWhat from 'pages/App/guarantee-of-origin/what/GOWhat.tsx'
import GuaranteeOfOriginStatus from 'pages/App/guarantee-of-origin/status/GOStatus.tsx'

const router = createBrowserRouter([
  {
    element: <RootLayout />,
    errorElement: <PageLoading isLoading={false} showError />,
    children: [
      { path: '/', element: <RedirectToIndex replace /> },
      {
        path: '/sales',
        element: <Navigate to={isAuthenticated().sales ? routes.SALES_HOME : routes.LOGIN_SALES} replace />
      },
      {
        path: '/superuser',
        element: <Navigate to={isAuthenticated().superuser ? routes.CUSTOMER_EMAIL : routes.LOGIN_SUPER_USER} replace />
      },

      // AUTH
      {
        element: <Auth />,
        children: [
          // LOGIN ROUTES
          ...[routes.LOGIN, routes.LOGIN_SUPER_USER, routes.LOGIN_SALES].map((path) => ({
            path,
            element: <Login />
          })),

          // REGISTER ROUTE
          { path: routes.REGISTER, element: <Register /> },

          // ACTIVATE / RESET PASSWORD ROUTES
          ...[
            routes.ACTIVATE,
            routes.ACTIVATE_SALES,
            routes.ACTIVATE_SUPER_USER,
            routes.REQUEST_NEW_PASSWORD,
            routes.REQUEST_NEW_PASSWORD_SALES,
            routes.REQUEST_NEW_PASSWORD_SUPER_USER,
            routes.RESET_PASSWORD,
            routes.RESET_PASSWORD_SALES,
            routes.RESET_PASSWORD_SUPER_USER
          ].map((path) => ({ path, element: <ResetPassword /> }))
        ]
      },

      // PROTECTED ROUTES (SALES)
      {
        element: <ProtectedRoute userTypes={[UserTypes.SALES]} />,
        children: [
          {
            element: <SalesApp />,
            errorElement: <PageLoading isLoading={false} showError />,
            children: [
              { path: routes.SALES_HOME, element: <Home /> },
              { path: routes.SALES_CONTRACTS_ADD, element: <Add /> },
              { path: routes.SALES_DETAILS, element: <Details />, handle: { layout: Layouts.EDIT_LAYOUT } },
              { path: routes.SALES_EVENTS, element: <Events /> }
            ]
          }
        ]
      },

      // PROTECTED ROUTES (APP, SUPER_USER)
      {
        element: <ProtectedRoute userTypes={[UserTypes.CUSTOMER, UserTypes.SUPER_USER]} />,
        errorElement: <PageLoading isLoading={false} showError />,
        children: [
          {
            element: <App />,
            children: [
              { path: routes.ACCOUNT_SELECTION, element: <AccountSelection /> },
              { path: routes.CUSTOMER_EMAIL, element: <CustomerEmail /> },
              {
                element: <RouteWithSelectedContract />,
                children: [
                  { path: routes.MORE, element: <MoreNavigation /> },
                  { path: routes.OVERVIEW, element: <Overview /> },
                  { path: routes.PRODUCER, element: <Producer /> },
                  {
                    path: routes.ADDRESSES,
                    children: [
                      { index: true, element: <Addresses /> },
                      { path: routes.ADDRESS_DETAIL, element: <AddressDetail /> }
                    ]
                  },
                  { path: routes.PRODUCER, element: <Producer /> },
                  {
                    path: routes.BILLING,
                    element: <Billing />,
                    children: [
                      { path: routes.BILLING_INVOICES, element: <Invoices /> },
                      { path: routes.BILLING_INSTALMENT, element: <Instalment /> },
                      {
                        path: routes.BILLING_EDIT_INSTALMENT,
                        element: <EditInstalment />,
                        handle: { layout: Layouts.EDIT_LAYOUT }
                      },
                      { path: routes.BILLING_CYCLES, element: <BillingCyclesOverview /> },
                      { path: routes.BILLING_CYCLE_DETAIL, element: <BillingCycleDetail /> }
                    ]
                  },
                  {
                    path: routes.CONSUMPTION,
                    element: <Consumption />,
                    children: [
                      { path: routes.CONSUMPTION_ELECTRICITY, element: <ElectricityConsumption /> },
                      { path: routes.CONSUMPTION_GAS, element: <GasConsumption /> },
                      { path: routes.CONSUMPTION_METER_READINGS, element: <MeterReadings /> },
                      {
                        path: routes.CONSUMPTION_EDIT_METER_READINGS,
                        element: <EditMeterReadings />,
                        handle: { layout: Layouts.EDIT_LAYOUT }
                      }
                    ]
                  },
                  { path: routes.MARKET_PRICES, element: <MarketPrices /> },
                  {
                    path: routes.GUARANTEE_OF_ORIGIN,
                    element: <GuaranteeOfOrigin />,
                    children: [
                      { index: true, element: <GuaranteeOfOriginWhat /> },
                      { path: routes.GUARANTEE_OF_ORIGIN_STATUS, element: <GuaranteeOfOriginStatus /> }
                    ]
                  },
                  { path: routes.ENERGY_OVERVIEW, element: <EnergyOverview /> },
                  {
                    path: routes.REFERRAL,
                    element: <Referral />,
                    children: [
                      { path: routes.REFERRAL_FRIENDS, element: <Friends /> },
                      {
                        path: routes.REFERRAL_INFO,
                        element: <HowItWorks />
                      }
                    ]
                  },
                  {
                    path: routes.USER,
                    element: <User />,
                    children: [
                      { path: routes.USER_PERSONAL, element: <Personal />, handle: { layout: Layouts.PAGE_LAYOUT } },
                      { path: routes.USER_CONTACT, element: <Contact />, handle: { layout: Layouts.PAGE_LAYOUT } },
                      { path: routes.USER_PAYMENT, element: <Payment />, handle: { layout: Layouts.PAGE_LAYOUT } },
                      {
                        path: routes.USER_EDIT_CONTACT,
                        element: <ContactForm />,
                        handle: { layout: Layouts.EDIT_LAYOUT }
                      },
                      {
                        path: routes.USER_EDIT_PAYMENT,
                        element: <PaymentForm />,
                        handle: { layout: Layouts.EDIT_LAYOUT }
                      }
                    ]
                  },

                  {
                    path: routes.MOVE,
                    element: <Move />
                  },
                  {
                    path: routes.SUPPORT,
                    element: <Support />,
                    children: [
                      { path: routes.SUPPORT_CONTACT, element: <SupportContact /> },
                      { path: routes.SUPPORT_FAQ, element: <Faq /> }
                    ]
                  },
                  { path: routes.EVENT_LOG, element: <EventLog /> },
                  {
                    path: routes.CONTRACTS,
                    children: [
                      { index: true, element: <ContractsIndex /> },
                      { path: routes.CONTRACTS_ADD, element: <Add /> },
                      { path: routes.CONTRACTS_MOVE, element: <Add /> },
                      { path: routes.CONTRACTS_TERMINATE, element: <Terminate /> }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },

  // FALLBACK ROUTE
  {
    path: '*',
    element: <RedirectToIndex />
  }
])

export default router
