import { getCssVariable } from 'utils/app.ts'
import { ScriptableScaleContext } from 'chart.js'
import { ChartColorKeys, GetBarChartDefaults } from 'components/Charts/types.ts'

export const CHART_COLORS: { [key in ChartColorKeys]: string } = {
  green: getCssVariable('--chart-green', '#23d5ae'),
  blue: getCssVariable('--chart-blue', '#100061'),
  lightblue: getCssVariable('--chart-lightblue', '#69baff'),
  grey: getCssVariable('--chart-grey', '#B5B49E'),
  orange: getCssVariable('--accent-orange', '#ffa010'),
  purple: getCssVariable('--chart-purple', '#a564f0'),
  yellow: getCssVariable('--chart-yellow', '#f6df48'),
  pink: getCssVariable('--chart-pink', '#ff94f2'),
  textSubdued: getCssVariable('--text-subdued', '#848375'),
  gridBaseline: getCssVariable('--border-high-contrast', '#100061'),
  gridLine: 'rgba(132, 131, 117, .2)', // removed getCssVariable for safari support (black color prevention for opacity colors)
  background: '#ffffff00', // transparent
  backgroundHighlight: 'rgba(132,131,117,0.1)' // removed getCssVariable for safari support (black color prevention for opacity colors)
}

/**
 * Get bar chart default values
 * @param {GetBarChartDefaults} { alternatingBackground, isMobile }
 */
export const getBarChartDefaults = ({ alternatingBackground, isMobile }: GetBarChartDefaults) => {
  return {
    responsive: true,
    animation: {
      duration: 0
    },
    categoryPercentage: 0.75,
    barPercentage: isMobile ? 0.85 : 1,
    layout: {
      padding: {
        top: 40,
        left: 0,
        right: 0,
        bottom: 0
      }
    },
    scales: {
      y: {
        type: 'linear',
        grace: '20%',
        position: 'right',
        border: { display: false },
        ticks: {
          font: {
            family: "'Commuters Sans', sans-serif",
            size: 11
          },
          crossAlign: 'far',
          color: CHART_COLORS.textSubdued,
          padding: isMobile ? 4 : 7,
          maxTicksLimit: 6
        },
        grid: {
          drawBorder: false,
          drawTicks: false,
          color: (context: ScriptableScaleContext) => (context.tick.value === 0 ? CHART_COLORS.gridBaseline : CHART_COLORS.gridLine)
        }
      },
      x: {
        grid: {
          lineWidth: 0
        },
        ticks: {
          color: CHART_COLORS.textSubdued,
          font: {
            family: "'Commuters Sans', sans-serif",
            size: isMobile ? 9 : 10
          },
          minRotation: 0,
          maxRotation: 0
        }
      }
    },
    interaction: {
      intersect: !isMobile,
      mode: isMobile ? 'x' : 'index'
    },
    plugins: alternatingBackground
      ? {
          annotation: {
            common: {
              drawTime: 'beforeDraw'
            },
            annotations: alternatingBackground
          }
        }
      : undefined
  } as const
}
