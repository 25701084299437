import { CellType } from '../cell/types.ts'
import classNames from 'classnames'
import styles from './Cell.module.scss'

const Cell = ({ variant, title, children, color }: CellType) => {
  return (
    <div
      title={title}
      className={classNames(styles.cell, { [styles[variant!]]: !!variant })}
      style={color ? { color: `var(--text-${color})` } : {}}
    >
      {children}
    </div>
  )
}

export default Cell
