import { createElement, ForwardedRef, forwardRef } from 'react'
import { Heading } from '@boltenergy-be/design-system'
import styles from './Card.module.scss'
import classNames from 'classnames'
import { AvailableCardTags, CardColors, CardHeaderProps, CardProps, CardTitleProps } from './types'
import useWindowSize from 'hooks/useWindowSize.tsx'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'

const Title = ({ children, as = 'h2', variant = 'h5', weight = 400, ...props }: CardTitleProps) => {
  return <Heading {...{ as, variant, weight, ...props }}>{children}</Heading>
}

const Header = ({ children, ...headerProps }: CardHeaderProps) => {
  return (
    <header className={styles['card-header']} {...headerProps}>
      {children}
    </header>
  )
}

const Card = forwardRef(
  <Tag extends AvailableCardTags>(
    {
      border = true,
      image,
      children,
      className,
      as = 'div' as Tag,
      color = CardColors.WHITE,
      padding = 500,
      shadow = true,
      ...tagProps
    }: CardProps<Tag>,
    ref: ForwardedRef<HTMLElement>
  ) => {
    // Window size
    const { isTablet } = useWindowSize()

    // Constants
    const hasImageAndContent = !!image && !!children
    const containerPadding = typeof padding === 'number' ? padding : isTablet ? padding.mobile : padding.desktop

    return createElement(
      as,
      {
        className: classNames(
          styles.card,
          styles[color],
          { [styles['hide-border']]: !border, [styles['hide-shadow']]: !shadow },
          className
        ),
        ...tagProps,
        style: { ...tagProps.style, padding: hasImageAndContent ? 0 : `var(--spacing-${containerPadding})` },
        ref
      },
      <>
        {!!image && (
          <figure>
            {image.src ? (
              <img
                className={classNames(styles.img, { [styles['only-img']]: !!image && !children }, image.className)}
                src={image.src}
                alt={image.alt}
              />
            ) : (
              image.loading && (
                <LoadingSkeleton.Rectangle
                  width="100%"
                  height="100%"
                  className={classNames(styles['img-loader'], { [styles['only-img']]: !!image && !children })}
                />
              )
            )}
          </figure>
        )}
        {hasImageAndContent ? (
          <div data-id="card-content" className={styles.content} style={{ padding: `var(--spacing-${containerPadding})` }}>
            {children}
          </div>
        ) : (
          children
        )}
      </>
    )
  }
)

const CardWithSubcomponents = Object.assign(Card, { Title, Header })

export default CardWithSubcomponents
