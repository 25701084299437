import { useTranslation } from 'react-i18next'
import styles from './Addresses.module.scss'
import { useStoreDispatch, useStoreSelector } from 'hooks/store.ts'
import { formatContractStatus, isActiveContract } from 'utils/contracts.ts'
import Tag from 'components/Tag/Tag.tsx'
import { ContractStatus } from './types.ts'
import dayjs from 'dayjs'
import { BillingContract } from 'types/contracts.ts'
import { Button, Icon } from '@boltenergy-be/design-system'
import { useState } from 'react'
import mixpanel from 'mixpanel-browser'
import { setMoveFlow } from 'store/app/slice'
import { MoveFlows } from 'store/app/types'
import { routes } from 'types/routes'
import { AddressEvents, ContractFlowEvents, ContractFlowStartPoints, ContractFlowTrackingTypes } from 'types/tracking'
import { Link, useNavigate } from 'react-router-dom'
import PageLayout from 'layouts/page-layout/PageLayout.tsx'
import { capitalize } from 'utils/format.ts'
import { IS_BETA } from 'constants/envs.ts'
import { selectCurrentAccount } from 'store/contact/selectors.ts'
import { FaqSlug } from 'types/faq.ts'

const Addresses = () => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)
  const { billingContracts, isProducer, accounts, selected } = useStoreSelector((store) => store.contact)
  const dispatch = useStoreDispatch()

  // Local constants
  const { billingContracts: accountBillingContracts } = selectCurrentAccount({ accounts, selected })
  const inactiveContracts = accountBillingContracts.filter(
    (contract) => !isActiveContract(billingContracts[contract].serviceContracts.electricity)
  )
  const inactiveContractsCount = inactiveContracts.length

  // React router
  const navigate = useNavigate()

  // i18n
  const { t } = useTranslation('addresses')

  const [showInactive, setShowInactive] = useState(inactiveContractsCount === accountBillingContracts.length)

  /**
   * Get the contract status
   * @param {BillingContract} contract
   * @returns {ContractStatus[]}
   */
  const getContractStatus = ({ serviceContracts }: BillingContract): ContractStatus[] => {
    const electricityStatus = formatContractStatus(serviceContracts.electricity)
    const gasStatus = serviceContracts?.gas ? formatContractStatus(serviceContracts.gas) : null

    if (!gasStatus) {
      return [
        {
          color: electricityStatus.color,
          icons: ['electricity'],
          type: 'EL',
          text: t(`status.${electricityStatus.key}`, { date: formatStatusDate(electricityStatus.date) })
        }
      ]
    }

    if (JSON.stringify(electricityStatus) === JSON.stringify(gasStatus)) {
      return [
        {
          color: electricityStatus.color,
          icons: ['electricity', 'gas'],
          type: 'EL+GAS',
          text: t(`status.${electricityStatus.key}`, { date: formatStatusDate(electricityStatus.date) })
        }
      ]
    }

    return [
      {
        color: electricityStatus.color,
        icons: ['electricity'],
        type: 'EL',
        text: t(`status.${electricityStatus.key}`, { date: formatStatusDate(electricityStatus.date) })
      },
      {
        color: gasStatus.color,
        icons: ['gas'],
        type: 'GAS',
        text: t(`status.${gasStatus.key}`, { date: formatStatusDate(gasStatus.date) })
      }
    ]
  }

  const formatStatusDate = (date: string | undefined) => {
    return dayjs(date).locale(language).format('D MMMM YYYY')
  }

  return (
    <PageLayout title={t('title')} classes={{ body: styles.body }} faqCategories={[FaqSlug.MY_ADDRESSES]}>
      <ul className={styles['address-list']}>
        {accountBillingContracts
          .filter((contract) => (showInactive ? true : isActiveContract(billingContracts[contract].serviceContracts.electricity)))
          .map((c) => {
            const contract = billingContracts[c]
            const statuses = getContractStatus(contract)

            return (
              <li key={contract.contractNumber}>
                <Link
                  to={routes.ADDRESS_DETAIL.replace(':id', contract.contractNumber)}
                  onClick={() => mixpanel.track(AddressEvents.OPEN_ADDRESS_DETAIL)}
                  className={styles.address}
                >
                  <div className={styles['address-first-line']}>
                    <strong>
                      {capitalize(contract.serviceContracts.electricity.deliveryPoint.address.streetName)}{' '}
                      {contract.serviceContracts.electricity.deliveryPoint.address.streetNumber}
                      {contract.serviceContracts.electricity.deliveryPoint.address.streetBox
                        ? `, ${contract.serviceContracts.electricity.deliveryPoint.address.streetBox}`
                        : ''}
                    </strong>
                    <Icon name="chevronRight" className={styles.icon} />
                  </div>
                  <span>
                    {contract.serviceContracts.electricity.deliveryPoint.address.postalCode}{' '}
                    {capitalize(contract.serviceContracts.electricity.deliveryPoint.address.townName)}
                  </span>
                  <div className={styles.statuses}>
                    {statuses.map((status) => (
                      <Tag key={`${contract.contractNumber}-${status.type}`} size="small" {...status}>
                        {status.text}
                      </Tag>
                    ))}
                  </div>
                </Link>
              </li>
            )
          })}
      </ul>

      {!IS_BETA && !isProducer && (
        <Button
          isFullwidthMobile
          leadingIcon="homePlus"
          variant="secondary"
          className={styles['action-button']}
          onClick={() => {
            const flow = MoveFlows.START
            mixpanel.track(ContractFlowEvents.START, { flow, type: ContractFlowTrackingTypes.ADD, from: ContractFlowStartPoints.ADDRESSES })
            dispatch(setMoveFlow(flow))
            navigate(routes.CONTRACTS_ADD)
          }}
        >
          {t('myContract.addContract', { ns: 'contract' })}
        </Button>
      )}

      {!showInactive && inactiveContractsCount > 0 && (
        <div className={styles.footer}>
          <small className="text-subdued">
            {inactiveContractsCount === 1
              ? t('inactiveSingle')
              : inactiveContractsCount > 1 && t('inactivePlural', { amount: inactiveContractsCount })}
          </small>
          <Button variant="secondary" onClick={() => setShowInactive(true)} representation="link" size="small">
            {t('showInactive')}
          </Button>
        </div>
      )}
    </PageLayout>
  )
}

export default Addresses
