import { useTranslation } from 'react-i18next'
import Dotdotdot from 'react-dotdotdot'
import classNames from 'classnames'
import { getProducerLocation, parseProducerImageUrl, parseProducerReadMoreLink } from 'utils/producer'
import { SelectProducerCardProps } from './types'
import styles from './SelectProducerCard.module.scss'
import ProducerLocation from 'components/ProducerLocation/ProducerLocation'
import { Heading } from '@boltenergy-be/design-system'
import Tag from 'components/Tag/Tag'
import { useStoreSelector } from 'hooks/store.ts'
import { getLowerCaseLanguage } from 'utils/app.ts'
import { ProducerEnergyType } from 'types/producer.ts'

const SelectProducerCard = ({ producer, activeProducerId, handleSelectProducer, tag }: SelectProducerCardProps) => {
  // REDUX STORE
  const { language } = useStoreSelector((store) => store.app)
  const lowerCaseLanguage = getLowerCaseLanguage(language)

  // i18n
  const { t } = useTranslation()

  return (
    <button
      className={classNames(styles.container, { [styles.active]: producer.id === activeProducerId })}
      onClick={() => handleSelectProducer(producer)}
    >
      {!!tag && (
        <Tag color="blue" size="small" className={styles.tag}>
          {tag}
        </Tag>
      )}
      <img
        className={styles.image}
        src={parseProducerImageUrl(producer.images?.profile?.url, 600)}
        alt={`${producer.name[lowerCaseLanguage]} thumbnail`}
      />
      <div className={styles.content}>
        <ProducerLocation
          energyType={producer.energyType}
          location={getProducerLocation(lowerCaseLanguage, producer?.location)}
          energyName={t(`energyType.${producer.energyType || ProducerEnergyType.WIND}`, { ns: 'producer' })}
        />

        <div>
          <Heading as="h3" variant="h5" className="mb-200">
            <Dotdotdot clamp={1}>{producer.name[lowerCaseLanguage]}</Dotdotdot>
          </Heading>
          <div className={styles.text}>
            {producer.description[lowerCaseLanguage]}{' '}
            <a
              href={parseProducerReadMoreLink(producer.slug, language)}
              rel="noopener noreferrer"
              target="_blank"
              className={styles['read-more']}
              onClick={(e) => e.stopPropagation()}
            >
              {t('readMore')}
            </a>
          </div>
        </div>
      </div>
    </button>
  )
}

export default SelectProducerCard
