import { BoltAmbitionConstant, Province, ProvinceWithCities, Region } from 'pages/App/energy-overview/types'
import { Language } from 'store/app/types.ts'

export const STATIC_MAPS_STYLE =
  'style=element:geometry%7Ccolor:0xf5f5f5&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x616161&style=element:labels.text.stroke%7Ccolor:0xf5f5f5&style=feature:administrative.land_parcel%7Cvisibility:off&style=feature:administrative.land_parcel%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:administrative.neighborhood%7Cvisibility:off&style=feature:poi%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:poi%7Celement:labels.text%7Cvisibility:off&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.business%7Cvisibility:off&style=feature:poi.park%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:road%7Celement:geometry%7Ccolor:0xffffff&style=feature:road%7Celement:labels%7Cvisibility:off&style=feature:road%7Celement:labels.icon%7Cvisibility:off&style=feature:road.arterial%7Celement:labels%7Cvisibility:off&style=feature:road.arterial%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:road.highway%7Celement:geometry%7Ccolor:0xdadada&style=feature:road.highway%7Celement:labels%7Cvisibility:off&style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:road.local%7Cvisibility:off&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:transit%7Cvisibility:off&style=feature:transit.line%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:transit.station%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:water%7Celement:geometry%7Ccolor:0xc9c9c9&style=feature:water%7Celement:labels.text%7Cvisibility:off&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x9e9e9e'

export const BOLT_AMBITIONS: BoltAmbitionConstant[] = [
  {
    amount: 25_000,
    [Region.FLA]: 'Diest',
    [Region.WAL]: 'Tubize',
    [Region.BRU]: { [Language.NL]: 'Oudergem', [Language.FR]: 'Auderghem' }
  },
  {
    amount: 50_000,
    [Region.FLA]: 'Beveren',
    [Region.WAL]: 'Verviers',
    [Region.BRU]: { [Language.NL]: 'Vorst', [Language.FR]: 'Forest' }
  },
  {
    amount: 75_000,
    [Region.FLA]: 'Kortrijk',
    [Region.WAL]: 'La Louvière',
    [Region.BRU]: { [Language.NL]: 'Elsene', [Language.FR]: 'Ixelles' }
  },
  {
    amount: 100_000,
    [Region.FLA]: 'Leuven',
    [Region.WAL]: 'Namur',
    [Region.BRU]: { [Language.NL]: 'Schaarbeek', [Language.FR]: 'Schaerbeek' }
  },
  {
    amount: 200_000,
    [Region.FLA]: 'Gent',
    [Region.WAL]: 'Charleroi',
    [Region.BRU]: { [Language.NL]: 'Brussel', [Language.FR]: 'Bruxelles' }
  }
]

// Underscores in a number field is a type of number format to make it more readable.
// This does not affect the value of the number, it's only visual.
export const PROVINCES_WITH_CITIES: ProvinceWithCities[] = [
  {
    province: Province.BRU, // Brussel
    region: Region.BRU,
    cities: [
      { name: { [Language.NL]: 'Brussel', [Language.FR]: 'Bruxelles' }, population: 186_916, emission: 281_458_363 },
      { name: { [Language.NL]: 'Schaarbeek', [Language.FR]: 'Schaerbeek' }, population: 131_451, emission: 197_939_092 },
      { name: { [Language.NL]: 'Elsene', [Language.FR]: 'Ixelles' }, population: 87_488, emission: 131_739_547 },
      { name: { [Language.NL]: 'Vorst', [Language.FR]: 'Forest' }, population: 56_281, emission: 84_748_005 },
      { name: { [Language.NL]: 'Sint-Pieters-Woluwe', [Language.FR]: 'Woluwe-Saint-Pierre' }, population: 41_996, emission: 63_237_633 },
      { name: { [Language.NL]: 'Oudergem', [Language.FR]: 'Auderghem' }, population: 34_723, emission: 52_285_939 }
    ]
  },
  {
    province: Province.WVL, // West-Vlaanderen
    region: Region.FLA,
    cities: [
      { name: 'Brugge', population: 118_467, emission: 178_387_767 },
      { name: 'Kortrijk', population: 77_231, emission: 116_294_543 },
      { name: 'Roeselare', population: 63_763, emission: 96_014_410 },
      { name: 'Waregem', population: 38_535, emission: 58_026_054 },
      { name: 'Ieper', population: 34_954, emission: 52_633_780 },
      { name: 'Harelbeke', population: 28_614, emission: 43_086_999 }
    ]
  },
  {
    province: Province.OVL, // Oost-Vlaanderen
    region: Region.FLA,
    cities: [
      { name: 'Gent', population: 263_703, emission: 397_084_331 },
      { name: 'Aalst', population: 87_978, emission: 132_477_390 },
      { name: 'Sint Niklaas', population: 79_357, emission: 119_495_877 },
      { name: 'Beveren', population: 49_062, emission: 73_877_625 },
      { name: 'Lokeren', population: 42_145, emission: 63_461_997 },
      { name: 'Geraardsbergen', population: 33_970, emission: 51_152_071 }
    ]
  },
  {
    province: Province.ANT, // Antwerpen
    region: Region.FLA,
    cities: [
      { name: 'Antwerpen', population: 529_247, emission: 796_940_842 },
      { name: 'Mechelen', population: 86_911, emission: 130_870_700 },
      { name: 'Turnhout', population: 45_874, emission: 69_077_130 },
      { name: 'Heist-op-den-berg', population: 43_096, emission: 64_894_014 },
      { name: 'Geel', population: 40_781, emission: 61_408_084 },
      { name: 'Brasschaat', population: 38_211, emission: 57_538_175 }
    ]
  },
  {
    province: Province.LIM, // Limburg
    region: Region.FLA,
    cities: [
      { name: 'Hasselt', population: 79_089, emission: 119_092_322 },
      { name: 'Genk', population: 66_673, emission: 100_396_292 },
      { name: 'Beringen', population: 46_882, emission: 70_594_978 },
      { name: 'Sint-Truiden', population: 40_557, emission: 61_070_785 },
      { name: 'Heusden-Zolder', population: 34_207, emission: 51_508_946 },
      { name: 'Houthalen-Helchteren', population: 30_498, emission: 45_923_929 }
    ]
  },
  {
    province: Province.VLB, // Vlaams-Brabant
    region: Region.FLA,
    cities: [
      { name: 'Leuven', population: 101_032, emission: 152_134_121 },
      { name: 'Vilvoorde', population: 45_715, emission: 68_837_708 },
      { name: 'Dilbeek', population: 43_732, emission: 65_851_704 },
      { name: 'Sint-Pieters-Leeuw', population: 35_061, emission: 52_794_900 },
      { name: 'Asse', population: 33_827, emission: 50_936_741 },
      { name: 'Diest', population: 24_370, emission: 36_696_378 }
    ]
  },
  {
    province: Province.WLB, // Waals-Brabant
    region: Region.WAL,
    cities: [
      { name: "Braine-l'Alleud", population: 40_093, emission: 60_372_093 },
      { name: 'Wavre', population: 34_826, emission: 52_441_037 },
      { name: 'Waterloo', population: 30_379, emission: 45_744_738 },
      { name: 'Nivelles', population: 29_032, emission: 43_716_424 },
      { name: 'Tubize', population: 27_338, emission: 43_716_424 },
      { name: 'Rixensart', population: 22_764, emission: 34_278_061 }
    ]
  },
  {
    province: Province.HAI, // Henegouwen
    region: Region.WAL,
    cities: [
      { name: 'Charleroi', population: 201_837, emission: 303_926_425 },
      { name: 'Mons', population: 95_705, emission: 144_112_717 },
      { name: 'La Louvière', population: 80_944, emission: 121_885_583 },
      { name: 'Tournai', population: 68_795, emission: 103_591_603 },
      { name: 'Binche', population: 33_416, emission: 50_317_857 },
      { name: 'Courcelles', population: 31_053, emission: 46_759_649 }
    ]
  },
  {
    province: Province.NMR, // Namen
    region: Region.WAL,
    cities: [
      { name: 'Namur', population: 111_603, emission: 168_051_947 },
      { name: 'Sambreville', population: 28_318, emission: 42_641_282 },
      { name: 'Andenne', population: 27_669, emission: 41_664_017 },
      { name: 'Gembloux', population: 26_239, emission: 39_510_721 },
      { name: 'Jemeppe-sur-Sambre', population: 19_182, emission: 28_884_281 },
      { name: 'Ciney', population: 16_822, emission: 25_330_590 }
    ]
  },
  {
    province: Province.LIE, // Luik
    region: Region.WAL,
    cities: [
      { name: 'Liège', population: 196_296, emission: 295_582_780 },
      { name: 'Seraing', population: 63_787, emission: 96_050_550 },
      { name: 'Verviers', population: 55_159, emission: 83_058_496 },
      { name: 'Herstal', population: 40_157, emission: 60_468_464 },
      { name: 'Ans', population: 28_503, emission: 42_919_855 },
      { name: 'Flémalle', population: 26_402, emission: 39_756_167 }
    ]
  },
  {
    province: Province.LUX, // Luxemburg
    region: Region.WAL,
    cities: [
      { name: 'Arlon', population: 30_393, emission: 45_765_820 },
      { name: 'Marche-en-Famenne', population: 17_658, emission: 26_589_440 },
      { name: 'Aubange', population: 17_251, emission: 25_976_578 },
      { name: 'Bastogne', population: 16_295, emission: 24_537_032 },
      { name: 'Durbuy', population: 11_531, emission: 17_363_395 },
      { name: 'Libramont-Chevigny', population: 11_482, emission: 17_289_611 }
    ]
  }
]
