import classNames from 'classnames'
import styles from './SmallProducerCard.module.scss'
import { SmallCardProps } from 'components/SmallProducerCard/types'
import { Heading } from '@boltenergy-be/design-system'
import ProducerLocation from 'components/ProducerLocation/ProducerLocation.tsx'

const SmallProducerCard = ({ className, title, inverted, energyType, location }: SmallCardProps) => {
  return (
    <div className={classNames(styles.card, { [styles.inverted]: inverted }, className)}>
      {energyType && <ProducerLocation energyType={energyType} location={location} />}
      {title && (
        <Heading as="h3" variant="h6" className={inverted ? 'mb-200' : 'mt-200'}>
          {title}
        </Heading>
      )}
    </div>
  )
}

export default SmallProducerCard
