import {
  CustomerType,
  ELProduct,
  NGProduct,
  Product,
  ProductBillingOptions,
  ProductConfigOption,
  ProductConfigOptions,
  ProductContactOptions,
  ProductInstalments,
  ProductTypes
} from 'types/products'
import { Region } from 'api/types'

import { SourceType } from 'types/user'

import { EnergyType } from 'types/contracts.ts'

export const BOLT_GO_PRICES = {
  [EnergyType.ELECTRICITY]: 1500,
  [EnergyType.GAS]: 2500
}

export const PRODUCTS_CONFIG: { [key in Product]: ProductConfigOptions } = {
  // BOLT EARLY BIRD OFFLINE
  [Product.EARLY_BIRD_OFFLINE]: {
    [ProductConfigOption.TYPE]: ProductTypes.VARIABLE,
    [ProductConfigOption.PLATFORM_COST]: {
      [CustomerType.RESIDENTIAL]: 8.99,
      [CustomerType.PROFESSIONAL]: 8.99
    },
    [ProductConfigOption.INSTALMENT_PERIODICITY]: ProductInstalments.MONTHLY,
    [ProductConfigOption.DIRECT_DEBIT_MANDATORY]: false,
    [ProductConfigOption.CONTACT_OPTIONS]: [ProductContactOptions.DIGITAL, ProductContactOptions.PHONE],
    [ProductConfigOption.BILLING_OPTIONS]: [ProductBillingOptions.DIGITAL, ProductBillingOptions.PAPER],
    [ProductConfigOption.AVAILABLE_IN_REGIONS]: {
      [SourceType.PULL]: [],
      [SourceType.PUSH]: []
    },
    [ProductConfigOption.PRODUCT_CODES]: {
      [EnergyType.ELECTRICITY]: ELProduct.EarlyBirdOff,
      [EnergyType.GAS]: NGProduct.EarlyBirdOff
    }
  },

  // BOLT EARLY BIRD ONLINE
  [Product.EARLY_BIRD_ONLINE]: {
    [ProductConfigOption.TYPE]: ProductTypes.VARIABLE,
    [ProductConfigOption.PLATFORM_COST]: {
      [CustomerType.RESIDENTIAL]: 5.99,
      [CustomerType.PROFESSIONAL]: 5.99
    },
    [ProductConfigOption.INSTALMENT_PERIODICITY]: ProductInstalments.MONTHLY,
    [ProductConfigOption.DIRECT_DEBIT_MANDATORY]: false,
    [ProductConfigOption.CONTACT_OPTIONS]: [ProductContactOptions.DIGITAL],
    [ProductConfigOption.BILLING_OPTIONS]: [ProductBillingOptions.DIGITAL],
    [ProductConfigOption.AVAILABLE_IN_REGIONS]: {
      [SourceType.PULL]: [],
      [SourceType.PUSH]: []
    },
    [ProductConfigOption.PRODUCT_CODES]: {
      [EnergyType.ELECTRICITY]: ELProduct.EarlyBirdOn,
      [EnergyType.GAS]: NGProduct.EarlyBirdOn
    }
  },

  // BOLT VARIABLE ONLINE
  [Product.VARIABLE_ONLINE]: {
    [ProductConfigOption.TYPE]: ProductTypes.VARIABLE,
    [ProductConfigOption.PLATFORM_COST]: {
      [CustomerType.RESIDENTIAL]: 7.99,
      [CustomerType.PROFESSIONAL]: 9.99
    },
    [ProductConfigOption.INSTALMENT_PERIODICITY]: ProductInstalments.MONTHLY,
    [ProductConfigOption.DIRECT_DEBIT_MANDATORY]: false,
    [ProductConfigOption.CONTACT_OPTIONS]: [ProductContactOptions.DIGITAL],
    [ProductConfigOption.BILLING_OPTIONS]: [ProductBillingOptions.DIGITAL],
    [ProductConfigOption.AVAILABLE_IN_REGIONS]: {
      [SourceType.PULL]: [Region.FLANDERS],
      [SourceType.PUSH]: [Region.FLANDERS]
    },
    [ProductConfigOption.PRODUCT_CODES]: {
      [EnergyType.ELECTRICITY]: ELProduct.VariableOn,
      [EnergyType.GAS]: NGProduct.VariableOn
    }
  },

  // BOLT VARIABLE
  [Product.VARIABLE_OFFLINE]: {
    [ProductConfigOption.TYPE]: ProductTypes.VARIABLE,
    [ProductConfigOption.PLATFORM_COST]: {
      [CustomerType.RESIDENTIAL]: 10.99,
      [CustomerType.PROFESSIONAL]: 13.99
    },
    [ProductConfigOption.INSTALMENT_PERIODICITY]: ProductInstalments.MONTHLY,
    [ProductConfigOption.DIRECT_DEBIT_MANDATORY]: false,
    [ProductConfigOption.CONTACT_OPTIONS]: [ProductContactOptions.PHONE, ProductContactOptions.DIGITAL],
    [ProductConfigOption.BILLING_OPTIONS]: [ProductBillingOptions.PAPER, ProductBillingOptions.DIGITAL],
    [ProductConfigOption.AVAILABLE_IN_REGIONS]: {
      [SourceType.PULL]: [Region.FLANDERS, Region.BRUSSELS, Region.WALLONIA],
      [SourceType.PUSH]: [Region.FLANDERS, Region.BRUSSELS, Region.WALLONIA]
    },
    [ProductConfigOption.PRODUCT_CODES]: {
      [EnergyType.ELECTRICITY]: ELProduct.VariableOff,
      [EnergyType.GAS]: NGProduct.VariableOff
    }
  },

  // BOLT FIXED
  [Product.FIX]: {
    [ProductConfigOption.TYPE]: ProductTypes.FIX,
    [ProductConfigOption.PLATFORM_COST]: {
      [CustomerType.RESIDENTIAL]: 10.99,
      [CustomerType.PROFESSIONAL]: 13.99
    },
    [ProductConfigOption.INSTALMENT_PERIODICITY]: ProductInstalments.MONTHLY,
    [ProductConfigOption.DIRECT_DEBIT_MANDATORY]: false,
    [ProductConfigOption.CONTACT_OPTIONS]: [ProductContactOptions.PHONE, ProductContactOptions.DIGITAL],
    [ProductConfigOption.BILLING_OPTIONS]: [ProductBillingOptions.PAPER, ProductBillingOptions.DIGITAL],
    [ProductConfigOption.AVAILABLE_IN_REGIONS]: {
      [SourceType.PULL]: [Region.FLANDERS],
      [SourceType.PUSH]: [Region.FLANDERS]
    },
    [ProductConfigOption.PRODUCT_CODES]: {
      [EnergyType.ELECTRICITY]: ELProduct.Fix,
      [EnergyType.GAS]: NGProduct.Fix
    }
  },

  // BOLT GO
  [Product.GO]: {
    [ProductConfigOption.TYPE]: ProductTypes.VARIABLE,
    [ProductConfigOption.PLATFORM_COST]: {
      [CustomerType.RESIDENTIAL]: 0.99,
      [CustomerType.PROFESSIONAL]: 0.99
    },
    [ProductConfigOption.INSTALMENT_PERIODICITY]: ProductInstalments.YEARLY,
    [ProductConfigOption.DIRECT_DEBIT_MANDATORY]: false,
    [ProductConfigOption.CONTACT_OPTIONS]: [ProductContactOptions.DIGITAL],
    [ProductConfigOption.BILLING_OPTIONS]: [ProductBillingOptions.DIGITAL],
    [ProductConfigOption.AVAILABLE_IN_REGIONS]: {
      [SourceType.PULL]: [Region.FLANDERS],
      [SourceType.PUSH]: [Region.FLANDERS]
    },
    [ProductConfigOption.PRODUCT_CODES]: {
      [EnergyType.ELECTRICITY]: ELProduct.Go,
      [EnergyType.GAS]: NGProduct.Go
    }
  }
}
