import { useTranslation } from 'react-i18next'
import { ChurnReasonInputs, ChurnReasons, TerminateStepProps, TerminateSteps } from '../../types'
import styles from '../../../Contracts.module.scss'
import FormButtons from 'features/contracts/components/form-buttons/FormButtons.tsx'
import { useForm } from 'react-hook-form'
import { Form, Heading, Radio, RadioList } from '@boltenergy-be/design-system'

const ChurnReason = ({ terminateData, setNextStep }: TerminateStepProps) => {
  // i18n
  const { t } = useTranslation('contracts')

  // React Hook form
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<{ reason: ChurnReasonInputs }>({
    defaultValues: {
      reason: terminateData[TerminateSteps.CHURN]
    }
  })

  /**
   * Handles the form submit
   * @param {{reason: ChurnReasonInputs}} data
   */
  const onSubmit = (data: { reason: ChurnReasonInputs }) => {
    setNextStep(data.reason, TerminateSteps.METER_DETAILS)
  }

  /**
   *
   * @param {ChurnReasons} reason
   * @returns {any}
   */
  const getReasonKey = (reason: ChurnReasons): keyof typeof ChurnReasons => {
    return Object.keys(ChurnReasons)[Object.values(ChurnReasons).indexOf(reason)] as keyof typeof ChurnReasons
  }

  return (
    <section className={styles['terminate-section']}>
      <Heading as="h1" variant="h4">
        {t('terminate.churn.title')}
      </Heading>
      <Form onSubmit={handleSubmit(onSubmit)} className={styles['narrow-grid']}>
        {/* MY SITUATION */}
        <RadioList label={t('terminate.churn.label')} error={errors?.reason?.message}>
          {Object.values(ChurnReasons).map((churnReason) => (
            <Radio
              {...register('reason', { required: t('required', { ns: 'validation' }) })}
              onChange={(e) => setValue('reason', e.currentTarget.value as ChurnReasons)} //still required as data setting through register has issues otherwise
              name="churnReason"
              key={churnReason}
              id={getReasonKey(churnReason)}
              value={churnReason}
              label={t(`terminate.churn.reasons.${churnReason}`)}
            />
          ))}
        </RadioList>

        <FormButtons currentStep={TerminateSteps.CHURN} />
      </Form>
    </section>
  )
}

export default ChurnReason
