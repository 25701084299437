import styles from './EditLayout.module.scss'
import { PropsWithChildren } from 'react'
import { EditLayoutProps } from './types.ts'
import { useNavigate } from 'react-router'
import { Button, Heading } from '@boltenergy-be/design-system'
import useWindowSize from 'hooks/useWindowSize.tsx'
import classNames from 'classnames'

const EditLayout = ({ children, title, classes }: PropsWithChildren<EditLayoutProps>) => {
  // Router
  const navigate = useNavigate()

  // Window size
  const { isTablet } = useWindowSize()

  return (
    <>
      <header className={classNames(styles.header, classes?.header)}>
        <Button size={isTablet ? 'small' : 'large'} variant="tertiary" leadingIcon="arrowLeft" onClick={() => navigate(-1)} />

        <div className={styles['title-wrapper']}>
          {typeof title === 'string' ? (
            <Heading as="h1" variant={isTablet ? 'h6' : 'h4'} weight={600}>
              {title}
            </Heading>
          ) : (
            title
          )}
        </div>
      </header>

      <div className={classNames(styles.wrapper, classes?.body)}>{children}</div>
    </>
  )
}

export default EditLayout
