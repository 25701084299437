export type GetInvoicesStatusConfigPayload = {
  balance: number
  usesDirectDebit: boolean
  hasExpiredInvoices: boolean
}

export type InvoiceStatusTextKeys = 'open' | 'neutral' | 'directDebit'

export enum InvoicesStatus {
  BREAK_EVEN = 'breakEven',
  IS_GETTING_MONEY_BACK = 'isGettingMoneyBack',
  HAS_UNPAID_INVOICES = 'hasUnpaidInvoices',
  DIRECT_DEBIT = 'directDebit',
  DIRECT_DEBIT_WITH_UNPAID_INVOICES = 'directDebitWithUnpaidInvoices'
}
