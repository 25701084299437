import { PropsWithClassName } from 'types/utility.ts'

export type FeedbackWidgetProps = PropsWithClassName<{
  id: string
  openFeedbackWidget?: boolean
  showTrigger?: boolean
  data?: {
    [key: string]: unknown
  }
  isBetaFeedback?: boolean
}>

export enum FeedbackWidgetStep {
  RATING = 'rating',
  FEEDBACK = 'feedback',
  USER_TESTING = 'user-testing'
}
