import { AvailableTableCells, TableEntryData, TableProps } from './types'
import styles from './Table.module.scss'
import classNames from 'classnames'
import TableHead from 'components/Table/TableHead.tsx'
import useWindowSize from 'hooks/useWindowSize.tsx'
import { Icon } from '@boltenergy-be/design-system'
import { SMALL_DESKTOP_BREAKPOINT } from 'constants/viewport.ts'

export const Table = <Cells extends AvailableTableCells>({
  data,
  className,
  onSort,
  sorting,
  showHeader,
  handleClickRow
}: TableProps<Cells>) => {
  // Window size
  const { isSmaller } = useWindowSize(SMALL_DESKTOP_BREAKPOINT)

  return data?.length ? (
    <table className={classNames(styles.table, className)}>
      <thead hidden={!showHeader}>
        <tr>
          {Object.entries(data[0].entries).map(([key, entry]) => (
            <TableHead
              key={key}
              cellKey={key as AvailableTableCells}
              hidden={(entry as TableEntryData).hidden || (isSmaller && (entry as TableEntryData).hideOnSmallDesktop)}
              header={(entry as TableEntryData).header}
              {...{ onSort, sorting }}
            />
          ))}
        </tr>
      </thead>

      <tbody>
        {data.map((row, rowIndex: number) => {
          const isClickable =
            isSmaller && Object.values(row.entries).some((cell) => (cell as TableEntryData).hideOnSmallDesktop) && !!handleClickRow

          return (
            <tr
              key={rowIndex}
              tabIndex={isClickable ? 0 : undefined}
              onClick={isClickable ? () => handleClickRow(rowIndex) : undefined}
              onKeyDown={
                isClickable
                  ? (event) => {
                      if (['Space', 'Enter'].includes(event.code)) handleClickRow(rowIndex)
                    }
                  : undefined
              }
              className={classNames({ [styles.clickable]: isClickable })}
            >
              {Object.values(row.entries)
                .filter(Boolean)
                .map((c, cellIndex) => {
                  const cell = c as TableEntryData

                  return (
                    <td key={cellIndex} className={cell.extraClasses} hidden={cell.hidden || (cell.hideOnSmallDesktop && isSmaller)}>
                      {cell.content}
                      {cell?.extraContent && cell.extraContent in row.entries && (
                        <small className={classNames('text-subdued', styles.truncate)}>
                          {((row.entries as any)[cell.extraContent] as TableEntryData)?.content}
                        </small>
                      )}
                    </td>
                  )
                })}
              {isClickable && (
                <td>
                  <Icon name="chevronRight" />
                </td>
              )}
            </tr>
          )
        })}
      </tbody>
    </table>
  ) : null
}
