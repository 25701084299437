import { Button, ButtonLoadingIndicator, Modal } from '@boltenergy-be/design-system'
import SearchBar from 'components/Search/SearchBar'
import SelectProducerCard from 'features/contracts/add/components/SelectProducerCard/SelectProducerCard'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { normalizeText } from 'utils/format'
import styles from './SelectProducerModal.module.scss'
import { SelectProducerModalProps } from './types'
import { useStoreSelector } from 'hooks/store'
import { useGetProducersQuery } from 'store/queries/bolt-api/producers'
import { getProducerLocation } from 'utils/producer.ts'
import { getLowerCaseLanguage } from 'utils/app.ts'
import { Producer, ProducerEnergyType } from 'types/producer.ts'

const SelectProducerModal = ({ isOpen, setClose, addContractData, setAddContractData }: SelectProducerModalProps) => {
  // REDUX STORE
  const { language } = useStoreSelector((store) => store.app)
  const lowerCaseLanguage = getLowerCaseLanguage(language)

  // Redux queries
  const { data, isLoading } = useGetProducersQuery()

  // i18n
  const { t } = useTranslation('contracts')

  // Local state
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [shownProducers, setShownProducers] = useState<number>(3)

  /**
   * Update producer in registration data state object
   * @param {Producer} producer
   */
  const handleSelectProducer = (producer: Producer) => {
    if (typeof setAddContractData !== 'undefined') {
      setAddContractData({
        ...addContractData,
        producer
      })
    }

    setClose()
  }

  // Memoized constants
  const searchableProducers = useMemo<Producer[]>(() => {
    const normalizedQuery = normalizeText(searchQuery)
    const filteredProducers = data?.producers.filter((p) => p.enabled && !p.isSoldOut) || []

    return filteredProducers.filter(
      (p) =>
        normalizeText(p?.name[lowerCaseLanguage])?.includes(normalizedQuery) ||
        normalizeText(getProducerLocation(lowerCaseLanguage, p?.location))?.includes(normalizedQuery) ||
        normalizeText(t(`energyType.${p.energyType || ProducerEnergyType.WIND}`, { ns: 'producer' }))?.includes(normalizedQuery)
    )
  }, [language, data, searchQuery, t])

  return (
    <Modal onRequestClose={setClose} isOpen={isOpen} title={t('add.steps.producer.chooseProducerModal.title')} className={styles.modal}>
      <p>{t('add.steps.producer.chooseProducerModal.description')}</p>

      <SearchBar
        disabled={isLoading}
        placeholder={t('add.steps.producer.chooseProducerModal.searchPlaceholder')}
        onInput={(value) => setSearchQuery(value)}
      />

      {isLoading ? (
        <ButtonLoadingIndicator />
      ) : (
        <>
          <ul className={styles.grid}>
            {searchableProducers.slice(0, shownProducers).map((producer) => (
              <li key={producer.id}>
                <SelectProducerCard
                  producer={producer}
                  activeProducerId={addContractData.producer?.id || ''}
                  handleSelectProducer={handleSelectProducer}
                />
              </li>
            ))}
          </ul>

          {shownProducers < searchableProducers.length && (
            <Button className="mx-auto" type="button" onClick={() => setShownProducers(shownProducers + 3)}>
              {t('add.steps.producer.chooseProducerModal.showMore')}
            </Button>
          )}
        </>
      )}
    </Modal>
  )
}

export default SelectProducerModal
