// Definition of the colors used in the design system (KEY = color primitive, VALUE = color token)
export enum DSChartColors {
  GREEN = 'green',
  YELLOW = 'yellow',
  ORANGE = 'orange',
  PURPLE = 'purple',
  PINK = 'pink',
  BLUE = 'blue',
  LIGHTBLUE = 'lightblue',
  GREY = 'grey'
}

export enum DSFunctionalColors {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  WARNING = 'warning'
}
