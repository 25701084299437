import { SalesOpportunity } from 'types/sales'

import { EnergyType } from 'types/contracts.ts'

export type EanLookupResponseBody = {
  electricity: string | null
  gas: string | null
}

export type GetCustomerBalancePayUrlResponseBody = {
  balancePayUrl: string
}

export type GetEanDataResponseBody = {
  energyType: EnergyType
  switchDate: string | null // ISO date
  invalidContractStartDate?: boolean
}

export type Town = {
  postalCode: number
  townName: string
  townCode: number
  mainTownName: string
  geoCode: string
  languageCode: 'NL' | 'FR'
}

export type Towns = Town[]

export type TownResponse = {
  towns: Town[]
  region: Region
}

export type OpportunitiesResponse = {
  opportunities: SalesOpportunity[]
}

export type GetContractConsumptionsResponseBody = {
  consumptionsCsv: string
}

// ENUMS

export enum Region {
  FLANDERS = 'Flanders',
  BRUSSELS = 'Brussels',
  WALLONIA = 'Wallonia'
}

export enum SearchOpportunityTypes {
  CANCELLED = 'CANCELLED',
  EFFECTIVE = 'EFFECTIVE',
  TERMINATED = 'TERMINATED',
  TO_BE_CONFIRMED = 'TO_BE_CONFIRMED'
}
