import { SalesStore } from 'store/sales/types.ts'
import { Language } from 'store/app/types.ts'

export const initialSalesStore: SalesStore = {
  _id: '',
  agentId: '',
  activated: false,
  firstName: '',
  lastName: '',
  email: '',
  language: Language.NL,
  partner: {
    id: '',
    name: '',
    allowAccessToAllCustomers: false
  },
  loading: true,
  error: null
}
