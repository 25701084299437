import { useState } from 'react'
import Cookies from 'js-cookie'
import { InstalmentInfoModalProps } from './types'
import { Button } from '@boltenergy-be/design-system'
import { useTranslation } from 'react-i18next'
import styles from './InstalmentInfoModal.module.scss'
import { NEVER_SHOW_INSTALMENT_INFO_MODAL } from '../../constants'
import parse from 'html-react-parser'
import { getProductFromProductCode } from 'utils/products'
import { Product } from 'types/products'
import { useStoreSelector } from 'hooks/store.ts'
import { selectCurrentContracts } from 'store/contact/selectors.ts'
import Modal from 'components/Modals/Modal/Modal.tsx'

const InstalmentInfoModal = ({ isOpen, setClose }: InstalmentInfoModalProps) => {
  // Redux
  const contactStore = useStoreSelector((store) => store.contact)
  const { electricity } = selectCurrentContracts(contactStore).serviceContracts

  // Local state
  const [checkboxChecked, setCheckboxChecked] = useState<boolean>(false)

  // i18n
  const { t } = useTranslation('billing')

  // Constants
  const elProduct = getProductFromProductCode(electricity?.detail?.productCode)

  /**
   * Closes the modal and sets the cookie to never show it again
   */
  const closeModal = () => {
    if (checkboxChecked) {
      Cookies.set(NEVER_SHOW_INSTALMENT_INFO_MODAL, 'true', { expires: 365 })
    }

    setClose()
  }

  return (
    <Modal
      setClose={() => closeModal()}
      {...{ isOpen }}
      className={styles.modal}
      title={t('billingCycles.current.instalmentInfoModal.title')}
    >
      <p>{parse(t(`billingCycles.current.instalmentInfoModal.${elProduct === Product.FIX ? 'descriptionFix' : 'description'}`))}</p>

      <footer className={styles.footer}>
        <form>
          <label className={styles.checkbox}>
            <input type="checkbox" checked={checkboxChecked} onChange={() => setCheckboxChecked(!checkboxChecked)} />
            <span>{t('billingCycles.current.instalmentInfoModal.checkbox')}</span>
          </label>
        </form>

        <Button onClick={closeModal}>{t('billingCycles.current.instalmentInfoModal.close')}</Button>
      </footer>
    </Modal>
  )
}

export default InstalmentInfoModal
