import styles from './AddressPicker.module.scss'
import { useEffect, useRef } from 'react'
import classNames from 'classnames'
import { AddressPickerProps } from './types'
import { formatAddress } from 'utils/user'
import { CSSTransition } from 'react-transition-group'
import useWindowSize from 'hooks/useWindowSize'
import { Banner, Icon } from '@boltenergy-be/design-system'
import useToggleWithClickOutside from 'hooks/useToggleWithClickOutside.ts'

const AddressPicker = ({ selectedContractId, setSelectedContractId, label, error, options }: AddressPickerProps) => {
  // Refs
  const dropdownRef = useRef<HTMLDivElement>(null)
  const { isOpen, setIsOpen } = useToggleWithClickOutside(dropdownRef)

  // Window size
  const { isMobile } = useWindowSize()

  /**
   * Handles the tile click
   *
   * @param {string} selectedId
   * @returns void
   */
  const handleOptionClick = (selectedId: string) => {
    setIsOpen(false)
    setSelectedContractId(selectedId)
  }

  /**
   * Toggles no-scroll-mobile class on body
   */
  useEffect(() => {
    if (isOpen) document.body.classList.add('no-scroll-mobile')

    return () => {
      if (isOpen) document.body.classList.remove('no-scroll-mobile')
    }
  }, [isOpen])

  return Object.keys(options).length ? (
    <>
      <div className={styles['address-dropdown-group']}>
        <span className={styles.label}>{label}</span>

        <div ref={dropdownRef} className={styles['dropdown-wrapper']}>
          {Object.keys(options).length === 1 ? (
            <div className={styles['single-address']}>
              {formatAddress(options[selectedContractId].serviceContracts.electricity.deliveryPoint.address)}
            </div>
          ) : (
            <>
              <button className={styles.toggle} type="button" aria-haspopup="true" onClick={() => setIsOpen(!isOpen)}>
                {formatAddress(options[selectedContractId].serviceContracts.electricity.deliveryPoint.address)}

                <Icon name="chevronDown" className={classNames(styles.icon, { [styles.open]: isOpen })} />
              </button>

              <CSSTransition in={isOpen} timeout={300} classNames="mobile-slide-up" unmountOnExit>
                <>
                  {isOpen && (
                    <ul className={styles.menu} role="listbox" aria-expanded={isOpen}>
                      {isOpen && isMobile && <li className={styles['menu-title']}>{label}</li>}
                      {Object.values(options).map((contract) => (
                        <li
                          key={contract.contractNumber}
                          className={classNames(styles['menu-item'], { [styles.selected]: contract.contractNumber === selectedContractId })}
                        >
                          <button
                            role="option"
                            aria-selected={contract.contractNumber === selectedContractId}
                            onClick={() => handleOptionClick(contract.contractNumber)}
                          >
                            {formatAddress(contract.serviceContracts.electricity.deliveryPoint.address)}
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              </CSSTransition>
            </>
          )}
        </div>
      </div>
      {isMobile && (
        <CSSTransition in={isOpen} timeout={300} classNames="fade-in" unmountOnExit>
          <div className="overlay" />
        </CSSTransition>
      )}
    </>
  ) : (
    <Banner type="blocking" title={error.title}>
      <p>{error.description}</p>
    </Banner>
  )
}

export default AddressPicker
