import { Language } from 'store/app/types'
import { useTranslation } from 'react-i18next'
import styles from './InvitedFriends.module.scss'
import dayjs from 'dayjs'
import FriendStatus from 'pages/App/referral/components/FriendStatus/FriendStatus'
import { useStoreSelector } from 'hooks/store.ts'
import { selectContact } from 'store/contact/selectors.ts'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton'
import { useGetContactReferralsQuery } from 'store/queries/bolt-api/contacts'

const Friends = () => {
  // REDUX
  const { language } = useStoreSelector((store) => store.app)
  const { contact } = useStoreSelector((store) => store.contact)
  const { sfId } = selectContact({ contact })

  // Dayjs
  dayjs.locale(language === Language.FR ? 'fr' : 'nl')

  // Redux queries
  const { data: referrals, isLoading, isError } = useGetContactReferralsQuery(sfId)

  // constants
  const friendsAmount = referrals?.filter((referral) => !referral.pending)?.length ?? 0
  const singularOrPlural = friendsAmount === 1 ? 'singular' : 'plural'

  // i18n
  const { t } = useTranslation('referral')

  return (
    <article className={styles['card-group']}>
      <section className={styles['friends-card']}>
        {isLoading ? (
          <LoadingSkeleton>
            <LoadingSkeleton.Paragraph lines={1} />
            <div className={styles['friends-content']}>
              {Array.from({ length: 3 }).map((_, index) => (
                <div key={index} className={styles['loading-status']}>
                  <LoadingSkeleton.Rectangle width="40px" height="40px" />
                  <LoadingSkeleton.Rectangle width="100%" height="40px" />
                </div>
              ))}
            </div>
          </LoadingSkeleton>
        ) : isError || !friendsAmount ? (
          <p className={styles['no-friends']}>{t('referrals.noFriends.paragraph')}</p>
        ) : (
          <>
            <p className={styles['friends-header']}>{t(`referrals.hasFriends.${singularOrPlural}.paragraph`, { friendsAmount })}</p>
            <ul className={styles['friends-content']}>
              {/* Pending referrals first */}
              {[...(referrals ?? [])]
                .sort((a, b) => (a.pending === b.pending ? 0 : a.pending ? -1 : 1))
                .map((referral, index) => (
                  <FriendStatus key={index} referral={referral} />
                ))}
            </ul>
          </>
        )}
      </section>
    </article>
  )
}

export default Friends
