import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Email } from 'types/types'
import request, { getAxiosRequestConfig } from 'utils/request'
import { Methods } from 'types/request'
import { useStoreSelector } from 'hooks/store'
import styles from './Contact.module.scss'
import { Banner, Heading, Form, TextArea, TextField, Button } from '@boltenergy-be/design-system'
import { selectContact, selectCurrentAccount, selectCurrentContracts } from 'store/contact/selectors.ts'
import { useGetProducerContactQuery } from 'store/queries/cms-api'
import LoadingCard from 'components/LoadingCard/LoadingCard'
import Link from 'components/Link/Link'
import { BOLT_EMAIL_CONTACT, BOLT_PHONE_CONTACT } from 'constants/constants'
import mixpanel from 'mixpanel-browser'
import { SupportEvents } from 'types/tracking.ts'

const Contact = () => {
  // REDUX STORE
  const { billingContracts, contact, selected, accounts, isProducer } = useStoreSelector((store) => store.contact)
  const { language } = useStoreSelector((store) => store.app)
  const { email } = selectContact({ contact })
  const selectedAccount = selectCurrentAccount({ selected, accounts })

  // Redux Queries
  const { data, isLoading } = useGetProducerContactQuery({ contactId: selectedAccount.ownerId || '' }, { skip: !isProducer })

  // React Hooks
  const { t } = useTranslation('support')
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Email>()

  // Local state
  const [status, setStatus] = useState<'submitted' | 'failed' | 'sending' | null>(null)

  // Local constants
  const contactPhone = isProducer && data?.contact.phone ? data?.contact.phone : BOLT_PHONE_CONTACT
  const contactEmail = isProducer && data?.contact.email ? data?.contact.email : BOLT_EMAIL_CONTACT[language]

  /**
   * Handles the on submit after valdiation
   *
   * @param data
   */
  const onSubmit = async (data: Email) => {
    setStatus('sending')
    const { subject, message } = data
    const from: string = email
    const { electricity } = selectCurrentContracts({ billingContracts, selected }).serviceContracts
    const deliveryAddress = electricity.deliveryPoint.address

    try {
      const { success } = await request(
        getAxiosRequestConfig(Methods.POST, '/contact/support', { email: from, subject, message, deliveryAddress })
      )

      if (success) {
        setStatus('submitted')
      } else {
        setStatus('failed')
      }

      mixpanel.track(SupportEvents.SUBMIT_CONTACT_FORM, { status: success ? 'submitted' : 'failed' })
    } catch (err) {
      setStatus('failed')
    }
  }

  return isLoading ? (
    <LoadingCard />
  ) : (
    <div className={styles['contact-container']}>
      <img
        src={
          isProducer
            ? data?.contact.avatar.url
            : 'https://res.cloudinary.com/bolt-energie/image/upload/v1705664314/website/common/customer-care.png'
        }
        alt={isProducer ? data?.contact.avatar.alternativeText : 'Our support team'}
        className={styles.image}
      />

      <div className={styles.information}>
        <section className={styles.contact}>
          {isProducer && (
            <Heading as="h2" variant="h5">
              {data?.contact.firstName} {data?.contact.lastName}
            </Heading>
          )}
          <p className="text-lg">{isProducer ? t('contact.contactPerson', { name: data?.contact.firstName }) : t('contact.team')}</p>
          <ul className={styles['contact-cards']}>
            <li>
              <Link representation="button" isFullwidth variant="secondary" href={`mailto:${contactEmail}`}>
                <strong>{contactEmail}</strong>
                <small>{t('contact.emailAvailability')}</small>
              </Link>
            </li>
            <li>
              <Link representation="button" isFullwidth variant="secondary" href={`tel:${contactPhone}`}>
                <strong>{contactPhone}</strong>
                <small>{t('contact.phoneAvailability')}</small>
              </Link>
            </li>
          </ul>
        </section>

        <Form onSubmit={handleSubmit(onSubmit)} className={styles['contact-form']}>
          <Heading as="h2" variant="h5" weight={400}>
            {t('contact.orMessage')}
          </Heading>

          <TextField
            label={t('contact.subject')}
            id="subject"
            {...register('subject', {
              required: t('required', { ns: 'validation' })
            })}
            maxLength={255}
            grid="full"
            error={errors?.subject?.type === 'required'}
          />

          <TextArea
            label={t('contact.message')}
            id="message"
            {...register('message', {
              required: t('required', { ns: 'validation' })
            })}
            grid="full"
            error={errors?.subject?.type === 'required'}
          />

          <Button disabled={!!status} loading={status === 'sending'} isFullwidth>
            {t('contact.send')}
          </Button>
        </Form>

        {(status === 'submitted' || status === 'failed') && (
          <Banner type={status === 'failed' ? 'blocking' : 'positive'}>
            {status === 'submitted' && t('contact.messageSent')}
            {status === 'failed' && t('error', { ns: 'common' })}
          </Banner>
        )}
      </div>
    </div>
  )
}

export default Contact
